import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import Traditions from './Traditions'

const GET_TRADITIONS = gql`
  query traditions {
    traditions {
      id
      titleRus
      titleKaz
      imageUrl
      traditionArticles {
        id
        order
        title
      }
    }
  }
`
const CREATE_TRADITION = gql`
  mutation createTradition($input: TraditionsInput) {
    createTradition(input: $input) {
      id
      titleRus
      titleKaz
      imageUrl
      traditionArticles {
        id
        order
        title
      }
    }
  }
`
const UPDATE_TRADITION = gql`
  mutation updateTradition($input: UpdateTraditionInput!) {
    updateTradition(input: $input) {
      id
      titleRus
      titleKaz
      imageUrl
      traditionArticles {
        id
        order
        title
      }
    }
  }
`

const DELETE_TRADITION = gql`
  mutation deleteTradition($id: ID!) {
    deleteTradition(id: $id) {
      id
      titleRus
      titleKaz
      imageUrl
      traditionArticles {
        id
        order
        title
      }
    }
  }
`
const GET_TRADITIONS_ARTICLES = gql`
  query traditionsArticles {
    traditionsArticles {
      id
      order
      title
      textShort
      text
    }
  }
`

const TraditionsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [traditions, setTraditions] = useState([])
  const [traditionsArticles, setTraditionsArticles] = useState([])

  const { loading: traditionsLoading } = useQuery(GET_TRADITIONS, {
    onCompleted: data => {
      setTraditions(data.traditions)
    },
    onError: error => console.log(error)
  })

  const { loading: articlesLoading } = useQuery(GET_TRADITIONS_ARTICLES, {
    onCompleted: data => {
      setTraditionsArticles(data.traditionsArticles)
    },
    onError: error => console.log(error)
  })

  const [createTradition] = useMutation(CREATE_TRADITION, {
    onError: error => console.log(error),
    update(cache, { data: { createTradition: newItem } }) {
      const { traditions } = cache.readQuery({
        query: GET_TRADITIONS
      })
      const newArray = traditions
        .filter(item => item.id !== newItem.id)
        .concat(newItem)
      setTraditions(newArray)
      cache.writeQuery({
        query: GET_TRADITIONS,
        data: {
          traditions: newArray
        }
      })
      hideLoading()
      toast.success('Традиция добавлена')
    }
  })

  const [updateTradition] = useMutation(UPDATE_TRADITION, {
    onError: error => console.log(error),
    update(cache, { data: { updateTradition: newItem } }) {
      const { traditions } = cache.readQuery({
        query: GET_TRADITIONS
      })
      const newArray = traditions.map(item =>
        item.id !== newItem.id ? item : newItem
      )
      setTraditions(newArray)
      cache.writeQuery({
        query: GET_TRADITIONS,
        data: {
          traditions: newArray
        }
      })
      hideLoading()
      toast.success('Традиция обновлена')
    }
  })

  const [deleteTradition] = useMutation(DELETE_TRADITION, {
    onError: error => console.log(error),
    update(cache, { data: { deleteTradition: newItem } }) {
      const { traditions } = cache.readQuery({
        query: GET_TRADITIONS
      })
      const newArray = traditions.filter(item => item.id !== newItem.id)
      setTraditions(newArray)
      cache.writeQuery({
        query: GET_TRADITIONS,
        data: {
          traditions: newArray
        }
      })
      hideLoading()
      toast.success('Традиция удалена')
    }
  })

  const createTraditionHandler = (
    form,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        createTradition({
          variables: {
            input: {
              titleRus: val.titleRus,
              titleKaz: val.titleKaz,
              imageFile,
              traditionArticles: val.traditionArticles
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateTraditionHandler = (
    form,
    traditionId,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateTradition({
          variables: {
            input: {
              id: traditionId,
              titleRus: val.titleRus,
              titleKaz: val.titleKaz,
              imageFile: imageFile,
              traditionArticles: val.traditionArticles
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteTraditionHandler = (traditionId, closeFunc) => {
    showLoading()
    deleteTradition({
      variables: {
        id: traditionId
      }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (traditionsLoading || articlesLoading) {
    return <Loading />
  }

  return (
    <Traditions
      traditions={traditions}
      traditionsArticles={traditionsArticles}
      createTraditionHandler={createTraditionHandler}
      updateTraditionHandler={updateTraditionHandler}
      deleteTraditionHandler={deleteTraditionHandler}
    />
  )
}

export default WithMainLayout(TraditionsContainer)
