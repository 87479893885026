import React from 'react'
import { Form, InputNumber } from 'antd'
import PropTypes from 'prop-types'

const UserLevelsForm = ({ form, currentLevel }) => {
  const FormItem = Form.Item

  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="currentLevel"
        label="Уровень"
        name="currentLevel"
        rules={[
          {
            required: true
          }
        ]}
        initialValue={currentLevel}
      >
        <InputNumber min={1} placeholder="Уровень" />
      </FormItem>
    </Form>
  )
}

UserLevelsForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  currentLevel: PropTypes.number
}

UserLevelsForm.defaultProps = {
  currentLevel: 1
}

export default UserLevelsForm
