import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Syllables from './Syllables'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_SYLLABLES = gql`
  query syllables {
    syllables {
      id
      syllable
      syllableAudioURL
    }
  }
`

const ADD_SYLLABLES = gql`
  mutation addSyllable($audioFile: FileUpload!, $input: SyllableInput!) {
    addSyllable(audioFile: $audioFile, input: $input) {
      id
      syllable
      syllableAudioURL
    }
  }
`

const UPDATE_LETTER = gql`
  mutation updateSyllable(
    $id: ID!
    $audioFile: FileUpload!
    $input: SyllableInput!
  ) {
    updateSyllable(id: $id, audioFile: $audioFile, input: $input) {
      id
      syllable
      syllableAudioURL
    }
  }
`

const DELETE_SYLLABLES = gql`
  mutation deleteSyllable($id: ID!) {
    deleteSyllable(id: $id) {
      id
      message
    }
  }
`

const LettersContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allSyllables, setAllSyllables] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_SYLLABLES)
  const [addSyllable, { error: errorAddSyllable }] = useMutation(
    ADD_SYLLABLES,
    {
      update(cache, { data: { addSyllable: addSyllableItem } }) {
        const { syllables } = cache.readQuery({
          query: GET_SYLLABLES
        })
        cache.writeQuery({
          query: GET_SYLLABLES,
          data: {
            syllables: syllables.concat(addSyllableItem)
          }
        })
        hideLoading()
        toast.success('Слог добавлена')
      }
    }
  )

  const [updateSyllable, { error: errorUpdateSyllable }] = useMutation(
    UPDATE_LETTER,
    {
      update(cache, { data: { updateSyllable: updateSyllableItem } }) {
        const { syllables } = cache.readQuery({
          query: GET_SYLLABLES
        })
        cache.writeQuery({
          query: GET_SYLLABLES,
          data: {
            syllables: syllables.concat(updateSyllableItem)
          }
        })
        hideLoading()
        toast.success('Слог обновлен')
      }
    }
  )
  const [deleteSyllable, { error: errorDeleteSyllable }] = useMutation(
    DELETE_SYLLABLES,
    {
      update(cache, { data: { updateSyllable: deletedSyllableItem } }) {
        const { syllable } = cache.readQuery({
          query: GET_SYLLABLES
        })
        hideLoading()
        cache.writeQuery({
          query: GET_SYLLABLES,
          data: {
            statusedLettersByIndex: syllable.filter(
              syllablee => syllablee.id !== deletedSyllableItem.id
            )
          }
        })
        toast.success('Слог удалена')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddSyllable) {
      console.log(errorAddSyllable)
    }
  }, [errorAddSyllable])

  React.useEffect(() => {
    if (errorDeleteSyllable) {
      console.log(errorDeleteSyllable)
    }
  }, [errorDeleteSyllable])

  React.useEffect(() => {
    if (errorUpdateSyllable) {
      console.log(errorUpdateSyllable)
    }
  }, [errorUpdateSyllable])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllSyllables(data.syllables)
    }
  }, [data, loading, error])

  const addSyllableHandler = (
    form,
    closeFunc,
    audioFile,
    setAudioFile,
    audioFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addSyllable({
          variables: {
            audioFile: audioFile,
            input: {
              syllable: val.syllable
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        hideLoading()
        audioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateSyllableHandle = (
    form,
    id,
    closeFunc,
    audioFile,
    setAudioFile,
    audioFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateSyllable({
          variables: {
            id: id,
            audioFile,
            input: {
              syllable: val.syllable
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        setAudioFile(null)
        audioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteSyllableHandler = (id, closeFunc) => {
    showLoading()
    deleteSyllable({
      variables: {
        id: id,
        input: {
          status: 'deleted'
        }
      }
    })
      .then(() => {
        closeFunc(false)
      })
      .finally(() => {
        closeFunc(false)
        refetch()
      })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Syllables
      syllables={allSyllables}
      addSyllable={addSyllable}
      refetch={refetch}
      addSyllableHandler={addSyllableHandler}
      updateSyllableHandle={updateSyllableHandle}
      deleteSyllableHandler={deleteSyllableHandler}
    />
  )
}

export default WithMainLayout(LettersContainer)
