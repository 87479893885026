import React from 'react'

import { Form, Input } from 'antd'
import PropTypes from 'prop-types'
import './Syllables.css'

const SyllablesForm = ({ form, syllable, setAudioFile, audioFileRef }) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Звучание слога </>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
      </div>

      <FormItem
        key="syllable"
        label="Слог"
        name="syllable"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите слог`
          }
        ]}
        initialValue={syllable}
      >
        <Input placeholder="Слово" />
      </FormItem>
    </Form>
  )
}

SyllablesForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

SyllablesForm.defaultProps = {
  syllable: ''
}

export default SyllablesForm
