import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import Proverbs from './Proverbs'

const GET_PROVERBS = gql`
  query statusedProverbs {
    statusedProverbs {
      id
      text
      type
      status
      audioUrl
    }
  }
`
const CREATE_PROVERB = gql`
  mutation create($input: ProverbsInput) {
    createProverb(input: $input) {
      id
      text
      type
      status
      audioUrl
    }
  }
`
const UPDATE_PROVERB = gql`
  mutation update($input: UpdateProverbsInput) {
    updateProverb(input: $input) {
      id
      text
      type
      status

      audioUrl
    }
  }
`
const DELETE_PROVERB = gql`
  mutation delete($id: ID!) {
    deleteProverb(id: $id) {
      id
    }
  }
`

const ProverbsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [proverbs, setProverbs] = useState([])

  const { loading } = useQuery(GET_PROVERBS, {
    onCompleted: data => {
      setProverbs(data.statusedProverbs)
    },
    onError: error => console.log(error)
  })

  const [createProverb] = useMutation(CREATE_PROVERB, {
    onError: error => console.log(error),
    update(cache, { data: { createProverb: newItem } }) {
      const { statusedProverbs } = cache.readQuery({
        query: GET_PROVERBS
      })
      const newArray = statusedProverbs
        .filter(item => item.id !== newItem.id)
        .concat(newItem)
      setProverbs(newArray)
      cache.writeQuery({
        query: GET_PROVERBS,
        data: {
          statusedProverbs: newArray
        }
      })
      hideLoading()
      toast.success('Скороговорка добавлена')
    }
  })

  const [updateProverb] = useMutation(UPDATE_PROVERB, {
    onError: error => console.log(error),
    update(cache, { data: { updateProverb: newItem } }) {
      const { statusedProverbs } = cache.readQuery({
        query: GET_PROVERBS
      })
      const newArray = statusedProverbs.map(item =>
        item.id !== newItem.id ? item : newItem
      )
      setProverbs(newArray)
      cache.writeQuery({
        query: GET_PROVERBS,
        data: {
          statusedProverbs: newArray
        }
      })
      hideLoading()
      toast.success('Скороговорка обновлена')
    }
  })

  const [deleteProverb] = useMutation(DELETE_PROVERB, {
    onError: error => console.log(error),
    update(cache, { data: { deleteProverb: newItem } }) {
      const { statusedProverbs } = cache.readQuery({
        query: GET_PROVERBS
      })
      const newArray = statusedProverbs.filter(item => item.id !== newItem.id)
      setProverbs(newArray)
      cache.writeQuery({
        query: GET_PROVERBS,
        data: {
          statusedProverbs: newArray
        }
      })
      hideLoading()
      toast.success('Скороговорка удалена')
    }
  })

  const createHandler = (
    form,
    closeFunc,
    audioFile,
    setAudioFile,
    audioFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        createProverb({
          variables: {
            input: {
              text: val.text,
              type: val.type,
              status: val.status,
              audioFile
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        setAudioFile(null)
        if (audioFileRef.current) audioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateHandler = (
    form,
    itemId,
    closeFunc,
    audioFile,
    setAudioFile,
    audioFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateProverb({
          variables: {
            input: {
              id: itemId,
              text: val.text,
              type: val.type,
              status: val.status,
              audioFile
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        setAudioFile(null)
        if (audioFileRef.current) audioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteHandler = (itemId, closeFunc) => {
    showLoading()
    deleteProverb({
      variables: {
        id: itemId
      }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Proverbs
      proverbs={proverbs}
      createHandler={createHandler}
      updateHandler={updateHandler}
      deleteHandler={deleteHandler}
    />
  )
}

export default WithMainLayout(ProverbsContainer)
