import React from 'react'

import { Form, Input, Select } from 'antd'
import PropTypes from 'prop-types'

const TagForm = ({ form, nameKZ, nameRU, status }) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="nameKZ"
        label="Название(каз)"
        name="nameKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Тэга на казахском`
          }
        ]}
        initialValue={nameKZ}
      >
        <Input placeholder="Имя" />
      </FormItem>
      <FormItem
        key="nameRU"
        label="Название(рус)"
        name="nameRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Тэга на русском`
          }
        ]}
        initialValue={nameRU}
      >
        <Input placeholder="Имя" />
      </FormItem>
    </Form>
  )
}

TagForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  nameKZ: PropTypes.string,
  nameRU: PropTypes.string
}

TagForm.defaultProps = {
  nameKZ: '',
  nameRU: '',
  status: 'active'
}

export default TagForm
