import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import LessonForm from './LessonsForm'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`
const Lessons = ({
  collectionId,
  course,
  lessons,
  loading,
  addLessonHandler,
  updateLessonHandler,
  deleteLessonHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [lessonItem, setLessonItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchLesson, setSearchLesson] = React.useState()
  const [listLessons, setListLessons] = React.useState(null)

  const [imageFile, setImageFile] = useState(null)
  const [audioKZFile, setAudioKZFile] = useState(null)
  const [audioRUFile, setAudioRUFile] = useState(null)

  const imageFileRef = React.useRef()
  const audioKZFileRef = React.useRef()
  const audioRUFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveLessons = lessons.map(lesson => {
      return {
        id: lesson.id,
        level: lesson.level,
        status: lesson.status,
        nameKZ: lesson.nameKZ,
        nameRU: lesson.nameRU,
        imageURL: lesson.imageURL,
        audioKZ: lesson.audioKZ,
        audioRU: lesson.audioRU,
        type: lesson.type,
        topics: lesson.topics,
        timerDuration: lesson.timerDuration,
        practiceTasks: lesson.practiceTasks,
        restrictions: lesson.restrictions
      }
    })
    let searchArray = descriptiveLessons

    if (searchLesson) {
      searchArray = searchArray.filter(lesson => {
        return lesson.lesson?.toLowerCase().includes(searchLesson)
      })
    }

    setListLessons(searchArray)
  }, [lessons, searchLesson])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'LVL',
      width: '1%',
      dataIndex: 'level'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        if (item.imageURL) {
          return (
            <>
              <Avatar shape="square" size={64} src={item.imageURL} />
            </>
          )
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Аудио(каз)',
      width: '5%',
      render: item => {
        if (item.audioKZ) {
          let audioLink = item.audioKZ
          if (!audioLink.startsWith('https://')) {
            audioLink = 'https://' + audioLink
          }
          return (
            <audio controls="controls">
              <source src={audioLink} type="audio/mpeg" />
            </audio>
          )
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Аудио(рус)',
      width: '5%',
      render: item => {
        if (item.audioRU) {
          let audioLink = item.audioRU
          if (!audioLink.startsWith('https://')) {
            audioLink = 'https://' + audioLink
          }
          return (
            <audio controls="controls">
              <source src={audioLink} type="audio/mpeg" />
            </audio>
          )
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Название(каз)',
      dataIndex: 'nameKZ',
      width: '5%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'nameRU',
      width: '5%'
    },
    {
      title: 'Тип',
      width: '5%',
      render: item => (
        <>
          {item.type === 'learning'
            ? 'Обучение'
            : item.type === 'practice'
            ? 'Практика'
            : '-'}
        </>
      )
    },
    {
      title: 'План',
      dataIndex: 'restrictions',
      width: '5%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setLessonItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setLessonItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Link to={'/educationCollections'}>
        <Tag>Коллекции</Tag>
      </Link>
      <Tag>{'->'}</Tag>
      <Link to={`/educationCollections/${collectionId}/courses`}>
        <Tag>Курсы</Tag>
      </Link>
      <Tag>{'->'}</Tag>
      <Tag>Уроки</Tag>
      <Table
        dataSource={listLessons}
        columns={columns}
        rowKey={item => item.id}
        loading={loading}
        pagination={{
          showSizeChanger: false
        }}
        title={() => (
          <>
            <Tag>
              {'Курс - ' +
                (course?.nameKZ || '') +
                ', ' +
                (course?.nameRU || '')}
            </Tag>
            <StyledHeaderContainer>
              <StyledInput
                prefix={<SearchOutlined />}
                placeholder="Урок"
                onChange={e => {
                  setSearchLesson(e.target.value.toLowerCase())
                }}
              />

              {course && (
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  <PlusOutlined /> Новый урок
                </Button>
              )}
            </StyledHeaderContainer>
          </>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый урок"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setImageFile(null)
          setAudioKZFile(null)
          setAudioRUFile(null)
          imageFileRef.current.value = null
          audioKZFileRef.current.value = null
          audioRUFileRef.current.value = null
        }}
        onOk={() => {
          if (imageFile !== null) {
            addLessonHandler(
              form,
              setModalVisible,
              imageFile,
              audioKZFile,
              audioRUFile,
              setImageFile,
              setAudioKZFile,
              setAudioRUFile,
              imageFileRef,
              audioKZFileRef,
              audioRUFileRef
            )
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <LessonForm
          setImageFile={setImageFile}
          setAudioKZFile={setAudioKZFile}
          setAudioRUFile={setAudioRUFile}
          imageFileRef={imageFileRef}
          audioKZFileRef={audioKZFileRef}
          audioRUFileRef={audioRUFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setImageFile(null)
            setAudioKZFile(null)
            setAudioRUFile(null)
            imageFileRef.current.value = null
            audioKZFileRef.current.value = null
            audioRUFileRef.current.value = null
          }}
          onOk={() => {
            updateLessonHandler(
              formForEdit,
              lessonItem.id,
              setEditModal,
              imageFile,
              audioKZFile,
              audioRUFile,
              setImageFile,
              setAudioKZFile,
              setAudioRUFile,
              imageFileRef,
              audioKZFileRef,
              audioRUFileRef
            )
          }}
        >
          <LessonForm
            form={formForEdit}
            level={lessonItem.level}
            status={lessonItem.status}
            nameKZ={lessonItem.nameKZ}
            nameRU={lessonItem.nameRU}
            type={lessonItem.type}
            topics={course.topicsPopulated}
            topcs={lessonItem.topic}
            restrictions={lessonItem.restrictions}
            timerDuration={lessonItem.timerDuration}
            practiceTasks={lessonItem.practiceTasks}
            setImageFile={setImageFile}
            setAudioKZFile={setAudioKZFile}
            setAudioRUFile={setAudioRUFile}
            imageFileRef={imageFileRef}
            audioKZFileRef={audioKZFileRef}
            audioRUFileRef={audioRUFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteLessonHandler(lessonItem.id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить '{lessonItem.nameKZ} -{' '}
            {lessonItem.nameRU}' ?
          </h3>
        </Modal>
      )}
    </>
  )
}

Lessons.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.object).isRequired,
  addLessonHandler: PropTypes.func.isRequired,
  updateLessonHandler: PropTypes.func.isRequired
}

export default Lessons
