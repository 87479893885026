import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import PracticeNumbers from './PracticeNumbers'
import WithMainLayout from '../../../hocs/withMainLayout'

const GET_PRACTICE_NUMBERS = gql`
  query statusedPracticeNumbersByIndex {
    statusedPracticeNumbersByIndex {
      id
      index
      number
      audioURL
      imageURL
      status
      restrictions
    }
  }
`

const ADD_PRACTICE_NUMBER = gql`
  mutation addPracticeNumber(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $input: PracticeNumberInput!
  ) {
    addPracticeNumber(
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      number
      index
      audioURL
      imageURL
      status
      restrictions
    }
  }
`

const UPDATE_PRACTICE_NUMBER = gql`
  mutation updatePracticeNumber(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $input: UpdatePracticeNumberInput
  ) {
    updatePracticeNumber(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      index
      number
      audioURL
      imageURL
      status
      restrictions
    }
  }
`

const DELETE_PRACTICE_NUMBER = gql`
  mutation deletePracticeNumber($id: ID!) {
    deletePracticeNumber(id: $id) {
      id
      message
    }
  }
`

const NumbersContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allNumbers, setAllNumbers] = React.useState([])
  const { data, loading, error } = useQuery(GET_PRACTICE_NUMBERS)
  const [addPracticeNumber, { error: errorAddPracticeNumber }] = useMutation(
    ADD_PRACTICE_NUMBER,
    {
      update(cache, { data: { addPracticeNumber: addPracticeNumberItem } }) {
        const { statusedPracticeNumbersByIndex } = cache.readQuery({
          query: GET_PRACTICE_NUMBERS
        })
        cache.writeQuery({
          query: GET_PRACTICE_NUMBERS,
          data: {
            statusedPracticeNumbersByIndex: statusedPracticeNumbersByIndex
              .concat(addPracticeNumberItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Буква практики добавлена')
      }
    }
  )
  const [
    updatePracticeNumber,
    { error: errorUpdatePracticeNumber }
  ] = useMutation(UPDATE_PRACTICE_NUMBER, {
    update(
      cache,
      { data: { updatePracticeNumber: updatePracticeNumberItem } }
    ) {
      const { statusedPracticeNumbersByIndex } = cache.readQuery({
        query: GET_PRACTICE_NUMBERS
      })
      cache.writeQuery({
        query: GET_PRACTICE_NUMBERS,
        data: {
          statusedPracticeNumbersByIndex: statusedPracticeNumbersByIndex
            .filter(i => i.id !== updatePracticeNumberItem.id)
            .concat(updatePracticeNumberItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Буква практики обновлена')
    }
  })
  const [
    deletePracticeNumber,
    { error: errorDeletePracticeNumber }
  ] = useMutation(DELETE_PRACTICE_NUMBER, {
    update(
      cache,
      { data: { deletePracticeNumber: deletePracticeNumberItem } }
    ) {
      const { statusedPracticeNumbersByIndex } = cache.readQuery({
        query: GET_PRACTICE_NUMBERS
      })
      cache.writeQuery({
        query: GET_PRACTICE_NUMBERS,
        data: {
          statusedPracticeNumbersByIndex: statusedPracticeNumbersByIndex.filter(
            i => i.id !== deletePracticeNumberItem.id
          )
        }
      })
      hideLoading()
      toast.success('Буква практики удалена')
    }
  })

  React.useEffect(() => {
    if (errorAddPracticeNumber) {
      console.log(errorAddPracticeNumber)
    }
  }, [errorAddPracticeNumber])

  React.useEffect(() => {
    if (errorDeletePracticeNumber) {
      console.log(errorDeletePracticeNumber)
    }
  }, [errorDeletePracticeNumber])

  React.useEffect(() => {
    if (errorUpdatePracticeNumber) {
      console.log(errorUpdatePracticeNumber)
    }
  }, [errorUpdatePracticeNumber])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllNumbers(data.statusedPracticeNumbersByIndex)
    }
  }, [data, loading, error])

  const addPracticeNumberHandler = (
    form,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addPracticeNumber({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            input: {
              index: val.index,
              number: val.number?.trim(),
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        hideLoading()
      })
      .catch(err => {
        console.error('this is error!', err)
      })
  }

  const updatePracticeNumberHandler = (
    form,
    numberId,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    showLoading()

    form
      .validateFields()
      .then(val => {
        updatePracticeNumber({
          variables: {
            id: numberId,
            audioFile,
            imageFile,
            input: {
              index: val.index,
              number: val.number?.trim(),
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        hideLoading()
        // toast.success('Буква обновлена')
      })
  }

  const deletePracticeNumberHandler = (numberId, closeFunc) => {
    showLoading()
    deletePracticeNumber({
      variables: {
        id: numberId
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  return (
    <PracticeNumbers
      numbers={allNumbers}
      loading={loading}
      addPracticeNumberHandler={addPracticeNumberHandler}
      updatePracticeNumberHandler={updatePracticeNumberHandler}
      deletePracticeNumberHandler={deletePracticeNumberHandler}
    />
  )
}

export default WithMainLayout(NumbersContainer)
