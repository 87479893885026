import React from 'react'

import { Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import './PracticeLetters.css'

const LetterForm = ({
  form,
  index,
  letter,
  status,
  setAudioFile,
  setImageFile,
  audioFileRef,
  imageFileRef
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио</>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
      </div>
      <FormItem
        key="index"
        label="Очередность Буквы"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность Документа`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность" />
      </FormItem>
      <FormItem
        key="status"
        label="Статус Буквы"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="letter"
        label="Буква"
        name="letter"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Букву`
          }
        ]}
        initialValue={letter}
      >
        <Input placeholder="Буква" maxLength={1} />
      </FormItem>
    </Form>
  )
}

LetterForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

LetterForm.defaultProps = {
  letter: '',
  status: 'active'
}

export default LetterForm
