import React, { useEffect, useRef, useState } from 'react'
import { Table, Button, Form, Modal, message, Input, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TraditionsForm from './TraditionsForm'
import { useLoading } from '../../../context/useLoading'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Traditions = ({
  traditions,
  traditionsArticles,
  createTraditionHandler,
  updateTraditionHandler,
  deleteTraditionHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [traditionsItem, setTraditionsItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchTradition, setSearchTradition] = useState(null)
  const [listTraditions, setListTraditions] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const imageFileRef = useRef()
  const { hideLoading } = useLoading()

  useEffect(() => {
    if (searchTradition) {
      const searchArray = traditions.filter(tradition => {
        return (
          tradition.titleRus?.toLowerCase().includes(searchTradition) ||
          tradition.titleKaz?.toLowerCase().includes(searchTradition)
        )
      })
      setListTraditions(searchArray)
    } else {
      setListTraditions(traditions)
    }
  }, [traditions, searchTradition])

  const columns = [
    {
      title: 'Название (каз)',
      dataIndex: 'titleKaz',
      width: '15%'
    },
    {
      title: 'Название (рус)',
      dataIndex: 'titleRus',
      width: '15%'
    },
    {
      title: 'Картинка',
      width: '10%',
      render: item => {
        return (
          <>
            <Image shape="square" size={64} src={item.imageUrl} />
          </>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setTraditionsItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setTraditionsItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listTraditions}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchTradition(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая традиция
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая традиция"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setImageFile(null)
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (imageFile !== null) {
            createTraditionHandler(
              form,
              setModalVisible,
              imageFile,
              setImageFile,
              imageFileRef
            )
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <TraditionsForm
          form={form}
          setImageFile={setImageFile}
          imageFileRef={imageFileRef}
          traditionsArticles={traditionsArticles}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
            setImageFile(null)
            imageFileRef.current.value = null
          }}
          onOk={() => {
            updateTraditionHandler(
              formForEdit,
              traditionsItem.id,
              setEditModal,
              imageFile,
              setImageFile,
              imageFileRef
            )
          }}
        >
          <TraditionsForm
            form={formForEdit}
            titleKaz={traditionsItem.titleKaz}
            titleRus={traditionsItem.titleRus}
            setImageFile={setImageFile}
            imageFileRef={imageFileRef}
            traditionsArticles={traditionsArticles}
            selectedTraditionsArticles={traditionsItem.traditionArticles}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteTraditionHandler(traditionsItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {traditionsItem.titleRus} ?</h3>
        </Modal>
      )}
    </>
  )
}

Traditions.propTypes = {
  traditionsArticles: PropTypes.arrayOf(PropTypes.object).isRequired,
  traditions: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTraditionHandler: PropTypes.func.isRequired,
  updateTraditionHandler: PropTypes.func.isRequired,
  deleteTraditionHandler: PropTypes.func.isRequired
}

export default Traditions
