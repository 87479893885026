import React, { useEffect, useState } from 'react'

import { Checkbox, Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import './Banner.css'

const BannerForm = ({
  form,
  index,
  titleKZ,
  titleRU,
  descriptionKZ,
  descriptionRU,
  openStyle,
  action,
  status,
  setImageFileKZ,
  imageFileRefKZ,
  setImageFileRU,
  imageFileRefRU
}) => {
  const FormItem = Form.Item
  const [hasAction, setHasAction] = useState(action ? true : false)

  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRefKZ}
          onChange={event => setImageFileKZ(event.target.files[0])}
        />
      </div>
      <div style={{ marginBottom: '3%' }}>
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRefRU}
          onChange={event => setImageFileRU(event.target.files[0])}
        />
      </div>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Активный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность Баннера"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность Баннера`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность Баннера" />
      </FormItem>
      <FormItem
        key="titleKZ"
        label="Заголовок на казахском"
        name="titleKZ"
        rules={[
          {
            required: false,
            message: `Пожалуйста, введите заголовок на казахском`
          }
        ]}
        initialValue={titleKZ}
      >
        <Input placeholder="titleKZ" />
      </FormItem>
      <FormItem
        key="titleRU"
        label="Заголовок на русском"
        name="titleRU"
        rules={[
          {
            required: false,
            message: `Пожалуйста, введите заголовок на русском`
          }
        ]}
        initialValue={titleRU}
      >
        <Input placeholder="titleRU" />
      </FormItem>
      <FormItem
        key="descriptionKZ"
        label="Описание на казахском"
        name="descriptionKZ"
        rules={[
          {
            required: false,
            message: `Пожалуйста, введите описание на казахском`
          }
        ]}
        initialValue={descriptionKZ}
      >
        <Input placeholder="Описание на казахском" />
      </FormItem>
      <FormItem
        key="descriptionRU"
        label="Описание на русском"
        name="descriptionRU"
        rules={[
          {
            required: false,
            message: `Пожалуйста, введите описание на русском`
          }
        ]}
        initialValue={descriptionRU}
      >
        <Input placeholder="Описание на русском" />
      </FormItem>
      <FormItem
        key="openStyle"
        label="Вариант открытия"
        name="openStyle"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите вариант открытия`
          }
        ]}
        initialValue={openStyle}
      >
        <Select placeholder="Выберите" mode="single" defaultValue={openStyle}>
          <Select.Option key="modal" value="modal">
            Модальное окно
          </Select.Option>
          <Select.Option key="screen" value="screen">
            Страница
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="hasAction"
        label="Наличие действия"
        name="hasAction"
        valuePropName="checked"
        initialValue={hasAction}
      >
        <Checkbox onChange={e => setHasAction(e.target.checked)}>
          Присутствует действие
        </Checkbox>
      </FormItem>

      {hasAction && (
        <>
          <FormItem
            key="actionType"
            label="Тип ссылки при нажатии"
            name={['action', 'actionType']}
            rules={[
              {
                required: true,
                message: `Пожалуйста, выберите вариант ссылки`
              }
            ]}
            initialValue={action?.actionType}
          >
            <Select
              placeholder="Выберите"
              mode="single"
              defaultValue={action?.actionType}
            >
              <Select.Option key="outer-link" value="outer-link">
                Внешняя ссылка
              </Select.Option>
              <Select.Option key="in-app-link" value="in-app-link">
                Внутренняя ссылка
              </Select.Option>
            </Select>
          </FormItem>
          <FormItem
            key="route"
            label="Ссылка"
            name={['action', 'route']}
            rules={[
              {
                required: true,
                message: `Пожалуйста, введите ссылку`
              }
            ]}
            initialValue={action?.route}
          >
            <Input placeholder="https или screenName" />
          </FormItem>
        </>
      )}
    </Form>
  )
}

BannerForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

BannerForm.defaultProps = {
  index: null,
  status: 'active',
  titleKZ: '',
  titleRU: '',
  descriptionKZ: '',
  descriptionRU: ''
}

export default BannerForm
