import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PracticeItemTwoColorForm from './PracticeItemsTwoColorsForm'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const PracticeItemsTwoColors = ({
  twoColors,
  loading,
  addPracticeItemsTwoColorsHandler,
  updatePracticeItemsTwoColorsHandler,
  deletePracticeItemsTwoColorsHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [TwoColorItem, setPracticeItemTwoColorItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [audioFile, setAudioFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const audioFileRef = React.useRef()
  const imageFileRef = React.useRef()

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => <div style={{ background: item.status === 'active' ? '#44FB82' : '#D4D4D4', height: '60px', width: '20px', margin: '-10px' }}></div>
    },
    {
      title: '',
      width: '1%',
      dataIndex: 'index'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.imageURL} />
          </>
        )
      }
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.audioURL
        if (!audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            <source src={audioLink} type="audio/mpeg" />
          </audio>
        )
      }
    },

    {
      title: 'Цвет',
      dataIndex: 'code_1',
      render: item => {
        return (
          <div style={{
            width: '40px',
            height: '50px',
            backgroundColor: '#' + item
          }}></div>
        )
      }
    },
    {
      title: 'Цвет 2',
      dataIndex: 'code_2',
      render: item => {
        return (
          <div style={{
            width: '40px',
            height: '50px',
            backgroundColor: '#' + item
          }}></div>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setPracticeItemTwoColorItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setPracticeItemTwoColorItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={twoColors}
        columns={columns}
        rowKey={item => item.id}
        loading={loading}
        pagination={{
          showSizeChanger: false
        }}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новые два цвета
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новые два цвета"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setImageFile(null)
          audioFileRef.current.value = null
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            if (imageFile !== null) {
              addPracticeItemsTwoColorsHandler(
                form,
                setModalVisible,
                audioFile,
                imageFile,
                setAudioFile,
                setImageFile,
                audioFileRef,
                imageFileRef
              )
            } else {
              message.error('Пожалуйста, выберите Изображение')
            }
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <PracticeItemTwoColorForm
          setAudioFile={setAudioFile}
          setImageFile={setImageFile}
          audioFileRef={audioFileRef}
          imageFileRef={imageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setImageFile(null)
            audioFileRef.current.value = null
            imageFileRef.current.value = null
          }}
          onOk={() => {
            updatePracticeItemsTwoColorsHandler(
              formForEdit,
              TwoColorItem.id,
              setEditModal,
              audioFile,
              imageFile,
              setAudioFile,
              setImageFile,
              audioFileRef,
              imageFileRef
            )
          }}
        >
          <PracticeItemTwoColorForm
            form={formForEdit}
            index={TwoColorItem.index}
            code_1={TwoColorItem.code_1}
            code_2={TwoColorItem.code_2}
            TwoColor={TwoColorItem.TwoColor}
            status={TwoColorItem.status}
            setAudioFile={setAudioFile}
            setImageFile={setImageFile}
            audioFileRef={audioFileRef}
            imageFileRef={imageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deletePracticeItemsTwoColorsHandler(TwoColorItem.id, setDeleteModal)
          }
        >
          <h3>Вы действительно хотите удалить '{TwoColorItem.TwoColor}' ?</h3>
        </Modal>
      )}
    </>
  )
}

PracticeItemsTwoColors.propTypes = {
  twoColors: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPracticeItemsTwoColorsHandler: PropTypes.func.isRequired,
  updatePracticeItemsTwoColorsHandler: PropTypes.func.isRequired
}

export default PracticeItemsTwoColors
