import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import Riddles from './Riddles'

const GET_RIDDLES = gql`
  query statusedRiddles {
    statusedRiddles {
      id
      riddleText
      answerText
      riddleAudioUrl
      answerAudioUrl
      status
    }
  }
`
const CREATE_RIDDLE = gql`
  mutation createRiddle($input: RiddlesInput) {
    createRiddle(input: $input) {
      id
      riddleText
      answerText
      riddleAudioUrl
      answerAudioUrl
      status
    }
  }
`
const UPDATE_RIDDLE = gql`
  mutation updateRiddle($input: UpdateRiddlesInput!) {
    updateRiddle(input: $input) {
      id
      riddleText
      answerText
      riddleAudioUrl
      answerAudioUrl
      status
    }
  }
`

const DELETE_RIDDLE = gql`
  mutation deleteRiddle($id: ID!) {
    deleteRiddle(id: $id) {
      id
    }
  }
`

const RiddlesContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [riddles, setRiddles] = useState([])

  const { loading } = useQuery(GET_RIDDLES, {
    onCompleted: data => {
      setRiddles(data.statusedRiddles)
    },
    onError: error => console.log(error)
  })

  const [createRiddle] = useMutation(CREATE_RIDDLE, {
    onError: error => console.log(error),
    update(cache, { data: { createRiddle: newItem } }) {
      const { statusedRiddles } = cache.readQuery({
        query: GET_RIDDLES
      })
      const newArray = statusedRiddles
        .filter(item => item.id !== newItem.id)
        .concat(newItem)
      setRiddles(newArray)
      cache.writeQuery({
        query: GET_RIDDLES,
        data: {
          statusedRiddles: newArray
        }
      })
      hideLoading()
      toast.success('Загадка добавлена')
    }
  })

  const [updateRiddle] = useMutation(UPDATE_RIDDLE, {
    onError: error => console.log(error),
    update(cache, { data: { updateRiddle: newItem } }) {
      const { statusedRiddles } = cache.readQuery({
        query: GET_RIDDLES
      })
      const newArray = statusedRiddles.map(item =>
        item.id !== newItem.id ? item : newItem
      )
      setRiddles(newArray)
      cache.writeQuery({
        query: GET_RIDDLES,
        data: {
          statusedRiddles: newArray
        }
      })
      hideLoading()
      toast.success('Загадка обновлена')
    }
  })

  const [deleteRiddle] = useMutation(DELETE_RIDDLE, {
    onError: error => console.log(error),
    update(cache, { data: { deleteRiddle: newItem } }) {
      const { statusedRiddles } = cache.readQuery({
        query: GET_RIDDLES
      })
      const newArray = statusedRiddles.filter(item => item.id !== newItem.id)
      setRiddles(newArray)
      cache.writeQuery({
        query: GET_RIDDLES,
        data: {
          statusedRiddles: newArray
        }
      })
      hideLoading()
      toast.success('Загадка удалена')
    }
  })

  const createHandler = (
    form,
    closeFunc,
    riddleAudioFile,
    setRiddleAudioFile,
    riddleAudioFileRef,
    answerAudioFile,
    setAnswerAudioFile,
    answerAudioFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        createRiddle({
          variables: {
            input: {
              riddleText: val.riddleText,
              answerText: val.answerText,
              riddleAudioFile,
              answerAudioFile,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setRiddleAudioFile(null)
        if (riddleAudioFileRef.current) riddleAudioFileRef.current.value = null
        setAnswerAudioFile(null)
        if (answerAudioFileRef.current) answerAudioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateHandler = (
    form,
    id,
    closeFunc,
    riddleAudioFile,
    setRiddleAudioFile,
    riddleAudioFileRef,
    answerAudioFile,
    setAnswerAudioFile,
    answerAudioFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateRiddle({
          variables: {
            input: {
              id,
              riddleText: val.riddleText,
              answerText: val.answerText,
              riddleAudioFile,
              answerAudioFile,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setRiddleAudioFile(null)
        if (riddleAudioFileRef.current) riddleAudioFileRef.current.value = null
        setAnswerAudioFile(null)
        if (answerAudioFileRef.current) answerAudioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteHandler = (id, closeFunc) => {
    showLoading()
    deleteRiddle({
      variables: { id }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Riddles
      riddles={riddles}
      createHandler={createHandler}
      updateHandler={updateHandler}
      deleteHandler={deleteHandler}
    />
  )
}

export default WithMainLayout(RiddlesContainer)
