import React from 'react'

import { Form, Input, Select } from 'antd'
import PropTypes from 'prop-types'

const TopicForm = ({ form, textKZ, textRU, status, restrictions }) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="textKZ"
        label="Название(каз)"
        name="textKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название на казахском`
          }
        ]}
        initialValue={textKZ}
      >
        <Input placeholder="Имя" />
      </FormItem>
      <FormItem
        key="textRU"
        label="Название(рус)"
        name="textRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название на русском`
          }
        ]}
        initialValue={textRU}
      >
        <Input placeholder="Имя" />
      </FormItem>

      <FormItem
        key="restrictions"
        label="План"
        name="restrictions"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите план`
          }
        ]}
        initialValue={restrictions}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="basic" value="basic">
            Basic
          </Select.Option>
          <Select.Option key="premium" value="premium">
            Premium
          </Select.Option>
        </Select>
      </FormItem>
    </Form>
  )
}

TopicForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  textKZ: PropTypes.string,
  textRU: PropTypes.string
}

TopicForm.defaultProps = {
  textKZ: '',
  textRU: '',
  status: 'active'
}

export default TopicForm
