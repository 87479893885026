import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Banners from './Banners'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_BANNERS_BY_INDEX = gql`
  query statusedBannersByIndex {
    statusedBannersByIndex {
      id
      index
      titleKZ
      titleRU
      descriptionKZ
      descriptionRU
      action {
        actionType
        route
      }
      status
      imageUrlKZ
      imageUrlRU
      openStyle
    }
  }
`

const ADD_BANNER = gql`
  mutation addBanner(
    $imageFileKZ: FileUpload!
    $imageFileRU: FileUpload!
    $input: BannerInput!
  ) {
    addBanner(
      imageFileKZ: $imageFileKZ
      imageFileRU: $imageFileRU
      input: $input
    ) {
      id
      titleKZ
      titleRU
      descriptionKZ
      descriptionRU
      index
      imageUrlKZ
      imageUrlRU
      status
    }
  }
`

const UPDATE_BANNER = gql`
  mutation updateBanner(
    $id: ID!
    $imageFileKZ: FileUpload
    $imageFileRU: FileUpload
    $input: UpdateBannerInput
  ) {
    updateBanner(
      id: $id
      imageFileKZ: $imageFileKZ
      imageFileRU: $imageFileRU
      input: $input
    ) {
      id
      titleKZ
      titleRU
      descriptionKZ
      descriptionRU
      index
      imageUrlKZ
      imageUrlRU
      status
    }
  }
`

// const DELETE_BANNER = gql`
//   mutation deleteAnimal($id: ID!) {
//     deleteAnimal(id: $id) {
//       id
//       message
//     }
//   }
// `

const DELETE_BANNER = gql`
  mutation updateBanner($id: ID!, $input: UpdateBannerInput) {
    updateBanner(id: $id, input: $input) {
      id
      index
      status
    }
  }
`

const BannersContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allBanners, setAllBanners] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_BANNERS_BY_INDEX)
  const [addBanner, { error: errorAddBanner }] = useMutation(ADD_BANNER, {
    update(cache, { data: { addBanner: addBannerItem } }) {
      const { statusedBannersByIndex } = cache.readQuery({
        query: GET_BANNERS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_BANNERS_BY_INDEX,
        data: {
          statusedBannersByIndex: statusedBannersByIndex
            .concat(addBannerItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Баннер добавлен')
    }
  })
  const [updateBanner, { error: errorUpdateBanner }] = useMutation(
    UPDATE_BANNER,
    {
      update(cache, { data: { updateBanner: updateBannerItem } }) {
        const { statusedBannersByIndex } = cache.readQuery({
          query: GET_BANNERS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_BANNERS_BY_INDEX,
          data: {
            statusedBannersByIndex: statusedBannersByIndex
              .filter(banner => banner.id !== updateBannerItem.id)
              .concat(updateBannerItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Баннер обновлен')
      }
    }
  )
  const [deleteBanner, { error: errorDeleteBanner }] = useMutation(
    DELETE_BANNER,
    {
      update(cache, { data: { updateBanner: deleteBannerItem } }) {
        const { statusedBannersByIndex } = cache.readQuery({
          query: GET_BANNERS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_BANNERS_BY_INDEX,
          data: {
            statusedBannersByIndex: statusedBannersByIndex.filter(
              banner => banner.id !== deleteBannerItem.id
            )
          }
        })
        hideLoading()
        toast.success('Баннер удалено')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddBanner) {
      console.log(errorAddBanner)
    }
  }, [errorAddBanner])

  React.useEffect(() => {
    if (errorDeleteBanner) {
      console.log(errorDeleteBanner)
    }
  }, [errorDeleteBanner])

  React.useEffect(() => {
    if (errorUpdateBanner) {
      console.log(errorUpdateBanner)
    }
  }, [errorUpdateBanner])

  React.useEffect(() => {
    if (data && !error && !loading) {
      console.log('data', data)
      setAllBanners(data.statusedBannersByIndex)
    }
    if (error) {
      console.log('err', error)
    }
  }, [data, loading, error])

  const addBannerHandler = (
    form,
    closeFunc,
    imageFileKZ,
    imageFileRU,
    setImageFileKZ,
    setImageFileRU,
    imageFileRefKZ,
    imageFileRefRU
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        console.log('val', val)

        showLoading()
        addBanner({
          variables: {
            imageFileKZ,
            imageFileRU,
            input: {
              index: val.index,
              status: val.status,
              titleKZ: val.titleKZ,
              titleRU: val.titleRU,
              descriptionKZ: val.descriptionKZ,
              descriptionRU: val.descriptionRU,
              openStyle: val.openStyle,
              action: val.hasAction
                ? val.action
                : { actionType: 'none', route: '' }
            }
          }
        })
      })
      .then(() => {
        setImageFileKZ(null)
        setImageFileRU(null)
        imageFileRefKZ.current.value = null
        imageFileRefRU.current.value = null
        closeFunc(false)
        hideLoading()
      })
  }

  const updateBannerHandler = (
    form,
    bannerId,
    closeFunc,
    imageFileKZ,
    imageFileRU,
    setImageFileKZ,
    setImageFileRU,
    imageFileRefKZ,
    imageFileRefRU
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateBanner({
          variables: {
            id: bannerId,
            imageFileKZ,
            imageFileRU,
            input: {
              index: val.index,
              status: val.status,
              titleKZ: val.titleKZ,
              titleRU: val.titleRU,
              descriptionKZ: val.descriptionKZ,
              descriptionRU: val.descriptionRU,
              openStyle: val.openStyle,
              action: val.hasAction
                ? val.action
                : { actionType: 'none', route: '' }
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        setImageFileKZ(null)
        setImageFileRU(null)
        imageFileRefKZ.current.value = null
        imageFileRefRU.current.value = null
        closeFunc(false)
        // toast.success('')
      })
  }

  const deleteBannerHandler = (bannerId, closeFunc) => {
    showLoading()
    deleteBanner({
      variables: {
        id: bannerId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Banners
      banners={allBanners}
      addBannerHandler={addBannerHandler}
      updateBannerHandler={updateBannerHandler}
      deleteBannerHandler={deleteBannerHandler}
    />
  )
}

export default WithMainLayout(BannersContainer)
