import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import WordsForm from './WordsForm'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Words = ({
  words = [],
  syllablesList,
  addWordHandler,
  updateWordHandler,
  deleteLetterHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [wordItem, setWordItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchWord, setSearchWord] = React.useState(null)

  const [listLetters, setListLetters] = React.useState(null)
  const [audioFile, setAudioFile] = useState(null)
  const [secondAudioFile, setSecondAudioFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [syllableAndOrder, setSyllableAndOrder] = useState([])

  const audioFileRef = React.useRef()
  const secondAudioFileRef = React.useRef()
  const imageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveWords = words.map(word => {
      return {
        id: word.id,
        word: word.word,
        order: word.order,
        wordAudioURL: word.wordAudioURL,
        secondAudioUrl: word.secondAudioUrl,
        link2Image: word.link2Image,
        syllables: word.syllables
      }
    })
    let searchArray = descriptiveWords

    if (searchWord) {
      searchArray = searchArray.filter(word => {
        return word.letter?.toLowerCase().includes(searchWord)
      })
    }

    setListLetters(searchArray)
  }, [words, searchWord])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Очередность',
      dataIndex: 'order',
      width: '10%'
    },
    {
      title: 'Слово',
      dataIndex: 'word',
      width: '10%'
    },
    {
      title: 'Картинка',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.link2Image} />
          </>
        )
      }
    },
    {
      title: 'Звучание',
      width: '5%',
      render: item => {
        let wordAudioURL = item.wordAudioURL
        if (!wordAudioURL.startsWith('https://')) {
          wordAudioURL = 'https://' + wordAudioURL
        }
        return (
          <audio controls="controls">
            <source src={wordAudioURL} type="audio/mpeg" />
          </audio>
        )
      }
    },
    {
      title: 'Втрое звучание',
      width: '5%',
      render: item => {
        let secondAudioUrl = item.secondAudioUrl
        if (!secondAudioUrl.startsWith('https://')) {
          secondAudioUrl = 'https://' + secondAudioUrl
        }
        return (
          <audio controls="controls">
            <source src={secondAudioUrl} type="audio/mpeg" />
          </audio>
        )
      }
    },
    {
      title: 'Слога',
      dataIndex: 'syllables',
      width: '20%',
      render: syllables => (
        <ul>
          {syllables.map((syllable, index) => (
            <li key={index}>
              {syllable.order} {syllable.syllable.syllable}
            </li>
          ))}
        </ul>
      )
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setWordItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setWordItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listLetters}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Буква"
              onChange={e => {
                setSearchWord(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая буква
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новое слово"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setSecondAudioFile(null)
          setImageFile(null)
          audioFileRef.current.value = null
          secondAudioFileRef.current.value = null
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            if (imageFile !== null) {
              addWordHandler(
                form,
                setModalVisible,
                audioFile,
                secondAudioFile,
                imageFile,
                setAudioFile,
                setSecondAudioFile,
                setImageFile,
                audioFileRef,
                secondAudioFileRef,
                imageFileRef,
                syllableAndOrder
              )
            } else {
              message.error('Пожалуйста, выберите Изображение')
            }
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <WordsForm
          setAudioFile={setAudioFile}
          setSecondAudioFile={setSecondAudioFile}
          setImageFile={setImageFile}
          audioFileRef={audioFileRef}
          secondAudioFile={secondAudioFile}
          imageFileRef={imageFileRef}
          form={form}
          setSyllableAndOrder={setSyllableAndOrder}
          syllablesList={syllablesList}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setSecondAudioFile(null)
            setImageFile(null)
            audioFileRef.current.value = null
            secondAudioFileRef.current.value = null
          }}
          onOk={() => {
            updateWordHandler(
              formForEdit,
              wordItem.id,
              setEditModal,
              audioFile,
              secondAudioFile,
              imageFile,
              setAudioFile,
              setSecondAudioFile,
              setImageFile,
              audioFileRef,
              secondAudioFileRef,
              imageFileRef,
              syllableAndOrder
            )
          }}
        >
          <WordsForm
            form={formForEdit}
            word={wordItem.word}
            order={wordItem.order}
            setAudioFile={setAudioFile}
            setSecondAudioFile={setSecondAudioFile}
            setImageFile={setImageFile}
            audioFileRef={audioFileRef}
            secondAudioFileRef={secondAudioFileRef}
            imageFileRef={imageFileRef}
            syllablesList={syllablesList}
            setSyllableAndOrder={setSyllableAndOrder}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteLetterHandler(wordItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить слово'{wordItem.word}' ?</h3>
        </Modal>
      )}
    </>
  )
}

export default Words
