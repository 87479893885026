import React from 'react'

import { Form, Input, Select, InputNumber } from 'antd'
import PropTypes from 'prop-types'

const CategoryForm = ({
  form,
  nameKZ,
  nameRU,
  index,
  status,
  setFile,
  fileRef
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <>Изорбражение</>
      <input
        style={{ marginBottom: '3%' }}
        type="file"
        accept="image/*"
        ref={fileRef}
        onChange={event => setFile(event.target.files[0])}
      />
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность Документа"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность Документа`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность" />
      </FormItem>
      <FormItem
        key="nameKZ"
        label="Название(каз)"
        name="nameKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Категории на казахском`
          }
        ]}
        initialValue={nameKZ}
      >
        <Input placeholder="Аты" />
      </FormItem>
      <FormItem
        key="nameRU"
        label="Название(рус)"
        name="nameRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Категории на русском`
          }
        ]}
        initialValue={nameRU}
      >
        <Input placeholder="Название" />
      </FormItem>
    </Form>
  )
}

CategoryForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string
}

CategoryForm.defaultProps = {
  nameKZ: '',
  nameRU: '',
  index: null,
  status: 'active'
}

export default CategoryForm
