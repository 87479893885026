import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import {
  PlusOutlined,
  SearchOutlined,
  LinkOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import CartoonForm from './CartoonForm'
// import Genres from '../shared/Genres'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Cartoons = ({
  cartoons,
  allTags,
  addCartoonHandler,
  updateCartoonHandler,
  deleteCartoonHandler,
  removeFilesHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [cartoonItem, setCartoonItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchCartoon, setSearchCartoon] = React.useState(null)

  const [listCartoons, setListCartoons] = React.useState(null)

  const [coverImageFile, setCoverImageFile] = useState(null)
  const coverImageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveCartoons = cartoons.map(cartoon => {
      return {
        id: cartoon.id,
        title: cartoon.title,
        tags: cartoon.tags,
        ageGroup: cartoon.ageGroup,
        episodes: cartoon.episodes,
        coverImage: cartoon.coverImage,
        descriptionKZ: cartoon.descriptionKZ,
        descriptionRU: cartoon.descriptionRU,
        status: cartoon.status,
        index: cartoon.index
      }
    })
    let searchArray = descriptiveCartoons

    if (searchCartoon) {
      searchArray = searchArray.filter(cartoon => {
        return cartoon.title?.toLowerCase().includes(searchCartoon)
      })
    }

    setListCartoons(searchArray)
  }, [cartoons, searchCartoon])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '1%'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item?.coverImage} />
          </>
        )
      }
    },
    {
      title: 'Название',
      dataIndex: 'title',
      width: '20%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setCartoonItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setCartoonItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listCartoons}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Title"
              onChange={e => {
                setSearchCartoon(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новое аудио
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новое аудио"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setCoverImageFile(null)
          coverImageFileRef.current.value = null
        }}
        onOk={() => {
          // addCartoonHandler(form, setModalVisible)
          if (coverImageFile !== null) {
            addCartoonHandler(
              form,
              setModalVisible,
              coverImageFile,
              setCoverImageFile,
              coverImageFileRef
            )
          } else {
            message.error('Пожалуйста, выберите обложку')
          }
        }}
      >
        <CartoonForm
          allTags={allTags}
          setCoverImageFile={setCoverImageFile}
          coverImageFileRef={coverImageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
          }}
          onOk={() =>
            updateCartoonHandler(
              formForEdit,
              cartoonItem.id,
              setEditModal,
              coverImageFile,
              setCoverImageFile,
              coverImageFileRef
            )
          }
        >
          <CartoonForm
            form={formForEdit}
            id={cartoonItem.id}
            title={cartoonItem.title}
            tags={cartoonItem.tags}
            ageGroup={cartoonItem.ageGroup}
            episodes={cartoonItem.episodes}
            descriptionKZ={cartoonItem.descriptionKZ}
            descriptionRU={cartoonItem.descriptionRU}
            status={cartoonItem.status}
            index={cartoonItem.index}
            allTags={allTags}
            setCoverImageFile={setCoverImageFile}
            coverImageFileRef={coverImageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteCartoonHandler(cartoonItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {cartoonItem.title}?</h3>
        </Modal>
      )}
    </>
  )
}

Cartoons.propTypes = {
  cartoons: PropTypes.arrayOf(PropTypes.object).isRequired,
  addCartoonHandler: PropTypes.func.isRequired,
  updateCartoonHandler: PropTypes.func.isRequired
}

export default Cartoons
