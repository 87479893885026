import React from 'react'
import { Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import './PracticeFigure.css'

const FigureForm = ({
  form,
  index,
  name,
  imageFileRef,
  setImageFile,
  imageMinRef,
  setImageMin,
  status,
  setAudioFile,
  audioFileRef,
  setObjectItem,
  objectItemRef,
  setImageBucket,
  imageBucketRef,
  restrictions
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио</>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
        <br />
        <br />
        <>Изображение объекта</>
        <input
          type="file"
          accept="image/*"
          ref={objectItemRef}
          onChange={event => setObjectItem(event.target.files[0])}
        />
        <>Изображение корзины</>
        <input
          type="file"
          accept="image/*"
          ref={imageBucketRef}
          onChange={event => setImageBucket(event.target.files[0])}
        />
        <>Изображение минимализм</>
        <input
          type="file"
          accept="image/*"
          ref={imageMinRef}
          onChange={event => setImageMin(event.target.files[0])}
        />
      </div>
      <FormItem
        key="index"
        label="Очередность фигуры"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность фигуры`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность" />
      </FormItem>
      <FormItem
        key="status"
        label="Статус Фигуры"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="name"
        label="Фигура"
        name="name"
        rules={[{ required: true, message: `Пожалуйста, введите имя фигуры` }]}
        initialValue={name}
      >
        <Input placeholder="Фигура" />
      </FormItem>

      <FormItem
        key="restrictions"
        label="План"
        name="restrictions"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите план`
          }
        ]}
        initialValue={restrictions}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="basic" value="basic">
            Basic
          </Select.Option>
          <Select.Option key="premium" value="premium">
            Premium
          </Select.Option>
        </Select>
      </FormItem>
    </Form>
  )
}

FigureForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

FigureForm.defaultProps = {
  name: '',
  status: 'active'
}

export default FigureForm
