import React from 'react'

import { Form, Input, Select, Button } from 'antd'
import PropTypes from 'prop-types'
import './ContentAudio.css'
import ageGroups from '../shared/ageGroups'
import { DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const StyledDivDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ContentAudioForm = ({
  form,
  setAudioFile,
  audiofile,
  audioFileRef,
  setTextFile,
  textfile,
  textFileRef,
  setCoverImageFile,
  coverImageFile,
  coverImageFileRef,
  id,
  title,
  authors,
  voiceActors,
  publisher,
  tags,
  ageGroup,
  descriptionKZ,
  descriptionRU,
  status,
  lyrics,
  allCreators,
  allPublishers,
  allTags,
  removeFilesHandler,
  restrictions
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      {/* <button
        onClick={() => {
          audioFileRef.current.value = null
          textFileRef.current.value = null
        }}
      >
        Сбросить выбранные файлы
      </button> */}
      <div style={{ marginBottom: '3%' }}>
        <div>
          <>Аудио</>
          <input
            type="file"
            accept="audio/*"
            ref={audioFileRef}
            onChange={event => {
              setAudioFile(event.target.files[0])
            }}
          />
        </div>
        <>Текст</>
        <StyledDivDelete>
          <input
            type="file"
            accept=".csv"
            ref={textFileRef}
            onChange={event => setTextFile(event.target.files[0])}
          />
        </StyledDivDelete>
        <>Обложка</>
        <StyledDivDelete>
          <input
            type="file"
            accept="image/*"
            ref={coverImageFileRef}
            onChange={event => setCoverImageFile(event.target.files[0])}
          />
          {id && (
            <Button
              danger
              onClick={() => {
                removeFilesHandler(id, false, true)
              }}
            >
              <DeleteOutlined />
            </Button>
          )}
        </StyledDivDelete>
      </div>
      {/* <label for="upload-photo">Аудио файл...</label>
      <input type="file" name="photo" id="upload-file" onChange={ (event) => setTextFile(event.target.files[0])} /> */}
      {/* <>Text File</>
      <input type="file" title="Choose Text File"  onChange={ (event) => setTextFile(event.target.files[0])}/>     */}
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="title"
        label="Название"
        name="title"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Аудио`
          }
        ]}
        initialValue={title}
      >
        <Input placeholder="Название" />
      </FormItem>
      <FormItem
        key="authors"
        label="Авторы"
        name="authors"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите Авторов`
          }
        ]}
        initialValue={authors
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Авторов"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCreators.creators.map(creator => (
            <Select.Option key={creator.id} value={creator.id}>
              {creator.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="tags"
        label="Тэги"
        name="tags"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите тэги`
          }
        ]}
        initialValue={tags ? tags.map(item => item?.id) : undefined}
      >
        <Select
          placeholder="Выберите тэги"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            // console.log('input', input)
            // console.log('option', option.children)
            return (
              option.children.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            )
          }}
        >
          {allTags.tags.map(item => (
            <Select.Option key={item.id} value={item.id}>
              <>{`${item.nameKZ} - ${item.nameRU}`}</>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="ageGroup"
        label="Возрастная группа"
        name="ageGroup"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите возрастную группу`
          }
        ]}
        initialValue={ageGroup ? ageGroup : undefined}
      >
        <Select
          placeholder="Выберите возрастную группу"
          mode="single"
          allowClear
        >
          {ageGroups.map(item => {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            )
          })}
          {/* <Select
            placeholder="Выберите автора"
            mode="single"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {allAuthors.authors.map(authorItem => (
              <Select.Option key={authorItem.id} value={authorItem.id}>
                {authorItem.name}
              </Select.Option>
            ))}
          </Select> */}
        </Select>
      </FormItem>
      <FormItem
        key="publisher"
        label="Издатель"
        name="publisher"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите издателя`
          }
        ]}
        initialValue={publisher ? publisher?.id : undefined}
      >
        <Select
          placeholder="Выберите издателя"
          mode="single"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allPublishers.publishers.map(publisherItem => (
            <Select.Option key={publisherItem.id} value={publisherItem.id}>
              {publisherItem.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="voiceActors"
        label="Исполнители"
        name="voiceActors"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите Исполнителей`
          }
        ]}
        initialValue={voiceActors
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Исполнителей"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCreators.creators.map(creator => (
            <Select.Option key={creator.id} value={creator.id}>
              {creator.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>

      <FormItem
        key="restrictions"
        label="План"
        name="restrictions"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите план`
          }
        ]}
        initialValue={restrictions}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="basic" value="basic">
            Basic
          </Select.Option>
          <Select.Option key="premium" value="premium">
            Premium
          </Select.Option>
        </Select>
      </FormItem>

      <FormItem
        key="descriptionKZ"
        label="Описание на казахском"
        name="descriptionKZ"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткое описание`
          }
        ]}
        initialValue={descriptionKZ}
      >
        <Input.TextArea placeholder="Описание..." />
      </FormItem>
      <FormItem
        key="descriptionRU"
        label="Описание на русском"
        name="descriptionRU"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткое описание`
          }
        ]}
        initialValue={descriptionRU}
      >
        <Input.TextArea placeholder="Описание..." />
      </FormItem>
      {lyrics && (
        <>
          <FormItem
            key="lyrics"
            label="Текст"
            name="lyrics"
            rules={[
              {
                required: false,
                message: `Пожалуйста, напишите короткое описание`
              }
            ]}
            initialValue={lyrics}
          >
            <Input.TextArea disabled />
          </FormItem>
          <Button
            danger
            onClick={() => {
              form.setFieldsValue({ lyrics: null })
            }}
          >
            Reset Text
          </Button>
        </>
      )}
    </Form>
  )
}

ContentAudioForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string)
}

ContentAudioForm.defaultProps = {
  title: '',
  authors: [],
  tags: [],
  ageGroup: undefined,
  publisher: {},
  voiceActors: [],
  status: 'active',
  lyrics: null
}

export default ContentAudioForm
