import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import Courses from './Courses'
import WithMainLayout from '../../../hocs/withMainLayout'

const GET_COURSES = gql`
  query statusedCoursesByCollection($collectionId: ID!) {
    statusedCoursesByCollection(collectionId: $collectionId) {
      educationCollection {
        id
        titleKZ
        titleRU
      }
      courses {
        id
        index
        status
        nameKZ
        nameRU
        color
        imageURL
        topics
      }
    }
  }
`

const ADD_COURSE = gql`
  mutation addCourse($imageFile: FileUpload!, $input: CourseInput) {
    addCourse(imageFile: $imageFile, input: $input) {
      id
      index
      status
      nameKZ
      nameRU
      color
      imageURL
      topics
    }
  }
`

const UPDATE_COURSE = gql`
  mutation updateCourse($id: ID!, $imageFile: FileUpload, $input: CourseInput) {
    updateCourse(id: $id, imageFile: $imageFile, input: $input) {
      id
      index
      status
      nameKZ
      nameRU
      color
      imageURL
      topics
    }
  }
`

const DELETE_COURSE = gql`
  mutation deleteCourse($id: ID!) {
    deleteCourse(id: $id) {
      id
      message
    }
  }
`

const GET_TOPICS = gql`
  query statusedTopics {
    statusedTopics {
      id
      textKZ
      textRU
    }
  }
`

const CoursesContainer = () => {
  const { collectionId } = useParams()
  const { showLoading, hideLoading } = useLoading()
  const [allCourses, setAllCourses] = useState([])
  const [collection, setCollection] = useState()
  const [allTopics, setAllTopics] = useState()
  const { data, loading, error } = useQuery(GET_COURSES, {
    variables: { collectionId }
  })
  const {
    data: dataTopics,
    loading: loadingTopics,
    error: errorTopics
  } = useQuery(GET_TOPICS)

  const [addCourse, { error: errorAddCourse }] = useMutation(ADD_COURSE, {
    update(cache, { data: { addCourse: addCourseItem } }) {
      const { statusedCoursesByCollection } = cache.readQuery({
        query: GET_COURSES,
        variables: { collectionId }
      })
      cache.writeQuery({
        query: GET_COURSES,
        variables: { collectionId },
        data: {
          statusedCoursesByCollection: {
            educationCollection:
              statusedCoursesByCollection.educationCollection,
            courses: statusedCoursesByCollection.courses
              .concat(addCourseItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        }
      })
      hideLoading()
      toast.success('Курс добавлен')
    }
  })
  const [updateCourse, { error: errorUpdateCourse }] = useMutation(
    UPDATE_COURSE,
    {
      update(cache, { data: { updateCourse: updateCourseItem } }) {
        const { statusedCoursesByCollection } = cache.readQuery({
          query: GET_COURSES,
          variables: { collectionId }
        })
        cache.writeQuery({
          query: GET_COURSES,
          variables: { collectionId },
          data: {
            statusedCoursesByCollection: {
              educationCollection:
                statusedCoursesByCollection.educationCollection,
              courses: statusedCoursesByCollection.courses
                .filter(i => i.id !== updateCourseItem.id)
                .concat(updateCourseItem)
                .sort((a, b) =>
                  a.index < b.index ? -1 : b.index > a.index ? 1 : 0
                )
            }
          }
        })
        hideLoading()
        toast.success('Курс обновлен')
      }
    }
  )
  const [deleteCourse, { error: errorDeleteCourse }] = useMutation(
    DELETE_COURSE,
    {
      update(cache, { data: { deleteCourse: deleteCourseItem } }) {
        const { statusedCoursesByCollection } = cache.readQuery({
          query: GET_COURSES,
          variables: { collectionId }
        })
        cache.writeQuery({
          query: GET_COURSES,
          variables: { collectionId },
          data: {
            statusedCoursesByCollection: {
              educationCollection:
                statusedCoursesByCollection.educationCollection,
              courses: statusedCoursesByCollection.courses.filter(
                i => i.id !== deleteCourseItem.id
              )
            }
          }
        })
        hideLoading()
        toast.success('Курс удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddCourse) {
      console.log(errorAddCourse)
    }
  }, [errorAddCourse])

  React.useEffect(() => {
    if (errorDeleteCourse) {
      console.log(errorDeleteCourse)
    }
  }, [errorDeleteCourse])

  React.useEffect(() => {
    if (errorUpdateCourse) {
      console.log(errorUpdateCourse)
    }
  }, [errorUpdateCourse])

  React.useEffect(() => {
    if (
      data &&
      !error &&
      !loading &&
      dataTopics &&
      !errorTopics &&
      !loadingTopics
    ) {
      setAllTopics(dataTopics.statusedTopics)
      setCollection(data.statusedCoursesByCollection.educationCollection)
      setAllCourses(data.statusedCoursesByCollection.courses)
    }
  }, [data, loading, error])

  const addCourseHandler = (
    form,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef,
    colorTemp,
    setColorTemp
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addCourse({
          variables: {
            imageFile: imageFile,
            input: {
              index: val.index,
              status: val.status,
              nameKZ: val.nameKZ && val.nameKZ?.trim(),
              nameRU: val.nameRU && val.nameRU?.trim(),
              color: colorTemp,
              topics: val.topics || [],
              educationCollection: collectionId
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        imageFileRef.current.value = null
        setColorTemp('#FFFFFF')
        closeFunc(false)
      })
  }

  const updateCourseHandler = (
    form,
    courseId,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef,
    colorTemp,
    setColorTemp
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        updateCourse({
          variables: {
            id: courseId,
            imageFile: imageFile,
            input: {
              index: val.index,
              status: val.status,
              nameKZ: val.nameKZ && val.nameKZ?.trim(),
              nameRU: val.nameRU && val.nameRU?.trim(),
              color: colorTemp,
              topics: val.topics || [],
              educationCollection: collectionId
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        imageFileRef.current.value = null
        setColorTemp('#FFFFFF')
        closeFunc(false)
      })
  }

  const deleteCourseHandler = (courseId, closeFunc) => {
    showLoading()
    deleteCourse({
      variables: {
        id: courseId
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  return (
    <Courses
      collectionId={collectionId}
      collection={collection}
      courses={allCourses}
      allTopics={allTopics}
      loading={loading}
      addCourse={addCourse}
      addCourseHandler={addCourseHandler}
      updateCourseHandler={updateCourseHandler}
      deleteCourseHandler={deleteCourseHandler}
    />
  )
}

export default WithMainLayout(CoursesContainer)
