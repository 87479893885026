import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Collections from './Collections'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

// const GET_COLLECTIONS = gql`
//   query collections {
//     collections {
//       id
//       title
//       coverImage
//       index
//       tales {
//         id
//         title
//         authors {
//           id
//           name
//         }
//       }
//     }
//   }
// `

const GET_COLLECTIONS_BY_INDEX = gql`
  query statusedCollectionsByIndex {
    statusedCollectionsByIndex {
      id
      titleKZ
      titleRU
      coverImage
      index
      tales {
        id
        title
        authors {
          id
          name
        }
      }
      status
      descriptionKZ
      descriptionRU
    }
  }
`

const ADD_COLLECTION = gql`
  mutation addCollection($coverImageFile: FileUpload, $input: CollectionInput) {
    addCollection(coverImageFile: $coverImageFile, input: $input) {
      id
      titleKZ
      titleRU
      tales {
        id
        title
        authors {
          id
          name
        }
      }
      coverImage
      index
      status
      descriptionKZ
      descriptionRU
    }
  }
`

const UPDATE_COLLECTION = gql`
  mutation updateCollection(
    $id: ID!
    $coverImageFile: FileUpload
    $input: UpdateCollectionInput
  ) {
    updateCollection(id: $id, coverImageFile: $coverImageFile, input: $input) {
      id
      titleKZ
      titleRU
      tales {
        id
        title
        authors {
          id
          name
        }
      }
      coverImage
      index
      status
      descriptionKZ
      descriptionRU
    }
  }
`

// const UPDATE_COLLECTION = gql`
//   mutation updateCollection($id: ID!, $input: CollectionInput) {
//     updateCollection(id: $id, input: $input) {
//       id,
//       title,
//       tales{id,title}
//     }
//   }
// `

// const DELETE_COLLECTION = gql`
//   mutation deleteCollection($id: ID!) {
//     deleteCollection(id: $id) {
//       id
//       message
//     }
//   }
// `

const DELETE_COLLECTION = gql`
  mutation updateCollection($id: ID!, $input: UpdateCollectionInput) {
    updateCollection(id: $id, input: $input) {
      id
      titleKZ
      titleRU
      tales {
        id
        title
        authors {
          id
          name
        }
      }
      coverImage
      index
      status
      descriptionKZ
      descriptionRU
    }
  }
`

const GET_TALES = gql`
  query tales {
    tales {
      id
      title
      authors {
        id
        name
      }
    }
  }
`

const CollectionsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allCollections, setAllCollections] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_COLLECTIONS_BY_INDEX)
  const { data: allTales, loading: talesLoading } = useQuery(GET_TALES)

  const [addCollection, { error: errorAddCollection }] = useMutation(
    ADD_COLLECTION,
    {
      update(cache, { data: { addCollection: addCollectionItem } }) {
        const { statusedCollectionsByIndex } = cache.readQuery({
          query: GET_COLLECTIONS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_COLLECTIONS_BY_INDEX,
          data: {
            statusedCollectionsByIndex: statusedCollectionsByIndex
              .concat(addCollectionItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Сборник добавлен')
      }
    }
  )

  const [updateCollection, { error: errorUpdateCollection }] = useMutation(
    UPDATE_COLLECTION,
    {
      update(cache, { data: { updateCollection: updateCollectionItem } }) {
        const { statusedCollectionsByIndex } = cache.readQuery({
          query: GET_COLLECTIONS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_COLLECTIONS_BY_INDEX,
          data: {
            statusedCollectionsByIndex: statusedCollectionsByIndex
              .filter(collection => collection.id !== updateCollectionItem.id)
              .concat(updateCollectionItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Сборник обновлен')
      }
    }
  )
  const [deleteCollection, { error: errorDeleteCollection }] = useMutation(
    DELETE_COLLECTION,
    {
      update(cache, { data: { updateCollection: deleteCollectionItem } }) {
        const { statusedCollectionsByIndex } = cache.readQuery({
          query: GET_COLLECTIONS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_COLLECTIONS_BY_INDEX,
          data: {
            statusedCollectionsByIndex: statusedCollectionsByIndex.filter(
              collection => collection.id !== deleteCollectionItem.id
            )
          }
        })
        hideLoading()
        toast.success('Сборник удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddCollection) {
      console.log(errorAddCollection)
    }
  }, [errorAddCollection])

  React.useEffect(() => {
    if (errorDeleteCollection) {
      console.log(errorDeleteCollection)
    }
  }, [errorDeleteCollection])

  React.useEffect(() => {
    if (errorUpdateCollection) {
      console.log(errorUpdateCollection)
    }
  }, [errorUpdateCollection])

  React.useEffect(() => {
    if (data && !error && !loading && !talesLoading) {
      setAllCollections(data.statusedCollectionsByIndex)
    }
  }, [data, loading, error, talesLoading])

  const addCollectionHandler = (form, closeFunc, coverImageFile) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addCollection({
          variables: {
            coverImageFile,
            input: {
              titleKZ: val.titleKZ,
              titleRU: val.titleRU,
              tales: val.tales,
              index: val.index,
              status: val.status,
              descriptionKZ: val.descriptionKZ ? val.descriptionKZ : null,
              descriptionRU: val.descriptionRU ? val.descriptionRU : null
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updateCollectionHandler = (
    form,
    collectionId,
    closeFunc,
    coverImageFile
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateCollection({
          variables: {
            coverImageFile,
            id: collectionId,
            input: {
              titleKZ: val.titleKZ,
              titleRU: val.titleRU,
              tales: val.tales,
              index: val.index,
              status: val.status,
              descriptionKZ: val.descriptionKZ ? val.descriptionKZ : null,
              descriptionRU: val.descriptionRU ? val.descriptionRU : null
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        closeFunc(false)
        // toast.success('Сборник обновлен')
      })
  }

  const removeFilesHandler = (collectionId, coverImage) => {
    updateCollection({
      variables: {
        id: collectionId,
        input: {
          coverImage: null
        }
      }
    }).then(() => {
      toast.success('Сборник обновлен')
    })
  }
  //   const updateCollectionHandler = (form, collectionId, closeFunc, coverImageFile) => {
  //   showLoading()
  //   form
  //     .validateFields()
  //     .then(val => {
  //       console.log(val)
  //       updateCollection({
  //         variables: {
  //           id: collectionId,
  //           input: {
  //             title: val.title,
  //             tales: val.tales
  //           }
  //         }
  //       })
  //     })
  //     .then(() => {
  //       hideLoading()
  //       closeFunc(false)
  //       toast.success('Плейлист обновлен')
  //     })
  // }
  const deleteCollectionHandler = (collectionId, closeFunc) => {
    showLoading()
    deleteCollection({
      variables: {
        id: collectionId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading || talesLoading) {
    return <Loading />
  }

  return (
    <Collections
      collections={allCollections}
      allTales={allTales}
      addCollection={addCollection}
      refetch={refetch}
      addCollectionHandler={addCollectionHandler}
      updateCollectionHandler={updateCollectionHandler}
      deleteCollectionHandler={deleteCollectionHandler}
      removeFilesHandler={removeFilesHandler}
    />
  )
}

export default WithMainLayout(CollectionsContainer)
