import React from 'react'

import { Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import './Color.css'

const ColorForm = ({
  form,
  color,
  index,
  status,
  setAudioFile,
  setImageFile,
  audioFileRef,
  imageFileRef,
  restrictions
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио</>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
      </div>
      {/* <label for="upload-photo">Аудио файл...</label>
      <input type="file" name="photo" id="upload-file" onChange={ (event) => setTextFile(event.target.files[0])} /> */}
      {/* <>Text File</>
      <input type="file" title="Choose Text File"  onChange={ (event) => setTextFile(event.target.files[0])}/>     */}
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность Цвета"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность Цвета`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность Цвета" />
      </FormItem>
      <FormItem
        key="color"
        label="Цвет"
        name="color"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Цвета`
          }
        ]}
        initialValue={color}
      >
        <Input placeholder="Цвет" />
      </FormItem>

      <FormItem
        key="restrictions"
        label="План"
        name="restrictions"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите план`
          }
        ]}
        initialValue={restrictions}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="basic" value="basic">
            Basic
          </Select.Option>
          <Select.Option key="premium" value="premium">
            Premium
          </Select.Option>
        </Select>
      </FormItem>
    </Form>
  )
}

ColorForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

ColorForm.defaultProps = {
  index: null,
  status: 'active'
}

export default ColorForm
