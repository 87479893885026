import React from 'react'

import { Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import './EducationElements.css'

const EducationElementForm = ({
  form,
  index,
  status,
  name,
  value,
  setAudioFile,
  setImageFile,
  setPracticeAudioFile,
  setPracticeImageFile,
  audioFileRef,
  imageFileRef,
  practiceAudioFileRef,
  practiceImageFileRef
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <div>
          <>Изображение</>
          <input
            type="file"
            accept="image/*"
            ref={imageFileRef}
            onChange={event => setImageFile(event.target.files[0])}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <>Аудио</>
          <input
            type="file"
            accept="audio/*"
            ref={audioFileRef}
            onChange={event => {
              setAudioFile(event.target.files[0])
            }}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <>Изображение практики</>
          <input
            type="file"
            accept="image/*"
            ref={practiceImageFileRef}
            onChange={event => setPracticeImageFile(event.target.files[0])}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <>Аудио Практики</>
          <input
            type="file"
            accept="audio/*"
            ref={practiceAudioFileRef}
            onChange={event => {
              setPracticeAudioFile(event.target.files[0])
            }}
          />
        </div>
      </div>
      <FormItem
        key="status"
        label="Статус Слова"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите очередность`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem
        key="name"
        label="Слово"
        name="name"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Слово`
          }
        ]}
        initialValue={name}>
        <Input
          placeholder="Слово"
          onInput={e => (e.target.value = e.target.value.toUpperCase())}
        />
      </FormItem>
      <FormItem key="value" label="Значение" name="value" initialValue={value}>
        <Input placeholder="Значение" />
      </FormItem>
    </Form>
  )
}

EducationElementForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

EducationElementForm.defaultProps = {
  name: '',
  value: '',
  status: 'active'
}

export default EducationElementForm
