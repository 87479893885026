import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import BackgroundImages from './BackgroundImages'
import { useLoading } from '../../../context/useLoading'

const GET_BACKGROUND_IMAGES = gql`
  query backgroundImages {
    backgroundImages {
      id
      imageUrl
      assignedTo
    }
  }
`
const CREATE_BACKGROUND_IMAGE = gql`
  mutation createBackgroundImage($input: BackgroundImageInput) {
    createBackgroundImage(input: $input) {
      id
      imageUrl
      assignedTo
    }
  }
`
const UPDATE_BACKGROUND_IMAGE = gql`
  mutation updateBackgroundImage($input: UpdateBackgroundImageInput) {
    updateBackgroundImage(input: $input) {
      id
      imageUrl
      assignedTo
    }
  }
`

const DELETE_BACKGROUND_IMAGE = gql`
  mutation deleteBackgroundImage($id: ID!) {
    deleteBackgroundImage(id: $id) {
      id
    }
  }
`

const BackgroundImagesContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [backgroundImages, setBackgroundImages] = useState([])

  const { loading } = useQuery(GET_BACKGROUND_IMAGES, {
    onCompleted: data => {
      setBackgroundImages(data.backgroundImages)
    },
    onError: error => console.log(error)
  })

  const [createBackgroundImage] = useMutation(CREATE_BACKGROUND_IMAGE, {
    onError: error => console.log(error),
    update(cache, { data: { createBackgroundImage: newItem } }) {
      const { backgroundImages } = cache.readQuery({
        query: GET_BACKGROUND_IMAGES
      })
      const newArray = backgroundImages
        .filter(item => item.id !== newItem.id)
        .concat(newItem)
      setBackgroundImages(newArray)
      cache.writeQuery({
        query: GET_BACKGROUND_IMAGES,
        data: {
          backgroundImages: newArray
        }
      })
      hideLoading()
      toast.success('Фото добавлено')
    }
  })

  const [updateBackgroundImage] = useMutation(UPDATE_BACKGROUND_IMAGE, {
    onError: error => console.log(error),
    update(cache, { data: { updateBackgroundImage: newItem } }) {
      const { backgroundImages } = cache.readQuery({
        query: GET_BACKGROUND_IMAGES
      })
      const newArray = backgroundImages
        .filter(item => item.id !== newItem.id)
        .concat(newItem)
      setBackgroundImages(newArray)
      cache.writeQuery({
        query: GET_BACKGROUND_IMAGES,
        data: {
          backgroundImages: newArray
        }
      })
      hideLoading()
      toast.success('Фото добавлено')
    }
  })

  const [deleteBackgroundImage] = useMutation(DELETE_BACKGROUND_IMAGE, {
    onError: error => console.log(error),
    update(cache, { data: { deleteBackgroundImage: newItem } }) {
      const { backgroundImages } = cache.readQuery({
        query: GET_BACKGROUND_IMAGES
      })
      const newArray = backgroundImages.filter(item => item.id !== newItem.id)
      setBackgroundImages(newArray)
      cache.writeQuery({
        query: GET_BACKGROUND_IMAGES,
        data: {
          backgroundImages: newArray
        }
      })
      hideLoading()
      toast.success('Фото удалено')
    }
  })

  const createHandler = (
    form,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        createBackgroundImage({
          variables: {
            input: {
              assignedTo: val.assignedTo,
              imageFile
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        if (imageFileRef.current) imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateHandler = (
    form,
    id,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        updateBackgroundImage({
          variables: {
            input: {
              id,
              assignedTo: val.assignedTo,
              imageFile
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        if (imageFileRef.current) imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteHandler = (id, closeFunc) => {
    showLoading()
    deleteBackgroundImage({
      variables: { id }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <BackgroundImages
      backgroundImages={backgroundImages}
      createHandler={createHandler}
      updateHandler={updateHandler}
      deleteHandler={deleteHandler}
    />
  )
}

export default WithMainLayout(BackgroundImagesContainer)
