import React from 'react'
import { Form, Input } from 'antd'

const BackgroundImagesForm = ({
  form,
  assignedTo,
  setImageFile,
  imageFileRef
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
      </div>
      <FormItem
        key="assignedTo"
        label="Назначение"
        name="assignedTo"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите назначение`
          }
        ]}
        initialValue={assignedTo}
      >
        <Input placeholder="Сандык" />
      </FormItem>
    </Form>
  )
}

export default BackgroundImagesForm
