import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import ProverbsCollections from './ProverbsCollections'

const GET_PROVERBS_COLLECTIONS = gql`
  query statusedProverbsCollections {
    statusedProverbsCollections {
      id
      titleRus
      titleKaz
      imageUrl
      status
      proverbs {
        id
        text
        type
      }
    }
  }
`
const CREATE_PROVERBS_COLLECTION = gql`
  mutation createProverbsCollection($input: ProverbsCollectionsInput) {
    createProverbsCollection(input: $input) {
      id
      titleRus
      titleKaz
      imageUrl
      status
      proverbs {
        id
        text
        type
      }
    }
  }
`
const UPDATE_PROVERBS_COLLECTION = gql`
  mutation updateProverbsCollection($input: UpdateProverbsCollectionInput) {
    updateProverbsCollection(input: $input) {
      id
      titleRus
      titleKaz
      imageUrl
      status
      proverbs {
        id
        text
        type
      }
    }
  }
`

const DELETE_PROVERBS_COLLECTION = gql`
  mutation deleteProverbsCollection($id: ID!) {
    deleteProverbsCollection(id: $id) {
      id
    }
  }
`

const GET_PROVERBS = gql`
  query proverbs {
    proverbs {
      id
      text
      type
    }
  }
`

const ProverbsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [proverbsCollections, setProverbsCollections] = useState([])
  const [proverbs, setProverbs] = useState([])

  const { loading: proverbsCollectionsLoading } = useQuery(
    GET_PROVERBS_COLLECTIONS,
    {
      onCompleted: data => {
        setProverbsCollections(data.statusedProverbsCollections)
      },
      onError: error => console.log(error)
    }
  )

  const { loading: proverbsLoading } = useQuery(GET_PROVERBS, {
    onCompleted: data => {
      setProverbs(data.proverbs)
    },
    onError: error => console.log(error)
  })

  const [createProverbsCollection] = useMutation(CREATE_PROVERBS_COLLECTION, {
    onError: error => console.log(error),
    update(cache, { data: { createProverbsCollection: newItem } }) {
      const { statusedProverbsCollections } = cache.readQuery({
        query: GET_PROVERBS_COLLECTIONS
      })
      const newArray = statusedProverbsCollections
        .filter(item => item.id !== newItem.id)
        .concat(newItem)
      setProverbsCollections(newArray)
      cache.writeQuery({
        query: GET_PROVERBS_COLLECTIONS,
        data: {
          statusedProverbsCollections: newArray
        }
      })
      hideLoading()
      toast.success('Коллекция добавлена')
    }
  })

  const [updateProverbsCollection] = useMutation(UPDATE_PROVERBS_COLLECTION, {
    onError: error => console.log(error),
    update(cache, { data: { updateProverbsCollection: newItem } }) {
      const { statusedProverbsCollections } = cache.readQuery({
        query: GET_PROVERBS_COLLECTIONS
      })
      const newArray = statusedProverbsCollections.map(item =>
        item.id !== newItem.id ? item : newItem
      )
      setProverbsCollections(newArray)
      cache.writeQuery({
        query: GET_PROVERBS_COLLECTIONS,
        data: {
          statusedProverbsCollections: newArray
        }
      })
      hideLoading()
      toast.success('Коллекция обновлена')
    }
  })

  const [deleteProverbsCollection] = useMutation(DELETE_PROVERBS_COLLECTION, {
    onError: error => console.log(error),
    update(cache, { data: { deleteProverbsCollection: newItem } }) {
      const { statusedProverbsCollections } = cache.readQuery({
        query: GET_PROVERBS_COLLECTIONS
      })
      const newArray = statusedProverbsCollections.filter(
        item => item.id !== newItem.id
      )
      setProverbsCollections(newArray)
      cache.writeQuery({
        query: GET_PROVERBS_COLLECTIONS,
        data: {
          statusedProverbsCollections: newArray
        }
      })
      hideLoading()
      toast.success('Коллекция удалена')
    }
  })

  const createHandler = (
    form,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        console.log(val.status)
        createProverbsCollection({
          variables: {
            input: {
              titleRus: val.titleRus,
              titleKaz: val.titleKaz,
              imageFile,
              status: val.status,
              proverbs: val.proverbs
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        if (imageFileRef.current) imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateHandler = (
    form,
    id,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateProverbsCollection({
          variables: {
            input: {
              id,
              titleRus: val.titleRus,
              titleKaz: val.titleKaz,
              imageFile,
              status: val.status,
              proverbs: val.proverbs
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        if (imageFileRef.current) imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteHandler = (id, closeFunc) => {
    showLoading()
    deleteProverbsCollection({
      variables: { id }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (proverbsCollectionsLoading || proverbsLoading) {
    return <Loading />
  }

  return (
    <ProverbsCollections
      proverbsCollections={proverbsCollections}
      createHandler={createHandler}
      updateHandler={updateHandler}
      deleteHandler={deleteHandler}
      proverbs={proverbs}
    />
  )
}

export default WithMainLayout(ProverbsContainer)
