import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, Avatar, message } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import GenreForm from './GenresForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Genres = ({
  genres,
  addGenreHandler,
  updateGenreHandler,
  deleteGenreHandler
}) => {
  const [form] = Form.useForm()
  const [formForUpdate] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [genreItem, setGenreItem] = React.useState(null)
  const [updateModal, setUpdateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [searchGenrename, setSearchGenrename] = React.useState(null)

  const [listGenres, setListGenres] = React.useState(null)

  const [file, setFile] = useState(null)
  const fileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveGenres = genres.map(genre => {
      return {
        id: genre.id,
        nameKZ: genre.nameKZ,
        nameRU: genre.nameRU,
        index: genre.index,
        status: genre.status,
        photo: genre.photo
      }
    })
    let searchArray = descriptiveGenres

    if (searchGenrename) {
      searchArray = searchArray.filter(genre => {
        return (
          genre.nameKZ?.toLowerCase().includes(searchGenrename) ||
          genre.nameRU?.toLowerCase().includes(searchGenrename)
        )
      })
    }
    setListGenres(searchArray)
  }, [genres, searchGenrename])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '1%'
    },
    // {
    //   title: 'Фото',
    //   width: '5%',
    //   render: item => {
    //     console.log("item: ", item.photo)
    //     return (
    //       <>
    //         <Avatar shape="square" size={64} src={item.photo} />
    //       </>
    //     )
    //   }
    // },
    {
      title: 'Название(каз)',
      dataIndex: 'nameKZ',
      width: '30%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'nameRU',
      width: '30%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <>
          {/* <span>
          <Link to={`/genres/${item.id}`}>Редактировать</Link>
        </span> */}
          <span>
            <Button
              type="link"
              onClick={() => {
                formForUpdate.resetFields()
                setUpdateModal(true)
                setGenreItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setGenreItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listGenres}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchGenrename(e.target.value.toLowerCase())
              }}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый жанр
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый жанр"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setFile(null)
          fileRef.current.value = null
        }}
        onOk={() => {
          if (file !== null) {
            addGenreHandler(form, setModalVisible, file, setFile, fileRef)
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <GenreForm
          setFile={setFile}
          file={file}
          form={form}
          fileRef={fileRef}
        />
      </Modal>
      {updateModal && (
        <Modal
          open={updateModal}
          onCancel={() => {
            setUpdateModal(false)
            setFile(null)
            fileRef.current.value = null
          }}
          onOk={() => {
            updateGenreHandler(
              formForUpdate,
              genreItem.id,
              setUpdateModal,
              file,
              setFile,
              fileRef
            )
          }}
        >
          <GenreForm
            form={formForUpdate}
            nameKZ={genreItem.nameKZ}
            nameRU={genreItem.nameRU}
            index={genreItem.index}
            photo={genreItem.photo}
            status={genreItem.status}
            fileRef={fileRef}
            setFile={setFile}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteGenreHandler(genreItem.id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить {genreItem.nameKZ} -{' '}
            {genreItem.nameRU} ?
          </h3>
        </Modal>
      )}
    </>
  )
}

Genres.propTypes = {
  genres: PropTypes.arrayOf(PropTypes.object).isRequired,
  addGenreHandler: PropTypes.func.isRequired,
  updateGenreHandler: PropTypes.func.isRequired
}

export default Genres
