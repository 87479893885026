import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Words from './Words'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_WORDS = gql`
  query words {
    words {
      id
      word
      order
      wordAudioURL
      secondAudioUrl
      link2Image
      syllables {
        order
        syllable {
          syllable
          syllableAudioURL
        }
      }
    }
  }
`
const GET_SYLLABLES = gql`
  query syllables {
    syllables {
      id
      syllable
      syllableAudioURL
    }
  }
`

const ADD_WORD = gql`
  mutation addWord(
    $audioFile: FileUpload!
    $secondAudioFile: FileUpload!
    $imageFile: FileUpload!
    $input: WordInput!
  ) {
    addWord(
      audioFile: $audioFile
      secondAudioFile: $secondAudioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      order
      word
      wordAudioURL
      secondAudioUrl
      link2Image
      syllables {
        order
        syllable {
          syllable
          syllableAudioURL
        }
      }
    }
  }
`

const UPDATE_WORD = gql`
  mutation updateWord(
    $id: ID!
    $audioFile: FileUpload
    $secondAudioFile: FileUpload
    $imageFile: FileUpload
    $input: WordInput
  ) {
    updateWord(
      id: $id
      audioFile: $audioFile
      secondAudioFile: $secondAudioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      order
      word
      wordAudioURL
      secondAudioUrl
      link2Image
      syllables {
        order
        syllable {
          syllable
          syllableAudioURL
        }
      }
    }
  }
`

const DELETE_WORD = gql`
  mutation deleteWord($id: ID!) {
    deleteWord(id: $id) {
      id
    }
  }
`

const WordsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allwords, setAllWords] = React.useState([])
  const [syllablesDataList, setSyllablesDataList] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_WORDS)
  const {
    data: syllablesData,
    loadingSyllables,
    errorSyllables,
    refetchSyllables
  } = useQuery(GET_SYLLABLES)

  const [addWord, { error: errorAddWord }] = useMutation(ADD_WORD, {
    update(cache, { data: { addWord: addWordItem } }) {
      const { word } = cache.readQuery({
        query: GET_WORDS
      })
      cache.writeQuery({
        query: GET_WORDS,
        data: {
          word: word.concat(addWordItem)
        }
      })
      hideLoading()
      toast.success('Слово добавлено')
    }
  })
  const [updateWord, { error: errorUpdateWord }] = useMutation(UPDATE_WORD, {
    update(cache, { data: { updateWord: updateWordItem } }) {
      const { statusedLettersByIndex } = cache.readQuery({
        query: GET_WORDS
      })
      cache.writeQuery({
        query: GET_WORDS,
        data: {}
      })
      hideLoading()
      toast.success('Слово обновлено!')
    }
  })
  const [deleteWord, { error: errorDeleteWord }] = useMutation(DELETE_WORD, {
    update(cache, { data: { deleteWord: deleteWordItem } }) {
      const { word } = cache.readQuery({
        query: GET_WORDS
      })
      cache.writeQuery({
        query: GET_WORDS,
        data: word
      })
      hideLoading()
      toast.success('Слово удалено')
    }
  })

  React.useEffect(() => {
    if (errorAddWord) {
      console.log(errorAddWord)
    }
  }, [errorAddWord])

  React.useEffect(() => {
    if (errorDeleteWord) {
      console.log(errorDeleteWord)
    }
  }, [errorDeleteWord])

  React.useEffect(() => {
    if (errorUpdateWord) {
      console.log(errorUpdateWord)
    }
  }, [errorUpdateWord])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllWords(data.words)
    }
  }, [data, loading, error])

  React.useEffect(() => {
    if (syllablesData && !errorSyllables && !loadingSyllables) {
      setSyllablesDataList(syllablesData.syllables)
    }
  }, [syllablesData, loadingSyllables, errorSyllables])

  const addWordHandler = (
    form,
    closeFunc,
    audioFile,
    secondAudioFile,
    imageFile,
    setAudioFile,
    setSecondAudioFile,
    setImageFile,
    audioFileRef,
    secondAudioFileRef,
    imageFileRef,
    syllableAndOrder
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addWord({
          variables: {
            audioFile: audioFile,
            secondAudioFile: secondAudioFile,
            imageFile: imageFile,
            input: {
              word: val.word,
              order: val.order,
              syllables: syllableAndOrder
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setSecondAudioFile(null)
        setImageFile(null)
        hideLoading()
        audioFileRef.current.value = null
        secondAudioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
      })
      .finally(() => {
        refetch()
      })
  }

  const updateWordHandler = (
    form,
    id,
    closeFunc,
    audioFile,
    secondAudioFile,
    imageFile,
    setAudioFile,
    setSecondAudioFile,
    setImageFile,
    audioFileRef,
    secondAudioFileRef,
    imageFileRef,
    syllableAndOrder
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        console.log('syllableAndOrder .>>', syllableAndOrder)
        updateWord({
          variables: {
            id: id,
            audioFile,
            secondAudioFile,
            imageFile,
            input: {
              word: val.word,
              order: val.order,
              syllables: syllableAndOrder
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        closeFunc(false)
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        secondAudioFileRef.current.value = null
        toast.success('Слово обновлено!')
      })
  }

  const deleteLetterHandler = (id, closeFunc) => {
    showLoading()
    deleteWord({
      variables: {
        id: id
      }
    })
      .then(() => {
        closeFunc(false)
      })
      .finally(() => {
        refetch()
      })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Words
      words={allwords}
      addWord={addWord}
      refetch={refetch}
      refetchSyllables={refetchSyllables}
      addWordHandler={addWordHandler}
      updateWordHandler={updateWordHandler}
      deleteLetterHandler={deleteLetterHandler}
      syllablesList={syllablesDataList}
    />
  )
}

export default WithMainLayout(WordsContainer)
