import React, { useState } from 'react'
import { Table, Button, Form, Modal, message, Avatar } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PracticeFigureForm from './PracticeFigureForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const PracticeFigure = ({
  figures,
  loading,
  addPracticeFigureHandler,
  updatePracticeFigureHandler,
  deletePracticeFigureHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [figureItem, setPracticeFigureItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [audioFile, setAudioFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [objectItem, setObjectItem] = useState(null)
  const [imageBucket, setImageBucket] = useState(null)
  const [imageMin, setImageMin] = useState(null)

  const audioFileRef = React.useRef()
  const imageFileRef = React.useRef()
  const imageMinRef = React.useRef()
  const objectItemRef = React.useRef()
  const imageBucketRef = React.useRef()

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: '',
      width: '1%',
      dataIndex: 'index'
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.audioURL
        if (!audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            <source src={audioLink} type="audio/mpeg" />
          </audio>
        )
      }
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      width: '10%'
    },

    {
      title: 'Фото',
      render: item => <Avatar shape="square" size={64} src={item.imageURL} />
    },
    {
      title: 'Объект',
      render: item => (
        <Avatar shape="square" size={64} src={item.objectItemURL} />
      )
    },
    {
      title: 'Корзина',
      render: item => (
        <Avatar shape="square" size={64} src={item.imageBucketURL} />
      )
    },
    {
      title: 'Фото минимализм',
      render: item => <Avatar shape="square" size={64} src={item.imageMinURL} />
    },
    {
      title: 'Имя',
      dataIndex: 'restrictions',
      width: '10%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setPracticeFigureItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setPracticeFigureItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={figures}
        columns={columns}
        rowKey={item => item.id}
        loading={loading}
        pagination={{
          showSizeChanger: false
        }}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая фигура
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новаая фигура"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setImageFile(null)
          setImageMin(null)
          setObjectItem(null)
          setImageBucket(null)
          audioFileRef.current.value = null
          imageFileRef.current.value = null
          imageMinRef.current.value = null
          objectItemRef.current.value = null
          imageBucketRef.current.value = null
        }}
        onOk={() => {
          if (audioFile && imageFile) {
            addPracticeFigureHandler(
              form,
              setModalVisible,
              audioFile,
              setAudioFile,
              audioFileRef,
              imageFile,
              imageFileRef,
              setImageFile,
              imageMin,
              imageMinRef,
              setImageFile,
              objectItem,
              objectItemRef,
              setObjectItem,
              imageBucket,
              setImageBucket,
              imageBucketRef
            )
          } else {
            message.error('Пожалуйста, выберите файл')
          }
        }}
      >
        <PracticeFigureForm
          setAudioFile={setAudioFile}
          audioFileRef={audioFileRef}
          imageFileRef={imageFileRef}
          setImageFile={setImageFile}
          setImageMin={setImageMin}
          objectItemRef={objectItemRef}
          setObjectItem={setObjectItem}
          setImageBucket={setImageBucket}
          imageBucketRef={imageBucketRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setImageFile(null)
            setImageMin(null)
            setObjectItem(null)
            setImageBucket(null)
            audioFileRef.current.value = null
            imageFileRef.current.value = null
            imageMinRef.current.value = null
            objectItemRef.current.value = null
            imageBucketRef.current.value = null
          }}
          onOk={() => {
            updatePracticeFigureHandler(
              formForEdit,
              figureItem.id,
              setEditModal,
              audioFile,
              setAudioFile,
              audioFileRef,
              imageFile,
              setImageFile,
              imageFileRef,
              imageMin,
              setImageMin,
              imageMinRef,
              objectItem,
              setObjectItem,
              objectItemRef,
              imageBucket,
              setImageBucket,
              imageBucketRef
            )
          }}
        >
          <PracticeFigureForm
            form={formForEdit}
            index={figureItem.index}
            name={figureItem.name}
            status={figureItem.status}
            restrictions={figureItem.restrictions}
            setAudioFile={setAudioFile}
            audioFileRef={audioFileRef}
            setImageFile={setImageFile}
            imageFileRef={imageFileRef}
            setImageMin={setImageMin}
            imageMinRef={imageMinRef}
            objectItemRef={objectItemRef}
            setObjectItem={setObjectItem}
            imageBucketRef={imageBucketRef}
            setImageBucket={setImageBucket}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deletePracticeFigureHandler(figureItem.id, setDeleteModal)
          }
        >
          <h3>Вы действительно хотите удалить '{figureItem.figure}' ?</h3>
        </Modal>
      )}
    </>
  )
}

PracticeFigure.propTypes = {
  figures: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPracticeFigureHandler: PropTypes.func.isRequired,
  updatePracticeFigureHandler: PropTypes.func.isRequired
}

export default PracticeFigure
