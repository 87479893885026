import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Digits from './Digits'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_DIGITS_BY_INDEX = gql`
  query statusedDigitsByIndex {
    statusedDigitsByIndex {
      id
      digit
      index
      link2Audio
      link2Image
      status
    }
  }
`

const ADD_DIGIT = gql`
  mutation addDigit(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $input: DigitInput!
  ) {
    addDigit(audioFile: $audioFile, imageFile: $imageFile, input: $input) {
      id
      digit
      index
      link2Audio
      link2Image
      status
    }
  }
`

const UPDATE_DIGIT = gql`
  mutation updateDigit(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $input: UpdateDigitInput
  ) {
    updateDigit(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      digit
      index
      link2Audio
      link2Image
      status
    }
  }
`

// const DELETE_DIGIT = gql`
//   mutation deleteDigit($id: ID!) {
//     deleteDigit(id: $id) {
//       id
//       message
//     }
//   }
// `

const DELETE_DIGIT = gql`
  mutation updateDigit($id: ID!, $input: UpdateDigitInput) {
    updateDigit(id: $id, input: $input) {
      id
      digit
      index
      link2Audio
      link2Image
      status
    }
  }
`

const DigitsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allDigits, setAllDigits] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_DIGITS_BY_INDEX)
  const [addDigit, { error: errorAddDigit }] = useMutation(ADD_DIGIT, {
    update(cache, { data: { addDigit: addDigitItem } }) {
      const { statusedDigitsByIndex } = cache.readQuery({
        query: GET_DIGITS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_DIGITS_BY_INDEX,
        data: {
          statusedDigitsByIndex: statusedDigitsByIndex
            .concat(addDigitItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Цифра добавлена')
    }
  })
  // const [updateDigit, { data: updateDigitData }] = useMutation(UPDATE_DIGIT)
  const [updateDigit, { error: errorUpdateDigit }] = useMutation(UPDATE_DIGIT, {
    update(cache, { data: { updateDigit: updateDigitItem } }) {
      const { statusedDigitsByIndex } = cache.readQuery({
        query: GET_DIGITS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_DIGITS_BY_INDEX,
        data: {
          statusedDigitsByIndex: statusedDigitsByIndex
            .filter(digit => digit.id !== updateDigitItem.id)
            .concat(updateDigitItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Цифра обновлена')
    }
  })
  const [deleteDigit, { error: errorDeleteDigit }] = useMutation(DELETE_DIGIT, {
    update(cache, { data: { updateDigit: deleteDigitItem } }) {
      const { statusedDigitsByIndex } = cache.readQuery({
        query: GET_DIGITS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_DIGITS_BY_INDEX,
        data: {
          statusedDigitsByIndex: statusedDigitsByIndex.filter(
            digit => digit.id !== deleteDigitItem.id
          )
        }
      })
      hideLoading()
      toast.success('Цифра удалена')
    }
  })

  React.useEffect(() => {
    if (errorAddDigit) {
      console.log(errorAddDigit)
    }
  }, [errorAddDigit])

  React.useEffect(() => {
    if (errorDeleteDigit) {
      console.log(errorDeleteDigit)
    }
  }, [errorDeleteDigit])

  React.useEffect(() => {
    if (errorUpdateDigit) {
      console.log(errorUpdateDigit)
    }
  }, [errorUpdateDigit])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllDigits(data.statusedDigitsByIndex)
    }
  }, [data, loading, error])

  const addDigitHandler = (
    form,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        // console.log('audioFile', audioFile)
        // console.log('imageFile', imageFile)
        // console.log('val', val)
        form.resetFields()
        showLoading()
        addDigit({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            input: {
              digit: Number(val.digit),
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateDigitHandler = (
    form,
    digitId,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateDigit({
          variables: {
            id: digitId,
            audioFile,
            imageFile,
            input: {
              digit: Number(val.digit),
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        // toast.success('Буква обновлена')
      })
  }

  const deleteDigitHandler = (digitId, closeFunc) => {
    showLoading()
    deleteDigit({
      variables: {
        id: digitId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Digits
      digits={allDigits}
      addDigit={addDigit}
      refetch={refetch}
      addDigitHandler={addDigitHandler}
      updateDigitHandler={updateDigitHandler}
      deleteDigitHandler={deleteDigitHandler}
    />
  )
}

export default WithMainLayout(DigitsContainer)
