import React from 'react'
import { Table, Button, Form, Modal, Input } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import UserForm from './UserForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Users = ({
  users,
  addUserHandler,
  editUserHandler,
  pagination,
  refetch,
  searchedUser
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [userItem, setUserItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [searchUsername, setSearchUsername] = React.useState(searchedUser)

  const [listUsers, setListUsers] = React.useState(null)

  React.useEffect(() => {
    const descriptiveUsers = users.map(user => {
      const expiresDate =
        user.subscription.expiresAt === 0
          ? moment()
          : moment(user.subscription.expiresAt)

      const formattedExpiresDate = expiresDate.format('DD.MM.YYYY')

      return {
        id: user.id,
        phoneNumber: user.phoneNumber,
        name: user.name,
        childAge: user.childAge,
        role: user.role,
        plan: user.subscription.plan,
        expiresDate: formattedExpiresDate,
        usedEmailAddress: user.usedEmailAddress
      }
    })
    let searchArray = descriptiveUsers

    setTimeout(() => {
      refetch({
        search: searchUsername
      })
    }, 500)

    setListUsers(searchArray)
  }, [users, searchUsername])

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      width: '25%'
    },
    {
      title: 'Номер телефона / Username',
      dataIndex: 'phoneNumber',
      width: '20%'
    },
    {
      title: 'e-mail',
      dataIndex: 'usedEmailAddress',
      width: '20%'
    },
    {
      title: 'Возраст ребёнка',
      dataIndex: 'childAge',
      width: '20%'
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      width: '15%'
    },
    {
      title: 'План',
      dataIndex: 'plan',
      width: '15%'
    },
    {
      title: 'Заканчивается',
      dataIndex: 'expiresDate',
      width: '15%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <span>
          <Link to={`/users/${item.id}`}>Редактировать</Link>
        </span> */}
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setUserItem(item)
            }}
          >
            Редактировать пользователя
          </Button>
          <Link to={'/userLevel/' + item.id}>Редактировать уровень</Link>
        </div>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listUsers}
        columns={columns}
        rowKey={item => item.id}
        pagination={pagination} // Use pagination prop
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Номер телефона/Имя/e-mail пользователя"
              value={searchUsername} // Set the value prop
              onChange={e => {
                setSearchUsername(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый пользователь
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый пользователь"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => addUserHandler(form, setModalVisible)}
      >
        <UserForm form={form} withPassword />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
          }}
          onOk={() => editUserHandler(formForEdit, userItem.id, setEditModal)}
        >
          <UserForm
            form={formForEdit}
            phoneNumber={userItem.phoneNumber}
            role={userItem.role}
            name={userItem.name}
            plan={userItem.plan}
            expiresDate={moment(userItem.expiresDate, 'DD.MM.YYYY')}
            childAge={userItem.childAge}
          />
        </Modal>
      )}
    </>
  )
}

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  addUserHandler: PropTypes.func.isRequired,
  editUserHandler: PropTypes.func.isRequired
}

export default Users
