import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import PublisherForm from './PublisherForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Publishers = ({
  publishers,
  addPublisherHandler,
  updatePublisherHandler,
  deletePublisherHandler
}) => {
  const [form] = Form.useForm()
  const [formForUpdate] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [publisherItem, setPublisherItem] = React.useState(null)
  const [updateModal, setUpdateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [searchPublishername, setSearchPublishername] = React.useState(null)

  const [listPublishers, setListPublishers] = React.useState(null)

  React.useEffect(() => {
    const descriptivePublishers = publishers.map(publisher => {
      return {
        id: publisher.id,
        name: publisher.name,
        photo: publisher.photo,
        status: publisher.status
      }
    })
    let searchArray = descriptivePublishers

    if (searchPublishername) {
      searchArray = searchArray.filter(publisher => {
        return publisher.name?.toLowerCase().includes(searchPublishername)
      })
    }

    setListPublishers(searchArray)
  }, [publishers, searchPublishername])

  const [file, setFile] = useState(null)

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      width: '35%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForUpdate.resetFields()
                setUpdateModal(true)
                setPublisherItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setPublisherItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listPublishers}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Name"
              onChange={e => {
                setSearchPublishername(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый издатель
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый издатель"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => addPublisherHandler(form, setModalVisible, file)}
      >
        <PublisherForm setFile={setFile} file={file} form={form} />
      </Modal>
      {updateModal && (
        <Modal
          open={updateModal}
          onCancel={() => {
            setUpdateModal(false)
          }}
          onOk={() =>
            updatePublisherHandler(
              formForUpdate,
              publisherItem.id,
              setUpdateModal
            )
          }
        >
          <PublisherForm
            form={formForUpdate}
            name={publisherItem.name}
            photo={publisherItem.photo}
            status={publisherItem.status}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deletePublisherHandler(publisherItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {publisherItem.name} ?</h3>
        </Modal>
      )}
    </>
  )
}

Publishers.propTypes = {
  publishers: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPublisherHandler: PropTypes.func.isRequired,
  updatePublisherHandler: PropTypes.func.isRequired
}

export default Publishers
