import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, Avatar, message } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import CategoryForm from './CategoriesForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Categories = ({
  categories,
  addCategoryHandler,
  updateCategoryHandler,
  deleteCategoryHandler
}) => {
  const [form] = Form.useForm()
  const [formForUpdate] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [categoryItem, setCategoryItem] = React.useState(null)
  const [updateModal, setUpdateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [searchCategoryname, setSearchCategoryname] = React.useState(null)

  const [listCategories, setListCategories] = React.useState(null)

  const [file, setFile] = useState(null)
  const fileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveCategories = categories.map(category => {
      return {
        id: category.id,
        nameKZ: category.nameKZ,
        nameRU: category.nameRU,
        index: category.index,
        status: category.status,
        photo: category.photo
      }
    })
    let searchArray = descriptiveCategories

    if (searchCategoryname) {
      searchArray = searchArray.filter(category => {
        return (
          category.nameKZ?.toLowerCase().includes(searchCategoryname) ||
          category.nameRU?.toLowerCase().includes(searchCategoryname)
        )
      })
    }
    setListCategories(searchArray)
  }, [categories, searchCategoryname])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '1%'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.photo} />
          </>
        )
      }
    },
    {
      title: 'Название(каз)',
      dataIndex: 'nameKZ',
      width: '30%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'nameRU',
      width: '30%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <>
          {/* <span>
          <Link to={`/categories/${item.id}`}>Редактировать</Link>
        </span> */}
          <span>
            <Button
              type="link"
              onClick={() => {
                formForUpdate.resetFields()
                setUpdateModal(true)
                setCategoryItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setCategoryItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listCategories}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchCategoryname(e.target.value.toLowerCase())
              }}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая Категория
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая Категория"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setFile(null)
          fileRef.current.value = null
        }}
        onOk={() => {
          if (file !== null) {
            addCategoryHandler(form, setModalVisible, file, setFile, fileRef)
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <CategoryForm
          setFile={setFile}
          file={file}
          form={form}
          fileRef={fileRef}
        />
      </Modal>
      {updateModal && (
        <Modal
          open={updateModal}
          onCancel={() => {
            setUpdateModal(false)
            setFile(null)
            fileRef.current.value = null
          }}
          onOk={() => {
            updateCategoryHandler(
              formForUpdate,
              categoryItem.id,
              setUpdateModal,
              file,
              setFile,
              fileRef
            )
          }}
        >
          <CategoryForm
            form={formForUpdate}
            nameKZ={categoryItem.nameKZ}
            nameRU={categoryItem.nameRU}
            index={categoryItem.index}
            photo={categoryItem.photo}
            status={categoryItem.status}
            fileRef={fileRef}
            setFile={setFile}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteCategoryHandler(categoryItem.id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить {categoryItem.nameKZ} -{' '}
            {categoryItem.nameRU} ?
          </h3>
        </Modal>
      )}
    </>
  )
}

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  addCategoryHandler: PropTypes.func.isRequired,
  updateCategoryHandler: PropTypes.func.isRequired
}

export default Categories
