import React from 'react'

import { Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import './Letter.css'
// import Genres from '../shared/genres'
import Instruments from '../shared/Instruments'

const LetterForm = ({
  form,
  letter,
  word,
  index,
  status,
  setAudioFile,
  setSecondAudioFile,
  setImageFile,
  audioFileRef,
  secondAudioFileRef,
  imageFileRef,
  restrictions
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио</>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
        <>Звучание буквы </>
        <input
          type="file"
          accept="audio/*"
          ref={secondAudioFileRef}
          onChange={event => {
            setSecondAudioFile(event.target.files[0])
          }}
        />
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
      </div>
      {/* <label for="upload-photo">Аудио файл...</label>
      <input type="file" name="photo" id="upload-file" onChange={ (event) => setTextFile(event.target.files[0])} /> */}
      {/* <>Text File</>
      <input type="file" title="Choose Text File"  onChange={ (event) => setTextFile(event.target.files[0])}/>     */}
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность Буквы"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность Буквы`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность Буквы" />
      </FormItem>
      <FormItem
        key="letter"
        label="Буква"
        name="letter"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Букву`
          }
        ]}
        initialValue={letter}
      >
        <Input placeholder="Буква" maxLength={1} />
      </FormItem>
      <FormItem
        key="word"
        label="Слово"
        name="word"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Слово`
          }
        ]}
        initialValue={word}
      >
        <Input placeholder="Слово" />
      </FormItem>

      <FormItem
        key="restrictions"
        label="План"
        name="restrictions"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите план`
          }
        ]}
        initialValue={restrictions}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="basic" value="basic">
            Basic
          </Select.Option>
          <Select.Option key="premium" value="premium">
            Premium
          </Select.Option>
        </Select>
      </FormItem>
    </Form>
  )
}

LetterForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

LetterForm.defaultProps = {
  letter: '',
  word: '',
  index: null,
  status: 'active'
}

export default LetterForm
