import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import Papa from 'papaparse'
import { message } from 'antd'
import { useLoading } from '../../context/useLoading'
import QaraSozs from './QaraSozs'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_QARASOZS_BY_INDEX = gql`
  query statusedQaraSozs1 {
    statusedQaraSozs1 {
      id
      title
      index
      authors {
        id
        name
      }
      voiceActors {
        id
        name
      }
      link2Audio
      coverImage
      status
      type
      version
      lyrics
    }
  }
`

const ADD_QARASOZ = gql`
  mutation addContentAudio(
    $audiofile: FileUpload!
    $coverImageFile: FileUpload
    $input: ContentAudioInput
  ) {
    addContentAudio(
      audiofile: $audiofile
      coverImageFile: $coverImageFile
      input: $input
    ) {
      id
      title
      index
      authors {
        id
        name
      }
      voiceActors {
        id
        name
      }
      link2Audio
      coverImage
      status
      type
      version
      lyrics
    }
  }
`

const UPDATE_QARASOZ = gql`
  mutation updateContentAudio(
    $id: ID!
    $audiofile: FileUpload
    $coverImageFile: FileUpload
    $input: UpdateContentAudioInput
  ) {
    updateContentAudio(
      id: $id
      audiofile: $audiofile
      coverImageFile: $coverImageFile
      input: $input
    ) {
      id
      title
      index
      authors {
        id
        name
      }
      voiceActors {
        id
        name
      }
      link2Audio
      coverImage
      status
      type
      version
      lyrics
    }
  }
`

const DELETE_QARASOZ = gql`
  mutation updateContentAudio($id: ID!, $input: UpdateContentAudioInput) {
    updateContentAudio(id: $id, input: $input) {
      id
      title
      index
      authors {
        id
        name
      }
      voiceActors {
        id
        name
      }
      link2Audio
      coverImage
      status
      type
      version
      lyrics
    }
  }
`

// const DELETE_QARASOZ = gql`
//   mutation deleteQaraSoz($id: ID!) {
//     deleteQaraSoz(id: $id) {
//       id
//       message
//     }
//   }
// `

const GET_CREATORS = gql`
  query creators {
    creators {
      id
      name
      photo
    }
  }
`
// let sortedStudents = students.sort((a, b) =>
//   a.firstName.toLowerCase() < b.firstName.toLowerCase()
//     ? -1
//     : b.firstName.toLowerCase() > a.firstName.toLowerCase()
//     ? 1
//     : 0
// )
const QaraSozsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allQaraSozs, setAllQaraSozs] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_QARASOZS_BY_INDEX)
  const { data: allCreators, loading: creatorsLoading } = useQuery(GET_CREATORS)
  const [addContentAudio, { error: errorAddQaraSoz }] = useMutation(
    ADD_QARASOZ,
    {
      update(cache, { data: { addContentAudio: addQaraSozItem } }) {
        const { statusedQaraSozs1 } = cache.readQuery({
          query: GET_QARASOZS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_QARASOZS_BY_INDEX,
          data: {
            statusedQaraSozs1: statusedQaraSozs1
              .concat(addQaraSozItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Аудио добавлено')
      }
    }
  )
  const [updateContentAudio, { error: errorUpdateQaraSoz }] = useMutation(
    UPDATE_QARASOZ,
    {
      update(cache, { data: { updateContentAudio: updateQaraSozItem } }) {
        const { statusedQaraSozs1 } = cache.readQuery({
          query: GET_QARASOZS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_QARASOZS_BY_INDEX,
          data: {
            statusedQaraSozs1: statusedQaraSozs1
              .filter(qaraSoz => qaraSoz.id !== updateQaraSozItem.id)
              .concat(updateQaraSozItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Аудио обновлено')
      }
    }
  )
  // const [updateContentAudio, { data: updateQaraSozData }] = useMutation(
  //   UPDATE_QARASOZ
  // )
  const [deleteQaraSoz, { error: errorDeleteQaraSoz }] = useMutation(
    DELETE_QARASOZ,
    {
      update(cache, { data: { updateContentAudio: deleteQaraSozItem } }) {
        const { statusedQaraSozs1 } = cache.readQuery({
          query: GET_QARASOZS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_QARASOZS_BY_INDEX,
          data: {
            statusedQaraSozs1: statusedQaraSozs1.filter(
              qaraSoz => qaraSoz.id !== deleteQaraSozItem.id
            )
          }
        })
        hideLoading()
        toast.success('Аудио удалено')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddQaraSoz) {
      console.log(errorAddQaraSoz)
    }
  }, [errorAddQaraSoz])

  React.useEffect(() => {
    if (errorUpdateQaraSoz) {
      console.log(errorUpdateQaraSoz)
    }
  }, [errorUpdateQaraSoz])

  React.useEffect(() => {
    if (errorDeleteQaraSoz) {
      console.log(errorDeleteQaraSoz)
    }
  }, [errorDeleteQaraSoz])

  React.useEffect(() => {
    if (data && !error && !loading && !creatorsLoading) {
      setAllQaraSozs(data.statusedQaraSozs1)
    }
  }, [data, loading, error, creatorsLoading])

  function csv2StringParser(file) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete(results, file) {
          try {
            const obj = {}
            results.data.map(item => {
              if (item.Time) obj[Number(item.Time.trim())] = item.Text.trim()
            })
            if (Object.keys(obj).length === 0) {
              message.error('Невозможно обработать текстовый файл')
              resolve(-1)
            }
            resolve(JSON.stringify(obj))
          } catch {
            message.error('Невозможно обработать текстовый файл')
            resolve(-1)
          }
        },
        error(err, file) {
          message.error('Невозможно обработать текстовый файл')
          console.log(err)
          resolve(-1)
        }
      })
    })
  }

  const addQaraSozHandler = async (
    form,
    closeFunc,
    audioFile,
    textFile,
    imageFile,
    setAudioFile,
    setTextFile,
    setImageFile,
    audioFileRef,
    textFileRef,
    imageFileRef
  ) => {
    showLoading()
    let lyrics
    if (textFile) lyrics = await csv2StringParser(textFile)
    // console.log('lyrics', lyrics)
    lyrics !== -1
      ? form
          .validateFields()
          .then(val => {
            // console.log('audioFile', audioFile)
            // console.log('imageFile', imageFile)
            // console.log('val', val)
            form.resetFields()
            addContentAudio({
              variables: {
                audiofile: audioFile,
                coverImageFile: imageFile,
                input: {
                  type: 'qaraSoz',
                  title: val.title,
                  authors: val.authors,
                  voiceActors: val.voiceActors,
                  status: val.status,
                  index: val.index,
                  version: val.version,
                  lyrics: lyrics ? lyrics : val.lyrics
                }
              }
            })
          })
          .then(() => {
            setAudioFile(null)
            setTextFile(null)
            setImageFile(null)
            audioFileRef.current.value = null
            textFileRef.current.value = null
            imageFileRef.current.value = null
            closeFunc(false)
          })
      : hideLoading()
  }

  const updateQaraSozHandler = async (
    form,
    qaraSozId,
    closeFunc,
    audioFile,
    textFile,
    imageFile,
    setAudioFile,
    setTextFile,
    setImageFile,
    audioFileRef,
    textFileRef,
    imageFileRef
  ) => {
    showLoading()
    let lyrics
    if (textFile) lyrics = await csv2StringParser(textFile)
    lyrics !== -1
      ? form
          .validateFields()
          .then(val => {
            updateContentAudio({
              variables: {
                id: qaraSozId,
                audiofile: audioFile,
                coverImageFile: imageFile,
                input: {
                  title: val.title,
                  authors: val.authors,
                  voiceActors: val.voiceActors,
                  status: val.status,
                  index: val.index,
                  version: val.version,
                  lyrics: lyrics ? lyrics : val.lyrics
                }
              }
            })
          })
          .then(() => {
            setAudioFile(null)
            setTextFile(null)
            setImageFile(null)

            audioFileRef.current.value = null
            textFileRef.current.value = null
            imageFileRef.current.value = null

            closeFunc(false)
          })
      : hideLoading()
  }

  const deleteQaraSozHandler = (qaraSozId, closeFunc) => {
    showLoading()
    deleteQaraSoz({
      variables: {
        id: qaraSozId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // console.log("data ", deleteQaraSozData)
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading || creatorsLoading) {
    return <Loading />
  }

  return (
    <QaraSozs
      qaraSozs={allQaraSozs}
      allCreators={allCreators}
      // addQaraSoz={addQaraSoz}
      refetch={refetch}
      addQaraSozHandler={addQaraSozHandler}
      updateQaraSozHandler={updateQaraSozHandler}
      deleteQaraSozHandler={deleteQaraSozHandler}
    />
  )
}

export default WithMainLayout(QaraSozsContainer)
