import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import Papa from 'papaparse'
import { message } from 'antd'
import { useLoading } from '../../context/useLoading'
import ContentAudios from './ContentAudios'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_CONTENTAUDIOS = gql`
  query statusedSongs {
    statusedSongs {
      id
      title
      genres {
        id
        nameKZ
        nameRU
      }
      categories {
        id
        nameKZ
        nameRU
      }
      authors {
        id
        name
      }
      composers {
        id
        name
      }
      voiceActors {
        id
        name
      }
      publisher {
        id
        name
      }
      instruments {
        id
        nameKZ
        nameRU
        descriptionKZ
        descriptionRU
        photo
      }
      link2Audio
      link2Text
      coverImage
      status
      lyrics
    }
  }
`

const ADD_CONTENTAUDIO = gql`
  mutation addContentAudio(
    $audiofile: FileUpload!
    $textfile: FileUpload
    $coverImageFile: FileUpload
    $input: ContentAudioInput
  ) {
    addContentAudio(
      audiofile: $audiofile
      textfile: $textfile
      coverImageFile: $coverImageFile
      input: $input
    ) {
      id
      title
      genres {
        id
        nameKZ
        nameRU
      }
      categories {
        id
        nameKZ
        nameRU
      }
      authors {
        id
        name
      }
      composers {
        id
        name
      }
      voiceActors {
        id
        name
      }
      publisher {
        id
        name
      }
      instruments {
        id
        nameKZ
        nameRU
        descriptionKZ
        descriptionRU
        photo
      }
      link2Audio
      link2Text
      coverImage
      status
      lyrics
    }
  }
`

const UPDATE_CONTENTAUDIO = gql`
  mutation updateContentAudio(
    $id: ID!
    $audiofile: FileUpload
    $textfile: FileUpload
    $coverImageFile: FileUpload
    $input: UpdateContentAudioInput
  ) {
    updateContentAudio(
      id: $id
      audiofile: $audiofile
      textfile: $textfile
      coverImageFile: $coverImageFile
      input: $input
    ) {
      id
      title
      genres {
        id
        nameKZ
        nameRU
      }
      categories {
        id
        nameKZ
        nameRU
      }
      authors {
        id
        name
      }
      composers {
        id
        name
      }
      voiceActors {
        id
        name
      }
      publisher {
        id
        name
      }
      instruments {
        id
        nameKZ
        nameRU
        descriptionKZ
        descriptionRU
        photo
      }
      link2Audio
      link2Text
      coverImage
      status
      lyrics
    }
  }
`

const DELETE_CONTENTAUDIO = gql`
  mutation updateContentAudio($id: ID!, $input: UpdateContentAudioInput) {
    updateContentAudio(id: $id, input: $input) {
      id
      title
      genres {
        id
        nameKZ
        nameRU
      }
      categories {
        id
        nameKZ
        nameRU
      }
      authors {
        id
        name
      }
      composers {
        id
        name
      }
      voiceActors {
        id
        name
      }
      publisher {
        id
        name
      }
      instruments {
        id
        nameKZ
        nameRU
        descriptionKZ
        descriptionRU
        photo
      }
      link2Audio
      link2Text
      coverImage
      status
      lyrics
    }
  }
`

// const DELETE_CONTENTAUDIO = gql`
//   mutation deleteContentAudio($id: ID!) {
//     deleteContentAudio(id: $id) {
//       id
//       message
//     }
//   }
// `

const GET_CREATORS = gql`
  query creators {
    creators {
      id
      name
      photo
    }
  }
`

const GET_PUBLISHERS = gql`
  query publishers {
    publishers {
      id
      name
    }
  }
`

const GET_GENRES = gql`
  query genres {
    genres {
      id
      nameKZ
      nameRU
    }
  }
`

const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      nameKZ
      nameRU
    }
  }
`

const GET_INSTRUMENTS = gql`
  query instruments {
    instruments {
      id
      nameKZ
      nameRU
      descriptionKZ
      descriptionRU
      photo
      status
    }
  }
`

const ContentAudiosContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allContentAudios, setAllContentAudios] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_CONTENTAUDIOS)
  const { data: allCreators, loading: creatorsLoading } = useQuery(GET_CREATORS)
  const { data: allPublishers, loading: publishersLoading } = useQuery(
    GET_PUBLISHERS
  )
  const { data: allGenres, loading: genresLoading } = useQuery(GET_GENRES)
  const { data: allCategories, loading: categoriesLoading } = useQuery(
    GET_CATEGORIES
  )
  const { data: allInstruments, loading: instrumentsLoading } = useQuery(
    GET_INSTRUMENTS
  )
  const [addContentAudio, { error: errorAddContentAudio }] = useMutation(
    ADD_CONTENTAUDIO,
    {
      update(cache, { data: { addContentAudio: addContentAudioItem } }) {
        const { statusedSongs } = cache.readQuery({ query: GET_CONTENTAUDIOS })
        cache.writeQuery({
          query: GET_CONTENTAUDIOS,
          data: { statusedSongs: [addContentAudioItem].concat(statusedSongs) }
        })
        hideLoading()
        toast.success('Песня добавлена')
      }
    }
  )
  const [updateContentAudio, { data: updateContentAudioData }] = useMutation(
    UPDATE_CONTENTAUDIO
  )
  const [deleteContentAudio, { error: errorDeleteContentAudio }] = useMutation(
    DELETE_CONTENTAUDIO,
    {
      update(cache, { data: { updateContentAudio: deleteContentAudioItem } }) {
        const { statusedSongs } = cache.readQuery({ query: GET_CONTENTAUDIOS })
        cache.writeQuery({
          query: GET_CONTENTAUDIOS,
          data: {
            statusedSongs: statusedSongs.filter(
              contentAudio => contentAudio.id !== deleteContentAudioItem.id
            )
          }
        })
        hideLoading()
        toast.success('Песня удалена')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddContentAudio) {
      console.log(errorAddContentAudio)
    }
  }, [errorAddContentAudio])

  React.useEffect(() => {
    if (errorDeleteContentAudio) {
      console.log(errorDeleteContentAudio)
    }
  }, [errorDeleteContentAudio])

  React.useEffect(() => {
    if (
      data &&
      !error &&
      !loading &&
      !creatorsLoading &&
      !publishersLoading &&
      !genresLoading &&
      !categoriesLoading &&
      !instrumentsLoading
    ) {
      // console.log(data.statusedSongs)
      setAllContentAudios(data.statusedSongs)
    }
  }, [
    data,
    loading,
    error,
    updateContentAudioData,
    publishersLoading,
    creatorsLoading,
    genresLoading,
    categoriesLoading,
    instrumentsLoading
  ])

  // const csv2StringParser = file => {
  //   const arr = []
  //   Papa.parse(file, {
  //     header: true,
  //     skipEmptyLines: true,
  //     complete: function(results) {
  //       // console.log(results)
  //       return results
  //     }
  //   })
  //   console.log(arr)
  //   // return str
  // }

  function csv2StringParser(file) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete(results, file) {
          try {
            const obj = {}
            results.data.map(item => {
              if (item.Time) obj[Number(item.Time.trim())] = item.Text.trim()
            })
            if (Object.keys(obj).length === 0) {
              message.error('Невозможно обработать текстовый файл')
              resolve(-1)
            }
            resolve(JSON.stringify(obj))
          } catch {
            message.error('Невозможно обработать текстовый файл')
            resolve(-1)
          }
        },
        error(err, file) {
          message.error('Невозможно обработать текстовый файл')
          console.log(err)
          resolve(-1)
        }
      })
    })
  }

  const addContentAudioHandler = async (
    form,
    closeFunc,
    audiofile,
    textfile,
    coverImageFile,
    setAudioFile,
    setTextFile,
    setCoverImageFile,
    audioFileRef,
    textFileRef,
    coverImageFileRef
  ) => {
    showLoading()
    let lyrics
    if (textfile) lyrics = await csv2StringParser(textfile)
    lyrics !== -1
      ? form
          .validateFields()
          .then(val => {
            // console.log('add vals', val)
            form.resetFields()
            addContentAudio({
              variables: {
                audiofile: audiofile,
                coverImageFile: coverImageFile,
                input: {
                  type: 'song',
                  title: val.title,
                  genres: val.genres ? val.genres : [],
                  categories: val.categories ? val.categories : [],
                  authors: val.authors,
                  composers: val.composers ? val.composers : [],
                  voiceActors: val.voiceActors ? val.voiceActors : [],
                  publisher: val.publisher ? val.publisher : null,
                  instruments: val.instruments ? val.instruments : [],
                  status: val.status,
                  lyrics: lyrics ? lyrics : val.lyrics
                }
              }
            })
          })
          .then(() => {
            setAudioFile(null)
            setTextFile(null)
            setCoverImageFile(null)
            audioFileRef.current.value = null
            textFileRef.current.value = null
            coverImageFileRef.current.value = null
            closeFunc(false)
          })
      : hideLoading()
  }

  const updateContentAudioHandler = async (
    form,
    contentAudioId,
    closeFunc,
    audiofile,
    textfile,
    coverImageFile,
    setAudioFile,
    setTextFile,
    setCoverImageFile,
    audioFileRef,
    textFileRef,
    coverImageFileRef
  ) => {
    showLoading()
    let lyrics
    if (textfile) lyrics = await csv2StringParser(textfile)
    lyrics !== -1
      ? form
          .validateFields()
          .then(val => {
            // console.log('upd vals', val)
            updateContentAudio({
              variables: {
                id: contentAudioId,
                audiofile,
                coverImageFile,
                input: {
                  title: val.title,
                  genres: val.genres ? val.genres : [],
                  categories: val.categories ? val.categories : [],
                  authors: val.authors,
                  composers: val.composers ? val.composers : [],
                  voiceActors: val.voiceActors ? val.voiceActors : [],
                  publisher: val.publisher ? val.publisher : null,
                  instruments: val.instruments ? val.instruments : [],
                  status: val.status,
                  lyrics: lyrics ? lyrics : val.lyrics
                }
              }
            })
          })
          .then(() => {
            hideLoading()

            setAudioFile(null)
            setTextFile(null)
            setCoverImageFile(null)
            audioFileRef.current.value = null
            textFileRef.current.value = null
            coverImageFileRef.current.value = null

            closeFunc(false)
            toast.success('Песня обновлена')
          })
      : hideLoading()
  }

  const deleteContentAudioHandler = (contentAudioId, closeFunc) => {
    showLoading()
    deleteContentAudio({
      variables: {
        id: contentAudioId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // console.log("data ", deleteContentAudioData)
      // hideLoading()
      closeFunc(false)
    })
  }

  const removeFilesHandler = (contentAudioId, textFile, coverImage) => {
    // console.log('id', contentAudioId)
    // console.log('textFile', textFile)
    // console.log('coverImage', coverImage)
    updateContentAudio({
      variables: {
        id: contentAudioId,
        input: textFile
          ? {
              link2Text: null
            }
          : {
              coverImage: null
            }
      }
    }).then(() => {
      toast.success('Песня обновлена')
    })
  }

  if (
    loading ||
    creatorsLoading ||
    publishersLoading ||
    genresLoading ||
    instrumentsLoading ||
    categoriesLoading
  ) {
    return <Loading />
  }

  return (
    <ContentAudios
      contentAudios={allContentAudios}
      allCreators={allCreators}
      allPublishers={allPublishers}
      allGenres={allGenres}
      allCategories={allCategories}
      allInstruments={allInstruments}
      addContentAudio={addContentAudio}
      refetch={refetch}
      addContentAudioHandler={addContentAudioHandler}
      updateContentAudioHandler={updateContentAudioHandler}
      deleteContentAudioHandler={deleteContentAudioHandler}
      removeFilesHandler={removeFilesHandler}
    />
  )
}

export default WithMainLayout(ContentAudiosContainer)
