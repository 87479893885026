import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import Zhanyltpashtar from './Zhanyltpashtar'

const GET_ZHANYLTPASHTAR = gql`
  query statusedZhanyltpashtar {
    statusedZhanyltpashtar {
      id
      text
      type
      status
      index
      audioUrl
    }
  }
`
const CREATE_ZHANYLTPASH = gql`
  mutation create($input: ZhanyltpashtarInput) {
    createZhanyltpash(input: $input) {
      id
      text
      type
      status
      index
      audioUrl
    }
  }
`
const UPDATE_ZHANYLTPASH = gql`
  mutation update($input: UpdateZhanyltpashtarInput) {
    updateZhanyltpash(input: $input) {
      id
      text
      type
      status
      index
      audioUrl
    }
  }
`
const DELETE_ZHANYLTPASH = gql`
  mutation delete($id: ID!) {
    deleteZhanyltpash(id: $id) {
      id
    }
  }
`

const ZhanyltpashtarContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [zhanyltpashtar, setZhanyltpashtar] = useState([])

  const { loading } = useQuery(GET_ZHANYLTPASHTAR, {
    onCompleted: data => {
      setZhanyltpashtar(data.statusedZhanyltpashtar)
    },
    onError: error => console.log(error)
  })

  const [createZhanyltpash] = useMutation(CREATE_ZHANYLTPASH, {
    onError: error => console.log(error),
    update(cache, { data: { createZhanyltpash: newItem } }) {
      const { statusedZhanyltpashtar } = cache.readQuery({
        query: GET_ZHANYLTPASHTAR
      })
      const newArray = statusedZhanyltpashtar
        .filter(item => item.id !== newItem.id)
        .concat(newItem)
      setZhanyltpashtar(newArray)
      cache.writeQuery({
        query: GET_ZHANYLTPASHTAR,
        data: {
          statusedZhanyltpashtar: newArray
        }
      })
      hideLoading()
      toast.success('Скороговорка добавлена')
    }
  })

  const [updateZhanyltpash] = useMutation(UPDATE_ZHANYLTPASH, {
    onError: error => console.log(error),
    update(cache, { data: { updateZhanyltpash: newItem } }) {
      const { statusedZhanyltpashtar } = cache.readQuery({
        query: GET_ZHANYLTPASHTAR
      })
      const newArray = statusedZhanyltpashtar.map(item =>
        item.id !== newItem.id ? item : newItem
      )
      setZhanyltpashtar(newArray)
      cache.writeQuery({
        query: GET_ZHANYLTPASHTAR,
        data: {
          statusedZhanyltpashtar: newArray
        }
      })
      hideLoading()
      toast.success('Скороговорка обновлена')
    }
  })

  const [deleteZhanyltpash] = useMutation(DELETE_ZHANYLTPASH, {
    onError: error => console.log(error),
    update(cache, { data: { deleteZhanyltpash: newItem } }) {
      const { statusedZhanyltpashtar } = cache.readQuery({
        query: GET_ZHANYLTPASHTAR
      })
      const newArray = statusedZhanyltpashtar.filter(
        item => item.id !== newItem.id
      )
      setZhanyltpashtar(newArray)
      cache.writeQuery({
        query: GET_ZHANYLTPASHTAR,
        data: {
          statusedZhanyltpashtar: newArray
        }
      })
      hideLoading()
      toast.success('Скороговорка удалена')
    }
  })

  const createHandler = (
    form,
    closeFunc,
    audioFile,
    setAudioFile,
    audioFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        createZhanyltpash({
          variables: {
            input: {
              text: val.text,
              type: val.type,
              status: val.status,
              index: val.index,
              audioFile
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        setAudioFile(null)
        audioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateHandler = (
    form,
    itemId,
    closeFunc,
    audioFile,
    setAudioFile,
    audioFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateZhanyltpash({
          variables: {
            input: {
              id: itemId,
              text: val.text,
              type: val.type,
              status: val.status,
              index: val.index,
              audioFile
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        setAudioFile(null)
        audioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteHandler = (itemId, closeFunc) => {
    showLoading()
    deleteZhanyltpash({
      variables: {
        id: itemId
      }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Zhanyltpashtar
      zhanyltpashtar={zhanyltpashtar}
      createHandler={createHandler}
      updateHandler={updateHandler}
      deleteHandler={deleteHandler}
    />
  )
}

export default WithMainLayout(ZhanyltpashtarContainer)
