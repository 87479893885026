import React, { useEffect, useRef, useState } from 'react'
import { Table, Button, Form, Modal, message, Input, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import RiddlesCollectionsForm from './RiddlesCollectionsForm'
import { useLoading } from '../../../context/useLoading'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const RiddlesCollections = ({
  riddlesCollections: riddlesCollections,
  riddles: riddles,
  createHandler,
  updateHandler,
  deleteHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [riddlesCollectionsItem, setRiddlesCollectionsItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchRiddlesCollection, setSearchRiddlesCollection] = useState(null)
  const [listRiddles, setListRiddles] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const imageFileRef = useRef()
  const { hideLoading } = useLoading()

  useEffect(() => {
    if (searchRiddlesCollection) {
      const searchArray = riddlesCollections.filter(riddlesCollection => {
        return (
          riddlesCollection.titleRus
            ?.toLowerCase()
            .includes(searchRiddlesCollection) ||
          riddlesCollection.titleKaz
            ?.toLowerCase()
            .includes(searchRiddlesCollection)
        )
      })
      setListRiddles(searchArray)
    } else {
      setListRiddles(riddlesCollections)
    }
  }, [riddlesCollections, searchRiddlesCollection])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Название (на русском)',
      dataIndex: 'titleRus',
      width: '15%'
    },
    {
      title: 'Название (на казахском)',
      dataIndex: 'titleKaz',
      width: '15%'
    },
    {
      title: 'Картинка',
      width: '10%',
      render: item => {
        return (
          <>
            <Image shape="square" size={64} src={item.imageUrl} />
          </>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setRiddlesCollectionsItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setRiddlesCollectionsItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listRiddles}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchRiddlesCollection(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая коллекция загадок
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая коллекция загадок"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setImageFile(null)
          if (imageFileRef.current) imageFileRef.current.value = null
        }}
        onOk={() => {
          if (imageFile !== null) {
            createHandler(
              form,
              setModalVisible,
              imageFile,
              setImageFile,
              imageFileRef
            )
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <RiddlesCollectionsForm
          form={form}
          setImageFile={setImageFile}
          imageFileRef={imageFileRef}
          riddles={riddles}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
            setImageFile(null)
            if (imageFileRef.current) imageFileRef.current.value = null
          }}
          onOk={() => {
            updateHandler(
              formForEdit,
              riddlesCollectionsItem.id,
              setEditModal,
              imageFile,
              setImageFile,
              imageFileRef
            )
          }}
        >
          <RiddlesCollectionsForm
            form={formForEdit}
            titleRus={riddlesCollectionsItem.titleRus}
            titleKaz={riddlesCollectionsItem.titleKaz}
            setImageFile={setImageFile}
            audioFileRef={imageFileRef}
            status={riddlesCollectionsItem.status}
            riddles={riddles}
            selectedRiddles={riddlesCollectionsItem.riddles}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(riddlesCollectionsItem.id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить {riddlesCollectionsItem.titleRus} ?
          </h3>
        </Modal>
      )}
    </>
  )
}

RiddlesCollections.propTypes = {
  riddlesCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  riddles: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default RiddlesCollections
