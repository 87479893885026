import React from 'react'

import { Form, Input, Select, InputNumber, Button } from 'antd'
import PropTypes from 'prop-types'
import { DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const StyledDivDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PlaylistForm = ({
  form,
  id,
  titleKZ,
  titleRU,
  songs,
  index,
  status,
  descriptionKZ,
  descriptionRU,
  setCoverImageFile,
  coverImageFileRef,
  allSongs,
  removeFilesHandler
}) => {
  // console.log('songs', songs)
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Обложка</>
        <StyledDivDelete>
          <input
            type="file"
            accept="image/*"
            ref={coverImageFileRef}
            onChange={event => setCoverImageFile(event.target.files[0])}
          />
          {id && (
            <Button
              danger
              onClick={() => {
                removeFilesHandler(id)
              }}
            >
              <DeleteOutlined />
            </Button>
          )}
        </StyledDivDelete>
      </div>

      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность документа"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность документа`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность документа" />
      </FormItem>
      {/* <FormItem
        key="title"
        label="Название"
        name="title"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Плейлиста`
          }
        ]}
        initialValue={title}
      >
        <Input placeholder="Название" />
      </FormItem> */}
      <FormItem
        key="titleKZ"
        label="Название(каз)"
        name="titleKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Плейлиста на казахском`
          }
        ]}
        initialValue={titleKZ}
      >
        <Input placeholder="Аты" />
      </FormItem>
      <FormItem
        key="titleRU"
        label="Название(рус)"
        name="titleRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Плейлиста на русском`
          }
        ]}
        initialValue={titleRU}
      >
        <Input placeholder="Название" />
      </FormItem>
      <FormItem
        key="songs"
        label="Аудио дорожки"
        name="songs"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите аудио дорожки`
          }
        ]}
        initialValue={songs.map(item => (item ? item?.id : item))}
      >
        <Select
          placeholder="Выберите аудио дорожки"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allSongs.songs.map(song => (
            <Select.Option key={song.id} value={song.id}>
              {`${song.title} - ${song?.voiceActors
                ?.map(item => item?.name)
                .join(', ')}`}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="descriptionKZ"
        label="Описание на казахском"
        name="descriptionKZ"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткое описание`
          }
        ]}
        initialValue={descriptionKZ}
      >
        <Input.TextArea placeholder="Описание..." />
      </FormItem>
      <FormItem
        key="descriptionRU"
        label="Описание на русском"
        name="descriptionRU"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткое описание`
          }
        ]}
        initialValue={descriptionRU}
      >
        <Input.TextArea placeholder="Описание..." />
      </FormItem>
    </Form>
  )
}

PlaylistForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  titleKZ: PropTypes.string,
  titleRU: PropTypes.string
}

PlaylistForm.defaultProps = {
  titleKZ: '',
  titleRU: '',
  songs: [],
  index: null,
  status: 'active'
}

export default PlaylistForm
