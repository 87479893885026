import React from 'react'
import { Table, Checkbox, Input, Avatar } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import styled from 'styled-components'

import PropTypes from 'prop-types'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const CheckBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Dashboard = ({ allAudios }) => {
  const [searchAudio, setSearchAudio] = React.useState(null)
  const [listAudios, setListAudios] = React.useState(null)
  const [sortByRating, setSortByRating] = React.useState(true)
  const [sortByCntRating, setSortByCntRating] = React.useState(false)
  const [sortByAddedFav, setSortByAddedFav] = React.useState(false)
  const [descriptiveAudios, setDescriptiveAudios] = React.useState([])

  // React.useEffect(() => {
  //   let parseAudios = allAudios.map(item => {
  //     return {
  //       id: item.id,
  //       title: item.title,
  //       authorsNames: item?.authors?.map(item => item?.name).join(', '),
  //       rating:
  //         item.amntRating && item.cntRating
  //           ? (item.amntRating / item.cntRating).toPrecision(2)
  //           : 0,
  //       cntRating: item.cntRating,
  //       status: item.status,
  //       coverImage: item.coverImage,
  //       cntAddedFav: item.cntAddedFav
  //     }
  //   })
  // }, [allAudios,sortByAddedFav])

  // React.useEffect(() => {
  //   let parseAudios = allAudios.map(item => {
  //     return {
  //       id: item.id,
  //       title: item.title,
  //       authorsNames: item?.authors?.map(item => item?.name).join(', '),
  //       rating:
  //         item.amntRating && item.cntRating
  //           ? (item.amntRating / item.cntRating).toPrecision(2)
  //           : 0,
  //       cntRating: item.cntRating,
  //       status: item.status,
  //       coverImage: item.coverImage,
  //       cntAddedFav: item.cntAddedFav
  //     }
  //   })
  //   sortByRating &&
  //     parseAudios.sort((a, b) =>
  //       a.rating < b.rating ? 1 : a.rating > b.rating ? -1 : 0
  //     )
  // }, [allAudios,sortByRating])

  React.useEffect(() => {
    const descriptiveAudios = allAudios.map(item => {
      return {
        id: item.id,
        title: item.title,
        authorsNames: item?.authors?.map(item => item?.name).join(', '),
        rating:
          item.amntRating && item.cntRating
            ? (item.amntRating / item.cntRating).toPrecision(2)
            : 0,
        cntRating: item.cntRating,
        status: item.status,
        coverImage: item.coverImage,
        cntAddedFav: item.cntAddedFav
      }
    })
    let searchArray = descriptiveAudios

    if (searchAudio) {
      searchArray = searchArray.filter(item => {
        return (
          item.title?.toLowerCase().includes(searchAudio) ||
          item.authorsNames?.toLowerCase().includes(searchAudio)
        )
      })
    }

    sortByRating &&
      searchArray.sort((a, b) =>
        a.rating < b.rating ? 1 : a.rating > b.rating ? -1 : 0
      )

    sortByCntRating &&
      searchArray.sort((a, b) =>
        a.cntRating < b.cntRating ? 1 : a.cntRating > b.cntRating ? -1 : 0
      )
    sortByAddedFav &&
      searchArray.sort((a, b) =>
        a.cntAddedFav < b.cntAddedFav
          ? 1
          : a.cntAddedFav > b.cntAddedFav
          ? -1
          : 0
      )

    setListAudios(searchArray)
  }, [allAudios, searchAudio, sortByAddedFav, sortByRating, sortByCntRating])

  // React.useEffect(() => {
  //   const descriptiveAudios = allAudios.map(item => {
  //     return {
  //       id: item.id,
  //       title: item.title,
  //       authorsNames: item?.authors?.map(item => item?.name).join(', '),
  //       rating:
  //         item.amntRating && item.cntRating
  //           ? (item.amntRating / item.cntRating).toPrecision(2)
  //           : 0,
  //       cntRating: item.cntRating,
  //       status: item.status,
  //       coverImage: item.coverImage,
  //       cntAddedFav: item.cntAddedFav
  //     }
  //   })
  //   let searchArray = descriptiveAudios

  //   if (searchAudio) {
  //     searchArray = searchArray.filter(item => {
  //       return (
  //         item.title?.toLowerCase().includes(searchAudio) ||
  //         item.authorsNames?.toLowerCase().includes(searchAudio)
  //       )
  //     })
  //   }

  //   sortByRating &&
  //     searchArray.sort((a, b) =>
  //       a.rating < b.rating ? 1 : a.rating > b.rating ? -1 : 0
  //     )
  //   sortByAddedFav &&
  //     searchArray.sort((a, b) =>
  //       a.cntAddedFav < b.cntAddedFav
  //         ? 1
  //         : a.cntAddedFav > b.cntAddedFav
  //         ? -1
  //         : 0
  //     )

  //   setListAudios(searchArray)
  // }, [allAudios, searchAudio, sortByAddedFav, sortByRating])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Рейтинг',
      width: '1%',
      render: item => {
        return <>{`${item.rating}(${item.cntRating})`}</>
      }
    },
    {
      title: 'Избранное у',
      dataIndex: 'cntAddedFav',
      width: '1%'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.coverImage} />
          </>
        )
      }
    },
    {
      title: 'Название',
      dataIndex: 'title',
      width: '15%'
    },
    {
      title: 'Авторы',
      dataIndex: 'authorsNames',
      width: '15%'
    }
  ]

  return (
    <>
      <Table
        dataSource={listAudios}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchAudio(e.target.value.toLowerCase())
              }}
            />
            <CheckBoxDiv>
              <Checkbox
                defaultChecked
                onChange={e => {
                  setSortByRating(e.target.checked)
                }}
              >
                По рейтингу
              </Checkbox>
              <Checkbox
                onChange={e => {
                  setSortByCntRating(e.target.checked)
                }}
              >
                По количеству рейтингов
              </Checkbox>
              <Checkbox
                onChange={e => {
                  setSortByAddedFav(e.target.checked)
                }}
              >
                По количеству избранных
              </Checkbox>
            </CheckBoxDiv>
          </StyledHeaderContainer>
        )}
      />
    </>
  )
}

Dashboard.propTypes = {
  instruments: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Dashboard
