import React from 'react'
import { Table, Button, Form, Modal, Input } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TraditionsArticleForm from './TraditionsArticlesForm'
import { useLoading } from '../../../context/useLoading'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const TraditionsArticles = ({
  traditionsArticles,
  createTraditionsArticleHandler,
  updateTraditionsArticleHandler,
  deleteTraditionsArticleHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchItem, setSearchItem] = React.useState(null)
  const [listItems, setListItems] = React.useState(null)
  const [imageFiles, setImageFiles] = React.useState([])
  const [imagePreviews, setImagePreviews] = React.useState([])
  const { hideLoading } = useLoading()

  React.useEffect(() => {
    if (searchItem) {
      const searchArray = traditionsArticles.filter(traditionsArticle => {
        return traditionsArticle.title?.toLowerCase().includes(searchItem)
      })
      setListItems(searchArray)
    } else {
      setListItems(traditionsArticles)
    }
  }, [traditionsArticles, searchItem])

  const columns = [
    {
      title: 'Очередность',
      dataIndex: 'order',
      width: '1%'
    },
    {
      title: 'Название',
      dataIndex: 'title',
      width: '15%'
    },
    {
      title: 'Краткое описание',
      dataIndex: 'textShort',
      width: '15%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setSelectedItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setSelectedItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listItems}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchItem(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая статья традиций
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая статья традиций"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setImageFiles([])
          setImagePreviews([])
        }}
        onOk={() => {
          createTraditionsArticleHandler(form, setModalVisible, imageFiles)
          setImageFiles([])
          setImagePreviews([])
        }}
      >
        <TraditionsArticleForm
          form={form}
          imageFiles={imageFiles}
          imagePreviews={imagePreviews}
          setImagePreviews={setImagePreviews}
          setImageFiles={setImageFiles}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
            setImageFiles([])
            setImagePreviews([])
          }}
          onOk={() => {
            updateTraditionsArticleHandler(
              formForEdit,
              selectedItem.id,
              setEditModal,
              imageFiles
            )
            setImageFiles([])
            setImagePreviews([])
          }}
        >
          <TraditionsArticleForm
            form={formForEdit}
            order={selectedItem.order}
            title={selectedItem.title}
            textShort={selectedItem.textShort}
            text={selectedItem.text}
            storiesImageUrls={selectedItem.storiesImageUrls}
            imageFiles={imageFiles}
            imagePreviews={imagePreviews}
            setImageFiles={setImageFiles}
            setImagePreviews={setImagePreviews}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deleteTraditionsArticleHandler(selectedItem.id, setDeleteModal)
          }
        >
          <h3>Вы действительно хотите удалить {selectedItem.title} ?</h3>
        </Modal>
      )}
    </>
  )
}

TraditionsArticles.propTypes = {
  traditionsArticles: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTraditionsArticleHandler: PropTypes.func.isRequired,
  deleteTraditionsArticleHandler: PropTypes.func.isRequired
}

export default TraditionsArticles
