import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import TraditionsCollections from './TraditionsCollections'

const GET_TRADITIONS_COLLECTIONS = gql`
  query traditionsCollections {
    traditionsCollections {
      id
      titleRus
      titleKaz
      imageUrl
      traditions {
        id
        titleRus
        titleKaz
      }
    }
  }
`
const CREATE_TRADITIONS_COLLECTION = gql`
  mutation createTraditionsCollection($input: TraditionsCollectionsInput) {
    createTraditionsCollection(input: $input) {
      id
      titleRus
      titleKaz
      imageUrl
      traditions {
        id
        titleRus
        titleKaz
      }
    }
  }
`
const UPDATE_TRADITIONS_COLLECTION = gql`
  mutation updateTraditionsCollection(
    $input: UpdateTraditionsCollectionInput!
  ) {
    updateTraditionsCollection(input: $input) {
      id
      titleRus
      titleKaz
      imageUrl
      traditions {
        id
        titleRus
        titleKaz
      }
    }
  }
`

const DELETE_TRADITIONS_COLLECTION = gql`
  mutation deleteTraditionsCollection($id: ID!) {
    deleteTraditionsCollection(id: $id) {
      id
      titleRus
      titleKaz
      imageUrl
      traditions {
        id
        titleRus
        titleKaz
      }
    }
  }
`

const GET_TRADITIONS = gql`
  query traditions {
    traditions {
      id
      titleRus
      titleKaz
    }
  }
`
const TraditionsCollectionsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [traditionsCollections, setTraditionsCollections] = useState([])
  const [traditions, setTraditions] = useState([])

  const { loading: traditionsCollectionsLoading } = useQuery(
    GET_TRADITIONS_COLLECTIONS,
    {
      onCompleted: data => {
        setTraditionsCollections(data.traditionsCollections)
      },
      onError: error => console.log(error)
    }
  )

  const { loading: traditionsLoading } = useQuery(GET_TRADITIONS, {
    onCompleted: data => {
      setTraditions(data.traditions)
    },
    onError: error => console.log(error)
  })

  const [createTraditionsCollection] = useMutation(
    CREATE_TRADITIONS_COLLECTION,
    {
      onError: error => console.log(error),
      update(cache, { data: { createTraditionsCollection: newItem } }) {
        const { traditionsCollections } = cache.readQuery({
          query: GET_TRADITIONS_COLLECTIONS
        })
        const newArray = traditionsCollections
          .filter(item => item.id !== newItem.id)
          .concat(newItem)
        setTraditionsCollections(newArray)
        cache.writeQuery({
          query: GET_TRADITIONS_COLLECTIONS,
          data: {
            traditionsCollections: newArray
          }
        })
        hideLoading()
        toast.success('Коллекция традиций добавлена')
      }
    }
  )

  const [updateTraditionsCollection] = useMutation(
    UPDATE_TRADITIONS_COLLECTION,
    {
      onError: error => console.log(error),
      update(cache, { data: { updateTraditionsCollection: newItem } }) {
        const { traditionsCollections } = cache.readQuery({
          query: GET_TRADITIONS_COLLECTIONS
        })
        const newArray = traditionsCollections.map(item =>
          item.id !== newItem.id ? item : newItem
        )
        setTraditionsCollections(newArray)
        cache.writeQuery({
          query: GET_TRADITIONS_COLLECTIONS,
          data: {
            traditionsCollections: newArray
          }
        })
        hideLoading()
        toast.success('Коллекция традиции обновлена')
      }
    }
  )

  const [deleteTraditionsCollection] = useMutation(
    DELETE_TRADITIONS_COLLECTION,
    {
      onError: error => console.log(error),
      update(cache, { data: { deleteTraditionsCollection: newItem } }) {
        const { traditionsCollections } = cache.readQuery({
          query: GET_TRADITIONS_COLLECTIONS
        })
        const newArray = traditionsCollections.filter(
          item => item.id !== newItem.id
        )
        setTraditionsCollections(newArray)
        cache.writeQuery({
          query: GET_TRADITIONS_COLLECTIONS,
          data: {
            traditionsCollections: newArray
          }
        })
        hideLoading()
        toast.success('Коллекция традиции удалена')
      }
    }
  )

  const createTraditionsCollectionHandler = (
    form,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        createTraditionsCollection({
          variables: {
            input: {
              titleRus: val.titleRus,
              titleKaz: val.titleKaz,
              imageFile,
              traditions: val.traditions
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateTraditionsCollectionHandler = (
    form,
    collectionId,
    closeFunc,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateTraditionsCollection({
          variables: {
            input: {
              id: collectionId,
              titleRus: val.titleRus,
              titleKaz: val.titleKaz,
              imageFile: imageFile,
              traditions: val.traditions
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteTraditionsCollectionHandler = (collectionId, closeFunc) => {
    showLoading()
    deleteTraditionsCollection({
      variables: {
        id: collectionId
      }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (traditionsCollectionsLoading || traditionsLoading) {
    return <Loading />
  }

  return (
    <TraditionsCollections
      traditionsCollections={traditionsCollections}
      traditions={traditions}
      createTraditionsCollectionHandler={createTraditionsCollectionHandler}
      updateTraditionsCollectionHandler={updateTraditionsCollectionHandler}
      deleteTraditionsCollectionHandler={deleteTraditionsCollectionHandler}
    />
  )
}

export default WithMainLayout(TraditionsCollectionsContainer)
