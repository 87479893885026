import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Letters from './Letters'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_LETTERS_BY_INDEX = gql`
  query statusedLettersByIndex {
    statusedLettersByIndex {
      id
      letter
      word
      index
      link2Audio
      link2Image
      letterAudioURL
      status
      restrictions
    }
  }
`

const ADD_LETTER = gql`
  mutation addLetter(
    $audioFile: FileUpload!
    $secondAudioFile: FileUpload
    $imageFile: FileUpload!
    $input: LetterInput!
  ) {
    addLetter(
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
      secondAudioFile: $secondAudioFile
    ) {
      id
      letter
      word
      index
      link2Audio
      link2Image
      letterAudioURL
      status
      restrictions
    }
  }
`

const UPDATE_LETTER = gql`
  mutation updateLetter(
    $id: ID!
    $audioFile: FileUpload
    $secondAudioFile: FileUpload
    $imageFile: FileUpload
    $input: UpdateLetterInput
  ) {
    updateLetter(
      id: $id
      audioFile: $audioFile
      secondAudioFile: $secondAudioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      letter
      word
      index
      link2Audio
      link2Image
      letterAudioURL
      status
    }
  }
`

const DELETE_LETTER = gql`
  mutation updateLetter($id: ID!, $input: UpdateLetterInput) {
    updateLetter(id: $id, input: $input) {
      id
      letter
      word
      index
      link2Audio
      link2Image
      letterAudioURL
      status
    }
  }
`

const LettersContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allLetters, setAllLetters] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_LETTERS_BY_INDEX)
  const [addLetter, { error: errorAddLetter }] = useMutation(ADD_LETTER, {
    update(cache, { data: { addLetter: addLetterItem } }) {
      const { statusedLettersByIndex } = cache.readQuery({
        query: GET_LETTERS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_LETTERS_BY_INDEX,
        data: {
          statusedLettersByIndex: statusedLettersByIndex
            .concat(addLetterItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Буква добавлена')
    }
  })
  // const [updateLetter, { data: updateLetterData }] = useMutation(UPDATE_LETTER)
  const [updateLetter, { error: errorUpdateLetter }] = useMutation(
    UPDATE_LETTER,
    {
      update(cache, { data: { updateLetter: updateLetterItem } }) {
        const { statusedLettersByIndex } = cache.readQuery({
          query: GET_LETTERS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_LETTERS_BY_INDEX,
          data: {
            statusedLettersByIndex: statusedLettersByIndex
              .filter(letter => letter.id !== updateLetterItem.id)
              .concat(updateLetterItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Буква обновлена')
      }
    }
  )
  const [deleteLetter, { error: errorDeleteLetter }] = useMutation(
    DELETE_LETTER,
    {
      update(cache, { data: { updateLetter: deleteLetterItem } }) {
        const { statusedLettersByIndex } = cache.readQuery({
          query: GET_LETTERS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_LETTERS_BY_INDEX,
          data: {
            statusedLettersByIndex: statusedLettersByIndex.filter(
              letter => letter.id !== deleteLetterItem.id
            )
          }
        })
        hideLoading()
        toast.success('Буква удалена')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddLetter) {
      console.log(errorAddLetter)
    }
  }, [errorAddLetter])

  React.useEffect(() => {
    if (errorDeleteLetter) {
      console.log(errorDeleteLetter)
    }
  }, [errorDeleteLetter])

  React.useEffect(() => {
    if (errorUpdateLetter) {
      console.log(errorUpdateLetter)
    }
  }, [errorUpdateLetter])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllLetters(data.statusedLettersByIndex)
    }
  }, [data, loading, error])

  const addLetterHandler = (
    form,
    closeFunc,
    audioFile,
    secondAudioFile,
    imageFile,
    setAudioFile,
    setSecondAudioFile,
    setImageFile,
    audioFileRef,
    secondAudioFileRef,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        // console.log('audioFile', audioFile)
        // console.log('imageFile', imageFile)
        // console.log('val', val)
        form.resetFields()
        showLoading()
        addLetter({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            secondAudioFile: secondAudioFile,
            input: {
              letter: val.letter,
              word: val.word,
              index: val.index,
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        setSecondAudioFile(null)
        hideLoading()
        audioFileRef.current.value = null
        secondAudioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateLetterHandler = (
    form,
    letterId,
    closeFunc,
    audioFile,
    secondAudioFile,
    imageFile,
    setAudioFile,
    setSecondAudioFile,
    setImageFile,
    audioFileRef,
    secondAudioFileRef,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateLetter({
          variables: {
            id: letterId,
            audioFile,
            imageFile,
            secondAudioFile,
            input: {
              letter: val.letter,
              word: val.word,
              index: val.index,
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setSecondAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        secondAudioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        // toast.success('Буква обновлена')
      })
  }

  const deleteLetterHandler = (letterId, closeFunc) => {
    showLoading()
    deleteLetter({
      variables: {
        id: letterId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Letters
      letters={allLetters}
      addLetter={addLetter}
      refetch={refetch}
      addLetterHandler={addLetterHandler}
      updateLetterHandler={updateLetterHandler}
      deleteLetterHandler={deleteLetterHandler}
    />
  )
}

export default WithMainLayout(LettersContainer)
