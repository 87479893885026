import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, Avatar } from 'antd'
import { PlusOutlined, SearchOutlined, MenuOutlined } from '@ant-design/icons'
import {
  // SortableContainer,
  // SortableElement,
  SortableHandle
} from 'react-sortable-hoc'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import CollectionForm from './CollectionForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

// const SortableItem = SortableElement(props => <tr {...props} />)
// const SortableBody = SortableContainer(props => <tbody {...props} />)

const Collections = ({
  collections,
  allTales,
  addCollectionHandler,
  updateCollectionHandler,
  deleteCollectionHandler,
  removeFilesHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [collectionItem, setCollectionItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchCollection, setSearchCollection] = React.useState(null)

  const [listCollections, setListCollections] = React.useState(null)

  const [coverImageFile, setCoverImageFile] = useState(null)
  const coverImageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveCollections = collections.map(collection => {
      return {
        id: collection.id,
        titleKZ: collection.titleKZ,
        titleRU: collection.titleRU,
        tales: collection.tales,
        coverImage: collection.coverImage,
        index: collection.index,
        status: collection.status,
        descriptionKZ: collection.descriptionKZ,
        descriptionRU: collection.descriptionRU
      }
    })
    let searchArray = descriptiveCollections

    if (searchCollection) {
      searchArray = searchArray.filter(collection => {
        return collection.title?.toLowerCase().includes(searchCollection)
      })
    }

    setListCollections(searchArray)
  }, [collections, searchCollection])

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   // const { dataSource } = this.state
  //   // if (oldIndex !== newIndex) {
  //   //   const newData = arrayMoveImmutable(
  //   //     [].concat(dataSource),
  //   //     oldIndex,
  //   //     newIndex
  //   //   ).filter(el => !!el)
  //   //   console.log('Sorted items: ', newData)
  //   //   this.setState({ dataSource: newData })
  //   // }
  // }

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: '1%',
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '1%'
    },
    {
      title: 'Обложка',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.coverImage} />
          </>
        )
      }
    },
    {
      title: 'Название(каз)',
      dataIndex: 'titleKZ',
      width: '15%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'titleRU',
      width: '15%'
    },
    {
      title: 'Количество аудио',
      width: '5%',
      render: item => {
        return <>{item.tales.length}</>
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          {/* <span>
          <Link to={`/collections/${item.id}`}>Редактировать</Link>
        </span> */}
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setCollectionItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setCollectionItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listCollections}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Title"
              onChange={e => {
                setSearchCollection(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый сборник
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый сборник"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setCoverImageFile(null)
          coverImageFileRef.current.value = null
        }}
        onOk={() => {
          addCollectionHandler(form, setModalVisible, coverImageFile, allTales)
          setCoverImageFile(null)
          coverImageFileRef.current.value = null
        }}
      >
        <CollectionForm
          setCoverImageFile={setCoverImageFile}
          allTales={allTales}
          coverImageFileRef={coverImageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setCoverImageFile(null)
            coverImageFileRef.current.value = null
          }}
          onOk={() => {
            updateCollectionHandler(
              formForEdit,
              collectionItem.id,
              setEditModal,
              coverImageFile,
              allTales
            )
            setCoverImageFile(null)
            coverImageFileRef.current.value = null
          }}
        >
          <CollectionForm
            form={formForEdit}
            id={collectionItem.id}
            titleKZ={collectionItem.titleKZ}
            titleRU={collectionItem.titleRU}
            tales={collectionItem.tales}
            status={collectionItem.status}
            index={collectionItem.index}
            descriptionKZ={collectionItem.descriptionKZ}
            descriptionRU={collectionItem.descriptionRU}
            setCoverImageFile={setCoverImageFile}
            allTales={allTales}
            coverImageFileRef={coverImageFileRef}
            removeFilesHandler={removeFilesHandler}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deleteCollectionHandler(collectionItem.id, setDeleteModal)
          }
        >
          <h3>Вы действительно хотите удалить {collectionItem.title} ?</h3>
        </Modal>
      )}
    </>
  )
}

Collections.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  addCollectionHandler: PropTypes.func.isRequired,
  updateCollectionHandler: PropTypes.func.isRequired
}

export default Collections
