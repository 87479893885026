import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import PracticeItemsTwoColors from './PracticeItemsTwoColors'
import WithMainLayout from '../../../hocs/withMainLayout'

const GET_PRACTICE_ITEMS_TWO_COLORS = gql`
  query statusedPracticeItemsTwoColorsByIndex {
    statusedPracticeItemsTwoColorsByIndex {
      id
      index
      code_1
      code_2
      audioURL
      imageURL
      status
    }
  }
`

const ADD_PRACTICE_ITEMS_TWO_COLOR = gql`
  mutation addPracticeItemsTwoColors(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $input: PracticeItemsTwoColorsInput!
  ) {
    addPracticeItemsTwoColors(
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      code_1
      code_2
      index
      audioURL
      imageURL
      status
    }
  }
`

const UPDATE_PRACTICE_ITEMS_TWO_COLOR = gql`
  mutation updatePracticeItemsTwoColor(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $input: UpdatePracticeItemsTwoColorsInput
  ) {
    updatePracticeItemsTwoColors(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      index
      code_1
      code_2
      audioURL
      imageURL
      status
    }
  }
`

const DELETE_PRACTICE_ITEMS_TWO_COLOR = gql`
  mutation deletePracticeItemsTwoColor($id: ID!) {
    deletePracticeItemsTwoColors(id: $id) {
      id
      message
    }
  }
`

const TwoColorsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allTwoColors, setAllTwoColors] = React.useState([])
  const { data, loading, error } = useQuery(GET_PRACTICE_ITEMS_TWO_COLORS)
  const [addPracticeItemsTwoColors, { error: errorAddPracticeItemsTwoColor }] = useMutation(
    ADD_PRACTICE_ITEMS_TWO_COLOR,
    {
      update(cache, { data: { addPracticeItemsTwoColors: addPracticeItemsTwoColorItem } }) {
        const { statusedPracticeItemsTwoColorsByIndex } = cache.readQuery({
          query: GET_PRACTICE_ITEMS_TWO_COLORS
        })
        cache.writeQuery({
          query: GET_PRACTICE_ITEMS_TWO_COLORS,
          data: {
            statusedPracticeItemsTwoColorsByIndex: statusedPracticeItemsTwoColorsByIndex
              .concat(addPracticeItemsTwoColorItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Два цвета практики добавлена')
      }
    }
  )
  const [
    updatePracticeItemsTwoColor,
    { error: errorUpdatePracticeItemsTwoColors }
  ] = useMutation(UPDATE_PRACTICE_ITEMS_TWO_COLOR, {
    update(
      cache,
      { data: { updatePracticeItemsTwoColor: updatePracticeItemsTwoColorItem } }
    ) {
      const { statusedPracticeItemsTwoColorsByIndex } = cache.readQuery({
        query: GET_PRACTICE_ITEMS_TWO_COLORS
      })
      cache.writeQuery({
        query: GET_PRACTICE_ITEMS_TWO_COLORS,
        data: {
          statusedPracticeItemsTwoColorsByIndex: statusedPracticeItemsTwoColorsByIndex
            .filter(i => i.id !== updatePracticeItemsTwoColorItem.id)
            .concat(updatePracticeItemsTwoColorItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Два цвета практики обновлена')
    }
  })
  const [
    deletePracticeItemsTwoColor,
    { error: errorDeletePracticeItemsTwoColor }
  ] = useMutation(DELETE_PRACTICE_ITEMS_TWO_COLOR, {
    update(
      cache,
      { data: { deletePracticeItemsTwoColor: deletePracticeItemsTwoColorItem } }
    ) {
      const { statusedPracticeItemsTwoColorsByIndex } = cache.readQuery({
        query: GET_PRACTICE_ITEMS_TWO_COLORS
      })
      cache.writeQuery({
        query: GET_PRACTICE_ITEMS_TWO_COLORS,
        data: {
          statusedPracticeItemsTwoColorsByIndex: statusedPracticeItemsTwoColorsByIndex.filter(
            i => i.id !== deletePracticeItemsTwoColorItem.id
          )
        }
      })
      hideLoading()
      toast.success('Два цвета практики удалена')
    }
  })

  React.useEffect(() => {
    if (errorAddPracticeItemsTwoColor) {
      console.log(errorAddPracticeItemsTwoColor)
    }
  }, [errorAddPracticeItemsTwoColor])

  React.useEffect(() => {
    if (errorDeletePracticeItemsTwoColor) {
      console.log(errorDeletePracticeItemsTwoColor)
    }
  }, [errorDeletePracticeItemsTwoColor])

  React.useEffect(() => {
    if (errorUpdatePracticeItemsTwoColors) {
      console.log(errorUpdatePracticeItemsTwoColors)
    }
  }, [errorUpdatePracticeItemsTwoColors])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllTwoColors(data.statusedPracticeItemsTwoColorsByIndex)
    }
  }, [data, loading, error])

  const addPracticeItemsTwoColorsHandler = (
    form,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addPracticeItemsTwoColors({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            input: {
              index: val.index,
              code_1: val.code_1,
              code_2: val.code_2,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updatePracticeItemsTwoColorsHandler = (
    form,
    twoColorsId,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updatePracticeItemsTwoColor({
          variables: {
            id: twoColorsId,
            audioFile,
            imageFile,
            input: {
              index: val.index,
              code_1: val.code_1,
              code_2: val.code_2,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        // toast.success('Буква обновлена')
      })
  }

  const deletePracticeItemsTwoColorsHandler = (twoColorsId, closeFunc) => {
    showLoading()
    deletePracticeItemsTwoColor({
      variables: {
        id: twoColorsId
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  return (
    <PracticeItemsTwoColors
      twoColors={allTwoColors}
      loading={loading}
      addPracticeItemsTwoColorsHandler={addPracticeItemsTwoColorsHandler}
      updatePracticeItemsTwoColorsHandler={updatePracticeItemsTwoColorsHandler}
      deletePracticeItemsTwoColorsHandler={deletePracticeItemsTwoColorsHandler}
    />
  )
}

export default WithMainLayout(TwoColorsContainer)
