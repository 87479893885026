import React from 'react'

import { Form, Input, Select } from 'antd'
import PropTypes from 'prop-types'

const PublisherForm = ({ form, name, status }) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="name"
        label="Название"
        name="name"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Издателя`
          }
        ]}
        initialValue={name}
      >
        <Input placeholder="Название" />
      </FormItem>
    </Form>
  )
}

PublisherForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string
}

PublisherForm.defaultProps = {
  name: '',
  status: 'active'
}

export default PublisherForm
