import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Genres from './Genres'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_GENRES_BY_INDEX = gql`
  query statusedGenresByIndex {
    statusedGenresByIndex {
      id
      nameKZ
      nameRU
      photo
      index
      status
    }
  }
`

const ADD_GENRE = gql`
  mutation addGenre($photoFile: FileUpload!, $input: GenreInput) {
    addGenre(photoFile: $photoFile, input: $input) {
      id
      nameKZ
      nameRU
      photo
      index
      status
    }
  }
`

const UPDATE_GENRE = gql`
  mutation updateGenre(
    $id: ID!
    $photoFile: FileUpload
    $input: UpdateGenreInput
  ) {
    updateGenre(id: $id, photoFile: $photoFile, input: $input) {
      id
      nameKZ
      nameRU
      photo
      index
      status
    }
  }
`

const DELETE_GENRE = gql`
  mutation updateGenre($id: ID!, $input: UpdateGenreInput) {
    updateGenre(id: $id, input: $input) {
      id
      nameKZ
      nameRU
      photo
      index
      status
    }
  }
`

const GenresContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allGenres, setAllGenres] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_GENRES_BY_INDEX)
  const [addGenre, { error: errorAddGenre }] = useMutation(ADD_GENRE, {
    update(cache, { data: { addGenre: addGenreItem } }) {
      const { statusedGenresByIndex } = cache.readQuery({
        query: GET_GENRES_BY_INDEX
      })
      cache.writeQuery({
        query: GET_GENRES_BY_INDEX,
        data: {
          statusedGenresByIndex: statusedGenresByIndex
            .concat(addGenreItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Жанр добавлен')
    }
  })
  // const [updateGenre, { data: updateGenreData }] = useMutation(UPDATE_GENRE)
  const [updateGenre, { error: errorUpdateGenre }] = useMutation(UPDATE_GENRE, {
    update(cache, { data: { updateGenre: updateGenreItem } }) {
      const { statusedGenresByIndex } = cache.readQuery({
        query: GET_GENRES_BY_INDEX
      })
      cache.writeQuery({
        query: GET_GENRES_BY_INDEX,
        data: {
          statusedGenresByIndex: statusedGenresByIndex
            .filter(genre => genre.id !== updateGenreItem.id)
            .concat(updateGenreItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Жанр обновлен')
    }
  })
  const [deleteGenre, { error: errorDeleteGenre }] = useMutation(DELETE_GENRE, {
    update(cache, { data: { updateGenre: deleteGenreItem } }) {
      const { statusedGenresByIndex } = cache.readQuery({
        query: GET_GENRES_BY_INDEX
      })
      cache.writeQuery({
        query: GET_GENRES_BY_INDEX,
        data: {
          statusedGenresByIndex: statusedGenresByIndex.filter(
            genre => genre.id !== deleteGenreItem.id
          )
        }
      })
      hideLoading()
      toast.success('Жанр удален')
    }
  })

  React.useEffect(() => {
    if (errorDeleteGenre) {
      console.log(errorDeleteGenre)
    }
  }, [errorDeleteGenre])

  React.useEffect(() => {
    if (errorAddGenre) {
      console.log(errorAddGenre)
    }
  }, [errorAddGenre])

  React.useEffect(() => {
    if (errorAddGenre) {
      console.log(errorAddGenre)
    }
  }, [errorUpdateGenre])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllGenres(data.statusedGenresByIndex)
    }
  }, [data, loading, error])

  const addGenreHandler = (form, closeFunc, file, setFile, fileRef) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addGenre({
          variables: {
            photoFile: file,
            input: {
              nameKZ: val.nameKZ,
              nameRU: val.nameRU,
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setFile(null)
        fileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateGenreHandler = (
    form,
    genreId,
    closeFunc,
    file,
    setFile,
    fileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateGenre({
          variables: {
            id: genreId,
            photoFile: file,
            input: {
              nameKZ: val.nameKZ,
              nameRU: val.nameRU,
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setFile(null)
        fileRef.current.value = null
        // hideLoading()
        closeFunc(false)
        // toast.success('Жанр изменен')
      })
  }

  const deleteGenreHandler = (genreId, closeFunc) => {
    showLoading()
    deleteGenre({
      variables: {
        id: genreId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Genres
      genres={allGenres}
      addGenre={addGenre}
      refetch={refetch}
      addGenreHandler={addGenreHandler}
      updateGenreHandler={updateGenreHandler}
      deleteGenreHandler={deleteGenreHandler}
    />
  )
}

export default WithMainLayout(GenresContainer)
