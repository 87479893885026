import React, { useEffect, useRef, useState } from 'react'
import { Table, Button, Form, Modal, message, Input } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import RiddlesForm from './RiddlesForm'
import { useLoading } from '../../../context/useLoading'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Riddles = ({ riddles, createHandler, updateHandler, deleteHandler }) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [riddlesItem, setRiddlesItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchRiddle, setSearchRiddle] = useState(null)
  const [listRiddles, setListRiddles] = useState(null)
  const [riddleAudioFile, setRiddleAudioFile] = useState(null)
  const riddleAudioFileRef = useRef()
  const [answerAudioFile, setAnswerAudioFile] = useState(null)
  const answerAudioFileRef = useRef()
  const { hideLoading } = useLoading()

  useEffect(() => {
    if (searchRiddle) {
      const searchArray = riddles.filter(riddle => {
        return (
          riddle.riddleText?.toLowerCase().includes(searchRiddle) ||
          riddle.answerText?.toLowerCase().includes(searchRiddle)
        )
      })
      setListRiddles(searchArray)
    } else {
      setListRiddles(riddles)
    }
  }, [riddles, searchRiddle])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Текст Загадки',
      dataIndex: 'riddleText',
      width: '15%'
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.riddleAudioUrl
        if (audioLink && !audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            {audioLink && <source src={audioLink} type="audio/mpeg" />}
          </audio>
        )
      }
    },
    {
      title: 'Ответ Загадки',
      dataIndex: 'answerText',
      width: '15%'
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.answerAudioUrl
        if (audioLink && !audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            {audioLink && <source src={audioLink} type="audio/mpeg" />}
          </audio>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setRiddlesItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setRiddlesItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listRiddles}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Текст"
              onChange={e => {
                setSearchRiddle(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая загадка
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая загадка"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setRiddleAudioFile(null)
          if (riddleAudioFileRef.current)
            riddleAudioFileRef.current.value = null
          setAnswerAudioFile(null)
          if (answerAudioFileRef.current)
            answerAudioFileRef.current.value = null
        }}
        onOk={() => {
          // if (riddleAudioFileRef !== null || answerAudioFileRef !== null) {
          createHandler(
            form,
            setModalVisible,
            riddleAudioFile,
            setRiddleAudioFile,
            riddleAudioFileRef,
            answerAudioFile,
            setAnswerAudioFile,
            answerAudioFileRef
          )
          // } else {
          //   message.error('Пожалуйста, выберите Аудио')
          // }
        }}
      >
        <RiddlesForm
          form={form}
          setRiddleAudioFile={setRiddleAudioFile}
          setAnswerAudioFile={setAnswerAudioFile}
          riddleAudioFileRef={riddleAudioFileRef}
          answerAudioFileRef={answerAudioFileRef}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
            setRiddleAudioFile(null)
            setAnswerAudioFile(null)
            if (riddleAudioFileRef.current)
              riddleAudioFileRef.current.value = null
            if (answerAudioFileRef.current)
              answerAudioFileRef.current.value = null
          }}
          onOk={() => {
            updateHandler(
              formForEdit,
              riddlesItem.id,
              setEditModal,
              riddleAudioFile,
              setRiddleAudioFile,
              riddleAudioFileRef,
              answerAudioFile,
              setAnswerAudioFile,
              answerAudioFileRef
            )
          }}
        >
          <RiddlesForm
            form={formForEdit}
            riddleText={riddlesItem.riddleText}
            answerText={riddlesItem.answerText}
            setRiddleAudioFile={setRiddleAudioFile}
            riddleAudioFileRef={riddleAudioFileRef}
            setAnswerAudioFile={setAnswerAudioFile}
            answerAudioFileRef={answerAudioFileRef}
            status={riddlesItem.status}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(riddlesItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {riddlesItem.answerText} ?</h3>
        </Modal>
      )}
    </>
  )
}

Riddles.propTypes = {
  riddles: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default Riddles
