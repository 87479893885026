import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Kitchens from './Kitchens'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_KITCHENS_BY_INDEX = gql`
  query statusedKitchensByIndex {
    statusedKitchensByIndex {
      id
      kitchen
      index
      link2Audio
      link2Image
      status
    }
  }
`

const ADD_KITCHEN = gql`
  mutation addKitchen(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $input: KitchenInput!
  ) {
    addKitchen(audioFile: $audioFile, imageFile: $imageFile, input: $input) {
      id
      kitchen
      index
      link2Audio
      link2Image
      status
    }
  }
`

const UPDATE_KITCHEN = gql`
  mutation updateKitchen(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $input: UpdateKitchenInput
  ) {
    updateKitchen(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      kitchen
      index
      link2Audio
      link2Image
      status
    }
  }
`

// const DELETE_KITCHEN = gql`
//   mutation deleteKitchen($id: ID!) {
//     deleteKitchen(id: $id) {
//       id
//       message
//     }
//   }
// `

const DELETE_KITCHEN = gql`
  mutation updateKitchen($id: ID!, $input: UpdateKitchenInput) {
    updateKitchen(id: $id, input: $input) {
      id
      kitchen
      index
      link2Audio
      link2Image
      status
    }
  }
`

const KitchensContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allKitchens, setAllKitchens] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_KITCHENS_BY_INDEX)
  const [addKitchen, { error: errorAddKitchen }] = useMutation(ADD_KITCHEN, {
    update(cache, { data: { addKitchen: addKitchenItem } }) {
      const { statusedKitchensByIndex } = cache.readQuery({
        query: GET_KITCHENS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_KITCHENS_BY_INDEX,
        data: {
          statusedKitchensByIndex: statusedKitchensByIndex
            .concat(addKitchenItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Предмет добавлен')
    }
  })
  // const [updateKitchen, { data: updateKitchenData }] = useMutation(UPDATE_KITCHEN)
  const [updateKitchen, { error: errorUpdateKitchen }] = useMutation(
    UPDATE_KITCHEN,
    {
      update(cache, { data: { updateKitchen: updateKitchenItem } }) {
        const { statusedKitchensByIndex } = cache.readQuery({
          query: GET_KITCHENS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_KITCHENS_BY_INDEX,
          data: {
            statusedKitchensByIndex: statusedKitchensByIndex
              .filter(kitchen => kitchen.id !== updateKitchenItem.id)
              .concat(updateKitchenItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Предмет обновлен')
      }
    }
  )
  const [deleteKitchen, { error: errorDeleteKitchen }] = useMutation(
    DELETE_KITCHEN,
    {
      update(cache, { data: { updateKitchen: deleteKitchenItem } }) {
        const { statusedKitchensByIndex } = cache.readQuery({
          query: GET_KITCHENS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_KITCHENS_BY_INDEX,
          data: {
            statusedKitchensByIndex: statusedKitchensByIndex.filter(
              kitchen => kitchen.id !== deleteKitchenItem.id
            )
          }
        })
        hideLoading()
        toast.success('Предмет удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddKitchen) {
      console.log(errorAddKitchen)
    }
  }, [errorAddKitchen])

  React.useEffect(() => {
    if (errorDeleteKitchen) {
      console.log(errorDeleteKitchen)
    }
  }, [errorDeleteKitchen])

  React.useEffect(() => {
    if (errorUpdateKitchen) {
      console.log(errorUpdateKitchen)
    }
  }, [errorUpdateKitchen])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllKitchens(data.statusedKitchensByIndex)
    }
  }, [data, loading, error])

  const addKitchenHandler = (
    form,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        // console.log('audioFile', audioFile)
        // console.log('imageFile', imageFile)
        // console.log('val', val)
        form.resetFields()
        showLoading()
        addKitchen({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            input: {
              kitchen: val.kitchen,
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateKitchenHandler = (
    form,
    kitchenId,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateKitchen({
          variables: {
            id: kitchenId,
            audioFile,
            imageFile,
            input: {
              kitchen: val.kitchen,
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        // toast.success('Буква обновлена')
      })
  }

  const deleteKitchenHandler = (kitchenId, closeFunc) => {
    showLoading()
    deleteKitchen({
      variables: {
        id: kitchenId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Kitchens
      kitchens={allKitchens}
      addKitchen={addKitchen}
      refetch={refetch}
      addKitchenHandler={addKitchenHandler}
      updateKitchenHandler={updateKitchenHandler}
      deleteKitchenHandler={deleteKitchenHandler}
    />
  )
}

export default WithMainLayout(KitchensContainer)
