import React from 'react'
import { Form, Input, Select, InputNumber } from 'antd'
import PropTypes from 'prop-types'
import TextArea from 'antd/lib/input/TextArea'

const RiddlesForm = ({
  form,
  riddleText,
  answerText,
  status,
  setRiddleAudioFile,
  setAnswerAudioFile,
  riddleAudioFileRef,
  answerAudioFileRef
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите статус" mode="single">
          <Select.Option key="active" value="active">
            Активный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="riddleText"
        label="Текст загадки"
        name="riddleText"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите текст загадки`
          }
        ]}
        initialValue={riddleText}
      >
        <TextArea placeholder="Текст загадки" />
      </FormItem>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио загадки</>
        <input
          type="file"
          accept="audio/*"
          ref={riddleAudioFileRef}
          onChange={event => {
            setRiddleAudioFile(event.target.files[0])
          }}
        />
      </div>
      <FormItem
        key="answerText"
        label="Ответ загадки"
        name="answerText"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите ответ загадки`
          }
        ]}
        initialValue={answerText}
      >
        <Input placeholder="Ответ загадки" />
      </FormItem>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио ответа загадки</>
        <input
          type="file"
          accept="audio/*"
          ref={answerAudioFileRef}
          onChange={event => {
            setAnswerAudioFile(event.target.files[0])
          }}
        />
      </div>
    </Form>
  )
}

RiddlesForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

export default RiddlesForm
