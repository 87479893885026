import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import {
  CustomerServiceOutlined,
  StarOutlined,
  ThunderboltOutlined,
  CrownOutlined,
  LogoutOutlined,
  TeamOutlined,
  BarsOutlined,
  FontColorsOutlined,
  FieldNumberOutlined,
  HighlightOutlined,
  NotificationOutlined,
  DashboardOutlined,
  DingdingOutlined,
  HistoryOutlined,
  SortDescendingOutlined
} from '@ant-design/icons'
import { Menu } from 'antd'
import styled from 'styled-components'
import { useAuth } from '../context/useAuth'
import Loading from '../pages/shared/Loading'
import EducationIcon from '../assets/icons/Education.svg'

const Icon = styled.img`
  width: 14px;
`

function MainMenu({ currentUrl }) {
  const { user, logout } = useAuth()
  const history = useHistory()

  const [usedUrl, setUsedUrl] = useState()
  const [openKeys, setOpenKeys] = useState([])

  const contentManagerMenu = [
    { link: '/stories', name: 'Истории-новости', icon: HistoryOutlined },
    { link: '/notifications', name: 'Уведомления', icon: NotificationOutlined },
    { link: '/creators', name: 'Авторы/Исполнители', icon: StarOutlined },
    { link: '/publishers', name: 'Издатели', icon: ThunderboltOutlined },
    { link: '/instruments', name: 'Инструменты', icon: NotificationOutlined },
    { link: '/genres', name: 'Жанры', icon: CrownOutlined },
    { link: '/categories', name: 'Категории', icon: CrownOutlined },
    { link: '/songs', name: 'Песни', icon: CustomerServiceOutlined },
    { link: '/playlists', name: 'Плейлисты', icon: BarsOutlined },
    {
      link: '/playlistsCollections',
      name: 'Коллекции плейлистов',
      icon: BarsOutlined
    },
    { link: '/tags', name: 'Тэги', icon: CrownOutlined },
    { link: '/tales', name: 'Сказки', icon: CustomerServiceOutlined },
    { link: '/collections', name: 'Сборники', icon: BarsOutlined },
    { link: '/cartoons', name: 'Мультфильмы', icon: DingdingOutlined },
    { link: '/education', name: 'Обучение обложки', icon: FontColorsOutlined },
    { link: '/letters', name: 'Алфавит', icon: SortDescendingOutlined },
    {
      isGroup: true,
      key: 'educationSection',
      name: 'Обучение',
      icon: () => <Icon style={{ marginRight: '10px' }} src={EducationIcon} />,
      children: [
        { link: '/topics', name: 'Темы' },
        { link: '/educationCollections', name: 'Коллекции Обучения' },
        { link: '/practiceLetters', name: 'Буквы практики' },
        { link: '/practiceNumbers', name: 'Цифры практики' },
        { link: '/practiceFigures', name: 'Фигуры практики' },
        { link: '/practiceColors', name: 'Цвета практики' },
        { link: '/practiceItems', name: 'Объекты практики' },
        { link: '/practiceItemsTwoColors', name: 'Найди два цвета' },
        { link: '/lessonTypeAudios', name: 'Аудио типов урока' }
      ]
    },
    {
      isGroup: true,
      key: 'Traditions',
      name: 'Сандык - Традиции',
      icon: () => <Icon style={{ marginRight: '10px' }} src={EducationIcon} />,
      children: [
        { link: '/traditionsArticles', name: 'Статьи традиций' },
        { link: '/traditions', name: 'Традиции' },
        { link: '/traditionsCollections', name: 'Коллекции традиций' }
      ]
    },
    {
      isGroup: true,
      key: 'Riddles',
      name: 'Сандык - Загадки',
      icon: () => <Icon style={{ marginRight: '10px' }} src={EducationIcon} />,
      children: [
        {
          link: '/riddles',
          name: 'Загадки'
        },
        { link: '/riddlesCollections', name: 'Коллекции загадок' }
      ]
    },
    {
      link: '/zhanyltpashtar',
      name: 'Сандык - Скороговорки',
      icon: FieldNumberOutlined
    },
    {
      isGroup: true,
      key: 'Proverbs',
      name: 'Сандык - Пословицы',
      icon: () => <Icon style={{ marginRight: '10px' }} src={EducationIcon} />,
      children: [
        { link: '/proverbs', name: 'Пословицы и поговорки' },
        { link: '/proverbsCollections', name: 'Коллекции пословиц и поговорок' }
      ]
    },
    {
      link: '/backgroundImages',
      name: 'Сандык - Фоновые изображения',
      icon: FieldNumberOutlined
    },
    { link: '/digits', name: 'Цифры', icon: FieldNumberOutlined },
    { link: '/colors', name: 'Цвета', icon: FieldNumberOutlined },
    { link: '/animals', name: 'Животные', icon: FieldNumberOutlined },
    { link: '/kitchen', name: 'Предметы на кухне', icon: FieldNumberOutlined },
    {
      link: '/qaraSozder',
      name: 'Слова назидания Абая',
      icon: HighlightOutlined
    },
    { link: '/dashboard', name: 'Dashboard', icon: DashboardOutlined },
    { link: '/courses', name: 'Курсы обучения', icon: FontColorsOutlined },
    { link: '/syllables', name: 'Слога', icon: FontColorsOutlined },
    { link: '/words', name: 'Слова', icon: FontColorsOutlined },
    { link: '/banners', name: 'Баннеры', icon: FontColorsOutlined }
  ]

  const adminMenu = [
    {
      isGroup: true,
      key: 'transactionsSection',
      name: 'Статистика',
      icon: FontColorsOutlined,
      children: [
        {
          link: '/transactions',
          name: 'Транзакции',
          icon: FontColorsOutlined
        },
        {
          link: '/usersTransactions',
          name: 'Транзакции пользователей',
          icon: FontColorsOutlined
        },
        {
          link: '/appDetails',
          name: 'Детали приложения пользователей',
          icon: FontColorsOutlined
        }
      ]
    },
    { link: '/users', name: 'Пользователи', icon: TeamOutlined },
    ...contentManagerMenu
  ]

  const userToMenu = {
    admin: adminMenu,
    contentManager: contentManagerMenu
  }

  useEffect(() => {
    adminMenu.every(menuItem => {
      let isFinded = false
      if (menuItem.isGroup) {
        menuItem.children.every(child => {
          if (
            currentUrl === child.link ||
            currentUrl.startsWith(child.link + '/')
          ) {
            setUsedUrl(child.link)
            setOpenKeys([menuItem.key])
            isFinded = true
            return false
          } else {
            return true
          }
        })
      } else if (
        currentUrl === menuItem.link ||
        currentUrl.startsWith(menuItem.link + '/')
      ) {
        setUsedUrl(menuItem.link)
        isFinded = true
      }
      return !isFinded
    })
  }, [currentUrl])

  if (!user) {
    return <Loading />
  }

  const currentMenu = userToMenu[user.role] || []

  const onLogoutClick = () => {
    logout()
    history.push('/login')
  }

  if (user) {
    return (
      <Menu
        selectedKeys={[usedUrl]}
        openKeys={openKeys}
        onOpenChange={openedKey => {
          setOpenKeys(openedKey)
        }}
        mode="inline"
        theme="light"
      >
        {currentMenu.map(item => {
          return item.isGroup ? (
            <Menu.SubMenu
              key={item.key}
              title={item.name}
              icon={<item.icon />}
              children={item.children.map(child => (
                <Menu.Item key={child.link}>
                  <Link to={child.link}>{child.name}</Link>
                </Menu.Item>
              ))}
            />
          ) : (
            <Menu.Item key={item.link} icon={<item.icon />} type={item.type}>
              <Link to={item.link} key={item.link}>
                {item.name}
              </Link>
            </Menu.Item>
          )
        })}

        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={onLogoutClick}
          danger
        >
          Выйти
        </Menu.Item>
      </Menu>
    )
  }
  return <React.Fragment />
}

MainMenu.propTypes = {
  currentUrl: PropTypes.string.isRequired
}

export default MainMenu
