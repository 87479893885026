import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Tags from './Tags'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_TAGS = gql`
  query statusedTags {
    statusedTags {
      id
      nameKZ
      nameRU
      status
    }
  }
`

const ADD_TAG = gql`
  mutation addTag($input: TagInput) {
    addTag(input: $input) {
      id
      nameKZ
      nameRU
      status
    }
  }
`

const UPDATE_TAG = gql`
  mutation updateTag($id: ID!, $input: UpdateTagInput) {
    updateTag(id: $id, input: $input) {
      id
      nameKZ
      nameRU
      status
    }
  }
`

const DELETE_TAG = gql`
  mutation updateTag($id: ID!, $input: UpdateTagInput) {
    updateTag(id: $id, input: $input) {
      id
      nameKZ
      nameRU
      status
    }
  }
`

// const DELETE_TAG = gql`
//   mutation deleteTag($id: ID!) {
//     deleteTag(id: $id) {
//       id
//       message
//     }
//   }
// `

const TagsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allTags, setAllTags] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_TAGS)
  const [addTag, { error: errorAddTag }] = useMutation(ADD_TAG, {
    update(cache, { data: { addTag: addTagItem } }) {
      const { statusedTags } = cache.readQuery({ query: GET_TAGS })
      cache.writeQuery({
        query: GET_TAGS,
        data: { statusedTags: [addTagItem].concat(statusedTags) }
      })
      hideLoading()
      toast.success('Тэг добавлен')
    }
  })
  const [updateTag, { data: updateTagData }] = useMutation(UPDATE_TAG)
  const [deleteTag, { error: errorDeleteTag }] = useMutation(DELETE_TAG, {
    update(cache, { data: { updateTag: deleteTagItem } }) {
      const { statusedTags } = cache.readQuery({ query: GET_TAGS })
      cache.writeQuery({
        query: GET_TAGS,
        data: {
          statusedTags: statusedTags.filter(tag => tag.id !== deleteTagItem.id)
        }
      })
      hideLoading()
      toast.success('Тэг удален')
    }
  })

  React.useEffect(() => {
    if (errorDeleteTag) {
      console.log(errorDeleteTag)
    }
  }, [errorDeleteTag])

  React.useEffect(() => {
    if (errorAddTag) {
      console.log(errorAddTag)
    }
  }, [errorAddTag])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllTags(data.statusedTags)
    }
  }, [data, loading, error, updateTagData])

  const addTagHandler = (form, closeFunc) => {
    // console.log(form)
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addTag({
          variables: {
            input: {
              nameKZ: val.nameKZ,
              nameRU: val.nameRU,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updateTagHandler = (form, tagId, closeFunc) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateTag({
          variables: {
            id: tagId,
            input: {
              nameKZ: val.nameKZ,
              nameRU: val.nameRU,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        closeFunc(false)
        toast.success('Тэг изменен')
      })
  }

  const deleteTagHandler = (tagId, closeFunc) => {
    showLoading()
    deleteTag({
      variables: {
        id: tagId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Tags
      tags={allTags}
      addTag={addTag}
      refetch={refetch}
      addTagHandler={addTagHandler}
      updateTagHandler={updateTagHandler}
      deleteTagHandler={deleteTagHandler}
    />
  )
}

export default WithMainLayout(TagsContainer)
