import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import CourseForm from './CoursesForm'
import UpIcon from '../../../assets/icons/Up.svg'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
`

const Courses = ({
  collectionId,
  collection,
  courses,
  allTopics,
  loading,
  addCourseHandler,
  updateCourseHandler,
  deleteCourseHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [courseItem, setCourseItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchCourse, setSearchCourse] = React.useState()
  const [listCourses, setListCourses] = React.useState(null)

  const [imageFile, setImageFile] = useState(null)

  const imageFileRef = React.useRef()

  const [colorTemp, setColorTemp] = useState('#FFFFFF')

  React.useEffect(() => {
    const descriptiveCourses = courses.map(course => {
      return {
        id: course.id,
        status: course.status,
        index: course.index,
        nameKZ: course.nameKZ,
        nameRU: course.nameRU,
        imageURL: course.imageURL,
        topics: course.topics,
        color: course.color
      }
    })
    let searchArray = descriptiveCourses

    if (searchCourse) {
      searchArray = searchArray.filter(course => {
        return course.course?.toLowerCase().includes(searchCourse)
      })
    }

    setListCourses(searchArray)
  }, [courses, searchCourse])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => <div style={{ background: item.status === 'active' ? '#44FB82' : '#D4D4D4', height: '60px', width: '20px', margin: '-10px' }}></div>
    },
    {
      title: '#',
      width: '1%',
      dataIndex: 'index'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        if (item.imageURL) {
          return (
            <>
              <Avatar shape="square" size={64} src={item.imageURL} />
            </>
          )
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Название(каз)',
      dataIndex: 'nameKZ',
      width: '5%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'nameRU',
      width: '5%'
    },
    {
      title: 'Цвет',
      width: '1%',
      render: item => {
        if (item.color) {
          return {
            props: {
              style: {
                background: item.color
              }
            }
          }
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setCourseItem(item)
                setEditModal(true)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setCourseItem(item)
                setDeleteModal(true)
              }}
            >
              Удалить
            </Button>
            <Link
              to={`/educationCollections/${collectionId}/courses/${item.id}/lessons`}
            >
              <Button style={{ marginLeft: '5%' }}>
                <Icon src={UpIcon} style={{ transform: 'rotate(90deg)' }} />
              </Button>
            </Link>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Link to={'/educationCollections'}>
        <Tag>Коллекции</Tag>
      </Link>
      <Tag>{'->'}</Tag>
      <Tag>Курсы</Tag>
      <Table
        dataSource={listCourses}
        columns={columns}
        rowKey={item => item.id}
        loading={loading}
        pagination={{
          showSizeChanger: false
        }}
        title={() => (
          <>
            <Tag>
              {'Коллекция - ' +
                (collection?.titleKZ || '') +
                ', ' +
                (collection?.titleRU || '')}
            </Tag>
            <StyledHeaderContainer>
              <StyledInput
                prefix={<SearchOutlined />}
                placeholder="Курс"
                onChange={e => {
                  setSearchCourse(e.target.value.toLowerCase())
                }}
              />

              {collection && (
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  <PlusOutlined /> Новый курс
                </Button>
              )}
            </StyledHeaderContainer>
          </>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новое курс"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setImageFile(null)
          imageFileRef.current.value = null
          setColorTemp('#FFFFFF')
        }}
        onOk={() => {
          if (imageFile !== null) {
            if (colorTemp) {
              addCourseHandler(
                form,
                setModalVisible,
                imageFile,
                setImageFile,
                imageFileRef,
                colorTemp,
                setColorTemp
              )
            } else {
              message.error('Пожалуйста, выберите цвет')
            }
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <CourseForm
          setImageFile={setImageFile}
          imageFileRef={imageFileRef}
          colorTemp={colorTemp}
          setColorTemp={setColorTemp}
          allTopics={allTopics}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setCourseItem(null)
            setImageFile(null)
            imageFileRef.current.value = null
            setColorTemp('#FFFFFF')
          }}
          onOk={() => {
            updateCourseHandler(
              formForEdit,
              courseItem.id,
              setEditModal,
              imageFile,
              setImageFile,
              imageFileRef,
              colorTemp,
              setColorTemp
            )
          }}
        >
          <CourseForm
            form={formForEdit}
            index={courseItem.index}
            status={courseItem.status}
            nameKZ={courseItem.nameKZ}
            nameRU={courseItem.nameRU}
            color={courseItem.color}
            topics={courseItem.topics}
            setImageFile={setImageFile}
            imageFileRef={imageFileRef}
            colorTemp={colorTemp}
            setColorTemp={setColorTemp}
            allTopics={allTopics}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteCourseHandler(courseItem.id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить '{courseItem.nameKZ} -{' '}
            {courseItem.nameRU}' ?
          </h3>
        </Modal>
      )}
    </>
  )
}

Courses.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  addCourseHandler: PropTypes.func.isRequired,
  updateCourseHandler: PropTypes.func.isRequired
}

export default Courses
