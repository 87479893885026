import React from 'react'
import { Form, Input, Select, InputNumber } from 'antd'
import PropTypes from 'prop-types'

const RiddlesCollectionsForm = ({
  form,
  status,
  titleKaz,
  titleRus,
  setImageFile,
  imageFileRef,
  riddles,
  selectedRiddles
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите статус" mode="single">
          <Select.Option key="active" value="active">
            Активный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="titleKaz"
        label="Название (каз)"
        name="titleKaz"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название на казахском`
          }
        ]}
        initialValue={titleKaz}
      >
        <Input placeholder="Название (каз)" />
      </FormItem>
      <FormItem
        key="titleRus"
        label="Название (рус)"
        name="titleRus"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название на русском`
          }
        ]}
        initialValue={titleRus}
      >
        <Input placeholder="Название (рус)" />
      </FormItem>
      <FormItem
        key="riddles"
        label="Загадки"
        name="riddles"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите загадки`
          }
        ]}
        initialValue={selectedRiddles?.map(item => (item ? item?.id : item))}
      >
        <Select
          placeholder="Выберите загадки"
          mode="multiple"
          showSearch
          optionFilterProp="children"
        >
          {riddles?.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {item.riddleText} - {item.answerText}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <div style={{ marginBottom: '3%' }}>
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
      </div>
    </Form>
  )
}

RiddlesCollectionsForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

export default RiddlesCollectionsForm
