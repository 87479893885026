import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import EducationTemp from './EducationTemp'
import WithMainLayout from '../../hocs/withMainLayout'

const UPLOAD_EDUCATION_IMAGE = gql`
  mutation uploadEducationImage($imageFile: FileUpload!, $name: String!) {
    uploadEducationImage(imageFile: $imageFile, name: $name) {
      status
      message
    }
  }
`

const UploadTempContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [
    uploadEducationImage,
    { error: errorUploadEducationImage }
  ] = useMutation(UPLOAD_EDUCATION_IMAGE)

  React.useEffect(() => {
    if (errorUploadEducationImage) {
      console.log(errorUploadEducationImage)
    }
  }, [errorUploadEducationImage])

  const uploadEducationImageHandler = (
    name,
    setName,
    imageFile,
    setImageFile,
    imageFileRef
  ) => {
    showLoading()
    uploadEducationImage({
      variables: {
        imageFile,
        name
      }
    }).then(() => {
      toast.success('Обложка обновлена!')
      setName(null)
      setImageFile(null)
      imageFileRef.current.value = null
      hideLoading()
    })
  }

  return (
    <EducationTemp uploadEducationImageHandler={uploadEducationImageHandler} />
  )
}

export default WithMainLayout(UploadTempContainer)
