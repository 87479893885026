import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Animals from './Animals'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_ANIMALS_BY_INDEX = gql`
  query statusedAnimalsByIndex {
    statusedAnimalsByIndex {
      id
      animal
      index
      link2Audio
      link2Image
      status
    }
  }
`

const ADD_ANIMAL = gql`
  mutation addAnimal(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $input: AnimalInput!
  ) {
    addAnimal(audioFile: $audioFile, imageFile: $imageFile, input: $input) {
      id
      animal
      index
      link2Audio
      link2Image
      status
    }
  }
`

const UPDATE_ANIMAL = gql`
  mutation updateAnimal(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $input: UpdateAnimalInput
  ) {
    updateAnimal(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      animal
      index
      link2Audio
      link2Image
      status
    }
  }
`

// const DELETE_ANIMAL = gql`
//   mutation deleteAnimal($id: ID!) {
//     deleteAnimal(id: $id) {
//       id
//       message
//     }
//   }
// `

const DELETE_ANIMAL = gql`
  mutation updateAnimal($id: ID!, $input: UpdateAnimalInput) {
    updateAnimal(id: $id, input: $input) {
      id
      animal
      index
      link2Audio
      link2Image
      status
    }
  }
`

const AnimalsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allAnimals, setAllAnimals] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_ANIMALS_BY_INDEX)
  const [addAnimal, { error: errorAddAnimal }] = useMutation(ADD_ANIMAL, {
    update(cache, { data: { addAnimal: addAnimalItem } }) {
      const { statusedAnimalsByIndex } = cache.readQuery({
        query: GET_ANIMALS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_ANIMALS_BY_INDEX,
        data: {
          statusedAnimalsByIndex: statusedAnimalsByIndex
            .concat(addAnimalItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Животное добавлено')
    }
  })
  // const [updateAnimal, { data: updateAnimalData }] = useMutation(UPDATE_ANIMAL)
  const [updateAnimal, { error: errorUpdateAnimal }] = useMutation(
    UPDATE_ANIMAL,
    {
      update(cache, { data: { updateAnimal: updateAnimalItem } }) {
        const { statusedAnimalsByIndex } = cache.readQuery({
          query: GET_ANIMALS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_ANIMALS_BY_INDEX,
          data: {
            statusedAnimalsByIndex: statusedAnimalsByIndex
              .filter(animal => animal.id !== updateAnimalItem.id)
              .concat(updateAnimalItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Животное обновлене')
      }
    }
  )
  const [deleteAnimal, { error: errorDeleteAnimal }] = useMutation(
    DELETE_ANIMAL,
    {
      update(cache, { data: { updateAnimal: deleteAnimalItem } }) {
        const { statusedAnimalsByIndex } = cache.readQuery({
          query: GET_ANIMALS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_ANIMALS_BY_INDEX,
          data: {
            statusedAnimalsByIndex: statusedAnimalsByIndex.filter(
              animal => animal.id !== deleteAnimalItem.id
            )
          }
        })
        hideLoading()
        toast.success('Животное удалено')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddAnimal) {
      console.log(errorAddAnimal)
    }
  }, [errorAddAnimal])

  React.useEffect(() => {
    if (errorDeleteAnimal) {
      console.log(errorDeleteAnimal)
    }
  }, [errorDeleteAnimal])

  React.useEffect(() => {
    if (errorUpdateAnimal) {
      console.log(errorUpdateAnimal)
    }
  }, [errorUpdateAnimal])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllAnimals(data.statusedAnimalsByIndex)
    }
  }, [data, loading, error])

  const addAnimalHandler = (
    form,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        // console.log('audioFile', audioFile)
        // console.log('imageFile', imageFile)
        // console.log('val', val)
        form.resetFields()
        showLoading()
        addAnimal({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            input: {
              animal: val.animal,
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateAnimalHandler = (
    form,
    animalId,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateAnimal({
          variables: {
            id: animalId,
            audioFile,
            imageFile,
            input: {
              animal: val.animal,
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        // toast.success('Буква обновлена')
      })
  }

  const deleteAnimalHandler = (animalId, closeFunc) => {
    showLoading()
    deleteAnimal({
      variables: {
        id: animalId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Animals
      animals={allAnimals}
      addAnimal={addAnimal}
      refetch={refetch}
      addAnimalHandler={addAnimalHandler}
      updateAnimalHandler={updateAnimalHandler}
      deleteAnimalHandler={deleteAnimalHandler}
    />
  )
}

export default WithMainLayout(AnimalsContainer)
