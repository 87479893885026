import React from 'react'

import { Form, Input, Select, Button, Divider, Space, InputNumber } from 'antd'
import PropTypes from 'prop-types'
import './Cartoon.css'
import ageGroups from '../shared/ageGroups'
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons'
import styled from 'styled-components'

const StyledDivDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CartoonForm = ({
  form,
  setCoverImageFile,
  coverImageFileRef,
  type,
  title,
  tags,
  ageGroup,
  episodes,
  descriptionKZ,
  descriptionRU,
  status,
  index,
  allTags
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      {/* <button
        onClick={() => {
          audioFileRef.current.value = null
          textFileRef.current.value = null
        }}
      >
        Сбросить выбранные файлы
      </button> */}
      <div style={{ marginBottom: '3%' }}>
        <>Обложка</>
        <StyledDivDelete>
          <input
            type="file"
            accept="image/*"
            ref={coverImageFileRef}
            onChange={event => setCoverImageFile(event.target.files[0])}
          />
        </StyledDivDelete>
      </div>
      {/* <label for="upload-photo">Аудио файл...</label>
      <input type="file" name="photo" id="upload-file" onChange={ (event) => setTextFile(event.target.files[0])} /> */}
      {/* <>Text File</>
      <input type="file" title="Choose Text File"  onChange={ (event) => setTextFile(event.target.files[0])}/>     */}
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность мультфильма"
        name="index"
        rules={[
          {
            required: false,
            message: `Пожалуйста, введите Очередность Цифры`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность Цифры" />
      </FormItem>
      <FormItem
        key="title"
        label="Название"
        name="title"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Аудио`
          }
        ]}
        initialValue={title}
      >
        <Input placeholder="Название" />
      </FormItem>
      <FormItem
        key="tags"
        label="Тэги"
        name="tags"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите тэги`
          }
        ]}
        initialValue={tags ? tags.map(item => item?.id) : undefined}
      >
        <Select
          placeholder="Выберите тэги"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              option.children.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            )
          }}
        >
          {allTags.tags.map(item => (
            <Select.Option key={item.id} value={item.id}>
              <>{`${item.nameKZ} - ${item.nameRU}`}</>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="ageGroup"
        label="Возрастная группа"
        name="ageGroup"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите возрастную группу`
          }
        ]}
        initialValue={ageGroup ? ageGroup : undefined}
      >
        <Select
          placeholder="Выберите возрастную группу"
          mode="single"
          allowClear
        >
          {ageGroups.map(item => {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            )
          })}
        </Select>
      </FormItem>
      <FormItem
        key="descriptionKZ"
        label="Описание на казахском"
        name="descriptionKZ"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткое описание`
          }
        ]}
        initialValue={descriptionKZ}
      >
        <Input.TextArea placeholder="Описание..." />
      </FormItem>
      <FormItem
        key="descriptionRU"
        label="Описание на русском"
        name="descriptionRU"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткое описание`
          }
        ]}
        initialValue={descriptionRU}
      >
        <Input.TextArea placeholder="Описание..." />
      </FormItem>
      <>Серии</>
      <Form.List
        name="episodes"
        label="Серии"
        key="episodes"
        initialValue={episodes}
      >
        {(fields = { episodes }, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{ display: 'flex', marginBottom: 8 }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, 'episode']}
                  rules={[
                    { required: true, message: 'Отсутствует номер серии' }
                  ]}
                >
                  <InputNumber min={1} placeholder="Cерия" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'title']}
                  rules={[
                    { required: true, message: 'Отсутствует название серии' }
                  ]}
                >
                  <Input placeholder="Название" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'link2Video']}
                  rules={[
                    { required: true, message: 'Отсутствует ссылка на видео' },
                    { type: 'url', message: 'Недействительная ссылка' }
                  ]}
                >
                  <Input placeholder="Ссылка" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Добавить серию
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  )
}

CartoonForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string
}

CartoonForm.defaultProps = {
  title: '',
  tags: [],
  ageGroup: undefined,
  status: 'active',
  episodes: []
}

export default CartoonForm
