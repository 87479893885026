import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Playlists from './Playlists'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

// const GET_PLAYLISTS = gql`
//   query playlists {
//     playlists {
//       id
//       title
//       coverImage
//       index
//       songs {
//         id
//         title
//         author {
//           id
//           name
//         }
//       }
//     }
//   }
// `

const GET_PLAYLISTS_BY_INDEX = gql`
  query statusedPlaylistsByIndex {
    statusedPlaylistsByIndex {
      id
      titleKZ
      titleRU
      coverImage
      index
      songs {
        id
        title
        authors {
          id
          name
        }
        voiceActors {
          id
          name
        }
      }
      status
      descriptionKZ
      descriptionRU
    }
  }
`

const ADD_PLAYLIST = gql`
  mutation addPlaylist($coverImageFile: FileUpload, $input: PlaylistInput) {
    addPlaylist(coverImageFile: $coverImageFile, input: $input) {
      id
      titleKZ
      titleRU
      songs {
        id
        title
        authors {
          id
          name
        }
        voiceActors {
          id
          name
        }
      }
      coverImage
      index
      status
      descriptionKZ
      descriptionRU
    }
  }
`

const UPDATE_PLAYLIST = gql`
  mutation updatePlaylist(
    $id: ID!
    $coverImageFile: FileUpload
    $input: UpdatePlaylistInput
  ) {
    updatePlaylist(id: $id, coverImageFile: $coverImageFile, input: $input) {
      id
      titleKZ
      titleRU
      songs {
        id
        title
        authors {
          id
          name
        }
        voiceActors {
          id
          name
        }
      }
      coverImage
      index
      status
      descriptionKZ
      descriptionRU
    }
  }
`

// const UPDATE_PLAYLIST = gql`
//   mutation updatePlaylist($id: ID!, $input: PlaylistInput) {
//     updatePlaylist(id: $id, input: $input) {
//       id,
//       title,
//       songs{id,title}
//     }
//   }
// `

// const DELETE_PLAYLIST = gql`
//   mutation deletePlaylist($id: ID!) {
//     deletePlaylist(id: $id) {
//       id
//       message
//     }
//   }
// `

const DELETE_PLAYLIST = gql`
  mutation updatePlaylist($id: ID!, $input: UpdatePlaylistInput) {
    updatePlaylist(id: $id, input: $input) {
      id
      titleKZ
      titleRU
      songs {
        id
        title
        authors {
          id
          name
        }
        voiceActors {
          id
          name
        }
      }
      coverImage
      index
      status
      descriptionKZ
      descriptionRU
    }
  }
`

const GET_SONGS = gql`
  query songs {
    songs {
      id
      title
      authors {
        id
        name
      }
      voiceActors {
        id
        name
      }
    }
  }
`

const PlaylistsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allPlaylists, setAllPlaylists] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_PLAYLISTS_BY_INDEX)
  const { data: allSongs, loading: songsLoading } = useQuery(GET_SONGS)

  const [addPlaylist, { error: errorAddPlaylist }] = useMutation(ADD_PLAYLIST, {
    update(cache, { data: { addPlaylist: addPlaylistItem } }) {
      const { statusedPlaylistsByIndex } = cache.readQuery({
        query: GET_PLAYLISTS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_PLAYLISTS_BY_INDEX,
        data: {
          statusedPlaylistsByIndex: statusedPlaylistsByIndex
            .concat(addPlaylistItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Плейлист добавлен')
    }
  })
  // const [updatePlaylist, { data: updatePlaylistData }] = useMutation(
  //   UPDATE_PLAYLIST
  // )
  const [updatePlaylist, { error: errorUpdatePlaylist }] = useMutation(
    UPDATE_PLAYLIST,
    {
      update(cache, { data: { updatePlaylist: updatePlaylistItem } }) {
        const { statusedPlaylistsByIndex } = cache.readQuery({
          query: GET_PLAYLISTS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_PLAYLISTS_BY_INDEX,
          data: {
            statusedPlaylistsByIndex: statusedPlaylistsByIndex
              .filter(playlist => playlist.id !== updatePlaylistItem.id)
              .concat(updatePlaylistItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Плейлист обновлен')
      }
    }
  )
  const [deletePlaylist, { error: errorDeletePlaylist }] = useMutation(
    DELETE_PLAYLIST,
    {
      update(cache, { data: { updatePlaylist: deletePlaylistItem } }) {
        const { statusedPlaylistsByIndex } = cache.readQuery({
          query: GET_PLAYLISTS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_PLAYLISTS_BY_INDEX,
          data: {
            statusedPlaylistsByIndex: statusedPlaylistsByIndex.filter(
              playlist => playlist.id !== deletePlaylistItem.id
            )
          }
        })
        hideLoading()
        toast.success('Плейлист удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddPlaylist) {
      console.log(errorAddPlaylist)
    }
  }, [errorAddPlaylist])

  React.useEffect(() => {
    if (errorDeletePlaylist) {
      console.log(errorDeletePlaylist)
    }
  }, [errorDeletePlaylist])

  React.useEffect(() => {
    if (errorUpdatePlaylist) {
      console.log(errorUpdatePlaylist)
    }
  }, [errorUpdatePlaylist])

  React.useEffect(() => {
    if (data && !error && !loading && !songsLoading) {
      setAllPlaylists(data.statusedPlaylistsByIndex)
    }
  }, [data, loading, error, songsLoading])

  const addPlaylistHandler = (form, closeFunc, coverImageFile) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addPlaylist({
          variables: {
            coverImageFile,
            input: {
              titleKZ: val.titleKZ,
              titleRU: val.titleRU,
              songs: val.songs,
              index: val.index,
              status: val.status,
              descriptionKZ: val.descriptionKZ ? val.descriptionKZ : null,
              descriptionRU: val.descriptionRU ? val.descriptionRU : null
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updatePlaylistHandler = (
    form,
    playlistId,
    closeFunc,
    coverImageFile
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updatePlaylist({
          variables: {
            id: playlistId,
            coverImageFile,
            input: {
              titleKZ: val.titleKZ,
              titleRU: val.titleRU,
              songs: val.songs,
              index: val.index,
              status: val.status,
              descriptionKZ: val.descriptionKZ ? val.descriptionKZ : null,
              descriptionRU: val.descriptionRU ? val.descriptionRU : null
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const deletePlaylistHandler = (playlistId, closeFunc) => {
    showLoading()
    deletePlaylist({
      variables: {
        id: playlistId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  const removeFilesHandler = playlistId => {
    updatePlaylist({
      variables: {
        id: playlistId,
        input: {
          coverImage: null
        }
      }
    })
  }

  if (loading || songsLoading) {
    return <Loading />
  }

  return (
    <Playlists
      playlists={allPlaylists}
      allSongs={allSongs}
      addPlaylist={addPlaylist}
      refetch={refetch}
      addPlaylistHandler={addPlaylistHandler}
      updatePlaylistHandler={updatePlaylistHandler}
      deletePlaylistHandler={deletePlaylistHandler}
      removeFilesHandler={removeFilesHandler}
    />
  )
}

export default WithMainLayout(PlaylistsContainer)
