import React from 'react'

import { Form, Input, Select, Button } from 'antd'
import PropTypes from 'prop-types'
import './ContentAudio.css'
// import Genres from '../shared/genres'
import { DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const StyledDivDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ContentAudioForm = ({
  form,
  setAudioFile,
  setTextFile,
  setCoverImageFile,
  // audiofile,
  // textfile,
  // coverImageFile,
  audioFileRef,
  textFileRef,
  coverImageFileRef,
  id,
  title,
  genres,
  categories,
  authors,
  composers,
  voiceActors,
  publisher,
  instruments,
  status,
  lyrics,
  allCreators,
  allPublishers,
  allGenres,
  allCategories,
  allInstruments,
  removeFilesHandler
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <div>
          <>Аудио</>
          <input
            type="file"
            accept="audio/*"
            ref={audioFileRef}
            onChange={event => {
              setAudioFile(event.target.files[0])
            }}
          />
        </div>
        <>Текст</>
        <StyledDivDelete>
          <input
            type="file"
            accept=".csv"
            ref={textFileRef}
            onChange={event => setTextFile(event.target.files[0])}
          />
        </StyledDivDelete>
        <>Обложка</>
        <StyledDivDelete>
          <input
            type="file"
            accept="image/*"
            ref={coverImageFileRef}
            onChange={event => setCoverImageFile(event.target.files[0])}
          />
          {id && (
            <Button
              danger
              onClick={() => {
                removeFilesHandler(id, false, true)
              }}
            >
              <DeleteOutlined />
            </Button>
          )}
        </StyledDivDelete>
      </div>
      {/* <label for="upload-photo">Аудио файл...</label>
      <input type="file" name="photo" id="upload-file" onChange={ (event) => setTextFile(event.target.files[0])} /> */}
      {/* <>Text File</>
      <input type="file" title="Choose Text File"  onChange={ (event) => setTextFile(event.target.files[0])}/>     */}
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="title"
        label="Название"
        name="title"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Песни`
          }
        ]}
        initialValue={title}
      >
        <Input placeholder="Название" />
      </FormItem>
      <FormItem
        key="genres"
        label="Жанр"
        name="genres"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите жанр`
          }
        ]}
        initialValue={genres ? genres.map(item => item?.id) : []}
      >
        <Select
          placeholder="Выберите жанр"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            // console.log('input', input)
            // console.log('option', option.children)
            return (
              option.children.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            )
          }}
        >
          {allGenres.genres.map(item => (
            <Select.Option key={item.id} value={item.id}>
              <>{`${item.nameKZ} - ${item.nameRU}`}</>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="categories"
        label="Категории"
        name="categories"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите Категории`
          }
        ]}
        initialValue={categories
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Категории"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCategories.categories?.map(item => (
            <Select.Option key={item.id} value={item.id}>
              <>{`${item.nameKZ} - ${item.nameRU}`}</>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="authors"
        label="Авторы"
        name="authors"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите Авторов`
          }
        ]}
        initialValue={authors
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Авторов"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCreators.creators.map(creator => (
            <Select.Option key={creator.id} value={creator.id}>
              {creator.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="instruments"
        label="Инструменты"
        name="instruments"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите Инструменты`
          }
        ]}
        initialValue={instruments
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Инструменты"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allInstruments.instruments.map(instrument => (
            <Select.Option key={instrument.id} value={instrument.id}>
              <>{`${instrument.nameKZ} - ${instrument.nameRU}`}</>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      {/* <FormItem
        key="instruments"
        label="Инструменты"
        name="instruments"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите инструменты`
          }
        ]}
        initialValue={instruments}
      >
        <Select placeholder="Выберите инструменты" mode="multiple" allowClear>
          {Object.keys(Instruments).map(itemKey => {
            return (
              <Select.Option key={itemKey} value={itemKey}>
                {Instruments[itemKey]}
              </Select.Option>
            )
          })}
        </Select>
      </FormItem> */}
      <FormItem
        key="composers"
        label="Композиторы"
        name="composers"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите Композиторов`
          }
        ]}
        initialValue={composers
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Композиторов"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCreators.creators.map(creator => (
            <Select.Option key={creator.id} value={creator.id}>
              {creator.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="voiceActors"
        label="Исполнители"
        name="voiceActors"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите Исполнителей`
          }
        ]}
        initialValue={voiceActors
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Исполнителей"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCreators.creators.map(creator => (
            <Select.Option key={creator.id} value={creator.id}>
              {creator.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="publisher"
        label="Издатель"
        name="publisher"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите издателя`
          }
        ]}
        initialValue={publisher ? publisher?.id : undefined}
      >
        <Select
          allowClear
          placeholder="Выберите издателя"
          mode="single"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allPublishers.publishers.map(publisherItem => (
            <Select.Option key={publisherItem.id} value={publisherItem.id}>
              {publisherItem.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      {lyrics && (
        <>
          <FormItem
            key="lyrics"
            label="Текст"
            name="lyrics"
            rules={[
              {
                required: false,
                message: `Пожалуйста, напишите короткое описание`
              }
            ]}
            initialValue={lyrics}
          >
            <Input.TextArea disabled />
          </FormItem>
          <Button
            danger
            onClick={() => {
              form.setFieldsValue({ lyrics: null })
            }}
          >
            Reset Text
          </Button>
        </>
      )}
    </Form>
  )
}

ContentAudioForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string
}

ContentAudioForm.defaultProps = {
  title: '',
  authors: [],
  composers: [],
  voiceActors: [],
  genres: [],
  categories: [],
  instruments: [],
  status: 'active',
  publisher: null,
  lyrics: null
}

export default ContentAudioForm
