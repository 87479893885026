import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, Avatar } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import CreatorForm from './CreatorForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Creators = ({
  creators,
  addCreatorHandler,
  updateCreatorHandler,
  deleteCreatorHandler,
  removeFilesHandler
}) => {
  const [form] = Form.useForm()
  const [formForUpdate] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [creatorItem, setCreatorItem] = React.useState(null)
  const [updateModal, setUpdateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [searchCreatorname, setSearchCreatorname] = React.useState(null)

  const [listCreators, setListCreators] = React.useState(null)

  const [file, setFile] = useState(null)
  const fileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveCreators = creators.map(creator => {
      return {
        id: creator.id,
        name: creator.name,
        dateOfBirth: creator.dateOfBirth,
        dateOfDeath: creator.dateOfDeath,
        bioKZ: creator.bioKZ,
        bioRU: creator.bioRU,
        types: creator.types,
        photo: creator.photo,
        status: creator.status,
        index: creator.index
      }
    })
    let searchArray = descriptiveCreators

    if (searchCreatorname) {
      searchArray = searchArray.filter(creator => {
        return creator.name?.toLowerCase().includes(searchCreatorname)
      })
    }

    setListCreators(searchArray)
  }, [creators, searchCreatorname])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '1%'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.photo} />
          </>
        )
      }
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      width: '35%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <>
          {/* <span>
          <Link to={`/creators/${item.id}`}>Редактировать</Link>
        </span> */}
          <span>
            <Button
              type="link"
              onClick={() => {
                formForUpdate.resetFields()
                setUpdateModal(true)
                setCreatorItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setCreatorItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listCreators}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Name"
              onChange={e => {
                setSearchCreatorname(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый автор
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый автор"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setFile(null)
          fileRef.current.value = null
        }}
        onOk={() => {
          addCreatorHandler(form, setModalVisible, file)
          setFile(null)
          fileRef.current.value = null
        }}
      >
        <CreatorForm
          setFile={setFile}
          file={file}
          form={form}
          fileRef={fileRef}
        />
      </Modal>
      {updateModal && (
        <Modal
          open={updateModal}
          onCancel={() => {
            setUpdateModal(false)
            setFile(null)
            fileRef.current.value = null
          }}
          onOk={() => {
            updateCreatorHandler(
              formForUpdate,
              creatorItem.id,
              setUpdateModal,
              file
            )
            setFile(null)
            fileRef.current.value = null
          }}
        >
          <CreatorForm
            form={formForUpdate}
            id={creatorItem.id}
            name={creatorItem.name}
            dateOfBirth={creatorItem.dateOfBirth}
            dateOfDeath={creatorItem.dateOfDeath}
            bioKZ={creatorItem.bioKZ}
            bioRU={creatorItem.bioRU}
            types={creatorItem.types}
            photo={creatorItem.photo}
            status={creatorItem.status}
            index={creatorItem.index}
            fileRef={fileRef}
            setFile={setFile}
            removeFilesHandler={removeFilesHandler}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteCreatorHandler(creatorItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {creatorItem.name} ?</h3>
        </Modal>
      )}
    </>
  )
}

Creators.propTypes = {
  creators: PropTypes.arrayOf(PropTypes.object).isRequired,
  addCreatorHandler: PropTypes.func.isRequired,
  updateCreatorHandler: PropTypes.func.isRequired
}

export default Creators
