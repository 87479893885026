import React, { useState, useEffect } from 'react'
import { Button, message, Select } from 'antd'
import styled from 'styled-components'

import PropTypes from 'prop-types'

const SubDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
  margin-left: 25px;
`

const LessonTypeAudio = ({ uploadLessonTypeAudioHandler }) => {
  const { Option } = Select
  const [type, setType] = useState(null)
  const [language, setLanguage] = useState(null)
  const [audioFile, setAudioFile] = useState(null)
  const audioFileRef = React.useRef()

  const [prelisten, setPrelisten] = useState()

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!audioFile) {
      setPrelisten(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(audioFile)
    setPrelisten(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [audioFile])

  const onChange = value => {
    // console.log(`selected ${value}`)
    setType(value)
  }

  const onChangeLang = value => {
    // console.log(`selected ${value}`)
    setLanguage(value)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SubDiv
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <>Тип урока</>
          <Select
            style={{ width: '200px' }}
            value={type}
            placeholder="Тип"
            optionFilterProp="children"
            onChange={onChange}
          >
            <Option value="firstLetter">Первая буква</Option>
            <Option value="missingLetter">Пропущенная буква</Option>
            <Option value="buildWord">Собери слово</Option>
            <Option value="letterByImage">Буква по фото</Option>
            <Option value="imageByLetter">Фото по букве</Option>
            <Option value="wordByImage">Слово по фото</Option>

            <Option value="wordByNumber">Название цифры</Option>
            <Option value="numberByName">Цифра по названию</Option>
            <Option value="compareByImage">Сопоставь</Option>
            <Option value="twoColors">Два цвета</Option>

            <Option value="countByImage">Колличество предметов</Option>
            <Option value="imageByNumber">Картинка по цифре</Option>
            <Option key="dragNdropColor">Собери правильные цвета</Option>



            <Option value="wordByColor">Название цвета</Option>
            <Option value="colorByName">Цвет по названию</Option>
            <Option value="findColor">Найди цвет</Option>
            <Option value="mergeColors">Слияние цветов</Option>
            <Option value="colorByImage">Цвет предмета</Option>
            <Option value="twoColors">Два цвета</Option>


            <Option value="nameByFigure">Найди название фигуры</Option>
            <Option value="separateFigure">Найди отличающуюся фигуру</Option>
            <Option value="compareByFigure">Сопоставь подходящую фигуру</Option>
            <Option value="figureByImage">Найди фигуру на картинке</Option>
            <Option value="drawFigure">Нарисуй фигуру</Option>
            <Option value="completeFigure">Найди целую фигуру</Option>
            <Option value="findFigures">Найди фигуры</Option>

            <Option value="findSyllable">Найди слог</Option>
            <Option value="wordBySyllable">Найди слово по слогу</Option>
          </Select>
        </SubDiv>
        <SubDiv
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <>Язык</>
          <Select
            style={{ width: '200px' }}
            value={language}
            placeholder="Язык"
            optionFilterProp="children"
            onChange={onChangeLang}
          >
            <Option value="KZ">KZ</Option>
            <Option value="RU">RU</Option>
          </Select>
        </SubDiv>
        <SubDiv>
          <input
            type="file"
            accept="audio/*"
            ref={audioFileRef}
            onChange={event => setAudioFile(event.target.files[0])}
          />
        </SubDiv>
        <SubDiv>
          {audioFile && (
            <>
              <audio controls="controls">
                <source src={prelisten} type="audio/mpeg" />
              </audio>
              <Button
                onClick={() => {
                  if (!type) {
                    message.error('Пожалуйста выберите тип!')
                    return
                  }
                  if (!language) {
                    message.error('Пожалуйста выберите язык!')
                    return
                  }

                  uploadLessonTypeAudioHandler(
                    type,
                    setType,
                    language,
                    setLanguage,
                    audioFile,
                    setAudioFile,
                    audioFileRef
                  )
                }}
              >
                Загрузить
              </Button>
            </>
          )}
        </SubDiv>
      </div>
    </>
  )
}

LessonTypeAudio.propTypes = {
  uploadLessonTypeAudioHandler: PropTypes.func.isRequired
}

export default LessonTypeAudio
