import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import Topics from './Topics'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'

const GET_TOPICS = gql`
  query statusedTopics {
    statusedTopics {
      id
      textKZ
      textRU
      status
      restrictions
    }
  }
`

const ADD_TOPIC = gql`
  mutation addTopic($input: TopicInput) {
    addTopic(input: $input) {
      id
      textKZ
      textRU
      status
      restrictions
    }
  }
`

const UPDATE_TOPIC = gql`
  mutation updateTopic($id: ID!, $input: TopicInput) {
    updateTopic(id: $id, input: $input) {
      id
      textKZ
      textRU
      status
      restrictions
    }
  }
`

const DELETE_TOPIC = gql`
  mutation deleteTopic($id: ID!) {
    deleteTopic(id: $id) {
      id
      message
    }
  }
`

// const DELETE_TOPIC = gql`
//   mutation deleteTopic($id: ID!) {
//     deleteTopic(id: $id) {
//       id
//       message
//     }
//   }
// `

const TopicsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allTopics, setAllTopics] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_TOPICS)
  const [addTopic, { error: errorAddTopic }] = useMutation(ADD_TOPIC, {
    update(cache, { data: { addTopic: addTopicItem } }) {
      const { statusedTopics } = cache.readQuery({ query: GET_TOPICS })
      cache.writeQuery({
        query: GET_TOPICS,
        data: { statusedTopics: [addTopicItem].concat(statusedTopics) }
      })
      hideLoading()
      toast.success('Тема добавлена')
    }
  })
  const [updateTopic, { data: updateTopicData }] = useMutation(UPDATE_TOPIC)
  const [deleteTopic, { error: errorDeleteTopic }] = useMutation(DELETE_TOPIC, {
    update(cache, { data: { deleteTopic: deleteTopicItem } }) {
      const { statusedTopics } = cache.readQuery({ query: GET_TOPICS })
      cache.writeQuery({
        query: GET_TOPICS,
        data: {
          statusedTopics: statusedTopics.filter(
            topic => topic.id !== deleteTopicItem.id
          )
        }
      })
      hideLoading()
      toast.success('Тема удалена')
    }
  })

  React.useEffect(() => {
    if (errorDeleteTopic) {
      console.log(errorDeleteTopic)
    }
  }, [errorDeleteTopic])

  React.useEffect(() => {
    if (errorAddTopic) {
      console.log(errorAddTopic)
    }
  }, [errorAddTopic])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllTopics(data.statusedTopics)
    }
  }, [data, loading, error, updateTopicData])

  const addTopicHandler = (form, closeFunc) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addTopic({
          variables: {
            input: {
              textKZ: val.textKZ?.trim(),
              textRU: val.textRU?.trim(),
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updateTopicHandler = (form, topicId, closeFunc) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateTopic({
          variables: {
            id: topicId,
            input: {
              textKZ: val.textKZ?.trim(),
              textRU: val.textRU?.trim(),
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        closeFunc(false)
        toast.success('Тема изменена')
      })
  }

  const deleteTopicHandler = (topicId, closeFunc) => {
    showLoading()
    deleteTopic({
      variables: {
        id: topicId
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Topics
      topics={allTopics}
      addTopic={addTopic}
      refetch={refetch}
      addTopicHandler={addTopicHandler}
      updateTopicHandler={updateTopicHandler}
      deleteTopicHandler={deleteTopicHandler}
    />
  )
}

export default WithMainLayout(TopicsContainer)
