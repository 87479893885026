import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, Avatar } from 'antd'
import { PlusOutlined, SearchOutlined, MenuOutlined } from '@ant-design/icons'
import {
  // SortableContainer,
  // SortableElement,
  SortableHandle
} from 'react-sortable-hoc'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import PlaylistForm from './PlaylistForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

// const SortableItem = SortableElement(props => <tr {...props} />)
// const SortableBody = SortableContainer(props => <tbody {...props} />)

const Playlists = ({
  playlists,
  allSongs,
  addPlaylistHandler,
  updatePlaylistHandler,
  deletePlaylistHandler,
  removeFilesHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [playlistItem, setPlaylistItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchPlaylist, setSearchPlaylist] = React.useState(null)

  const [listPlaylists, setListPlaylists] = React.useState(null)

  const [coverImageFile, setCoverImageFile] = useState(null)
  const coverImageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptivePlaylists = playlists.map(playlist => {
      return {
        id: playlist.id,
        titleKZ: playlist.titleKZ,
        titleRU: playlist.titleRU,
        songs: playlist.songs,
        coverImage: playlist.coverImage,
        index: playlist.index,
        status: playlist.status,
        descriptionKZ: playlist.descriptionKZ,
        descriptionRU: playlist.descriptionRU
      }
    })
    let searchArray = descriptivePlaylists

    if (searchPlaylist) {
      searchArray = searchArray.filter(playlist => {
        return (
          playlist.nameKZ?.toLowerCase().includes(searchPlaylist) ||
          playlist.nameRU?.toLowerCase().includes(searchPlaylist)
        )
      })
    }

    setListPlaylists(searchArray)
  }, [playlists, searchPlaylist])

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   // const { dataSource } = this.state
  //   // if (oldIndex !== newIndex) {
  //   //   const newData = arrayMoveImmutable(
  //   //     [].concat(dataSource),
  //   //     oldIndex,
  //   //     newIndex
  //   //   ).filter(el => !!el)
  //   //   console.log('Sorted items: ', newData)
  //   //   this.setState({ dataSource: newData })
  //   // }
  // }

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: '1%',
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '1%'
    },
    {
      title: 'Обложка',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.coverImage} />
          </>
        )
      }
    },
    {
      title: 'Название(каз)',
      dataIndex: 'titleKZ',
      width: '15%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'titleRU',
      width: '15%'
    },
    {
      title: 'Количество аудио',
      width: '5%',
      render: item => {
        return <>{item.songs.length}</>
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          {/* <span>
          <Link to={`/playlists/${item.id}`}>Редактировать</Link>
        </span> */}
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setPlaylistItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setPlaylistItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listPlaylists}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Title"
              onChange={e => {
                setSearchPlaylist(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый плейлист
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый плейлист"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setCoverImageFile(null)
          coverImageFileRef.current.value = null
        }}
        onOk={() => {
          addPlaylistHandler(form, setModalVisible, coverImageFile, allSongs)
          setCoverImageFile(null)
          coverImageFileRef.current.value = null
        }}
      >
        <PlaylistForm
          setCoverImageFile={setCoverImageFile}
          allSongs={allSongs}
          coverImageFileRef={coverImageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setCoverImageFile(null)
            coverImageFileRef.current.value = null
          }}
          onOk={() => {
            updatePlaylistHandler(
              formForEdit,
              playlistItem.id,
              setEditModal,
              coverImageFile,
              allSongs
            )
            setCoverImageFile(null)
            coverImageFileRef.current.value = null
          }}
        >
          <PlaylistForm
            form={formForEdit}
            id={playlistItem.id}
            titleKZ={playlistItem.titleKZ}
            titleRU={playlistItem.titleRU}
            songs={playlistItem.songs}
            index={playlistItem.index}
            status={playlistItem.status}
            descriptionKZ={playlistItem.descriptionKZ}
            descriptionRU={playlistItem.descriptionRU}
            setCoverImageFile={setCoverImageFile}
            allSongs={allSongs}
            coverImageFileRef={coverImageFileRef}
            removeFilesHandler={removeFilesHandler}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deletePlaylistHandler(playlistItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {playlistItem.title} ?</h3>
        </Modal>
      )}
    </>
  )
}

Playlists.propTypes = {
  playlists: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPlaylistHandler: PropTypes.func.isRequired,
  updatePlaylistHandler: PropTypes.func.isRequired
}

export default Playlists
