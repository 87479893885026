import React from 'react'
import { Table, Button, Form, Modal, Input } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ZhanyltpashtarForm from './ZhanyltpashtarForm'
import { useLoading } from '../../../context/useLoading'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Zhanyltpashtar = ({
  zhanyltpashtar,
  createHandler,
  updateHandler,
  deleteHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchString, setSearchString] = React.useState(null)
  const [listItems, setListItems] = React.useState(null)
  const { hideLoading } = useLoading()
  const [audioFile, setAudioFile] = React.useState(null)
  const audioFileRef = React.useRef()

  React.useEffect(() => {
    if (searchString) {
      const searchArray = zhanyltpashtar?.filter(traditionsArticle => {
        return traditionsArticle.text?.toLowerCase().includes(searchString)
      })
      setListItems(searchArray)
    } else {
      setListItems(zhanyltpashtar)
    }
  }, [zhanyltpashtar, searchString])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Текст',
      dataIndex: 'text',
      width: '15%'
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.audioUrl
        if (audioLink && !audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            {audioLink && <source src={audioLink} type="audio/mpeg" />}
          </audio>
        )
      }
    },
    {
      title: 'Сложность',
      dataIndex: 'type',
      width: '15%'
    },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '2%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setSelectedItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setSelectedItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listItems}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchString(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая скороговорка
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая скороговорка"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          if (audioFileRef.current) audioFileRef.current.value = null
        }}
        onOk={() => {
          createHandler(
            form,
            setModalVisible,
            audioFile,
            setAudioFile,
            audioFileRef
          )
        }}
      >
        <ZhanyltpashtarForm
          form={form}
          setAudioFile={setAudioFile}
          audioFileRef={audioFileRef}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
            setAudioFile(null)
            if (audioFileRef.current) audioFileRef.current.value = null
          }}
          onOk={() => {
            updateHandler(
              formForEdit,
              selectedItem.id,
              setEditModal,
              audioFile,
              setAudioFile,
              audioFileRef
            )
          }}
        >
          <ZhanyltpashtarForm
            form={formForEdit}
            text={selectedItem.text}
            status={selectedItem.status}
            type={selectedItem.type}
            index={selectedItem.index}
            audioFile={audioFile}
            audioFileRef={audioFileRef}
            setAudioFile={setAudioFile}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(selectedItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {selectedItem.text} ?</h3>
        </Modal>
      )}
    </>
  )
}

Zhanyltpashtar.propTypes = {
  zhanyltpashtar: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default Zhanyltpashtar
