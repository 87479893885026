import React, { useEffect, useRef, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const GET_APP_DETAILS = gql`
  query appDetailsStatistics($exclude: ExcludeInput) {
    appDetailsStatistics(exclude: $exclude) {
      versions {
        item
        count
      }
      platforms {
        item
        count
      }
      languages {
        item
        count
      }
    }
  }
`
const operateData = (appDetailsStatistics, headers = null, dataset = null) => {
  const result = {}

  Object.keys(appDetailsStatistics).forEach(key => {
    const value = appDetailsStatistics[key]
    if (Array.isArray(value)) {
      result[key] = {
        labels: headers
          ? headers[key]?.labels
          : value.map(val => val.item || 'null'),
        datasets: [
          {
            label: 'Количество',
            data: headers
              ? headers[key].labels.map(label =>
                  dataset[key].includes(label)
                    ? 0
                    : value.find(
                        val =>
                          val.item === label ||
                          (val.item === null && label === 'null')
                      )?.count || 0
                )
              : value.map(val => val.count),
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }
        ]
      }
    }
  })
  return result
}

const AppDetailsContainer = () => {
  const [headers, setHeaders] = useState({})
  const [versions, setVersions] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [languages, setLanguages] = useState([])
  const [getAppDetails, { loading }] = useLazyQuery(GET_APP_DETAILS, {
    variables: { exclude: { versions, platforms, languages } },
    onCompleted: data => {
      if (!versions.length && !platforms.length && !languages.length) {
        const result = operateData(data.appDetailsStatistics)
        setHeaders(result)
      } else {
        const result = operateData(data.appDetailsStatistics, headers, {
          versions,
          platforms,
          languages
        })
        setHeaders(result)
      }
    },
    onError: error => {
      console.log(error)
    }
  })

  const options = (setState = () => {}) => {
    return {
      plugins: {
        legend: {
          display: true,
          position: 'top',
          onClick(e, legendItem, legend) {
            legend.chart.toggleDataVisibility(legendItem.index)
            legend.chart.update()

            if (legend.chart.getDataVisibility(legendItem.index)) {
              setState(prev =>
                prev.filter(item => {
                  if (legendItem.text == item) return false
                  else if (legendItem.text === 'null' && item === null)
                    return false
                  return true
                })
              )
            } else {
              setState(prev => {
                const newValue =
                  legendItem.text === 'null' ? null : legendItem.text
                return [...prev, newValue]
              })
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    getAppDetails(versions, platforms, languages)
  }, [versions, platforms, languages])

  if (!Object.keys(headers).length) return <Loading />
  else
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div>
            <Pie data={headers['versions']} options={options(setVersions)} />
          </div>
          <div>
            <Pie data={headers['languages']} options={options(setLanguages)} />
          </div>
        </div>
        <div>
          <Pie data={headers['platforms']} options={options(setPlatforms)} />
        </div>
      </div>
    )
}

export default WithMainLayout(AppDetailsContainer)
