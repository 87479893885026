import React from 'react'
import { Table, Button, Form, Modal, Input, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import TopicForm from './TopicsForm'

import UpIcon from '../../../assets/icons/Up.svg'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
`

const Topics = ({
  topics,
  addTopicHandler,
  updateTopicHandler,
  deleteTopicHandler
}) => {
  const [form] = Form.useForm()
  const [formForUpdate] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [topicItem, setTopicItem] = React.useState(null)
  const [updateModal, setUpdateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [searchTopicname, setSearchTopicname] = React.useState(null)

  const [listTopics, setListTopics] = React.useState(null)

  React.useEffect(() => {
    const descriptiveTopics = topics.map(topic => {
      return {
        id: topic.id,
        textKZ: topic.textKZ,
        textRU: topic.textRU,
        status: topic.status,
        restrictions: topic.restrictions
      }
    })
    let searchArray = descriptiveTopics

    if (searchTopicname) {
      searchArray = searchArray.filter(topic => {
        return (
          topic.textKZ?.toLowerCase().includes(searchTopicname) ||
          topic.textRU?.toLowerCase().includes(searchTopicname)
        )
      })
    }

    setListTopics(searchArray)
  }, [topics, searchTopicname])

  const columns = [
    {
      title: '',
      width: '0.001%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Название(каз)',
      dataIndex: 'textKZ',
      width: '30%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'textRU',
      width: '30%'
    },

    {
      title: 'План',
      dataIndex: 'restrictions',
      width: '30%'
    },

    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForUpdate.resetFields()
                setUpdateModal(true)
                setTopicItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setTopicItem(item)
              }}
            >
              Удалить
            </Button>
            <Link to={`/topics/${item.id}/elements`}>
              <Button style={{ marginLeft: '5%' }}>
                <Icon src={UpIcon} style={{ transform: 'rotate(90deg)' }} />
              </Button>
            </Link>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Tag>Темы</Tag>
      <Table
        dataSource={listTopics}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Тема"
              onChange={e => {
                setSearchTopicname(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая тема
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая тема"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => addTopicHandler(form, setModalVisible)}
      >
        <TopicForm form={form} />
      </Modal>
      {updateModal && (
        <Modal
          open={updateModal}
          onCancel={() => {
            setUpdateModal(false)
          }}
          onOk={() =>
            updateTopicHandler(formForUpdate, topicItem.id, setUpdateModal)
          }
        >
          <TopicForm
            form={formForUpdate}
            textKZ={topicItem.textKZ}
            textRU={topicItem.textRU}
            photo={topicItem.photo}
            status={topicItem.status}
            restrictions={topicItem.restrictions}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteTopicHandler(topicItem.id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить тэг "{topicItem.textKZ} -{' '}
            {topicItem.textRU}" ?
          </h3>
        </Modal>
      )}
    </>
  )
}

Topics.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
  addTopicHandler: PropTypes.func.isRequired,
  updateTopicHandler: PropTypes.func.isRequired
}

export default Topics
