import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import {
  PlusOutlined,
  SearchOutlined,
  LinkOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import ContentAudioForm from './ContentAudioForm'
// import Genres from '../shared/Genres'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const ContentAudios = ({
  contentAudios,
  allCreators,
  allPublishers,
  allTags,
  addContentAudioHandler,
  updateContentAudioHandler,
  deleteContentAudioHandler,
  removeFilesHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [contentAudioItem, setContentAudioItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchContentAudio, setSearchContentAudio] = React.useState(null)

  const [listContentAudios, setListContentAudios] = React.useState(null)

  const [audiofile, setAudioFile] = useState(null)
  const [textfile, setTextFile] = useState(null)
  const [coverImageFile, setCoverImageFile] = useState(null)
  const audioFileRef = React.useRef()
  const textFileRef = React.useRef()
  const coverImageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveContentAudios = contentAudios.map(contentAudio => {
      return {
        id: contentAudio.id,
        title: contentAudio.title,
        authors: contentAudio.authors,
        voiceActors: contentAudio.voiceActors,
        publisher: contentAudio.publisher,
        tags: contentAudio.tags,
        ageGroup: contentAudio.ageGroup,
        instruments: contentAudio.instruments,
        link2Audio: contentAudio.link2Audio,
        link2Text: contentAudio.link2Text,
        coverImage: contentAudio.coverImage,
        descriptionKZ: contentAudio.descriptionKZ,
        descriptionRU: contentAudio.descriptionRU,
        status: contentAudio.status,
        lyrics: contentAudio.lyrics,
        authorsNames: contentAudio?.authors?.map(item => item?.name).join(', '),
        restrictions: contentAudio.restrictions
      }
    })
    let searchArray = descriptiveContentAudios

    if (searchContentAudio) {
      searchArray = searchArray.filter(contentAudio => {
        return (
          contentAudio.title?.toLowerCase().includes(searchContentAudio) ||
          contentAudio.authorsNames?.toLowerCase().includes(searchContentAudio)
        )
      })
    }

    setListContentAudios(searchArray)
  }, [contentAudios, searchContentAudio])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.coverImage} />
          </>
        )
      }
    },
    {
      title: 'Текст',
      width: '1%',
      render: item => {
        return <>{item.lyrics && <CheckCircleOutlined />}</>
      }
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.link2Audio
        if (!audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            <source src={audioLink} type="audio/mpeg" />
          </audio>
        )
      }
    },
    {
      title: 'Название',
      dataIndex: 'title',
      width: '20%'
    },
    {
      title: 'Авторы',
      width: '15%',
      render: item => {
        // return <>{item?.authors?.map(item => item?.name).join(', ')}</>
        return <>{item.authorsNames}</>
      }
    },
    {
      title: 'План',
      dataIndex: 'restrictions',
      width: '20%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setContentAudioItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setContentAudioItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listContentAudios}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Title or Author"
              onChange={e => {
                setSearchContentAudio(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новое аудио
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новое аудио"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setTextFile(null)
          setCoverImageFile(null)
          audioFileRef.current.value = null
          textFileRef.current.value = null
          coverImageFileRef.current.value = null
        }}
        onOk={() => {
          if (audiofile !== null) {
            addContentAudioHandler(
              form,
              setModalVisible,
              audiofile,
              textfile,
              coverImageFile,
              setAudioFile,
              setTextFile,
              setCoverImageFile,
              audioFileRef,
              textFileRef,
              coverImageFileRef
            )
          } else {
            message.error('Пожалуйста, выберите аудио файл')
          }
        }}
      >
        <ContentAudioForm
          allCreators={allCreators}
          allPublishers={allPublishers}
          allTags={allTags}
          setAudioFile={setAudioFile}
          setTextFile={setTextFile}
          setCoverImageFile={setCoverImageFile}
          audiofile={audiofile}
          textfile={textfile}
          coverImageFile={coverImageFile}
          audioFileRef={audioFileRef}
          textFileRef={textFileRef}
          coverImageFileRef={coverImageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
          }}
          onOk={() =>
            updateContentAudioHandler(
              formForEdit,
              contentAudioItem.id,
              setEditModal,
              audiofile,
              textfile,
              coverImageFile,
              setAudioFile,
              setTextFile,
              setCoverImageFile,
              audioFileRef,
              textFileRef,
              coverImageFileRef
            )
          }
        >
          <ContentAudioForm
            form={formForEdit}
            id={contentAudioItem.id}
            title={contentAudioItem.title}
            genres={contentAudioItem.genres}
            authors={contentAudioItem.authors}
            tags={contentAudioItem.tags}
            ageGroup={contentAudioItem.ageGroup}
            publisher={contentAudioItem.publisher}
            instruments={contentAudioItem.instruments}
            voiceActors={contentAudioItem.voiceActors}
            descriptionKZ={contentAudioItem.descriptionKZ}
            descriptionRU={contentAudioItem.descriptionRU}
            status={contentAudioItem.status}
            lyrics={contentAudioItem.lyrics}
            restrictions={contentAudioItem.restrictions}
            allCreators={allCreators}
            allPublishers={allPublishers}
            allTags={allTags}
            setAudioFile={setAudioFile}
            setTextFile={setTextFile}
            setCoverImageFile={setCoverImageFile}
            audioFileRef={audioFileRef}
            textFileRef={textFileRef}
            coverImageFileRef={coverImageFileRef}
            removeFilesHandler={removeFilesHandler}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deleteContentAudioHandler(contentAudioItem.id, setDeleteModal)
          }
        >
          <h3>
            Вы действительно хотите удалить {contentAudioItem.title} -{' '}
            {contentAudioItem?.author?.name} ?
          </h3>
        </Modal>
      )}
    </>
  )
}

ContentAudios.propTypes = {
  contentAudios: PropTypes.arrayOf(PropTypes.object).isRequired,
  addContentAudioHandler: PropTypes.func.isRequired,
  updateContentAudioHandler: PropTypes.func.isRequired
}

export default ContentAudios
