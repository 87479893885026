import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { message } from 'antd'
import Papa from 'papaparse'
import { useLoading } from '../../context/useLoading'
import Cartoons from './Cartoons'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_CARTOONS = gql`
  query cartoons {
    statusedCartoons {
      id
      title
      tags {
        id
        nameKZ
        nameRU
      }
      episodes
      ageGroup
      coverImage
      descriptionKZ
      descriptionRU
      status
      index
    }
  }
`

const ADD_CARTOON = gql`
  mutation addCartoon(
    $coverImageFile: FileUpload!
    $input: VideoPlaylistInput
  ) {
    addVideoPlaylist(coverImageFile: $coverImageFile, input: $input) {
      id
      title
      tags {
        id
        nameKZ
        nameRU
      }
      ageGroup
      episodes
      coverImage
      descriptionKZ
      descriptionRU
      status
      index
    }
  }
`

const UPDATE_CARTOON = gql`
  mutation updateCartoon(
    $id: ID!
    $coverImageFile: FileUpload
    $input: UpdateVideoPlaylistInput
  ) {
    updateVideoPlaylist(
      id: $id
      coverImageFile: $coverImageFile
      input: $input
    ) {
      id
      title
      tags {
        id
        nameKZ
        nameRU
      }
      ageGroup
      episodes
      coverImage
      descriptionKZ
      descriptionRU
      status
      index
    }
  }
`

const DELETE_CARTOON = gql`
  mutation deleteCartoon($id: ID!, $input: UpdateVideoPlaylistInput) {
    updateVideoPlaylist(id: $id, input: $input) {
      id
      title
      tags {
        id
        nameKZ
        nameRU
      }
      ageGroup
      episodes
      coverImage
      descriptionKZ
      descriptionRU
      status
      index
    }
  }
`

// const DELETE_CARTOON = gql`
//   mutation deleteCartoon($id: ID!) {
//     deleteCartoon(id: $id) {
//       id
//       message
//     }
//   }
// `

const GET_TAGS = gql`
  query tags {
    tags {
      id
      nameKZ
      nameRU
    }
  }
`

const CartoonsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allCartoons, setAllCartoons] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_CARTOONS)
  const { data: allTags } = useQuery(GET_TAGS)

  const [addCartoon, { error: errorAddCartoon }] = useMutation(ADD_CARTOON, {
    update(cache, { data: { addVideoPlaylist: addCartoonItem } }) {
      const { statusedCartoons } = cache.readQuery({
        query: GET_CARTOONS
      })
      cache.writeQuery({
        // query: GET_CARTOONS,
        // data: {
        //   statusedCartoons: [addCartoonItem].concat(statusedCartoons)
        // }
        query: GET_CARTOONS,
        data: {
          statusedCartoons: statusedCartoons
            .filter(cartoon => cartoon.id !== addCartoonItem.id)
            .concat(addCartoonItem)
            .sort((a, b) => {
              return a.index && b.index
                ? a.index < b.index
                  ? -1
                  : b.index > a.index
                  ? 1
                  : 0
                : a.index && b.index === null
                ? -1
                : a.index === null && b.index
                ? 1
                : 0
            })
        }
      })
      hideLoading()
      toast.success('Мультфильм добавлен')
    }
  })
  // const [updateCartoon, { data: updateCartoonData }] = useMutation(
  //   UPDATE_CARTOON
  // )
  const [updateCartoon, { error: errorUpdateCartoon }] = useMutation(
    UPDATE_CARTOON,
    {
      update(cache, { data: { updateVideoPlaylist: updateCartoonItem } }) {
        const { statusedCartoons } = cache.readQuery({
          query: GET_CARTOONS
        })
        cache.writeQuery({
          query: GET_CARTOONS,
          data: {
            statusedCartoons: statusedCartoons
              .filter(cartoon => cartoon.id !== updateCartoonItem.id)
              .concat(updateCartoonItem)
              .sort((a, b) => {
                return a.index && b.index
                  ? a.index < b.index
                    ? -1
                    : b.index > a.index
                    ? 1
                    : 0
                  : a.index && b.index === null
                  ? -1
                  : a.index === null && b.index
                  ? 1
                  : 0
              })
          }
        })
        hideLoading()
        toast.success('Мультфильм обновлен')
      }
    }
  )
  const [deleteCartoon, { error: errorDeleteCartoon }] = useMutation(
    DELETE_CARTOON,
    {
      update(cache, { data: { updateVideoPlaylist: deleteCartoonItem } }) {
        const { statusedCartoons } = cache.readQuery({
          query: GET_CARTOONS
        })
        cache.writeQuery({
          query: GET_CARTOONS,
          data: {
            statusedCartoons: statusedCartoons.filter(
              cartoon => cartoon.id !== deleteCartoonItem.id
            )
          }
        })
        hideLoading()
        toast.success('Мультфильм удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddCartoon) {
      console.log(errorAddCartoon)
    }
  }, [errorAddCartoon])

  React.useEffect(() => {
    if (errorDeleteCartoon) {
      console.log(errorDeleteCartoon)
    }
  }, [errorDeleteCartoon])

  React.useEffect(() => {
    if (errorUpdateCartoon) {
      console.log(errorUpdateCartoon)
    }
  }, [errorUpdateCartoon])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllCartoons(data.statusedCartoons)
    }
  }, [data, loading, error])

  // const sortByEpisode = episodes => {
  //   return
  // }
  function sortByEpisode(a, b) {
    if (a.episode < b.episode) {
      return -1
    }
    if (a.episode > b.episode) {
      return 1
    }
    return 0
  }

  const youtube_parser = url => {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    let match = url.match(regExp)
    if (match && match[2].length == 11) {
      return match[2]
    } else {
      return null
    }
  }

  const prepareEpisodes = episodes => {
    let preparedEpisodes = episodes.map(episode => {
      // const link = episode.link2Video
      // let youtubeID
      // if (link.startsWith('https://www.youtube.com/')) {
      //   const urlParams = URLSearchParams(link)
      //   youtubeID = urlParams.get('v')
      // } else if (link.startsWith('https://youtu.be/')) {
      //   // https://youtu.be/y789fmA3tg8
      //   const
      // }
      return {
        ...episode,
        youtubeID: youtube_parser(episode.link2Video)
      }
    })
    return preparedEpisodes.sort(sortByEpisode)
  }

  const addCartoonHandler = async (
    form,
    closeFunc,
    coverImageFile,
    setCoverImageFile,
    coverImageFileRef
  ) => {
    showLoading()

    form
      .validateFields()
      .then(val => {
        form.resetFields()
        addCartoon({
          variables: {
            coverImageFile,
            input: {
              type: 'cartoonSeries',
              title: val.title.trim(),
              tags: val.tags ? val.tags : [],
              ageGroup: val.ageGroup ? val.ageGroup : null,
              episodes: val.episodes ? prepareEpisodes(val.episodes) : null,
              descriptionKZ: val.descriptionKZ
                ? val.descriptionKZ.trim()
                : null,
              descriptionRU: val.descriptionRU
                ? val.descriptionRU.trim()
                : null,
              status: val.status,
              index: val.index
            }
          }
        })
      })
      .then(() => {
        setCoverImageFile(null)
        coverImageFileRef.current.value = null
        hideLoading()
        closeFunc(false)
      })
  }

  const updateCartoonHandler = async (
    form,
    cartoonId,
    closeFunc,
    coverImageFile,
    setCoverImageFile,
    coverImageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateCartoon({
          variables: {
            id: cartoonId,
            coverImageFile,
            input: {
              type: 'cartoonSeries',
              title: val.title.trim(),
              tags: val.tags ? val.tags : [],
              ageGroup: val.ageGroup ? val.ageGroup : null,
              episodes: val.episodes ? prepareEpisodes(val.episodes) : null,
              descriptionKZ: val.descriptionKZ
                ? val.descriptionKZ.trim()
                : null,
              descriptionRU: val.descriptionRU
                ? val.descriptionRU.trim()
                : null,
              status: val.status,
              index: val.index
            }
          }
        })
      })
      .then(() => {
        setCoverImageFile(null)
        coverImageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const deleteCartoonHandler = (cartoonId, closeFunc) => {
    showLoading()
    deleteCartoon({
      variables: {
        id: cartoonId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // console.log("data ", deleteCartoonData)
      // hideLoading()
      closeFunc(false)
    })
  }

  const removeFilesHandler = (cartoonId, textFile, coverImage) => {
    // console.log('id', cartoonId)
    // console.log('textFile', textFile)
    // console.log('coverImage', coverImage)
    updateCartoon({
      variables: {
        id: cartoonId,
        input: textFile
          ? {
              link2Text: null
            }
          : {
              coverImage: null
            }
      }
    }).then(() => {
      toast.success('Песня обновлена')
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Cartoons
      cartoons={allCartoons}
      allTags={allTags}
      addCartoon={addCartoon}
      refetch={refetch}
      addCartoonHandler={addCartoonHandler}
      updateCartoonHandler={updateCartoonHandler}
      deleteCartoonHandler={deleteCartoonHandler}
      removeFilesHandler={removeFilesHandler}
    />
  )
}

export default WithMainLayout(CartoonsContainer)
