import React from 'react'
import { Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import './PracticeColor.css'

const ColorForm = ({
  form,
  index,
  name,
  code,
  status,
  setAudioFile,
  audioFileRef,
  setImageFile,
  imageFileRef,
  setMergeImageFile,
  mergeImageFileRef,
  objectFile,
  objectFileRef,
  setObjectFile
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио</>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
        <>Слияние цветов</>
        <input
          type="file"
          accept="image/*"
          ref={mergeImageFileRef}
          onChange={event => setMergeImageFile(event.target.files[0])}
        />

        <>Объект</>
        <input
          type="file"
          accept="image/*"
          ref={objectFileRef}
          onChange={event => setObjectFile(event.target.files[0])}
        />
      </div>
      <FormItem
        key="index"
        label="Очередность цвета"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность Документа`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность" />
      </FormItem>
      <FormItem
        key="status"
        label="Статус Цифры"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="name"
        label="Цвет"
        name="name"
        rules={[ { required: true, message: `Пожалуйста, введите имя цвета` } ]}
        initialValue={name}>
        <Input placeholder="Цвет" />
      </FormItem>
      <FormItem
        key="code"
        label="Код цвета"
        name="code"
        rules={[ { required: true, message: `Пожалуйста, введите код` } ]}
        initialValue={code}>
        <Input placeholder="#5A5A5A" />
      </FormItem>
    </Form>
  )
}

ColorForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

ColorForm.defaultProps = {
  name: '',
  status: 'active'
}

export default ColorForm
