import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar, Image } from 'antd'
import Icon, { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BannerForm from './BannerForm'
import { useLoading } from '../../context/useLoading'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const StyledAvatar = styled.img``

const Banners = ({
  banners,
  addBannerHandler,
  updateBannerHandler,
  deleteBannerHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [bannerItem, setBannerItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchBanner, setSearchBanner] = React.useState(null)
  const { showLoading, hideLoading } = useLoading()
  const [listBanners, setListBanners] = React.useState(null)

  const [imageFileKZ, setImageFileKZ] = useState(null)
  const imageFileRefKZ = React.useRef()
  const [imageFileRU, setImageFileRU] = useState(null)
  const imageFileRefRU = React.useRef()

  React.useEffect(() => {
    const descriptiveBanners = banners.map(banner => {
      return {
        id: banner?.id,
        titleKZ: banner?.titleKZ,
        titleRU: banner?.titleRU,
        descriptionKZ: banner?.descriptionKZ,
        descriptionRU: banner?.descriptionRU,
        openStyle: banner?.openStyle,
        index: banner?.index,
        action: banner?.action,
        imageUrlKZ: banner?.imageUrlKZ,
        imageUrlRU: banner?.imageUrlRU,
        status: banner?.status
      }
    })
    let searchArray = descriptiveBanners

    if (searchBanner) {
      searchArray = searchArray.filter(banner => {
        return banner.titleRU?.toLowerCase().includes(searchBanner)
      })
    }

    setListBanners(searchArray)
  }, [banners, searchBanner])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Индекс',
      dataIndex: 'index',
      width: '2%'
    },
    {
      title: 'БаннерKZ',
      width: '10%',
      render: item => {
        return (
          <>
            <Image shape="square" size={64} src={item.imageUrlKZ} />
          </>
        )
      }
    },
    {
      title: 'БаннерRU',
      width: '10%',
      render: item => {
        return (
          <>
            <Image shape="square" size={64} src={item.imageUrlRU} />
          </>
        )
      }
    },
    {
      title: 'Заголовок KZ',
      dataIndex: 'titleKZ',
      width: '5%'
    },
    {
      title: 'Заголовок RU',
      dataIndex: 'titleRU',
      width: '5%'
    },
    {
      title: 'Описание KZ',
      dataIndex: 'descriptionKZ',
      width: '5%'
    },
    {
      title: 'Описание RU',
      dataIndex: 'descriptionRU',
      width: '5%'
    },
    {
      title: 'Тип ссылки',
      dataIndex: ['action', 'actionType'],
      width: '5%'
    },
    {
      title: 'Путь ссылки',
      dataIndex: ['action', 'route'],
      width: '5%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setBannerItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setBannerItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listBanners}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Баннер"
              onChange={e => {
                setSearchBanner(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый баннер
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый баннер"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setImageFileKZ(null)
          imageFileRefKZ.current.value = null
          setImageFileRU(null)
          imageFileRefRU.current.value = null
        }}
        onOk={() => {
          if (imageFileKZ !== null && imageFileRU !== null) {
            console.log('🚀 ~ imageFileRU:', imageFileRU)
            console.log('🚀 ~ imageFileKZ:', imageFileKZ)
            addBannerHandler(
              form,
              setModalVisible,
              imageFileKZ,
              imageFileRU,
              setImageFileKZ,
              setImageFileRU,
              imageFileRefKZ,
              imageFileRefRU
            )
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <BannerForm
          setImageFileKZ={setImageFileKZ}
          imageFileRefKZ={imageFileRefKZ}
          setImageFileRU={setImageFileRU}
          imageFileRefRU={imageFileRefRU}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
            setImageFileKZ(null)
            imageFileRefKZ.current.value = null
            setImageFileRU(null)
            imageFileRefRU.current.value = null
          }}
          onOk={() => {
            updateBannerHandler(
              formForEdit,
              bannerItem.id,
              setEditModal,
              imageFileKZ,
              imageFileRU,
              setImageFileKZ,
              setImageFileRU,
              imageFileRefKZ,
              imageFileRefRU
            )
          }}
        >
          <BannerForm
            form={formForEdit}
            index={bannerItem.index}
            status={bannerItem.status}
            titleKZ={bannerItem.titleKZ}
            titleRU={bannerItem.titleRU}
            descriptionKZ={bannerItem.descriptionKZ}
            descriptionRU={bannerItem.descriptionRU}
            openStyle={bannerItem.openStyle}
            action={bannerItem.action}
            setImageFileKZ={setImageFileKZ}
            imageFileRefKZ={imageFileRefKZ}
            setImageFileRU={setImageFileRU}
            imageFileRefRU={imageFileRefRU}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteBannerHandler(bannerItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить '{bannerItem.titleRU}' ?</h3>
        </Modal>
      )}
    </>
  )
}

Banners.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object).isRequired,
  addBannerHandler: PropTypes.func.isRequired,
  updateBannerHandler: PropTypes.func.isRequired
}

export default Banners
