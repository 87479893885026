import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../context/useAuth'

import Loading from '../pages/shared/Loading'

function PrivateRoute({ roles, component: Component, ...rest }) {
  const { checkUserIsLoggedIn, getRole } = useAuth()
  const role = getRole()
  const userLoggedIn = checkUserIsLoggedIn()

  if (userLoggedIn && !role) {
    return <Loading />
  }
  return (
    <Route
      {...rest}
      render={props =>
        checkUserIsLoggedIn() && roles?.includes(role) ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  component: PropTypes.func.isRequired
}

export default PrivateRoute
