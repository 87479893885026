import React from 'react'

import { Form, Input, InputNumber, Select, Button } from 'antd'
import PropTypes from 'prop-types'
import './QaraSoz.css'

const QaraSozForm = ({
  form,
  allCreators,
  title,
  authors,
  voiceActors,
  status,
  index,
  type,
  version,
  lyrics,
  setAudioFile,
  setTextFile,
  setImageFile,
  audioFileRef,
  textFileRef,
  imageFileRef
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио</>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
        <>Текст</>
        <input
          type="file"
          accept=".csv"
          ref={textFileRef}
          onChange={event => setTextFile(event.target.files[0])}
        />
        <>Изображение</>
        <input
          type="file"
          aceept="image/*"
          ref={imageFileRef}
          title="Choose Image File"
          onChange={event => setImageFile(event.target.files[0])}
        />
      </div>
      {/* <label for="upload-photo">Аудио файл...</label>
      <input type="file" name="photo" id="upload-file" onChange={ (event) => setTextFile(event.target.files[0])} /> */}
      {/* <>Text File</>
      <input type="file" title="Choose Text File"  onChange={ (event) => setTextFile(event.target.files[0])}/>     */}
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="version"
        label="Версия"
        name="version"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите версию`
          }
        ]}
        initialValue={version}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="full" value="full">
            Полная
          </Select.Option>
          <Select.Option key="lite" value="lite">
            Короткая
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность документа"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность документа`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность документа" />
      </FormItem>
      <FormItem
        key="title"
        label="Название"
        name="title"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Название`
          }
        ]}
        initialValue={title}
      >
        <Input placeholder="Название" />
      </FormItem>
      <FormItem
        key="authors"
        label="Авторы"
        name="authors"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите Авторов`
          }
        ]}
        initialValue={authors
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Авторов"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCreators.creators.map(creator => (
            <Select.Option key={creator.id} value={creator.id}>
              {creator.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="voiceActors"
        label="Исполнители"
        name="voiceActors"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите Исполнителей`
          }
        ]}
        initialValue={voiceActors
          .filter(item => item !== null)
          .map(item => item?.id)}
      >
        <Select
          placeholder="Выберите Исполнителей"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCreators.creators.map(creator => (
            <Select.Option key={creator.id} value={creator.id}>
              {creator.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      {lyrics && (
        <>
          <FormItem
            key="lyrics"
            label="Текст"
            name="lyrics"
            rules={[
              {
                required: false,
                message: `Пожалуйста, напишите короткое описание`
              }
            ]}
            initialValue={lyrics}
          >
            <Input.TextArea disabled />
          </FormItem>
          <Button
            danger
            onClick={() => {
              form.setFieldsValue({ lyrics: null })
            }}
          >
            Reset Text
          </Button>
        </>
      )}
    </Form>
  )
}

QaraSozForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

QaraSozForm.defaultProps = {
  status: 'active',
  title: '',
  index: null,
  authors: [],
  voiceActors: [],
  lyrics: null
}

export default QaraSozForm
