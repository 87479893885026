import React from 'react'

import { Form, Input, InputNumber, Select, Button } from 'antd'
import PropTypes from 'prop-types'
import { DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const StyledDivDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CreatorForm = ({
  form,
  id,
  name,
  dateOfBirth,
  dateOfDeath,
  bioKZ,
  bioRU,
  types,
  status,
  index,
  setFile,
  fileRef,
  removeFilesHandler
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <>Изорбражение Автора/Исполнителя</>
        <StyledDivDelete>
          <input
            type="file"
            accept="image/*"
            ref={fileRef}
            onChange={event => setFile(event.target.files[0])}
          />
          {id && (
            <Button
              danger
              onClick={() => {
                removeFilesHandler(id)
              }}
            >
              <DeleteOutlined />
            </Button>
          )}
        </StyledDivDelete>
      </div>
      {/* <StyledDivDelete>
        <input
          style={{ marginBottom: '3%' }}
          type="file"
          accept="image/*"
          ref={fileRef}
          onChange={event => setFile(event.target.files[0])}
        />
      </StyledDivDelete> */}
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность"
        name="index"
        rules={[
          {
            required: false,
            message: `Пожалуйста, введите Очередность Документа`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность" />
      </FormItem>
      <FormItem
        key="name"
        label="Имя"
        name="name"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите имя Автора/Исполнителя`
          }
        ]}
        initialValue={name}
      >
        <Input placeholder="Имя" />
      </FormItem>
      <FormItem
        key="types"
        label="Автор/Композитор/Исполнитель"
        name="types"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите типы`
          }
        ]}
        initialValue={types}
      >
        <Select placeholder="Выберите" mode="multiple" allowClear>
          <Select.Option key="author" value="author">
            Автор
          </Select.Option>
          <Select.Option key="composer" value="composer">
            Композитор
          </Select.Option>
          <Select.Option key="voiceActor" value="voiceActor">
            Исполнитель
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="dateOfBirth"
        label="Год рождения"
        name="dateOfBirth"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите год рождения автора`
          }
        ]}
        initialValue={dateOfBirth}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem
        key="dateOfDeath"
        label="Год смерти"
        name="dateOfDeath"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите год смерти автора`
          }
        ]}
        initialValue={dateOfDeath}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem
        key="bioKZ"
        label="Короткая биография(каз)"
        name="bioKZ"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткую биографию автора/издателя на казахском`
          }
        ]}
        initialValue={bioKZ}
      >
        <Input.TextArea placeholder="Короткая биография(каз)" />
      </FormItem>
      <FormItem
        key="bioRU"
        label="Короткая биография(рус)"
        name="bioRU"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткую биографию автора/издателя на русском`
          }
        ]}
        initialValue={bioRU}
      >
        <Input.TextArea placeholder="Короткая биография(рус)" />
      </FormItem>
    </Form>
  )
}

CreatorForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string
}

CreatorForm.defaultProps = {
  name: '',
  dateOfBirth: null,
  dateOfDeath: null,
  bioKZ: null,
  bioRU: null,
  types: [],
  status: 'active',
  index: null
}

export default CreatorForm
