import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, Typography, Select, DatePicker } from 'antd'
import styled from 'styled-components'
import moment from 'moment'

const { TextArea } = Input
const { Text } = Typography

const InputItemContainer = styled.div`
  margin-bottom: 20px;
`

const CreateNotificationModal = ({
  isVisible,
  setIsVisible,
  headingKZ,
  headingRU,
  messageKZ,
  messageRU,
  setHeadingKZ,
  setHeadingRU,
  setType,
  time,
  hours,
  type,
  setTime,
  setHours,
  setMessageKZ,
  setMessageRU,
  handleSendNotification
}) => {
  return (
    <Modal
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      title="Новое уведомление"
      onOk={handleSendNotification}
    >
      <InputItemContainer>
        <Text>Заголовок (қаз)</Text>
        <Input value={headingKZ} onChange={e => setHeadingKZ(e.target.value)} />
      </InputItemContainer>
      <InputItemContainer>
        <Text>Заголовок (рус)</Text>
        <Input value={headingRU} onChange={e => setHeadingRU(e.target.value)} />
      </InputItemContainer>

      <InputItemContainer>
        <Text>Описание (қаз)</Text>
        <TextArea
          value={messageKZ}
          onChange={e => setMessageKZ(e.target.value)}
        />
      </InputItemContainer>
      <InputItemContainer>
        <Text>Описание (рус)</Text>
        <TextArea
          value={messageRU}
          onChange={e => setMessageRU(e.target.value)}
        />
      </InputItemContainer>

      <InputItemContainer>
        <Text>Тип уведомления</Text>
        <Select
          style={{ width: '100%' }}
          placeholder="Тип сообщения"
          showSearch
          value={type}
          onChange={value => setType(value)}
        >
          <Select.Option key="broadcast" value="broadcast">
            Всем
          </Select.Option>
          <Select.Option key="scheduled" value="scheduled">
            Запланированные
          </Select.Option>
        </Select>
      </InputItemContainer>
      {(() => {
        if (type === 'broadcast')
          return (
            <InputItemContainer>
              <Text>Дата отправки</Text>
              <br />
              <DatePicker
                format="HH:mm:ss DD-MM-YYYY"
                showTime
                placeholder="Выберите время отправки"
                onChange={(date, dateString) => setTime(date)}
                v-model={moment(time)}
                disabledDate={current =>
                  current &&
                  current < new Date().setDate(new Date().getDate() - 1)
                }
              />
            </InputItemContainer>
          )
        else
          return (
            <InputItemContainer>
              <Text>Через сколько часов после выхода</Text>
              <Input
                value={hours}
                onChange={e => setHours(parseInt(e.target.value))}
                type="number"
              />
            </InputItemContainer>
          )
      })()}
    </Modal>
  )
}

CreateNotificationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  headingKZ: PropTypes.string.isRequired,
  headingRU: PropTypes.string.isRequired,
  messageKZ: PropTypes.string.isRequired,
  messageRU: PropTypes.string.isRequired,
  setHeadingKZ: PropTypes.func.isRequired,
  setHeadingRU: PropTypes.func.isRequired,
  setMessageKZ: PropTypes.func.isRequired,
  setMessageRU: PropTypes.func.isRequired,
  time: PropTypes.instanceOf(Date),
  setTime: PropTypes.func,
  setHours: PropTypes.func,
  type: PropTypes.string.isRequired,
  hours: PropTypes.number,
  setType: PropTypes.func.isRequired,
  handleSendNotification: PropTypes.func.isRequired
}

export default CreateNotificationModal
