import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import PracticeColor from './PracticeColor'
import WithMainLayout from '../../../hocs/withMainLayout'

const GET_PRACTICE_COLORS = gql`
    query statusedPracticeColorsByIndex {
        statusedPracticeColorsByIndex {
            id
            index
            name
            code
            imageURL
            mergeImageURL
            objectURL
            audioURL
            status
        }
    }
`

const ADD_PRACTICE_COLOR = gql`
    mutation addPracticeColor(
        $audioFile: FileUpload!
        $imageFile: FileUpload!
        $mergeFile: FileUpload!
        $objectImageFile: FileUpload!
        $input: PracticeColorInput!
    ) {
        addPracticeColor(
            audioFile: $audioFile
            imageFile: $imageFile
            mergeImageFile: $mergeImageFile
            objectFile: $objectFile
            input: $input
        ) {
            id
            index
            name
            code
            imageURL
            mergeImageURL
            objectURL
            audioURL
            status
        }
    }
`

const UPDATE_PRACTICE_COLOR = gql`
    mutation updatePracticeColor(
        $id: ID!
        $audioFile: FileUpload
        $imageFile: FileUpload
        $mergeImageFile: FileUpload
        $objectFile: FileUpload
        $input: UpdatePracticeColorInput
    ) {
        updatePracticeColor(
            id: $id
            audioFile: $audioFile
            imageFile: $imageFile
            mergeImageFile: $mergeImageFile
            objectFile: $objectFile
            input: $input
        ) {
            id
            index
            name
            code
            imageURL
            mergeImageURL
            objectURL
            audioURL
            status
        }
    }
`

const DELETE_PRACTICE_COLOR = gql`
    mutation deletePracticeColor($id: ID!) {
        deletePracticeColor(id: $id) {
            id
            message
        }
    }
`

const ColorsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allColors, setAllColors] = React.useState([])
  const { data, loading, error } = useQuery(GET_PRACTICE_COLORS)
  const [addPracticeColor, { error: errorAddPracticeColor }] = useMutation(
    ADD_PRACTICE_COLOR,
    {
      update(cache, { data: { addPracticeColor: addPracticeColorItem } }) {
        const { statusedPracticeColorsByIndex } = cache.readQuery({
          query: GET_PRACTICE_COLORS
        })
        cache.writeQuery({
          query: GET_PRACTICE_COLORS,
          data: {
            statusedPracticeColorsByIndex: statusedPracticeColorsByIndex
              .concat(addPracticeColorItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Цвет практики добавлена')
      }
    }
  )
  const [
    updatePracticeColor,
    { error: errorUpdatePracticeColor }
  ] = useMutation(UPDATE_PRACTICE_COLOR, {
    update(
      cache,
      { data: { updatePracticeColor: updatePracticeColorItem } }
    ) {
      const { statusedPracticeColorsByIndex } = cache.readQuery({
        query: GET_PRACTICE_COLORS
      })
      cache.writeQuery({
        query: GET_PRACTICE_COLORS,
        data: {
          statusedPracticeColorsByIndex: statusedPracticeColorsByIndex
            .filter(i => i.id !== updatePracticeColorItem.id)
            .concat(updatePracticeColorItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Цвет практики обновлена')
    }
  })
  const [
    deletePracticeColor,
    { error: errorDeletePracticeColor }
  ] = useMutation(DELETE_PRACTICE_COLOR, {
    update(
      cache,
      { data: { deletePracticeColor: deletePracticeColorItem } }
    ) {
      const { statusedPracticeColorsByIndex } = cache.readQuery({
        query: GET_PRACTICE_COLORS
      })
      cache.writeQuery({
        query: GET_PRACTICE_COLORS,
        data: {
          statusedPracticeColorsByIndex: statusedPracticeColorsByIndex.filter(
            i => i.id !== deletePracticeColorItem.id
          )
        }
      })
      hideLoading()
      toast.success('Цвет практики удалена')
    }
  })

  React.useEffect(() => {
    if (errorAddPracticeColor) {
      console.log(errorAddPracticeColor)
    }
  }, [errorAddPracticeColor])

  React.useEffect(() => {
    if (errorDeletePracticeColor) {
      console.log(errorDeletePracticeColor)
    }
  }, [errorDeletePracticeColor])

  React.useEffect(() => {
    if (errorUpdatePracticeColor) {
      console.log(errorUpdatePracticeColor)
    }
  }, [errorUpdatePracticeColor])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllColors(data.statusedPracticeColorsByIndex)
    }
  }, [data, loading, error])

  const addPracticeColorHandler = (
    form,
    closeFunc,
    audioFile,
    audioFileRef,
    setAudioFile,
    imageFile,
    imageFileRef,
    setImageFile,
    mergeImageFile,
    mergeImageFileRef,
    setMergeImageFile,
    objectFile,
    objectFileRef,
    setObjectFile
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addPracticeColor({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            mergeImageFile: mergeImageFile,
            objectFile: objectFile,
            input: {
              index: val.index,
              name: val.name?.trim(),
              code: val.code?.trim(),
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        audioFileRef.current.value = null
        setImageFile(null)
        imageFileRef.current.value = null
        setMergeImageFile(null)
        mergeImageFileRef.current.value = null
        setObjectFile(null)
        objectFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updatePracticeColorHandler = (
    form,
    colorId,
    closeFunc,
    audioFile,
    audioFileRef,
    setAudioFile,
    imageFile,
    imageFileRef,
    setImageFile,
    mergeImageFile,
    mergeImageFileRef,
    setMergeImageFile,
    objectFile,
    objectFileRef,
    setObjectFile
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updatePracticeColor({
          variables: {
            id: colorId,
            audioFile,
            imageFile,
            mergeImageFile,
            objectFile,
            input: {
              index: val.index,
              name: val.name?.trim(),
              code: val.code?.trim(),
              status: val.status
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        audioFileRef.current.value = null
        setImageFile(null)
        imageFileRef.current.value = null
        setMergeImageFile(null)
        mergeImageFileRef.current.value = null
        setObjectFile(null)
        objectFileRef.current.value = null
        closeFunc(false)
        toast.success('Цвет обновлен')
      })
  }

  const deletePracticeColorHandler = (colorId, closeFunc) => {
    showLoading()
    deletePracticeColor({
      variables: {
        id: colorId
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  return (
    <PracticeColor
      colors={allColors}
      loading={loading}
      addPracticeColorHandler={addPracticeColorHandler}
      updatePracticeColorHandler={updatePracticeColorHandler}
      deletePracticeColorHandler={deletePracticeColorHandler}
    />
  )
}

export default WithMainLayout(ColorsContainer)
