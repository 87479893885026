import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Users from './Users'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const PAGE_SIZE = 15

const GET_USERS = gql`
  query users($page: Int!, $pageSize: Int!, $search: String) {
    users(page: $page, pageSize: $pageSize, search: $search) {
      users {
        id
        phoneNumber
        name
        childAge
        role
        subscription {
          plan
          expiresAt
        }
        usedEmailAddress
      }
      totalCount
      searchQuery
    }
  }
`

const GET_TOTAL_AMOUNT = gql`
  query GetTotalUserCount {
    getUsersTotalCount {
      amount
    }
  }
`

const ADD_USER = gql`
  mutation addUser($input: UserInput) {
    addUser(input: $input) {
      id
      phoneNumber
      name
      role
      childAge
    }
  }
`

const EDIT_USER = gql`
  mutation editUser($id: ID!, $input: EditUserInput) {
    editUser(id: $id, input: $input) {
      id
      phoneNumber
      name
      role
      childAge
      subscription {
        plan
        expiresAt
      }
    }
  }
`

const UsersContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allUsers, setAllUsers] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1) // Current page state

  const { data, loading, error, refetch } = useQuery(GET_USERS, {
    variables: {
      page: currentPage,
      pageSize: PAGE_SIZE,
      search: ''
    }
  })

  const { data: UsersAmount } = useQuery(GET_TOTAL_AMOUNT)

  const [addUser, { error: errorAddUser }] = useMutation(ADD_USER, {
    update(cache, { data: { addUser: addUserItem } }) {
      const { users } = cache.readQuery({ query: GET_USERS })
      cache.writeQuery({
        query: GET_USERS,
        data: { users: [addUserItem].concat(users) }
      })
      hideLoading()
      toast.success('Пользователь добавлен')
    }
  })
  const [editUser, { data: editUserData }] = useMutation(EDIT_USER)
  const handlePaginationChange = page => {
    setCurrentPage(page)
    refetch({ page, pageSize: PAGE_SIZE })
  }
  React.useEffect(() => {
    if (errorAddUser) {
      console.log(errorAddUser)
    }
  }, [errorAddUser])

  React.useEffect(() => {
    if (data && data.users && !error && !loading) {
      setAllUsers(data.users.users)
    }
  }, [data, loading, error, editUserData])

  const addUserHandler = (form, closeFunc) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addUser({
          variables: {
            input: {
              phoneNumber: val.phoneNumber,
              password: val.password,
              role: val.role,
              name: val.name,
              childAge: val.childAge,
              subscription: {
                plan: val.plan,
                expiresAt: val.expiresDate.valueOf()
              }
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const editUserHandler = (form, userId, closeFunc) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        editUser({
          variables: {
            id: userId,
            input: {
              phoneNumber: val.phoneNumber,
              password: val.password,
              role: val.role,
              name: val.name,
              childAge: val.childAge,
              subscription: {
                plan: val.plan,
                expiresAt: val.expiresDate.valueOf()
              }
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        closeFunc(false)
        toast.success('Пользователь изменен')
      })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Users
      users={allUsers}
      addUser={addUser}
      refetch={refetch}
      addUserHandler={addUserHandler}
      editUserHandler={editUserHandler}
      pagination={{
        current: currentPage,
        pageSize: PAGE_SIZE,
        total: UsersAmount?.getUsersTotalCount?.amount,
        onChange: handlePaginationChange
      }}
      searchedUser={data.users.searchQuery}
    />
  )
}

export default WithMainLayout(UsersContainer)
