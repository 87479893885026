import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import './DeleteAccount.css'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

const languages = {
  en: {
    phone: 'Phone number',
    sendSMS: 'Send SMS',
    codePassword: 'SMS code',
    deleteAccount: 'Delete account',
    problem: 'There was a mistake with phone number or SMS code',
    deleted: 'Account deleted',
    sent: 'SMS was sent'
  },
  ru: {
    phone: 'Номер телефона',
    sendSMS: 'Получить SMS',
    codePassword: 'SMS код',
    deleteAccount: 'Удалить аккаунт',
    problem: 'Есть ошибка в номере телефоне или в SMS коде',
    deleted: 'Аккаунт удален',
    sent: 'SMS отпавлен на номер'
  },
  kk: {
    phone: 'Телефон номері',
    sendSMS: 'SMS кодын алу',
    codePassword: 'SMS коды',
    deleteAccount: 'Аккаунты өшіру',
    problem: 'Телефон нөмірінде немесе SMS кодка қате бар',
    deleted: 'Аккаунт өшірілді',
    sent: 'SMS нөмірге жіберілді'
  }
}

const SEND_SMS_MUTATION = gql`
  mutation verifyPhoneNumber($phoneNumber: String!, $isKaz: Boolean!) {
    verifyPhoneNumber(phoneNumber: $phoneNumber, isKaz: $isKaz) {
      message
    }
  }
`
const TRUNCATE_ACCOUNT_MUTATION = gql`
  mutation TruncateAccount($input: truncateUserInput!) {
    truncateAccount(input: $input) {
      status
      message
    }
  }
`

const DeleteAccount = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [codePassword, setSmsCode] = useState('')
  const [currentLanguage, setCurrentLanguage] = useState('en')
  const [sendSms] = useMutation(SEND_SMS_MUTATION)
  const [isKaz, setIsKaz] = useState(true)
  const [truncateAccount] = useMutation(TRUNCATE_ACCOUNT_MUTATION)

  const handleSendSms = async () => {
    try {
      const result = await sendSms({ variables: { phoneNumber, isKaz } })
      console.log(result.data)
      toast.success(languageData.sent)
    } catch (error) {
      toast.error(languageData.problem)
      console.error(error)
    }
  }

  const formatPhoneNumber = input => {
    // Remove all non-numeric characters from the input
    const numericInput = input.replace(/\D/g, '')

    // If the input is empty, return an empty string
    if (numericInput === '') {
      return ''
    }

    // Apply the desired format "8 ### ### ## ##"
    let formattedNumber = '8'
    for (let i = 1; i < numericInput.length && i < 11; i++) {
      formattedNumber += numericInput[i]
    }

    return formattedNumber
  }

  const handleInputChange = e => {
    const formattedValue = formatPhoneNumber(e.target.value)
    setPhoneNumber(formattedValue)
  }

  const handleSubmit = async () => {
    try {
      const input = { phoneNumber, codePassword }
      const result = await truncateAccount({
        variables: { input }
      })
      console.log(result.data)
      toast.success(languageData.deleted)
    } catch (error) {
      toast.error(languageData.problem)
      console.error(error)
    }
  }

  const handleSmsCodeChange = e => {
    const input = e.target.value
    const numericInput = input.replace(/\D/g, '')
    setSmsCode(numericInput)
  }

  const handleChangeLanguage = language => {
    setCurrentLanguage(language)
  }

  const languageData = languages[currentLanguage]

  return (
    <div className="delete-account-form">
      <div className="language-selector">
        <Button onClick={() => handleChangeLanguage('en')}>English</Button>
        <Button onClick={() => handleChangeLanguage('ru')}>Русский</Button>
        <Button onClick={() => handleChangeLanguage('kk')}>Қазақша</Button>
      </div>

      <Form layout="vertical">
        <Form.Item label={languageData.phone}>
          <Input
            value={phoneNumber}
            onChange={handleInputChange}
            placeholder="8 701 123 34 45"
            maxLength={15}
          />
        </Form.Item>
        <Form.Item>
          <Button onClick={handleSendSms}>{languageData.sendSMS}</Button>
        </Form.Item>

        <Form.Item label={languageData.codePassword}>
          <Input
            type="number"
            value={codePassword}
            onChange={handleSmsCodeChange}
            placeholder={languageData.codePassword}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSubmit}>
            {languageData.deleteAccount}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default DeleteAccount
