import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Colors from './Colors'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_COLORS_BY_INDEX = gql`
  query statusedColorsByIndex {
    statusedColorsByIndex {
      id
      color
      index
      link2Audio
      link2Image
      status
      restrictions
    }
  }
`

const ADD_COLOR = gql`
  mutation addColor(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $input: ColorInput!
  ) {
    addColor(audioFile: $audioFile, imageFile: $imageFile, input: $input) {
      id
      color
      index
      link2Audio
      link2Image
      status
      restrictions
    }
  }
`

const UPDATE_COLOR = gql`
  mutation updateColor(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $input: UpdateColorInput
  ) {
    updateColor(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      color
      index
      link2Audio
      link2Image
      status
      restrictions
    }
  }
`

const DELETE_COLOR = gql`
  mutation updateColor($id: ID!, $input: UpdateColorInput) {
    updateColor(id: $id, input: $input) {
      id
      color
      index
      link2Audio
      link2Image
      status
    }
  }
`

const ColorsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allColors, setAllColors] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_COLORS_BY_INDEX)
  const [addColor, { error: errorAddColor }] = useMutation(ADD_COLOR, {
    update(cache, { data: { addColor: addColorItem } }) {
      const { statusedColorsByIndex } = cache.readQuery({
        query: GET_COLORS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_COLORS_BY_INDEX,
        data: {
          statusedColorsByIndex: statusedColorsByIndex
            .concat(addColorItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Цвет добавлен')
    }
  })
  // const [updateColor, { data: updateColorData }] = useMutation(UPDATE_COLOR)
  const [updateColor, { error: errorUpdateColor }] = useMutation(UPDATE_COLOR, {
    update(cache, { data: { updateColor: updateColorItem } }) {
      const { statusedColorsByIndex } = cache.readQuery({
        query: GET_COLORS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_COLORS_BY_INDEX,
        data: {
          statusedColorsByIndex: statusedColorsByIndex
            .filter(color => color.id !== updateColorItem.id)
            .concat(updateColorItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Цвет обновлен')
    }
  })
  const [deleteColor, { error: errorDeleteColor }] = useMutation(DELETE_COLOR, {
    update(cache, { data: { updateColor: deleteColorItem } }) {
      const { statusedColorsByIndex } = cache.readQuery({
        query: GET_COLORS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_COLORS_BY_INDEX,
        data: {
          statusedColorsByIndex: statusedColorsByIndex.filter(
            color => color.id !== deleteColorItem.id
          )
        }
      })
      hideLoading()
      toast.success('Цвет удален')
    }
  })

  React.useEffect(() => {
    if (errorAddColor) {
      console.log(errorAddColor)
    }
  }, [errorAddColor])

  React.useEffect(() => {
    if (errorDeleteColor) {
      console.log(errorDeleteColor)
    }
  }, [errorDeleteColor])

  React.useEffect(() => {
    if (errorUpdateColor) {
      console.log(errorUpdateColor)
    }
  }, [errorUpdateColor])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllColors(data.statusedColorsByIndex)
    }
  }, [data, loading, error])

  const addColorHandler = (
    form,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        // console.log('audioFile', audioFile)
        // console.log('imageFile', imageFile)
        // console.log('val', val)
        form.resetFields()
        showLoading()
        addColor({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            input: {
              color: val.color,
              index: val.index,
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateColorHandler = (
    form,
    colorId,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateColor({
          variables: {
            id: colorId,
            audioFile,
            imageFile,
            input: {
              color: val.color,
              index: val.index,
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        // toast.success('Буква обновлена')
      })
  }

  const deleteColorHandler = (colorId, closeFunc) => {
    showLoading()
    deleteColor({
      variables: {
        id: colorId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Colors
      colors={allColors}
      addColor={addColor}
      refetch={refetch}
      addColorHandler={addColorHandler}
      updateColorHandler={updateColorHandler}
      deleteColorHandler={deleteColorHandler}
    />
  )
}

export default WithMainLayout(ColorsContainer)
