import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import SyllablesForm from './SyllablesForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Syllables = ({
  syllables,
  addSyllableHandler,
  updateSyllableHandle,
  deleteSyllableHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [syllableItem, setSyllableItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchSyllable, setSearchSyllable] = React.useState(null)

  const [listSyllables, setListSyllables] = React.useState(null)

  const [audioFile, setAudioFile] = useState(null)
  const audioFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveSyllables = syllables.map(letter => {
      return {
        id: letter.id,
        syllable: letter.syllable,
        syllableAudioURL: letter.syllableAudioURL
      }
    })
    let searchArray = descriptiveSyllables

    if (searchSyllable) {
      searchArray = searchArray.filter(syllable => {
        return syllable.syllable?.toLowerCase().includes(searchSyllable)
      })
    }

    setListSyllables(searchArray)
  }, [syllables, searchSyllable])

  const columns = [
    {
      title: 'Слог',
      dataIndex: 'syllable',
      width: '5%'
    },
    {
      title: 'Звучание слога',
      width: '5%',
      render: item => {
        let audioLink = item.syllableAudioURL
        if (audioLink) {
          if (!audioLink.startsWith('https://')) {
            audioLink = 'https://' + audioLink
          }
          return (
            <audio controls="controls">
              <source src={audioLink} type="audio/mpeg" />
            </audio>
          )
        }
        return
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setSyllableItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setSyllableItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listSyllables}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Слог"
              onChange={e => {
                setSearchSyllable(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый слог
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая буква"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          audioFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            addSyllableHandler(
              form,
              setModalVisible,
              audioFile,
              setAudioFile,
              audioFileRef
            )
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <SyllablesForm
          setAudioFile={setAudioFile}
          audioFileRef={audioFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            audioFileRef.current.value = null
          }}
          onOk={() => {
            updateSyllableHandle(
              formForEdit,
              setSyllableItem.id,
              setEditModal,
              audioFile,
              setAudioFile,
              audioFileRef
            )
          }}
        >
          <SyllablesForm
            form={formForEdit}
            syllable={syllableItem.syllable}
            setAudioFile={setAudioFile}
            audioFileRef={audioFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteSyllableHandler(syllableItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить '{syllableItem.syllable}' ?</h3>
        </Modal>
      )}
    </>
  )
}

export default Syllables
