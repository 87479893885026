import React from 'react'
import { Table, Button, Form, Modal, Input } from 'antd'
import { PlusOutlined, SearchOutlined, MenuOutlined } from '@ant-design/icons'
import { SortableHandle } from 'react-sortable-hoc'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import PlaylistsCollectionForm from './PlaylistsCollectionForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

// const SortableItem = SortableElement(props => <tr {...props} />)
// const SortableBody = SortableContainer(props => <tbody {...props} />)

const PlaylistsCollections = ({
  playlistsCollections,
  allPlaylists,
  allCategories,
  addPlaylistsCollectionHandler,
  updatePlaylistsCollectionHandler,
  deletePlaylistsCollectionHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [playlistsCollectionItem, setPlaylistsCollectionItem] = React.useState(
    null
  )
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [
    searchPlaylistsCollection,
    setSearchPlaylistsCollection
  ] = React.useState(null)

  const [
    listPlaylistsCollections,
    setListPlaylistsCollections
  ] = React.useState(null)

  React.useEffect(() => {
    const descriptivePlaylistsCollections = playlistsCollections.map(
      playlistsCollection => {
        return {
          id: playlistsCollection.id,
          titleKZ: playlistsCollection.titleKZ,
          titleRU: playlistsCollection.titleRU,
          playlists: playlistsCollection.playlists,
          categories: playlistsCollection.categories,
          index: playlistsCollection.index,
          status: playlistsCollection.status
        }
      }
    )
    let searchArray = descriptivePlaylistsCollections

    if (searchPlaylistsCollection) {
      searchArray = searchArray.filter(playlistsCollection => {
        return (
          playlistsCollection.nameKZ
            ?.toLowerCase()
            .includes(searchPlaylistsCollection) ||
          playlistsCollection.nameRU
            ?.toLowerCase()
            .includes(searchPlaylistsCollection)
        )
      })
    }

    setListPlaylistsCollections(searchArray)
  }, [playlistsCollections, searchPlaylistsCollection])

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   // const { dataSource } = this.state
  //   // if (oldIndex !== newIndex) {
  //   //   const newData = arrayMoveImmutable(
  //   //     [].concat(dataSource),
  //   //     oldIndex,
  //   //     newIndex
  //   //   ).filter(el => !!el)
  //   //   console.log('Sorted items: ', newData)
  //   //   this.setState({ dataSource: newData })
  //   // }
  // }

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: '1%',
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '1%'
    },
    {
      title: 'Название(каз)',
      dataIndex: 'titleKZ',
      width: '15%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'titleRU',
      width: '15%'
    },
    {
      title: 'Количество плейлистов',
      width: '5%',
      render: item => {
        return <>{item.playlists.length}</>
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          {/* <span>
          <Link to={`/playlistsCollections/${item.id}`}>Редактировать</Link>
        </span> */}
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setPlaylistsCollectionItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setPlaylistsCollectionItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listPlaylistsCollections}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Title"
              onChange={e => {
                setSearchPlaylistsCollection(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая коллекция плейлистов
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая коллекция плейлистов"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          addPlaylistsCollectionHandler(form, setModalVisible, allPlaylists)
        }}
      >
        <PlaylistsCollectionForm
          allPlaylists={allPlaylists}
          allCategories={allCategories}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
          }}
          onOk={() => {
            updatePlaylistsCollectionHandler(
              formForEdit,
              playlistsCollectionItem.id,
              setEditModal
            )
          }}
        >
          <PlaylistsCollectionForm
            form={formForEdit}
            id={playlistsCollectionItem.id}
            titleKZ={playlistsCollectionItem.titleKZ}
            titleRU={playlistsCollectionItem.titleRU}
            playlists={playlistsCollectionItem.playlists}
            categories={playlistsCollectionItem.categories}
            index={playlistsCollectionItem.index}
            status={playlistsCollectionItem.status}
            allPlaylists={allPlaylists}
            allCategories={allCategories}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deletePlaylistsCollectionHandler(
              playlistsCollectionItem.id,
              setDeleteModal
            )
          }
        >
          <h3>
            Вы действительно хотите удалить{' '}
            {playlistsCollectionItem.titleKZ - playlistsCollectionItem.titleRU}{' '}
            ?
          </h3>
        </Modal>
      )}
    </>
  )
}

PlaylistsCollections.propTypes = {
  playlistsCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPlaylistsCollectionHandler: PropTypes.func.isRequired,
  updatePlaylistsCollectionHandler: PropTypes.func.isRequired
}

export default PlaylistsCollections
