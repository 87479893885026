import React from 'react'

import { Form, Input, Select, InputNumber } from 'antd'
import PropTypes from 'prop-types'

const EducationCollectionForm = ({
  form,
  index,
  titleKZ,
  titleRU,
  status,
  restrictions
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="index"
        label="Очередность"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите очерденость`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="titleKZ"
        label="Название(каз)"
        name="titleKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название на казахском`
          }
        ]}
        initialValue={titleKZ}
      >
        <Input placeholder="Имя" />
      </FormItem>
      <FormItem
        key="titleRU"
        label="Название(рус)"
        name="titleRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название на русском`
          }
        ]}
        initialValue={titleRU}
      >
        <Input placeholder="Имя" />
      </FormItem>

      <FormItem
        key="restrictions"
        label="План"
        name="restrictions"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите план`
          }
        ]}
        initialValue={restrictions}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="basic" value="basic">
            Basic
          </Select.Option>
          <Select.Option key="premium" value="premium">
            Premium
          </Select.Option>
        </Select>
      </FormItem>
    </Form>
  )
}

EducationCollectionForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  titleKZ: PropTypes.string,
  titleRU: PropTypes.string
}

EducationCollectionForm.defaultProps = {
  titleKZ: '',
  titleRU: '',
  status: 'active'
}

export default EducationCollectionForm
