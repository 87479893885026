import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import EducationCollections from './EducationCollections'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'

const GET_EDUCATION_COLLECTION = gql`
  query statusedEducationCollections {
    statusedEducationCollections {
      id
      index
      titleKZ
      titleRU
      status
      restrictions
    }
  }
`

const ADD_EDUCATION_COLLECTION = gql`
  mutation addEducationCollection($input: EducationCollectionInput) {
    addEducationCollection(input: $input) {
      id
      index
      titleKZ
      titleRU
      status
      restrictions
    }
  }
`

const UPDATE_EDUCATION_COLLECTION = gql`
  mutation updateEducationCollection(
    $id: ID!
    $input: EducationCollectionInput
  ) {
    updateEducationCollection(id: $id, input: $input) {
      id
      index
      titleKZ
      titleRU
      status
      restrictions
    }
  }
`

const DELETE_EDUCATION_COLLECTION = gql`
  mutation deleteEducationCollection($id: ID!) {
    deleteEducationCollection(id: $id) {
      id
      message
    }
  }
`

const EducationCollectionsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allEducationCollections, setAllEducationCollections] = React.useState(
    []
  )
  const { data, loading, error, refetch } = useQuery(GET_EDUCATION_COLLECTION)
  const [
    addEducationCollection,
    { error: errorAddEducationCollection }
  ] = useMutation(ADD_EDUCATION_COLLECTION, {
    update(
      cache,
      { data: { addEducationCollection: addEducationCollectionItem } }
    ) {
      const { statusedEducationCollections } = cache.readQuery({
        query: GET_EDUCATION_COLLECTION
      })
      cache.writeQuery({
        query: GET_EDUCATION_COLLECTION,
        data: {
          statusedEducationCollections: [addEducationCollectionItem]
            .concat(statusedEducationCollections)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Коллекция добавлена')
    }
  })
  const [
    updateEducationCollection,
    { error: errorUpdateEducationCollection }
  ] = useMutation(UPDATE_EDUCATION_COLLECTION, {
    update(
      cache,
      { data: { updateEducationCollection: updateEducationCollectionItem } }
    ) {
      const { statusedEducationCollections } = cache.readQuery({
        query: GET_EDUCATION_COLLECTION
      })
      cache.writeQuery({
        query: GET_EDUCATION_COLLECTION,
        data: {
          statusedEducationCollections: statusedEducationCollections
            .filter(ec => ec.id !== updateEducationCollectionItem.id)
            .concat(updateEducationCollectionItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Коллекция изменена')
    }
  })
  const [
    deleteEducationCollection,
    { error: errorDeleteEducationCollection }
  ] = useMutation(DELETE_EDUCATION_COLLECTION, {
    update(
      cache,
      { data: { deleteEducationCollection: deleteEducationCollectionItem } }
    ) {
      const { statusedEducationCollections } = cache.readQuery({
        query: GET_EDUCATION_COLLECTION
      })
      cache.writeQuery({
        query: GET_EDUCATION_COLLECTION,
        data: {
          statusedEducationCollections: statusedEducationCollections.filter(
            educationCollection =>
              educationCollection.id !== deleteEducationCollectionItem.id
          )
        }
      })
      hideLoading()
      toast.success('Коллекция удалена')
    }
  })

  React.useEffect(() => {
    if (errorDeleteEducationCollection) {
      console.log(errorDeleteEducationCollection)
    }
  }, [errorDeleteEducationCollection])

  React.useEffect(() => {
    if (errorAddEducationCollection) {
      console.log(errorAddEducationCollection)
    }
  }, [errorAddEducationCollection])

  React.useEffect(() => {
    if (errorUpdateEducationCollection) {
      console.log(errorUpdateEducationCollection)
    }
  }, [errorUpdateEducationCollection])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllEducationCollections(data.statusedEducationCollections)
    }
  }, [data, loading, error])

  const addEducationCollectionHandler = (form, closeFunc) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addEducationCollection({
          variables: {
            input: {
              index: val.index,
              titleKZ: val.titleKZ?.trim(),
              titleRU: val.titleRU?.trim(),
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updateEducationCollectionHandler = (
    form,
    educationCollectionId,
    closeFunc
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateEducationCollection({
          variables: {
            id: educationCollectionId,
            input: {
              index: val.index,
              titleKZ: val.titleKZ?.trim(),
              titleRU: val.titleRU?.trim(),
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const deleteEducationCollectionHandler = (
    educationCollectionId,
    closeFunc
  ) => {
    showLoading()
    deleteEducationCollection({
      variables: {
        id: educationCollectionId
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <EducationCollections
      educationCollections={allEducationCollections}
      addEducationCollection={addEducationCollection}
      refetch={refetch}
      addEducationCollectionHandler={addEducationCollectionHandler}
      updateEducationCollectionHandler={updateEducationCollectionHandler}
      deleteEducationCollectionHandler={deleteEducationCollectionHandler}
    />
  )
}

export default WithMainLayout(EducationCollectionsContainer)
