import React from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import styled from 'styled-components'
import CreateNotificationModal from './CreateNotification.modal'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Notifications = ({
  columns,
  notifications,
  isVisible,
  setIsVisible,
  headingKZ,
  headingRU,
  messageKZ,
  messageRU,
  setMessageKZ,
  setMessageRU,
  setHeadingKZ,
  setHeadingRU,
  type,
  time,
  setTime,
  hours,
  setHours,
  setType,
  handleSendNotification
}) => {
  return (
    <>
      <Table
        dataSource={notifications}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setIsVisible(true)}>
              <PlusOutlined /> Новая нотификация
            </Button>
          </StyledHeaderContainer>
        )}
      />
      {isVisible && (
        <CreateNotificationModal
          {...{
            isVisible,
            setIsVisible,
            headingKZ,
            headingRU,
            messageKZ,
            messageRU,
            setHeadingKZ,
            setHeadingRU,
            setMessageKZ,
            setMessageRU,
            time,
            hours,
            setHours,
            setTime,
            type,
            setType,
            handleSendNotification
          }}
        />
      )}
    </>
  )
}

Notifications.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  headingKZ: PropTypes.string.isRequired,
  headingRU: PropTypes.string.isRequired,
  messageKZ: PropTypes.string.isRequired,
  messageRU: PropTypes.string.isRequired,
  setHeadingKZ: PropTypes.func.isRequired,
  setHeadingRU: PropTypes.func.isRequired,
  setMessageKZ: PropTypes.func.isRequired,
  setMessageRU: PropTypes.func.isRequired,
  time: PropTypes.instanceOf(Date),
  hours: PropTypes.number,
  setTime: PropTypes.func,
  setHours: PropTypes.func,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  handleSendNotification: PropTypes.func.isRequired
}

export default Notifications
