import React from 'react'
import { Form, Input, InputNumber, Button, Image } from 'antd'
import PropTypes from 'prop-types'
import TextArea from 'antd/lib/input/TextArea'

const TraditionsArticleForm = ({
  form,
  order,
  title,
  textShort,
  text,
  storiesImageUrls,
  imageFiles,
  setImageFiles,
  imagePreviews,
  setImagePreviews
}) => {
  const handleAddImage = () => {
    setImageFiles([...imageFiles, ''])
  }

  const handleRemoveImage = index => {
    const newImageFiles = [...imageFiles]
    newImageFiles.splice(index, 1)
    setImageFiles(newImageFiles)
  }

  const handleImageChange = (index, event) => {
    const file = event.target.files[0]
    const newImageFiles = [...imageFiles]
    const newImagePreviews = [...imagePreviews]

    if (file) {
      newImageFiles[index] = file
      newImagePreviews[index] = URL.createObjectURL(file)
    } else {
      newImageFiles[index] = null
      newImagePreviews[index] = null
    }

    setImageFiles(newImageFiles)
    setImagePreviews(newImagePreviews)
  }

  const handleMultipleImageChange = event => {
    const files = Array.from(event.target.files)
    const newImageFiles = [...imageFiles, ...files]
    const newImagePreviews = [
      ...imagePreviews,
      ...files.map(file => URL.createObjectURL(file))
    ]

    setImageFiles(newImageFiles)
    setImagePreviews(newImagePreviews)
  }

  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="order"
        label="Очередность документа"
        name="order"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность документа`
          }
        ]}
        initialValue={order}
      >
        <InputNumber min={0} placeholder="Очередность документа" />
      </FormItem>
      <FormItem
        key="title"
        label="Название"
        name="title"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Статьи`
          }
        ]}
        initialValue={title}
      >
        <Input placeholder="Название" />
      </FormItem>
      <FormItem
        key="textShort"
        label="Краткий текст"
        name="textShort"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Краткий текст Статьи`
          }
        ]}
        initialValue={textShort}
      >
        <Input placeholder="Краткий текст" />
      </FormItem>
      <FormItem
        key="text"
        label="Текст"
        name="text"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите текст Статьи`
          }
        ]}
        initialValue={text}
      >
        <TextArea placeholder="Текст" />
      </FormItem>
      {storiesImageUrls.map((url, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>
          <Image shape="square" size={64} src={url} />
        </div>
      ))}
      {imageFiles.map((file, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>
          <input
            type="file"
            accept="image/*"
            onChange={e => handleImageChange(index, e)}
            style={{ marginRight: '8px' }}
          />
          {imagePreviews[index] && (
            <Image
              width={100}
              src={imagePreviews[index]}
              alt={`preview-${index}`}
              style={{ marginTop: '8px' }}
            />
          )}
          <Button type="danger" onClick={() => handleRemoveImage(index)}>
            Удалить
          </Button>
        </div>
      ))}
      <Button type="dashed" onClick={handleAddImage} style={{ width: '100%' }}>
        Добавить изображение
      </Button>
      <Button type="dashed" style={{ width: '100%', marginTop: '8px' }}>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleMultipleImageChange}
          style={{
            width: '100%',
            height: '100%',
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0
          }}
        />
        Загрузить несколько изображений
      </Button>
    </Form>
  )
}

TraditionsArticleForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  order: PropTypes.number,
  title: PropTypes.string,
  textShort: PropTypes.string,
  text: PropTypes.string,
  storiesImageUrls: PropTypes.arrayOf(PropTypes.string),
  imageFiles: PropTypes.arrayOf(PropTypes.any),
  setImageFiles: PropTypes.func.isRequired,
  imagePreviews: PropTypes.arrayOf(PropTypes.any),
  setImagePreviews: PropTypes.func.isRequired
}

TraditionsArticleForm.defaultProps = {
  order: 0,
  title: '',
  textShort: '',
  text: '',
  storiesImageUrls: [],
  imageFiles: [],
  imagePreviews: []
}

export default TraditionsArticleForm
