import React from 'react'

import { Form, Input, Select, InputNumber } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDivDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CollectionForm = ({
  form,
  id,
  titleKZ,
  titleRU,
  tales,
  status,
  index,
  descriptionKZ,
  descriptionRU,
  setCoverImageFile,
  coverImageFileRef,
  allTales,
  removeFilesHandler
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      {/* <>Обложка</> */}
      <input
        type="hidden"
        ref={coverImageFileRef}
        onChange={event => setCoverImageFile(event.target.files[0])}
      />
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность Сборника"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность Документа`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность" />
      </FormItem>
      {/* <FormItem
        key="title"
        label="Название"
        name="title"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Сборника`
          }
        ]}
        initialValue={title}
      >
        <Input placeholder="Название" />
      </FormItem> */}
      <FormItem
        key="titleKZ"
        label="Название(каз)"
        name="titleKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Коллекции на казахском`
          }
        ]}
        initialValue={titleKZ}
      >
        <Input placeholder="Аты" />
      </FormItem>
      <FormItem
        key="titleRU"
        label="Название(рус)"
        name="titleRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Коллекции на русском`
          }
        ]}
        initialValue={titleRU}
      >
        <Input placeholder="Название" />
      </FormItem>
      <FormItem
        key="tales"
        label="Аудио дорожки"
        name="tales"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите аудио дорожки`
          }
        ]}
        initialValue={tales.map(item => (item ? item.id : item))}
      >
        <Select
          placeholder="Выберите аудио дорожки"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allTales.tales.map(tale => (
            <Select.Option key={tale.id} value={tale.id}>
              {tale.title}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="descriptionKZ"
        label="Описание на казахском"
        name="descriptionKZ"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткое описание`
          }
        ]}
        initialValue={descriptionKZ}
      >
        <Input.TextArea placeholder="Описание..." />
      </FormItem>
      <FormItem
        key="descriptionRU"
        label="Описание на русском"
        name="descriptionRU"
        rules={[
          {
            required: false,
            message: `Пожалуйста, напишите короткое описание`
          }
        ]}
        initialValue={descriptionRU}
      >
        <Input.TextArea placeholder="Описание..." />
      </FormItem>
    </Form>
  )
}

CollectionForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  titleKZ: PropTypes.string,
  titleRU: PropTypes.string
}

CollectionForm.defaultProps = {
  titleKZ: '',
  titleRU: '',
  tales: [],
  status: 'active',
  index: null
}

export default CollectionForm
