import React, { useState, useRef } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
// import Icon from '@ant-design/icons'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import PracticeNumberForm from './PracticeNumberForm'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const PracticeNumbers = ({
  numbers,
  loading,
  addPracticeNumberHandler,
  updatePracticeNumberHandler,
  deletePracticeNumberHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [numberItem, setPracticeNumberItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const [audioFile, setAudioFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const audioFileRef = useRef()
  const imageFileRef = useRef()

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: '',
      width: '1%',
      dataIndex: 'index'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.imageURL} />
          </>
        )
      }
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.audioURL
        if (!audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            <source src={audioLink} type="audio/mpeg" />
          </audio>
        )
      }
    },

    {
      title: 'Цифра',
      dataIndex: 'number',
      width: '10%'
    },
    {
      title: 'План',
      dataIndex: 'restrictions',
      width: '10%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setPracticeNumberItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setPracticeNumberItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={numbers}
        columns={columns}
        rowKey={item => item.id}
        loading={loading}
        pagination={{
          showSizeChanger: false
        }}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая Цифра
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая Цифра"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setImageFile(null)
          audioFileRef.current.value = null
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            if (imageFile !== null) {
              addPracticeNumberHandler(
                form,
                setModalVisible,
                audioFile,
                imageFile,
                setAudioFile,
                setImageFile,
                audioFileRef,
                imageFileRef
              )
            } else {
              message.error('Пожалуйста, выберите Изображение')
            }
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <PracticeNumberForm
          setAudioFile={setAudioFile}
          setImageFile={setImageFile}
          audioFileRef={audioFileRef}
          imageFileRef={imageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setImageFile(null)
            audioFileRef.current.value = null
            imageFileRef.current.value = null
          }}
          onOk={() => {
            updatePracticeNumberHandler(
              formForEdit,
              numberItem.id,
              setEditModal,
              audioFile,
              imageFile,
              setAudioFile,
              setImageFile,
              audioFileRef,
              imageFileRef
            )
          }}
        >
          <PracticeNumberForm
            form={formForEdit}
            index={numberItem.index}
            number={numberItem.number}
            status={numberItem.status}
            restrictions={numberItem.restrictions}
            setAudioFile={setAudioFile}
            setImageFile={setImageFile}
            audioFileRef={audioFileRef}
            imageFileRef={imageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deletePracticeNumberHandler(numberItem.id, setDeleteModal)
          }
        >
          <h3>Вы действительно хотите удалить '{numberItem.number}' ?</h3>
        </Modal>
      )}
    </>
  )
}

PracticeNumbers.propTypes = {
  numbers: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPracticeNumberHandler: PropTypes.func.isRequired,
  updatePracticeNumberHandler: PropTypes.func.isRequired
}

export default PracticeNumbers
