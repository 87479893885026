import React from 'react'
import { Form, Input, Select } from 'antd'
import PropTypes from 'prop-types'

const TraditionsForm = ({
  form,
  titleKaz,
  titleRus,
  setImageFile,
  imageFileRef,
  traditionsArticles,
  selectedTraditionsArticles
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="titleKaz"
        label="Название (каз)"
        name="titleKaz"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название на казахском`
          }
        ]}
        initialValue={titleKaz}
      >
        <Input placeholder="Название (каз)" />
      </FormItem>
      <FormItem
        key="titleRus"
        label="Название (рус)"
        name="titleRus"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название на русском`
          }
        ]}
        initialValue={titleRus}
      >
        <Input placeholder="Название (рус)" />
      </FormItem>
      <FormItem
        key="traditionArticles"
        label="Статьи"
        name="traditionArticles"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите статьи`
          }
        ]}
        initialValue={selectedTraditionsArticles?.map(item =>
          item ? item?.id : item
        )}
      >
        <Select
          placeholder="Выберите статьи"
          mode="multiple"
          showSearch
          optionFilterProp="children"
        >
          {traditionsArticles?.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <div style={{ marginBottom: '3%' }}>
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
      </div>
    </Form>
  )
}

TraditionsForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

export default TraditionsForm
