import React, { useRef, useState } from 'react'
import { Table, Button, Form, Modal, message, Image } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BackgroundImagesForm from './BackgroundImagesForm'
import { useLoading } from '../../../context/useLoading'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const BackgroundImages = ({
  backgroundImages,
  createHandler,
  updateHandler,
  deleteHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [backgroundImageItem, setBackgroundImageItem] = useState(null)
  console.log(backgroundImageItem)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imageFile, setImageFile] = useState(null)
  const imageFileRef = useRef()
  const { hideLoading } = useLoading()

  const columns = [
    {
      title: 'Картинка',
      width: '10%',
      render: item => {
        return (
          <>
            <Image shape="square" size={64} src={item.imageUrl} />
          </>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setBackgroundImageItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setBackgroundImageItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={backgroundImages}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новое изображение
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новое фото"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setImageFile(null)
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (imageFile !== null) {
            createHandler(
              form,
              setModalVisible,
              imageFile,
              setImageFile,
              imageFileRef
            )
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <BackgroundImagesForm
          form={form}
          setImageFile={setImageFile}
          imageFileRef={imageFileRef}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
            setImageFile(null)
            imageFileRef.current.value = null
          }}
          onOk={() => {
            updateHandler(
              formForEdit,
              backgroundImageItem.id,
              setEditModal,
              imageFile,
              setImageFile,
              imageFileRef
            )
          }}
        >
          <BackgroundImagesForm
            form={formForEdit}
            assignedTo={backgroundImageItem.assignedTo}
            setImageFile={setImageFile}
            imageFileRef={imageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(backgroundImageItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить?</h3>
        </Modal>
      )}
    </>
  )
}

BackgroundImages.propTypes = {
  backgroundImages: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default BackgroundImages
