import React from 'react'
import { Table, Button, Form, Modal, Input, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import EducationCollectionForm from './EducationCollectionsForm'

import UpIcon from '../../../assets/icons/Up.svg'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
`

const EducationCollections = ({
  educationCollections,
  addEducationCollectionHandler,
  updateEducationCollectionHandler,
  deleteEducationCollectionHandler
}) => {
  const [form] = Form.useForm()
  const [formForUpdate] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [educationCollectionItem, setEducationCollectionItem] = React.useState(
    null
  )
  const [updateModal, setUpdateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [
    searchEducationCollectionname,
    setSearchEducationCollectionname
  ] = React.useState(null)

  const [
    listEducationCollections,
    setListEducationCollections
  ] = React.useState(null)

  React.useEffect(() => {
    const descriptiveEducationCollections = educationCollections.map(
      educationCollection => {
        return {
          id: educationCollection.id,
          index: educationCollection.index,
          titleKZ: educationCollection.titleKZ,
          titleRU: educationCollection.titleRU,
          status: educationCollection.status,
          restrictions: educationCollection.restrictions
        }
      }
    )
    let searchArray = descriptiveEducationCollections

    if (searchEducationCollectionname) {
      searchArray = searchArray.filter(educationCollection => {
        return (
          educationCollection.titleKZ
            ?.toLowerCase()
            .includes(searchEducationCollectionname) ||
          educationCollection.titleRU
            ?.toLowerCase()
            .includes(searchEducationCollectionname)
        )
      })
    }

    setListEducationCollections(searchArray)
  }, [educationCollections, searchEducationCollectionname])

  const columns = [
    {
      title: '',
      width: '0.001%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: '#',
      dataIndex: 'index',
      width: '1%'
    },
    {
      title: 'Название(каз)',
      dataIndex: 'titleKZ',
      width: '30%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'titleRU',
      width: '30%'
    },
    {
      title: 'План',
      dataIndex: 'restrictions',
      width: '30%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForUpdate.resetFields()
                setUpdateModal(true)
                setEducationCollectionItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setEducationCollectionItem(item)
              }}
            >
              Удалить
            </Button>
            <Link to={`/educationCollections/${item.id}/courses`}>
              <Button style={{ marginLeft: '5%' }}>
                <Icon src={UpIcon} style={{ transform: 'rotate(90deg)' }} />
              </Button>
            </Link>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listEducationCollections}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Коллекция"
              onChange={e => {
                setSearchEducationCollectionname(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая Коллекция
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая Коллекция"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => addEducationCollectionHandler(form, setModalVisible)}
      >
        <EducationCollectionForm form={form} />
      </Modal>
      {updateModal && (
        <Modal
          open={updateModal}
          onCancel={() => {
            setUpdateModal(false)
          }}
          onOk={() =>
            updateEducationCollectionHandler(
              formForUpdate,
              educationCollectionItem.id,
              setUpdateModal
            )
          }
        >
          <EducationCollectionForm
            form={formForUpdate}
            index={educationCollectionItem.index}
            titleKZ={educationCollectionItem.titleKZ}
            titleRU={educationCollectionItem.titleRU}
            status={educationCollectionItem.status}
            restrictions={educationCollectionItem.restrictions}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deleteEducationCollectionHandler(
              educationCollectionItem.id,
              setDeleteModal
            )
          }
        >
          <h3>
            Вы действительно хотите удалить коллекцию "
            {educationCollectionItem.titleKZ} -{' '}
            {educationCollectionItem.titleRU}" ?
          </h3>
        </Modal>
      )}
    </>
  )
}

EducationCollections.propTypes = {
  educationCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  addEducationCollectionHandler: PropTypes.func.isRequired,
  updateEducationCollectionHandler: PropTypes.func.isRequired
}

export default EducationCollections
