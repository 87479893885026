import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import PlaylistsCollections from './PlaylistsCollections'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_PLAYLISTSCOLLECTIONS_BY_INDEX = gql`
  query statusedPlaylistsCollectionsByIndex {
    statusedPlaylistsCollectionsByIndex {
      id
      titleKZ
      titleRU
      index
      playlists {
        id
        titleKZ
        titleRU
        songs {
          title
        }
      }
      categories {
        id
        nameKZ
        nameRU
      }
      status
    }
  }
`

const ADD_PLAYLISTSCOLLECTION = gql`
  mutation addPlaylistsCollection($input: PlaylistsCollectionInput) {
    addPlaylistsCollection(input: $input) {
      id
      titleKZ
      titleRU
      playlists {
        id
        titleKZ
        titleRU
        songs {
          title
        }
      }
      categories {
        id
        nameKZ
        nameRU
      }
      index
      status
    }
  }
`

const UPDATE_PLAYLISTSCOLLECTION = gql`
  mutation updatePlaylistsCollection(
    $id: ID!
    $input: UpdatePlaylistsCollectionInput
  ) {
    updatePlaylistsCollection(id: $id, input: $input) {
      id
      titleKZ
      titleRU
      playlists {
        id
        titleKZ
        titleRU
        songs {
          title
        }
      }
      categories {
        id
        nameKZ
        nameRU
      }
      index
      status
    }
  }
`

// const UPDATE_PLAYLISTSCOLLECTION = gql`
//   mutation updatePlaylistsCollection($id: ID!, $input: PlaylistsCollectionInput) {
//     updatePlaylistsCollection(id: $id, input: $input) {
//       id,
//       title,
//       songs{id,title}
//     }
//   }
// `

// const DELETE_PLAYLISTSCOLLECTION = gql`
//   mutation deletePlaylistsCollection($id: ID!) {
//     deletePlaylistsCollection(id: $id) {
//       id
//       message
//     }
//   }
// `

const DELETE_PLAYLISTSCOLLECTION = gql`
  mutation updatePlaylistsCollection(
    $id: ID!
    $input: UpdatePlaylistsCollectionInput
  ) {
    updatePlaylistsCollection(id: $id, input: $input) {
      id
      titleKZ
      titleRU
      playlists {
        id
        titleKZ
        titleRU
        songs {
          title
        }
      }
      categories {
        id
        nameKZ
        nameRU
      }
      index
      status
    }
  }
`

const GET_PLAYLISTS = gql`
  query playlists {
    playlists {
      id
      titleKZ
      titleRU
      songs {
        title
      }
    }
  }
`

const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      nameKZ
      nameRU
    }
  }
`

const PlaylistsCollectionsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allPlaylistsCollections, setAllPlaylistsCollections] = React.useState(
    []
  )
  const { data, loading, error, refetch } = useQuery(
    GET_PLAYLISTSCOLLECTIONS_BY_INDEX
  )
  const { data: allPlaylists, loading: playlistsLoading } = useQuery(
    GET_PLAYLISTS
  )

  const { data: allCategories, loading: categoriesLoading } = useQuery(
    GET_CATEGORIES
  )

  const [
    addPlaylistsCollection,
    { error: errorAddPlaylistsCollection }
  ] = useMutation(ADD_PLAYLISTSCOLLECTION, {
    update(
      cache,
      { data: { addPlaylistsCollection: addPlaylistsCollectionItem } }
    ) {
      const { statusedPlaylistsCollectionsByIndex } = cache.readQuery({
        query: GET_PLAYLISTSCOLLECTIONS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_PLAYLISTSCOLLECTIONS_BY_INDEX,
        data: {
          statusedPlaylistsCollectionsByIndex: statusedPlaylistsCollectionsByIndex
            .concat(addPlaylistsCollectionItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Коллекция плейлистов добавлена')
    }
  })

  const [
    updatePlaylistsCollection,
    { error: errorUpdatePlaylistsCollection }
  ] = useMutation(UPDATE_PLAYLISTSCOLLECTION, {
    update(
      cache,
      { data: { updatePlaylistsCollection: updatePlaylistsCollectionItem } }
    ) {
      const { statusedPlaylistsCollectionsByIndex } = cache.readQuery({
        query: GET_PLAYLISTSCOLLECTIONS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_PLAYLISTSCOLLECTIONS_BY_INDEX,
        data: {
          statusedPlaylistsCollectionsByIndex: statusedPlaylistsCollectionsByIndex
            .filter(
              playlistsCollection =>
                playlistsCollection.id !== updatePlaylistsCollectionItem.id
            )
            .concat(updatePlaylistsCollectionItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Коллекция плейлистов обновлена')
    }
  })
  const [
    deletePlaylistsCollection,
    { error: errorDeletePlaylistsCollection }
  ] = useMutation(DELETE_PLAYLISTSCOLLECTION, {
    update(
      cache,
      { data: { updatePlaylistsCollection: deletePlaylistsCollectionItem } }
    ) {
      const { statusedPlaylistsCollectionsByIndex } = cache.readQuery({
        query: GET_PLAYLISTSCOLLECTIONS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_PLAYLISTSCOLLECTIONS_BY_INDEX,
        data: {
          statusedPlaylistsCollectionsByIndex: statusedPlaylistsCollectionsByIndex.filter(
            playlistsCollection =>
              playlistsCollection.id !== deletePlaylistsCollectionItem.id
          )
        }
      })
      hideLoading()
      toast.success('Коллекция плейлистов удалена')
    }
  })

  React.useEffect(() => {
    if (errorAddPlaylistsCollection) {
      console.log(errorAddPlaylistsCollection)
    }
  }, [errorAddPlaylistsCollection])

  React.useEffect(() => {
    if (errorDeletePlaylistsCollection) {
      console.log(errorDeletePlaylistsCollection)
    }
  }, [errorDeletePlaylistsCollection])

  React.useEffect(() => {
    if (errorUpdatePlaylistsCollection) {
      console.log(errorUpdatePlaylistsCollection)
    }
  }, [errorUpdatePlaylistsCollection])

  React.useEffect(() => {
    if (data && !error && !loading && !playlistsLoading && !categoriesLoading) {
      setAllPlaylistsCollections(data.statusedPlaylistsCollectionsByIndex)
    }
  }, [data, loading, error, playlistsLoading, categoriesLoading])

  const addPlaylistsCollectionHandler = (form, closeFunc) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addPlaylistsCollection({
          variables: {
            input: {
              titleKZ: val.titleKZ,
              titleRU: val.titleRU,
              playlists: val.playlists,
              categories: val.categories ? val.categories : [],
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updatePlaylistsCollectionHandler = (
    form,
    playlistsCollectionId,
    closeFunc
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updatePlaylistsCollection({
          variables: {
            id: playlistsCollectionId,
            input: {
              titleKZ: val.titleKZ,
              titleRU: val.titleRU,
              playlists: val.playlists,
              categories: val.categories ? val.categories : [],
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const deletePlaylistsCollectionHandler = (
    playlistsCollectionId,
    closeFunc
  ) => {
    showLoading()
    deletePlaylistsCollection({
      variables: {
        id: playlistsCollectionId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading || playlistsLoading || categoriesLoading) {
    return <Loading />
  }

  return (
    <PlaylistsCollections
      playlistsCollections={allPlaylistsCollections}
      allPlaylists={allPlaylists}
      allCategories={allCategories}
      addPlaylistsCollection={addPlaylistsCollection}
      refetch={refetch}
      addPlaylistsCollectionHandler={addPlaylistsCollectionHandler}
      updatePlaylistsCollectionHandler={updatePlaylistsCollectionHandler}
      deletePlaylistsCollectionHandler={deletePlaylistsCollectionHandler}
    />
  )
}

export default WithMainLayout(PlaylistsCollectionsContainer)
