import React from 'react'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useLoading } from '../../context/useLoading'
import UserLevels from './UserLevels'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_LEVELS = gql`
  query userCourseLessonLevel($id: ID!) {
    userCourseLessonLevel(userId: $id) {
      courseId
      userId
      currentLevel
    }
  }
`
const GET_COURSES = gql`
  query educationCollections {
    educationCollections {
      id
      titleRU
      courses {
        id
        topics
        nameRU
      }
    }
  }
`

const EDIT_USER = gql`
  mutation updateUserCourseLevel(
    $userId: ID!
    $courseId: ID!
    $currentLevel: Int
  ) {
    updateUserCourseLevel(
      userId: $userId
      courseId: $courseId
      currentLevel: $currentLevel
    ) {
      courseId
      userId
      currentLevel
    }
  }
`

const UserLevel = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allUserLevels, setAllUserLevels] = React.useState([])
  const { userId } = useParams()
  const { loading: loadingLevels } = useQuery(GET_LEVELS, {
    variables: { id: userId },
    onError: e => console.log('GET_LEVELS', e),
    onCompleted: data => {
      setAllUserLevels(data.userCourseLessonLevel)
      getCourses()
    }
  })

  const [getCourses, { loading: loadingCourses }] = useLazyQuery(GET_COURSES, {
    onError: e => console.log('GET_COURSES', e),
    onCompleted: data => {
      const newCoursesById = []
      data.educationCollections.forEach(collection => {
        collection.courses.forEach(course => {
          const existingCourse = allUserLevels.find(
            item => item.courseId === course.id
          )
          if (existingCourse) {
            newCoursesById.push({ ...existingCourse, nameRU: course.nameRU })
          } else {
            newCoursesById.push({
              courseId: course.id,
              userId: userId,
              currentLevel: 1,
              nameRU: course.nameRU
            })
          }
        })
      })
      setAllUserLevels(newCoursesById)
    }
  })

  const [updateLevel] = useMutation(EDIT_USER, {
    update() {
      window.location.reload()
    }
  })

  const editHandler = (form, userId, courseId, closeFunc) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateLevel({
          variables: {
            userId: userId,
            courseId: courseId,
            currentLevel: val.currentLevel
          }
        })
      })
      .then(() => {
        hideLoading()
        closeFunc(false)
        toast.success('Уровень изменен')
      })
  }

  if (loadingCourses || loadingLevels) {
    return <Loading />
  }

  return <UserLevels userLevels={allUserLevels} editHandler={editHandler} />
}

export default WithMainLayout(UserLevel)
