import React from 'react'
import { Table, Button, Form, Modal } from 'antd'
import PropTypes from 'prop-types'
import UserLevelsForm from './UserLevelsForm'

const UserLevels = ({ userLevels, editHandler }) => {
  const [formForEdit] = Form.useForm()
  const [userLevelItem, setUserLevelItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)

  const columns = [
    {
      title: 'Название обучения',
      dataIndex: 'nameRU',
      width: '25%'
    },
    {
      title: 'Текущий уровень',
      dataIndex: 'currentLevel',
      width: '20%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <Button
          type="link"
          onClick={() => {
            formForEdit.resetFields()
            setEditModal(true)
            setUserLevelItem(item)
          }}
        >
          Редактировать
        </Button>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={userLevels}
        columns={columns}
        rowKey={item => item.id}
      />
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
          }}
          onOk={() =>
            editHandler(
              formForEdit,
              userLevelItem.userId,
              userLevelItem.courseId,
              setEditModal
            )
          }
        >
          <UserLevelsForm
            form={formForEdit}
            currentLevel={userLevelItem.currentLevel}
          />
        </Modal>
      )}
    </>
  )
}

UserLevels.propTypes = {
  userLevels: PropTypes.array.isRequired,
  editHandler: PropTypes.func.isRequired
}

export default UserLevels
