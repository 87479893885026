import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import Icon, { PlusOutlined, SearchOutlined } from '@ant-design/icons'
// import Icon from '@ant-design/icons'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import DigitForm from './DigitForm'
// import Genres from '../shared/Genres'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const StyledAvatar = styled.img``

const Digits = ({
  digits,
  addDigitHandler,
  updateDigitHandler,
  deleteDigitHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [digitItem, setDigitItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchDigit, setSearchDigit] = React.useState(null)

  const [listDigits, setListDigits] = React.useState(null)

  const [audioFile, setAudioFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const audioFileRef = React.useRef()
  const imageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveDigits = digits.map(digit => {
      return {
        id: digit.id,
        index: digit.index,
        digit: digit.digit,
        link2Audio: digit.link2Audio,
        link2Image: digit.link2Image,
        status: digit.status
      }
    })
    let searchArray = descriptiveDigits

    if (searchDigit) {
      searchArray = searchArray.filter(digit => {
        return digit.digit?.toLowerCase().includes(searchDigit)
      })
    }

    setListDigits(searchArray)
  }, [digits, searchDigit])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    // {
    //   title: 'Фото',
    //   width: '5%',
    //   render: (item) => {
    //     return (
    //      <>
    //       <Avatar shape="square" size={64} src={item.photo}/>
    //      </>
    //    );
    //   },
    // },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '2%'
    },
    // {
    //   title: 'Фото',
    //   width: '5%',
    //   render: item => {
    //     return (
    //       <>
    //         <img src={item.link2Image} />
    //       </>
    //     )
    //   }
    // },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.link2Image} />
          </>
        )
      }
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.link2Audio
        if (!audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            <source src={audioLink} type="audio/mpeg" />
          </audio>
        )
      }
    },
    {
      title: 'Цифра',
      dataIndex: 'digit',
      width: '5%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setDigitItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setDigitItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listDigits}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Цифра"
              onChange={e => {
                setSearchDigit(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая цифра
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая цифра"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setImageFile(null)
          audioFileRef.current.value = null
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            if (imageFile !== null) {
              addDigitHandler(
                form,
                setModalVisible,
                audioFile,
                imageFile,
                setAudioFile,
                setImageFile,
                audioFileRef,
                imageFileRef
              )
            } else {
              message.error('Пожалуйста, выберите Изображение')
            }
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <DigitForm
          setAudioFile={setAudioFile}
          setImageFile={setImageFile}
          audioFileRef={audioFileRef}
          imageFileRef={imageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setImageFile(null)
            audioFileRef.current.value = null
            imageFileRef.current.value = null
          }}
          onOk={() => {
            updateDigitHandler(
              formForEdit,
              digitItem.id,
              setEditModal,
              audioFile,
              imageFile,
              setAudioFile,
              setImageFile,
              audioFileRef,
              imageFileRef
            )
          }}
        >
          <DigitForm
            form={formForEdit}
            digit={digitItem.digit}
            index={digitItem.index}
            status={digitItem.status}
            setAudioFile={setAudioFile}
            setImageFile={setImageFile}
            audioFileRef={audioFileRef}
            imageFileRef={imageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteDigitHandler(digitItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить '{digitItem.digit}' ?</h3>
        </Modal>
      )}
    </>
  )
}

Digits.propTypes = {
  digits: PropTypes.arrayOf(PropTypes.object).isRequired,
  addDigitHandler: PropTypes.func.isRequired,
  updateDigitHandler: PropTypes.func.isRequired
}

export default Digits
