import React from 'react'

import { Form, Input, InputNumber, Select } from 'antd'
import './Words.css'

const { Option } = Select

const WordForm = ({
  form,
  word,
  order,
  setAudioFile,
  setImageFile,
  setSecondAudioFile,
  audioFileRef,
  secondAudioFileRef,
  imageFileRef,
  setSyllableAndOrder,
  syllablesList
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="order"
        label="Очередность"
        name="order"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите очередность`
          }
        ]}
        initialValue={order}
      >
        <InputNumber min={1} placeholder="Очередность" />
      </FormItem>
      <div style={{ marginBottom: '3%' }}>
        <>Звучание слова</>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
        <>Второе звучание слова</>
        <input
          type="file"
          accept="audio/*"
          ref={secondAudioFileRef}
          onChange={event => {
            setSecondAudioFile(event.target.files[0])
          }}
        />
        <>Изображение</>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          onChange={event => setImageFile(event.target.files[0])}
        />
      </div>
      <FormItem
        key="word"
        label="Слово"
        name="word"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Слово`
          }
        ]}
        initialValue={word}
      >
        <Input placeholder="Слово" />
      </FormItem>

      <FormItem
        key="syllablesAndOrder"
        label="Слоги и порядок"
        name="syllablesAndOrder"
      >
        <Select
          mode="multiple"
          placeholder="Выберите слоги и укажите порядок"
          style={{ width: '100%' }}
          onChange={selectedSyllables => {
            let order = 0
            const selectedSyllablesAndOrder = selectedSyllables.map(
              syllables => {
                order++
                const syllable = syllablesList.find(
                  syllable => syllable.id === syllables
                )
                return {
                  order,
                  syllable: {
                    id: syllable.id,
                    syllable: syllable.syllable,
                    syllableAudioURL: syllable.syllableAudioURL
                  }
                }
              }
            )
            console.log('selectedSyllablesAndOrder', selectedSyllablesAndOrder)
            setSyllableAndOrder(selectedSyllablesAndOrder)
          }}
        >
          {syllablesList.map(syllable => (
            <Option key={syllable.id} value={syllable.id}>
              {syllable.syllable}
            </Option>
          ))}
        </Select>
      </FormItem>
    </Form>
  )
}

export default WordForm
