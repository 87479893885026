import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import Icon, { PlusOutlined, SearchOutlined } from '@ant-design/icons'
// import Icon from '@ant-design/icons'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import LetterForm from './LetterForm'
// import Genres from '../shared/Genres'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const StyledAvatar = styled.img``

const Letters = ({
  letters,
  addLetterHandler,
  updateLetterHandler,
  deleteLetterHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [letterItem, setLetterItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchLetter, setSearchLetter] = React.useState(null)

  const [listLetters, setListLetters] = React.useState(null)

  const [audioFile, setAudioFile] = useState(null)
  const [secondAudioFile, setSecondAudioFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const audioFileRef = React.useRef()
  const secondAudioFileRef = React.useRef()
  const imageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveLetters = letters.map(letter => {
      return {
        id: letter.id,
        index: letter.index,
        letter: letter.letter,
        word: letter.word,
        link2Audio: letter.link2Audio,
        link2Image: letter.link2Image,
        letterAudioURL: letter.letterAudioURL,
        status: letter.status,
        restrictions: letter.restrictions
      }
    })
    let searchArray = descriptiveLetters

    if (searchLetter) {
      searchArray = searchArray.filter(letter => {
        return letter.letter?.toLowerCase().includes(searchLetter)
      })
    }

    setListLetters(searchArray)
  }, [letters, searchLetter])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    // {
    //   title: 'Фото',
    //   width: '5%',
    //   render: (item) => {
    //     return (
    //      <>
    //       <Avatar shape="square" size={64} src={item.photo}/>
    //      </>
    //    );
    //   },
    // },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '1%'
    },
    // {
    //   title: 'Фото',
    //   width: '5%',
    //   render: item => {
    //     return (
    //       <>
    //         <img src={item.link2Image} />
    //       </>
    //     )
    //   }
    // },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.link2Image} />
          </>
        )
      }
    },
    {
      title: 'Звучание буквы',
      width: '5%',
      render: item => {
        let audioLink = item.letterAudioURL
        if (audioLink) {
          if (!audioLink.startsWith('https://')) {
            audioLink = 'https://' + audioLink
          }
          return (
            <audio controls="controls">
              <source src={audioLink} type="audio/mpeg" />
            </audio>
          )
        }
        return
      }
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.link2Audio
        if (!audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            <source src={audioLink} type="audio/mpeg" />
          </audio>
        )
      }
    },
    {
      title: 'Буква',
      dataIndex: 'letter',
      width: '5%'
    },
    {
      title: 'Слово',
      dataIndex: 'word',
      width: '10%'
    },
    {
      title: 'План',
      dataIndex: 'restrictions',
      width: '10%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setLetterItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setLetterItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listLetters}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Буква"
              onChange={e => {
                setSearchLetter(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая буква
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая буква"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setImageFile(null)
          audioFileRef.current.value = null
          secondAudioFileRef.current.value = null
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            if (imageFile !== null) {
              addLetterHandler(
                form,
                setModalVisible,
                audioFile,
                secondAudioFile,
                imageFile,
                setAudioFile,
                setSecondAudioFile,
                setImageFile,
                audioFileRef,
                secondAudioFileRef,
                imageFileRef
              )
            } else {
              message.error('Пожалуйста, выберите Изображение')
            }
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <LetterForm
          setAudioFile={setAudioFile}
          setImageFile={setImageFile}
          setSecondAudioFile={setSecondAudioFile}
          audioFileRef={audioFileRef}
          secondAudioFileRef={secondAudioFileRef}
          imageFileRef={imageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setImageFile(null)
            secondAudioFileRef.current.value = null
            audioFileRef.current.value = null
            imageFileRef.current.value = null
          }}
          onOk={() => {
            updateLetterHandler(
              formForEdit,
              letterItem.id,
              setEditModal,
              audioFile,
              secondAudioFile,
              imageFile,
              setAudioFile,
              setSecondAudioFile,
              setImageFile,
              audioFileRef,
              secondAudioFileRef,
              imageFileRef
            )
          }}
        >
          <LetterForm
            form={formForEdit}
            letter={letterItem.letter}
            word={letterItem.word}
            index={letterItem.index}
            status={letterItem.status}
            restrictions={letterItem.restrictions}
            setAudioFile={setAudioFile}
            setSecondAudioFile={setSecondAudioFile}
            setImageFile={setImageFile}
            secondAudioFileRef={secondAudioFileRef}
            audioFileRef={audioFileRef}
            imageFileRef={imageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteLetterHandler(letterItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить '{letterItem.letter}' ?</h3>
        </Modal>
      )}
    </>
  )
}

Letters.propTypes = {
  letters: PropTypes.arrayOf(PropTypes.object).isRequired,
  addLetterHandler: PropTypes.func.isRequired,
  updateLetterHandler: PropTypes.func.isRequired
}

export default Letters
