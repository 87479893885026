import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Image, Avatar } from 'antd'
import {
  PlusOutlined,
  SearchOutlined,
  LinkOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'
import moment from 'moment'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import StoryForm from './StoryForm'
// import Genres from '../shared/Genres'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const StyledImage = styled(Image)`
  max-height: 200px;
`

const Stories = ({
  stories,
  addStoryHandler,
  updateStoryHandler,
  deleteStoryHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [storyItem, setStoryItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchStory, setSearchStory] = React.useState(null)

  const [listStories, setListStories] = React.useState(null)

  const [isCombinedState, setIsCombinedState] = React.useState(true)

  const [imageFile, setImageFile] = useState(null)
  const [imageRUFile, setImageRUFile] = useState(null)
  const imageFileRef = React.useRef()
  const imageRUFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveStories = stories.map(story => {
      return {
        id: story.id,
        date: moment(story.date),
        header: story.header,
        headerRU: story.headerRU,
        link2Image: story.link2Image,
        link2ImageRU: story.link2ImageRU,
        isCombined: story.isCombined,
        link: story.link,
        linkDescription: story.linkDescription,
        linkDescriptionRU: story.linkDescriptionRU,
        status: story.status
      }
    })
    let searchArray = descriptiveStories

    if (searchStory) {
      searchArray = searchArray.filter(story => {
        return story.header?.toLowerCase().includes(searchStory)
      })
    }

    setListStories(searchArray)
  }, [stories, searchStory])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Заголовок',
      dataIndex: 'header',
      width: '1%'
    },
    {
      title: '',
      width: '5%',
      render: item => {
        return (
          <>
            <StyledImage src={item.link2Image} />
          </>
        )
      }
    },
    {
      title: 'RU',
      dataIndex: 'headerRU',
      width: '1%'
    },
    {
      title: '',
      width: '5%',
      render: item => {
        return (
          <>
            <StyledImage src={item.link2ImageRU} />
          </>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setStoryItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setStoryItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listStories}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Title"
              onChange={e => {
                setSearchStory(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новоя история
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая история"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setIsCombinedState(true)
          setImageFile(null)
          setImageRUFile(null)
          imageFileRef?.current?.value && (imageFileRef.current.value = null)
          imageRUFileRef?.current?.value &&
            (imageRUFileRef.current.value = null)
        }}
        onOk={() => {
          if (imageFile !== null && (isCombinedState || imageRUFile)) {
            addStoryHandler(
              form,
              setModalVisible,
              setIsCombinedState,
              imageFile,
              imageRUFile,
              setImageFile,
              setImageRUFile,
              imageFileRef,
              imageRUFileRef
            )
          } else {
            message.error('Пожалуйста, выберите изображение')
          }
        }}
      >
        <StoryForm
          setImageFile={setImageFile}
          setImageRUFile={setImageRUFile}
          imageFileRef={imageFileRef}
          imageRUFileRef={imageRUFileRef}
          isCombinedState={isCombinedState}
          setIsCombinedState={setIsCombinedState}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setIsCombinedState(true)
            setImageFile(null)
            setImageRUFile(null)
            imageFileRef?.current?.value && (imageFileRef.current.value = null)
            imageRUFileRef?.current?.value &&
              (imageRUFileRef.current.value = null)
          }}
          onOk={() => {
            console.log('isCombinedState', isCombinedState)
            if (isCombinedState || storyItem.link2ImageRU || imageRUFile) {
              updateStoryHandler(
                formForEdit,
                storyItem.id,
                setEditModal,
                setIsCombinedState,
                imageFile,
                imageRUFile,
                setImageFile,
                setImageRUFile,
                imageFileRef,
                imageRUFileRef
              )
            } else {
              message.error('Пожалуйста, выберите изображение на русском')
            }
          }}
        >
          <StoryForm
            form={formForEdit}
            id={storyItem.id}
            date={storyItem.date}
            isCombined={storyItem.isCombined}
            header={storyItem.header}
            headerRU={storyItem.headerRU}
            link={storyItem.link}
            linkDescription={storyItem.linkDescription}
            linkDescriptionRU={storyItem.linkDescriptionRU}
            status={storyItem.status}
            isCombinedState={isCombinedState}
            setIsCombinedState={setIsCombinedState}
            setImageFile={setImageFile}
            setImageRUFile={setImageRUFile}
            imageFileRef={imageFileRef}
            imageRUFileRef={imageRUFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteStoryHandler(storyItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {storyItem.header}?</h3>
        </Modal>
      )}
    </>
  )
}

Stories.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  addStoryHandler: PropTypes.func.isRequired,
  updateStoryHandler: PropTypes.func.isRequired
}

export default Stories
