import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Creators from './Creators'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_CREATORS_BY_INDEX = gql`
  query statusedCreatorsByIndex {
    statusedCreatorsByIndex {
      id
      name
      photo
      dateOfBirth
      dateOfDeath
      bioKZ
      bioRU
      types
      status
      index
    }
  }
`

const ADD_CREATOR = gql`
  mutation addCreator($photoFile: FileUpload, $input: CreatorInput) {
    addCreator(photoFile: $photoFile, input: $input) {
      id
      name
      photo
      dateOfBirth
      dateOfDeath
      bioKZ
      bioRU
      types
      status
      index
    }
  }
`

const UPDATE_CREATOR = gql`
  mutation updateCreator(
    $id: ID!
    $photoFile: FileUpload
    $input: UpdateCreatorInput
  ) {
    updateCreator(id: $id, photoFile: $photoFile, input: $input) {
      id
      name
      photo
      dateOfBirth
      dateOfDeath
      bioKZ
      bioRU
      types
      status
      index
    }
  }
`

// const DELETE_CREATOR = gql`
//   mutation deleteCreator($id: ID!) {
//     deleteCreator(id: $id) {
//       id
//       message
//     }
//   }
// `

const DELETE_CREATOR = gql`
  mutation updateCreator($id: ID!, $input: UpdateCreatorInput) {
    updateCreator(id: $id, input: $input) {
      id
      name
      photo
      dateOfBirth
      dateOfDeath
      bioKZ
      bioRU
      types
      status
    }
  }
`

const CreatorsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allCreators, setAllCreators] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_CREATORS_BY_INDEX)
  const [addCreator, { error: errorAddCreator }] = useMutation(ADD_CREATOR, {
    update(cache, { data: { addCreator: addCreatorItem } }) {
      const { statusedCreatorsByIndex } = cache.readQuery({
        query: GET_CREATORS_BY_INDEX
      })
      cache.writeQuery({
        query: GET_CREATORS_BY_INDEX,
        data: {
          statusedCreatorsByIndex: [addCreatorItem].concat(
            statusedCreatorsByIndex
          )
        }
      })
      hideLoading()
      toast.success('Автор добавлен')
    }
  })
  // const [updateCreator, { data: updateCreatorData }] = useMutation(
  //   UPDATE_CREATOR
  // )

  const [updateCreator, { error: errorUpdateCreator }] = useMutation(
    UPDATE_CREATOR,
    {
      update(cache, { data: { updateCreator: updateCreatorItem } }) {
        const { statusedCreatorsByIndex } = cache.readQuery({
          query: GET_CREATORS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_CREATORS_BY_INDEX,
          data: {
            statusedCreatorsByIndex: statusedCreatorsByIndex
              .filter(creator => creator.id !== updateCreatorItem.id)
              .concat(updateCreatorItem)
              .sort((a, b) => {
                // return a?.index < b?.index ? -1 : b?.index > a?.index ? 1 : 0

                // console.log('a index name', a.index, a.name)
                // console.log('b index name', b.index, b.name)
                // if (a.index && b.index)
                //   return a.index < b.index ? -1 : a.index > b.index ? 1 : 0
                // else if (a.index === null && b.index) return -1
                // else if (a.index && b.index === null) return 1
                // else return 0
                return a.index && b.index
                  ? a.index < b.index
                    ? -1
                    : b.index > a.index
                    ? 1
                    : 0
                  : a.index && b.index === null
                  ? -1
                  : a.index === null && b.index
                  ? 1
                  : 0
              })
          }
        })
        hideLoading()
        toast.success('Автор обновлен')
      }
    }
  )

  const [deleteCreator, { error: errorDeleteCreator }] = useMutation(
    DELETE_CREATOR,
    {
      update(cache, { data: { updateCreator: deleteCreatorItem } }) {
        const { statusedCreatorsByIndex } = cache.readQuery({
          query: GET_CREATORS_BY_INDEX
        })
        cache.writeQuery({
          query: GET_CREATORS_BY_INDEX,
          data: {
            statusedCreatorsByIndex: statusedCreatorsByIndex.filter(
              creator => creator.id !== deleteCreatorItem.id
            )
          }
        })
        hideLoading()
        toast.success('Автор удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorDeleteCreator) {
      console.log(errorDeleteCreator)
    }
  }, [errorDeleteCreator])

  React.useEffect(() => {
    if (errorAddCreator) {
      console.log(errorAddCreator)
    }
  }, [errorAddCreator])

  React.useEffect(() => {
    if (errorUpdateCreator) {
      console.log(errorUpdateCreator)
    }
  }, [errorUpdateCreator])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllCreators(data.statusedCreatorsByIndex)
    }
  }, [data, loading, error])

  const addCreatorHandler = (form, closeFunc, file) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addCreator({
          variables: {
            photoFile: file,
            input: {
              name: val.name,
              dateOfBirth: val.dateOfBirth,
              dateOfDeath: val.dateOfDeath,
              bioKZ: val.bioKZ?.length ? val.bioKZ : null,
              bioRU: val.bioRU?.length ? val.bioRU : null,
              types: val.types ? val.types : [],
              status: val.status,
              index: val.indexe
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updateCreatorHandler = (form, creatorId, closeFunc, file) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateCreator({
          variables: {
            id: creatorId,
            photoFile: file,
            input: {
              name: val.name,
              dateOfBirth: val.dateOfBirth,
              dateOfDeath: val.dateOfDeath,
              bioKZ: val.bioKZ,
              bioRU: val.bioRU,
              types: val.types,
              status: val.status,
              index: val.index
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const deleteCreatorHandler = (creatorId, closeFunc) => {
    showLoading()
    deleteCreator({
      variables: {
        id: creatorId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  const removeFilesHandler = creatorId => {
    updateCreator({
      variables: {
        id: creatorId,
        input: {
          photo: null
        }
      }
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Creators
      creators={allCreators}
      addCreator={addCreator}
      refetch={refetch}
      addCreatorHandler={addCreatorHandler}
      updateCreatorHandler={updateCreatorHandler}
      deleteCreatorHandler={deleteCreatorHandler}
      removeFilesHandler={removeFilesHandler}
    />
  )
}

export default WithMainLayout(CreatorsContainer)
