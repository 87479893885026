import React, { useState, useEffect } from 'react'
import { Button, message, Image, Select } from 'antd'
import Icon, { PlusOutlined, SearchOutlined } from '@ant-design/icons'
// import Icon from '@ant-design/icons'
import styled from 'styled-components'

import PropTypes from 'prop-types'

const SubDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
  margin-left: 25px;
`

const EducationTemp = ({ uploadEducationImageHandler }) => {
  const { Option } = Select
  const [name, setName] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const imageFileRef = React.useRef()

  const [preview, setPreview] = useState()

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!imageFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(imageFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [imageFile])

  const onChange = value => {
    // console.log(`selected ${value}`)
    setName(value)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SubDiv>
          <Image
            width={350}
            src="https://education-temp.object.pscloud.io/education.png"
          />
          <>См. номер раздела на изображении</>
        </SubDiv>
        <SubDiv
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <>Номер раздела</>
          <Select
            value={name}
            placeholder="*-*"
            optionFilterProp="children"
            onChange={onChange}
          >
            <Option value="1-1">1-1</Option>
            <Option value="1-2">1-2</Option>
            <Option value="1-3">1-3</Option>
            <Option value="1-4">1-4</Option>
            <Option value="1-5">1-5</Option>
            <Option value="2-1">2-1</Option>
            <Option value="2-2">2-2</Option>
            <Option value="2-3">2-3</Option>
            <Option value="2-4">2-4</Option>
            <Option value="2-5">2-5</Option>
            <Option value="3-1">3-1</Option>
            <Option value="3-2">3-2</Option>
            <Option value="3-3">3-3</Option>
            <Option value="3-4">3-4</Option>
            <Option value="3-5">3-5</Option>
            <Option value="4-1">4-1</Option>
            <Option value="4-2">4-2</Option>
            <Option value="4-3">4-3</Option>
            <Option value="4-4">4-4</Option>
            <Option value="4-5">4-5</Option>
            <Option value="5-1">5-1</Option>
            <Option value="5-2">5-2</Option>
            <Option value="5-3">5-3</Option>
            <Option value="5-4">5-4</Option>
            <Option value="5-5">5-5</Option>
          </Select>
        </SubDiv>
        <SubDiv>
          <input
            type="file"
            accept="image/*"
            ref={imageFileRef}
            onChange={event => setImageFile(event.target.files[0])}
          />
        </SubDiv>
        <SubDiv>
          {imageFile && (
            <>
              <img src={preview} style={{ marginBottom: 50 }} />
              <Button
                onClick={() => {
                  if (name) {
                    uploadEducationImageHandler(
                      name,
                      setName,
                      imageFile,
                      setImageFile,
                      imageFileRef
                    )
                  } else {
                    message.error('Пожалуйста выберите раздел!')
                  }
                }}
              >
                Загрузить
              </Button>
            </>
          )}
        </SubDiv>
      </div>
    </>
  )
}

EducationTemp.propTypes = {
  uploadEducationImageHandler: PropTypes.func.isRequired
}

export default EducationTemp
