import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Instruments from './Instruments'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_INSTRUMENTS = gql`
  query statusedInstruments {
    statusedInstruments {
      id
      nameKZ
      nameRU
      descriptionKZ
      descriptionRU
      photo
      status
      index
    }
  }
`

const ADD_INSTRUMENT = gql`
  mutation addInstrument($photoFile: FileUpload!, $input: InstrumentInput) {
    addInstrument(photoFile: $photoFile, input: $input) {
      id
      nameKZ
      nameRU
      descriptionKZ
      descriptionRU
      photo
      status
      index
    }
  }
`

const UPDATE_INSTRUMENT = gql`
  mutation updateInstrument(
    $id: ID!
    $photoFile: FileUpload
    $input: UpdateInstrumentInput
  ) {
    updateInstrument(id: $id, photoFile: $photoFile, input: $input) {
      id
      nameKZ
      nameRU
      descriptionKZ
      descriptionRU
      photo
      status
      index
    }
  }
`

const DELETE_INSTRUMENT = gql`
  mutation updateInstrument($id: ID!, $input: UpdateInstrumentInput) {
    updateInstrument(id: $id, input: $input) {
      id
      nameKZ
      nameRU
      descriptionKZ
      descriptionRU
      photo
      status
      index
    }
  }
`

const InstrumentsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allInstruments, setAllInstruments] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_INSTRUMENTS)
  const [addInstrument, { error: errorAddInstrument }] = useMutation(
    ADD_INSTRUMENT,
    {
      update(cache, { data: { addInstrument: addInstrumentItem } }) {
        const { statusedInstruments } = cache.readQuery({
          query: GET_INSTRUMENTS
        })
        cache.writeQuery({
          query: GET_INSTRUMENTS,
          data: {
            statusedInstruments: [addInstrumentItem].concat(statusedInstruments)
          }
        })
        hideLoading()
        toast.success('Инструмент добавлен')
      }
    }
  )
  // const [updateInstrument, { data: updateInstrumentData }] = useMutation(
  //   UPDATE_INSTRUMENT
  // )
  const [updateInstrument, { error: errorUpdateInstrument }] = useMutation(
    UPDATE_INSTRUMENT,
    {
      update(cache, { data: { updateInstrument: updateInstrumentItem } }) {
        const { statusedInstruments } = cache.readQuery({
          query: GET_INSTRUMENTS
        })
        cache.writeQuery({
          query: GET_INSTRUMENTS,
          data: {
            statusedInstruments: statusedInstruments
              .filter(instrument => instrument.id !== updateInstrumentItem.id)
              .concat(updateInstrumentItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Инструмент обновлен')
      }
    }
  )
  const [deleteInstrument, { error: errorDeleteInstrument }] = useMutation(
    DELETE_INSTRUMENT,
    {
      update(cache, { data: { updateInstrument: deleteInstrumentItem } }) {
        const { statusedInstruments } = cache.readQuery({
          query: GET_INSTRUMENTS
        })
        cache.writeQuery({
          query: GET_INSTRUMENTS,
          data: {
            statusedInstruments: statusedInstruments.filter(
              instrument => instrument.id !== deleteInstrumentItem.id
            )
          }
        })
        hideLoading()
        toast.success('Инструмент удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorDeleteInstrument) {
      console.log(errorDeleteInstrument)
    }
  }, [errorDeleteInstrument])

  React.useEffect(() => {
    if (errorAddInstrument) {
      console.log(errorAddInstrument)
    }
  }, [errorAddInstrument])

  React.useEffect(() => {
    if (errorUpdateInstrument) {
      console.log(errorUpdateInstrument)
    }
  }, [errorUpdateInstrument])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllInstruments(data.statusedInstruments)
    }
  }, [data, loading, error])

  const addInstrumentHandler = (form, closeFunc, file, setFile, fileRef) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addInstrument({
          variables: {
            photoFile: file,
            input: {
              nameKZ: val.nameKZ,
              nameRU: val.nameRU,
              descriptionKZ: val.descriptionKZ?.length
                ? val.descriptionKZ
                : null,
              descriptionRU: val.descriptionRU?.length
                ? val.descriptionRU
                : null,
              status: val.status,
              index: val.index
            }
          }
        })
      })
      .then(() => {
        setFile(null)
        fileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateInstrumentHandler = (
    form,
    instrumentId,
    closeFunc,
    file,
    setFile,
    fileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateInstrument({
          variables: {
            id: instrumentId,
            photoFile: file,
            input: {
              nameKZ: val.nameKZ,
              nameRU: val.nameRU,
              descriptionKZ: val.descriptionKZ?.length
                ? val.descriptionKZ
                : null,
              descriptionRU: val.descriptionRU?.length
                ? val.descriptionRU
                : null,
              status: val.status,
              index: val.index
            }
          }
        })
      })
      .then(() => {
        setFile(null)
        fileRef.current.value = null
        // hideLoading()
        closeFunc(false)
        // toast.success('Инструмент изменен')
      })
  }

  const deleteInstrumentHandler = (instrumentId, closeFunc) => {
    showLoading()
    deleteInstrument({
      variables: {
        id: instrumentId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Instruments
      instruments={allInstruments}
      addInstrument={addInstrument}
      refetch={refetch}
      addInstrumentHandler={addInstrumentHandler}
      updateInstrumentHandler={updateInstrumentHandler}
      deleteInstrumentHandler={deleteInstrumentHandler}
    />
  )
}

export default WithMainLayout(InstrumentsContainer)
