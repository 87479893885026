import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import PracticeFigure from './PracticeFigure'
import WithMainLayout from '../../../hocs/withMainLayout'

const GET_PRACTICE_FIGURES = gql`
  query statusedPracticeFiguresByIndex {
    statusedPracticeFiguresByIndex {
      id
      index
      name
      audioURL
      imageURL
      imageMinURL
      objectItemURL
      imageBucketURL
      status
      restrictions
    }
  }
`

const ADD_PRACTICE_FIGURE = gql`
  mutation addPracticeFigure(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $imageMin: FileUpload!
    $objectItem: FileUpload!
    $imageBucket: FileUpload!
    $input: PracticeFigureInput!
  ) {
    addPracticeFigure(
      audioFile: $audioFile
      imageFile: $imageFile
      imageMin: $imageMin
      objectItem: $objectItem
      imageBucket: $imageBucket
      input: $input
    ) {
      id
      index
      name
      audioURL
      imageURL
      imageMinURL
      objectItemURL
      imageBucketURL
      status
      restrictions
    }
  }
`

const UPDATE_PRACTICE_FIGURE = gql`
  mutation updatePracticeFigure(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $imageMin: FileUpload
    $objectItem: FileUpload
    $imageBucket: FileUpload
    $input: UpdatePracticeFigureInput
  ) {
    updatePracticeFigure(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      imageMin: $imageMin
      objectItem: $objectItem
      imageBucket: $imageBucket
      input: $input
    ) {
      id
      index
      name
      audioURL
      imageURL
      objectItemURL
      imageBucketURL
      status
      restrictions
    }
  }
`

const DELETE_PRACTICE_FIGURE = gql`
  mutation deletePracticeFigure($id: ID!) {
    deletePracticeFigure(id: $id) {
      id
      message
    }
  }
`

const FiguresContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allFigures, setAllFigures] = React.useState([])
  const { data, loading, error } = useQuery(GET_PRACTICE_FIGURES)
  const [addPracticeFigure, { error: errorAddPracticeFigure }] = useMutation(
    ADD_PRACTICE_FIGURE,
    {
      update(cache, { data: { addPracticeFigure: addPracticeFigureItem } }) {
        const { statusedPracticeFiguresByIndex } = cache.readQuery({
          query: GET_PRACTICE_FIGURES
        })
        cache.writeQuery({
          query: GET_PRACTICE_FIGURES,
          data: {
            statusedPracticeFiguresByIndex: statusedPracticeFiguresByIndex
              .concat(addPracticeFigureItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Фируга практики добавлена')
      }
    }
  )
  const [
    updatePracticeFigure,
    { error: errorUpdatePracticeFigure }
  ] = useMutation(UPDATE_PRACTICE_FIGURE, {
    update(
      cache,
      { data: { updatePracticeFigure: updatePracticeFigureItem } }
    ) {
      const { statusedPracticeFiguresByIndex } = cache.readQuery({
        query: GET_PRACTICE_FIGURES
      })
      cache.writeQuery({
        query: GET_PRACTICE_FIGURES,
        data: {
          statusedPracticeFiguresByIndex: statusedPracticeFiguresByIndex
            .filter(i => i.id !== updatePracticeFigureItem.id)
            .concat(updatePracticeFigureItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Фируга практики обновлена')
    }
  })
  const [
    deletePracticeFigure,
    { error: errorDeletePracticeFigure }
  ] = useMutation(DELETE_PRACTICE_FIGURE, {
    update(
      cache,
      { data: { deletePracticeFigure: deletePracticeFigureItem } }
    ) {
      const { statusedPracticeFiguresByIndex } = cache.readQuery({
        query: GET_PRACTICE_FIGURES
      })
      cache.writeQuery({
        query: GET_PRACTICE_FIGURES,
        data: {
          statusedPracticeFiguresByIndex: statusedPracticeFiguresByIndex.filter(
            i => i.id !== deletePracticeFigureItem.id
          )
        }
      })
      hideLoading()
      toast.success('Фигура практики удалена')
    }
  })

  React.useEffect(() => {
    if (errorAddPracticeFigure) {
      console.log(errorAddPracticeFigure)
    }
  }, [errorAddPracticeFigure])

  React.useEffect(() => {
    if (errorDeletePracticeFigure) {
      console.log(errorDeletePracticeFigure)
    }
  }, [errorDeletePracticeFigure])

  React.useEffect(() => {
    if (errorUpdatePracticeFigure) {
      console.log(errorUpdatePracticeFigure)
    }
  }, [errorUpdatePracticeFigure])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllFigures(data.statusedPracticeFiguresByIndex)
    }
  }, [data, loading, error])

  const addPracticeFigureHandler = (
    form,
    closeFunc,
    audioFile,
    setAudioFile,
    audioFileRef,
    imageFile,
    imageFileRef,
    setImageFile,
    imageMin,
    imageMinRef,
    setImageMin,
    objectItem,
    objectItemRef,
    setObjectItem,
    imageBucket,
    imageBucketRef,
    setImageBucket
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addPracticeFigure({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            imageMin: imageMin,
            objectItem: objectItem,
            imageBucket: imageBucket,
            input: {
              index: val.index,
              name: val.name?.trim(),
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        setImageMin(null)
        setObjectItem(null)
        setImageBucket(null)
        audioFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updatePracticeFigureHandler = (
    form,
    figureId,
    closeFunc,
    audioFile,
    setAudioFile,
    audioFileRef,
    imageFile,
    setImageFile,
    imageFileRef,
    imageMin,
    setImageMin,
    imageMinRef,
    objectItem,
    setObjectItem,
    objectItemRef,
    imageBucket,
    setImageBucket,
    imageBucketRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updatePracticeFigure({
          variables: {
            id: figureId,
            audioFile,
            imageFile,
            imageMin,
            objectItem,
            imageBucket,
            input: {
              index: val.index,
              name: val.name?.trim(),
              status: val.status,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setImageFile(null)
        setObjectItem(null)
        setImageBucket(null)
        setImageMin(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        imageMinRef.current.value = null
        objectItemRef.current.value = null
        imageBucketRef.current.value = null
        closeFunc(false)
        toast.success('Фигура обновлена')
      })
  }

  const deletePracticeFigureHandler = (figureId, closeFunc) => {
    showLoading()
    deletePracticeFigure({
      variables: {
        id: figureId
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  return (
    <PracticeFigure
      figures={allFigures}
      loading={loading}
      addPracticeFigureHandler={addPracticeFigureHandler}
      updatePracticeFigureHandler={updatePracticeFigureHandler}
      deletePracticeFigureHandler={deletePracticeFigureHandler}
    />
  )
}

export default WithMainLayout(FiguresContainer)
