import React from 'react'
import { gql, useQuery } from '@apollo/client'

import { useLoading } from '../../context/useLoading'
import Dashboard from './Dashboard'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const DASHBOARD_DATA = gql`
  query dashboardData {
    dashboardData {
      users {
        favQaraSozs {
          id
        }
        favSongs {
          id
        }
        favTales {
          id
        }
      }
      contentAudios {
        id
        title
        coverImage
        authors {
          name
        }
        composers {
          name
        }
        voiceActors {
          name
        }
        cntRating
        amntRating
        status
      }
    }
  }
`

const DashboardContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allAudios, setAllAudios] = React.useState([])
  const { data, loading, error, refetch } = useQuery(DASHBOARD_DATA)

  React.useEffect(() => {
    showLoading()
    if (data && !error && !loading) {
      const favAudiosCount = {}
      // console.log('users', data.dashboardData.users)
      data.dashboardData.users.forEach(user => {
        // favAudiosCount[element.id] = favAudiosCount[element.id] ? favAudiosCount[element.id]+1 : 0
        user.favSongs.forEach(song => {
          favAudiosCount[song.id] = favAudiosCount[song.id]
            ? favAudiosCount[song.id] + 1
            : 1
        })
        user.favTales.forEach(tale => {
          favAudiosCount[tale.id] = favAudiosCount[tale.id]
            ? favAudiosCount[tale.id] + 1
            : 1
        })
        user.favQaraSozs.forEach(qaraSoz => {
          favAudiosCount[qaraSoz.id] = favAudiosCount[qaraSoz.id]
            ? favAudiosCount[qaraSoz.id] + 1
            : 1
        })
      })
      const audioList = data.dashboardData.contentAudios.map(audio => ({
        ...audio,
        cntAddedFav: favAudiosCount[audio.id] ? favAudiosCount[audio.id] : 0
      }))
      setAllAudios(audioList)
    }
    hideLoading()
  }, [data, loading, error])

  if (loading) {
    return <Loading />
  }

  return <Dashboard allAudios={allAudios} refetch={refetch} />
}

export default WithMainLayout(DashboardContainer)
