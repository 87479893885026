import React from 'react'
import { Form, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import TextArea from 'antd/lib/input/TextArea'

const ProverbsForm = ({
  form,
  text,
  status,
  type,
  index,
  setAudioFile,
  audioFileRef
}) => {
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите статус" mode="single">
          <Select.Option key="active" value="active">
            Активный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="text"
        label="Текст"
        name="text"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите текст`
          }
        ]}
        initialValue={text}
      >
        <TextArea placeholder="Текст" />
      </FormItem>
      <div style={{ marginBottom: '3%' }}>
        <>Аудио</>
        <input
          type="file"
          accept="audio/*"
          ref={audioFileRef}
          onChange={event => {
            setAudioFile(event.target.files[0])
          }}
        />
      </div>
      <FormItem
        key="type"
        label="Тип"
        name="type"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите тип`
          }
        ]}
        initialValue={type}
      >
        <Select placeholder="Выберите тип" mode="single">
          <Select.Option key="makal" value="makal">
            Пословица (Макал)
          </Select.Option>
          <Select.Option key="matel" value="matel">
            Поговорка (Мател)
          </Select.Option>
        </Select>
      </FormItem>
    </Form>
  )
}

ProverbsForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

export default ProverbsForm
