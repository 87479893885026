import React from 'react'
import { Table, Button, Form, Modal, message, Input, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TraditionsCollectionsForm from './TraditionsCollectionsForm'
import { useLoading } from '../../../context/useLoading'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Traditions = ({
  traditionsCollections,
  traditions,
  createTraditionsCollectionHandler,
  updateTraditionsCollectionHandler,
  deleteTraditionsCollectionHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [
    traditionsCollectionItem,
    setTraditionsCollectionItem
  ] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [
    searchTraditionCollection,
    setSearchTraditionCollection
  ] = React.useState(null)
  const [
    listTraditionsCollections,
    setListTraditionsCollections
  ] = React.useState(null)
  const [imageFile, setImageFile] = React.useState(null)
  const imageFileRef = React.useRef()
  const { hideLoading } = useLoading()

  React.useEffect(() => {
    if (searchTraditionCollection) {
      const searchArray = traditionsCollections.filter(tradition => {
        return (
          tradition.titleRus
            ?.toLowerCase()
            .includes(searchTraditionCollection) ||
          tradition.titleKaz?.toLowerCase().includes(searchTraditionCollection)
        )
      })
      setListTraditionsCollections(searchArray)
    } else {
      setListTraditionsCollections(traditionsCollections)
    }
  }, [traditionsCollections, searchTraditionCollection])

  const columns = [
    {
      title: 'Название (каз)',
      dataIndex: 'titleKaz',
      width: '15%'
    },
    {
      title: 'Название (рус)',
      dataIndex: 'titleRus',
      width: '15%'
    },
    {
      title: 'Картинка',
      width: '10%',
      render: item => {
        return (
          <>
            <Image shape="square" size={64} src={item.imageUrl} />
          </>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setTraditionsCollectionItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setTraditionsCollectionItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listTraditionsCollections}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchTraditionCollection(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая коллекция традиций
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая коллекция традиций"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setImageFile(null)
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (imageFile !== null) {
            createTraditionsCollectionHandler(
              form,
              setModalVisible,
              imageFile,
              setImageFile,
              imageFileRef
            )
          } else {
            message.error('Пожалуйста, выберите Изображение')
          }
        }}
      >
        <TraditionsCollectionsForm
          form={form}
          setImageFile={setImageFile}
          imageFileRef={imageFileRef}
          traditions={traditions}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
            setImageFile(null)
            imageFileRef.current.value = null
          }}
          onOk={() => {
            updateTraditionsCollectionHandler(
              formForEdit,
              traditionsCollectionItem.id,
              setEditModal,
              imageFile,
              setImageFile,
              imageFileRef
            )
          }}
        >
          <TraditionsCollectionsForm
            form={formForEdit}
            titleKaz={traditionsCollectionItem.titleKaz}
            titleRus={traditionsCollectionItem.titleRus}
            setImageFile={setImageFile}
            imageFileRef={imageFileRef}
            traditions={traditions}
            selectedTraditions={traditionsCollectionItem.traditions}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deleteTraditionsCollectionHandler(
              traditionsCollectionItem.id,
              setDeleteModal
            )
          }
        >
          <h3>
            Вы действительно хотите удалить {traditionsCollectionItem.titleRus}{' '}
            ?
          </h3>
        </Modal>
      )}
    </>
  )
}

Traditions.propTypes = {
  traditionsCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  traditions: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTraditionsCollectionHandler: PropTypes.func.isRequired,
  updateTraditionsCollectionHandler: PropTypes.func.isRequired,
  deleteTraditionsCollectionHandler: PropTypes.func.isRequired
}

export default Traditions
