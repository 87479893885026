import React, { useState } from 'react'
import { Form, Input, InputNumber, Select, DatePicker } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

const UserForm = ({
  form,
  name,
  childAge,
  withPassword,
  phoneNumber,
  role,
  plan,
  expiresDate
}) => {
  const [userRole, setUserRole] = React.useState(role)
  const FormItem = Form.Item

  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="name"
        label="Имя пользователя"
        name="name"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Имя`
          }
        ]}
        initialValue={name}
      >
        <Input placeholder="Имя" />
      </FormItem>
      {withPassword && (
        <FormItem
          key="password"
          label="Пароль"
          name="password"
          rules={[
            {
              required: true,
              message: `Пожалуйста, введите пароль`
            }
          ]}
        >
          <Input placeholder="Пароль" type="password" />
        </FormItem>
      )}
      <FormItem
        key="role"
        label="Роль"
        name="role"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите роль`
          }
        ]}
        initialValue={role}
      >
        <Select
          placeholder="Выберите роль"
          mode="single"
          onChange={value => setUserRole(value)}
        >
          <Select.Option key="admin" value="admin">
            admin
          </Select.Option>
          <Select.Option key="user" value="user">
            user
          </Select.Option>
          <Select.Option key="contentManager" value="contentManager">
            contentManager
          </Select.Option>
        </Select>
      </FormItem>
      {userRole === 'user' && (
        <>
          <FormItem
            key="childAge"
            label="Возраст ребёнка (не обязательно)"
            name="childAge"
            rules={[
              {
                required: false
              }
            ]}
            initialValue={childAge}
          >
            <InputNumber min={0} placeholder="Возраст ребёнка" />
          </FormItem>
          <FormItem
            key="phoneNumber"
            label="Номер телефона"
            name="phoneNumber"
            rules={[
              {
                required: false
              }
            ]}
            initialValue={phoneNumber}
          >
            <Input placeholder="Номер телефона" type="tel" />
          </FormItem>

          <FormItem
            key="plan"
            label="План"
            name="plan"
            rules={[
              {
                required: false
              }
            ]}
            initialValue={plan}
          >
            <Select placeholder="Выберите план" mode="single">
              <Select.Option key="basic" value="basic">
                basic
              </Select.Option>
              <Select.Option key="premium" value="premium">
                premium
              </Select.Option>
              <Select.Option key="exclusive" value="exclusive">
                exclusive
              </Select.Option>
            </Select>
          </FormItem>

          <FormItem
            key="expiresDate"
            label="Дата и время окончания подписки"
            name="expiresDate"
            rules={[
              {
                required: false
              }
            ]}
            initialValue={expiresDate}
          >
            <DatePicker showTime format="DD.MM.YYYY" />
          </FormItem>
        </>
      )}

      {(userRole === 'admin' || userRole === 'contentManager') && (
        <>
          <FormItem
            key="phoneNumber"
            label="Логин"
            name="Логин"
            rules={[
              {
                required: true
              }
            ]}
            initialValue={phoneNumber}
          >
            <Input placeholder="username" type="tel" />
          </FormItem>
        </>
      )}
    </Form>
  )
}

UserForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  withPassword: PropTypes.bool,
  phoneNumber: PropTypes.string,
  role: PropTypes.string,
  childAge: PropTypes.number,
  name: PropTypes.string,
  plan: PropTypes.string
}

UserForm.defaultProps = {
  name: '',
  withPassword: false,
  phoneNumber: '',
  childAge: null
}

export default UserForm
