import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Radio, Avatar } from 'antd'
import {
  PlusOutlined,
  SearchOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'
// import Icon from '@ant-design/icons'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import QaraSozForm from './QaraSozForm'
// import Genres from '../shared/Genres'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

// const StyledAvatar = styled.img``

const QaraSozs = ({
  qaraSozs,
  allCreators,
  addQaraSozHandler,
  updateQaraSozHandler,
  deleteQaraSozHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [qaraSozItem, setQaraSozItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchQaraSoz, setSearchQaraSoz] = React.useState(null)

  const [listQaraSozs, setListQaraSozs] = React.useState(null)

  const [audioFile, setAudioFile] = useState(null)
  const [textFile, setTextFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const audioFileRef = React.useRef()
  const textFileRef = React.useRef()
  const imageFileRef = React.useRef()

  const [radioValue, setRadioValue] = useState(null)

  React.useEffect(() => {
    const descriptiveQaraSozs = qaraSozs.map(qaraSoz => {
      return {
        id: qaraSoz.id,
        index: qaraSoz.index,
        type: qaraSoz.type,
        version: qaraSoz.version,
        title: qaraSoz.title,
        authors: qaraSoz.authors,
        voiceActors: qaraSoz.voiceActors,
        status: qaraSoz.status,
        link2Audio: qaraSoz.link2Audio,
        coverImage: qaraSoz.coverImage,
        lyrics: qaraSoz.lyrics
      }
    })
    let searchArray = descriptiveQaraSozs
    if (searchQaraSoz && radioValue) {
      searchArray = searchArray.filter(qaraSoz => {
        return (
          qaraSoz.version === radioValue &&
          qaraSoz.title?.toLowerCase().includes(searchQaraSoz)
        )
      })
    } else if (radioValue) {
      searchArray = searchArray.filter(qaraSoz => {
        return qaraSoz.version === radioValue
      })
    } else if (searchQaraSoz) {
      searchArray = searchArray.filter(qaraSoz => {
        return qaraSoz.title?.toLowerCase().includes(searchQaraSoz)
      })
    }
    setListQaraSozs(searchArray)
  }, [qaraSozs, searchQaraSoz, radioValue])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    // {
    //   title: 'Фото',
    //   width: '5%',
    //   render: (item) => {
    //     return (
    //      <>
    //       <Avatar shape="square" size={64} src={item.photo}/>
    //      </>
    //    );
    //   },
    // },
    {
      title: '',
      dataIndex: 'index',
      width: '2%'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.coverImage} />
          </>
        )
      }
    },
    {
      title: 'Текст',
      width: '1%',
      render: item => {
        return <>{item.lyrics && <CheckCircleOutlined />}</>
      }
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.link2Audio
        if (!audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            <source src={audioLink} type="audio/mpeg" />
          </audio>
        )
      }
    },
    {
      title: 'Название',
      dataIndex: 'title',
      width: '10%'
    },
    {
      title: 'Версия',
      width: '5%',
      render: item => {
        return item.version === 'full' ? <>Полная</> : <>Короткая</>
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setQaraSozItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setQaraSozItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  // const options = [
  //   { label: 'Все', value: null },
  //   { label: 'Полные', value: 'full' },
  //   { label: 'Короткие', value: 'short' }
  // ]

  return (
    <>
      <Table
        dataSource={listQaraSozs}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchQaraSoz(e.target.value.toLowerCase())
              }}
            />
            <Radio.Group
              // options={options}
              value={radioValue}
              onChange={e => {
                setRadioValue(e.target.value)
              }}
            >
              {/* <Space direction="vertical"> */}
              <Radio value={null} style={{ fontSize: '10px' }}>
                Все
              </Radio>
              <Radio value="full" style={{ fontSize: '10px' }}>
                Полные
              </Radio>
              <Radio value="lite" style={{ fontSize: '10px' }}>
                Короткие
              </Radio>
              {/* </Space> */}
            </Radio.Group>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый элемент"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setTextFile(null)
          setImageFile(null)
          audioFileRef.current.value = null
          textFileRef.current.value = null
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            if (imageFile !== null) {
              addQaraSozHandler(
                form,
                setModalVisible,
                audioFile,
                textFile,
                imageFile,
                setAudioFile,
                setTextFile,
                setImageFile,
                audioFileRef,
                textFileRef,
                imageFileRef
              )
            } else {
              message.error('Пожалуйста, выберите Изображение')
            }
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <QaraSozForm
          allCreators={allCreators}
          setAudioFile={setAudioFile}
          setTextFile={setTextFile}
          setImageFile={setImageFile}
          audioFileRef={audioFileRef}
          textFileRef={textFileRef}
          imageFileRef={imageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setImageFile(null)
            audioFileRef.current.value = null
            imageFileRef.current.value = null
            textFileRef.current.value = null
          }}
          onOk={() => {
            updateQaraSozHandler(
              formForEdit,
              qaraSozItem.id,
              setEditModal,
              audioFile,
              textFile,
              imageFile,
              setAudioFile,
              setTextFile,
              setImageFile,
              audioFileRef,
              textFileRef,
              imageFileRef
            )
          }}
        >
          <QaraSozForm
            form={formForEdit}
            allCreators={allCreators}
            title={qaraSozItem.title}
            authors={qaraSozItem.authors}
            voiceActors={qaraSozItem.voiceActors}
            index={qaraSozItem.index}
            type={qaraSozItem.type}
            version={qaraSozItem.version}
            status={qaraSozItem.status}
            lyrics={qaraSozItem.lyrics}
            setAudioFile={setAudioFile}
            setTextFile={setTextFile}
            setImageFile={setImageFile}
            audioFileRef={audioFileRef}
            textFileRef={textFileRef}
            imageFileRef={imageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteQaraSozHandler(qaraSozItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить '{qaraSozItem.title}' ?</h3>
        </Modal>
      )}
    </>
  )
}

QaraSozs.propTypes = {
  qaraSozs: PropTypes.arrayOf(PropTypes.object).isRequired,
  addQaraSozHandler: PropTypes.func.isRequired,
  updateQaraSozHandler: PropTypes.func.isRequired
}

export default QaraSozs
