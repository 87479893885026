import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../context/useLoading'
import Stories from './Stories'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'
import moment from 'moment'

const GET_STORIES = gql`
  query stories {
    statusedStories {
      id
      date
      header
      headerRU
      link2Image
      link2ImageRU
      isCombined
      link
      linkDescription
      linkDescriptionRU
      status
    }
  }
`

const ADD_STORY = gql`
  mutation addStory(
    $imageFile: FileUpload
    $imageRUFile: FileUpload
    $input: StoryInput
  ) {
    addStory(imageFile: $imageFile, imageRUFile: $imageRUFile, input: $input) {
      id
      date
      header
      headerRU
      link2Image
      link2ImageRU
      isCombined
      link
      linkDescription
      linkDescriptionRU
      status
    }
  }
`

const UPDATE_STORY = gql`
  mutation updateStory(
    $id: ID!
    $imageFile: FileUpload
    $imageRUFile: FileUpload
    $input: UpdateStoryInput
  ) {
    updateStory(
      id: $id
      imageFile: $imageFile
      imageRUFile: $imageRUFile
      input: $input
    ) {
      id
      date
      header
      headerRU
      link2Image
      link2ImageRU
      isCombined
      link
      linkDescription
      linkDescriptionRU
      status
    }
  }
`

const DELETE_STORY = gql`
  mutation deleteStory($id: ID!, $input: UpdateStoryInput) {
    updateStory(id: $id, input: $input) {
      id
      date
      header
      headerRU
      link2Image
      link2ImageRU
      isCombined
      link
      linkDescription
      linkDescriptionRU
      status
    }
  }
`

const StoriesContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allStories, setAllStories] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_STORIES)

  const [addStory, { error: errorAddStory }] = useMutation(ADD_STORY, {
    update(cache, { data: { addStory: addStoryItem } }) {
      const { statusedStories } = cache.readQuery({
        query: GET_STORIES
      })
      cache.writeQuery({
        query: GET_STORIES,
        data: {
          statusedStories: [addStoryItem].concat(statusedStories)
        }
      })
      hideLoading()
      toast.success('История добавлена')
    }
  })
  // const [updateStory, { data: updateStoryData }] = useMutation(
  //   UPDATE_STORY
  // )

  // const [updateStory, { data: updateStoryData }] = useMutation(UPDATE_STORY)

  const [updateStory, { error: errorUpdateStory }] = useMutation(UPDATE_STORY, {
    update(cache, { data: { updateStory: updateStoryItem } }) {
      const { statusedStories } = cache.readQuery({
        query: GET_STORIES
      })
      let tempUpdateStory = { ...updateStoryItem }
      tempUpdateStory.date = moment(updateStoryItem.date)
      cache.writeQuery({
        query: GET_STORIES,
        data: {
          statusedStories: statusedStories
            .filter(story => story.id !== tempUpdateStory.id)
            .concat(tempUpdateStory)
            .sort((a, b) => {
              return new Date(b.date) - new Date(a.date)
            })
        }
      })
      hideLoading()
      toast.success('История обновлена')
    }
  })
  const [deleteStory, { error: errorDeleteStory }] = useMutation(DELETE_STORY, {
    update(cache, { data: { updateStory: deleteStoryItem } }) {
      const { statusedStories } = cache.readQuery({
        query: GET_STORIES
      })
      cache.writeQuery({
        query: GET_STORIES,
        data: {
          statusedStories: statusedStories.filter(
            story => story.id !== deleteStoryItem.id
          )
        }
      })
      hideLoading()
      toast.success('История удалена')
    }
  })

  React.useEffect(() => {
    if (errorAddStory) {
      console.log(errorAddStory)
    }
  }, [errorAddStory])

  React.useEffect(() => {
    if (errorDeleteStory) {
      console.log(errorDeleteStory)
    }
  }, [errorDeleteStory])

  React.useEffect(() => {
    if (errorUpdateStory) {
      console.log(errorUpdateStory)
    }
  }, [errorUpdateStory])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllStories(data.statusedStories)
    }
  }, [data, loading, error])

  const addStoryHandler = async (
    form,
    closeFunc,
    setIsCombinedState,
    imageFile,
    imageRUFile,
    setImageFile,
    setImageRUFile,
    imageFileRef,
    imageRUFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        const dateAndTime = `${val.date
          .utc()
          .format(moment.HTML5_FMT.DATE)}T${val.time
          .utc()
          .format(moment.HTML5_FMT.TIME_MS)}+00:00`
        form.resetFields()
        addStory({
          variables: {
            imageFile: imageFile,
            ...(imageRUFile && !val.isCombined && { imageRUFile }),
            input: {
              date: dateAndTime,
              header: val.header,
              ...(val.headerRU && { headerRU: val.headerRU }),
              status: val.status,
              isCombined: val.isCombined,
              ...(val.link && { link: val.link }),
              ...(val.linkDescription && {
                linkDescription: val.linkDescription
              }),
              ...(val.linkDescriptionRU && {
                linkDescriptionRU: val.linkDescriptionRU
              })
            }
          }
        })
      })
      .then(() => {
        setIsCombinedState(true)
        setImageFile(null)
        setImageRUFile(null)
        imageFileRef?.current?.value && (imageFileRef.current.value = null)
        imageRUFileRef?.current?.value && (imageRUFileRef.current.value = null)
        hideLoading()
        closeFunc(false)
      })
  }

  const updateStoryHandler = async (
    form,
    storyId,
    closeFunc,
    setIsCombinedState,
    imageFile,
    imageRUFile,
    setImageFile,
    setImageRUFile,
    imageFileRef,
    imageRUFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        const dateAndTime = `${val.date
          .utc()
          .format(moment.HTML5_FMT.DATE)}T${val.time
          .utc()
          .format(moment.HTML5_FMT.TIME_MS)}+00:00`
        form.resetFields()
        updateStory({
          variables: {
            id: storyId,
            imageFile: imageFile,
            ...(imageRUFile && { imageRUFile }),
            input: {
              date: dateAndTime,
              header: val.header,
              headerRU: val.headerRU ? val.headerRU : null,
              status: val.status,
              isCombined: val.isCombined,
              link: val.link ? val.link : null,
              linkDescription: val.linkDescription ? val.linkDescription : null,
              linkDescriptionRU: val.linkDescriptionRU
                ? val.linkDescriptionRU
                : null
            }
          }
        })
      })
      .then(() => {
        setIsCombinedState(true)
        setImageFile(null)
        setImageRUFile(null)
        imageFileRef?.current?.value && (imageFileRef.current.value = null)
        imageRUFileRef?.current?.value && (imageRUFileRef.current.value = null)
        hideLoading()
        closeFunc(false)
      })
  }

  const deleteStoryHandler = (storyId, closeFunc) => {
    showLoading()
    deleteStory({
      variables: {
        id: storyId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Stories
      stories={allStories}
      addStory={addStory}
      refetch={refetch}
      addStoryHandler={addStoryHandler}
      updateStoryHandler={updateStoryHandler}
      deleteStoryHandler={deleteStoryHandler}
    />
  )
}

export default WithMainLayout(StoriesContainer)
