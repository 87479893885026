import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import Lessons from './Lessons'
import WithMainLayout from '../../../hocs/withMainLayout'

const GET_LESSONS = gql`
  query statusedLessonsByCourseId($courseId: ID!) {
    statusedLessonsByCourseId(courseId: $courseId) {
      course {
        id
        nameKZ
        nameRU
        topicsPopulated {
          id
          textKZ
          textRU
        }
      }
      lessons {
        id
        level
        status
        nameKZ
        nameRU
        audioRU
        audioKZ
        imageURL
        type
        timerDuration
        practiceTasks
        topics
        restrictions
      }
    }
  }
`

const ADD_LESSON = gql`
  mutation addLesson(
    $imageFile: FileUpload!
    $audioFileKZ: FileUpload
    $audioFileRU: FileUpload
    $input: LessonInput
  ) {
    addLesson(
      imageFile: $imageFile
      audioFileKZ: $audioFileKZ
      audioFileRU: $audioFileRU
      input: $input
    ) {
      id
      level
      status
      nameKZ
      nameRU
      audioRU
      audioKZ
      imageURL
      type
      topics
      timerDuration
      practiceTasks
      restrictions
    }
  }
`

const UPDATE_LESSON = gql`
  mutation updateLesson(
    $id: ID!
    $imageFile: FileUpload
    $audioFileKZ: FileUpload
    $audioFileRU: FileUpload
    $input: LessonInput
  ) {
    updateLesson(
      id: $id
      imageFile: $imageFile
      audioFileKZ: $audioFileKZ
      audioFileRU: $audioFileRU
      input: $input
    ) {
      id
      level
      status
      nameKZ
      nameRU
      audioRU
      audioKZ
      imageURL
      type
      topics
      timerDuration
      practiceTasks
      restrictions
    }
  }
`

const DELETE_LESSON = gql`
  mutation deleteLesson($id: ID!) {
    deleteLesson(id: $id) {
      id
      message
    }
  }
`

const GET_TOPICS = gql`
  query topics {
    topics {
      id
      textKZ
      textRU
    }
  }
`

const LessonsContainer = () => {
  const { collectionId, courseId } = useParams()

  const { showLoading, hideLoading } = useLoading()
  const [allLessons, setAllLessons] = useState([])
  const [course, setCourse] = useState()
  const { data, loading, error, refetch } = useQuery(GET_LESSONS, {
    variables: { courseId }
  })
  const [addLesson, { error: errorAddLesson }] = useMutation(ADD_LESSON, {
    update(cache, { data: { addLesson: addLessonItem } }) {
      const { statusedLessonsByCourseId } = cache.readQuery({
        query: GET_LESSONS,
        variables: { courseId }
      })
      cache.writeQuery({
        query: GET_LESSONS,
        variables: { courseId },
        data: {
          statusedLessonsByCourseId: {
            course: statusedLessonsByCourseId.course,
            lessons: statusedLessonsByCourseId.lessons
              .concat(addLessonItem)
              .sort((a, b) =>
                a.level < b.level ? -1 : b.level > a.level ? 1 : 0
              )
          }
        }
      })
      hideLoading()
      toast.success('Урок добавлен')
    }
  })

  const [updateLesson, { error: errorUpdateLesson }] = useMutation(
    UPDATE_LESSON,
    {
      update(cache, { data: { updateLesson: updateLessonItem } }) {
        const { statusedLessonsByCourseId } = cache.readQuery({
          query: GET_LESSONS,
          variables: { courseId }
        })
        cache.writeQuery({
          query: GET_LESSONS,
          variables: { courseId },
          data: {
            statusedLessonsByCourseId: {
              course: statusedLessonsByCourseId.course,
              lessons: statusedLessonsByCourseId.lessons
                .filter(i => i.id !== updateLessonItem.id)
                .concat(updateLessonItem)
                .sort((a, b) =>
                  a.level < b.level ? -1 : b.level > a.level ? 1 : 0
                )
            }
          }
        })
        hideLoading()
        toast.success('Урок обновлен')
      }
    }
  )
  const [deleteLesson, { error: errorDeleteLesson }] = useMutation(
    DELETE_LESSON,
    {
      update(cache, { data: { deleteLesson: deleteLessonItem } }) {
        const { statusedLessonsByCourseId } = cache.readQuery({
          query: GET_LESSONS,
          variables: { courseId }
        })
        cache.writeQuery({
          query: GET_LESSONS,
          variables: { courseId },
          data: {
            statusedLessonsByCourseId: {
              course: statusedLessonsByCourseId.course,
              lessons: statusedLessonsByCourseId.lessons.filter(
                i => i.id !== deleteLessonItem.id
              )
            }
          }
        })
        hideLoading()
        toast.success('Урок удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorAddLesson) {
      console.log(errorAddLesson)
    }
  }, [errorAddLesson])

  React.useEffect(() => {
    if (errorDeleteLesson) {
      console.log(errorDeleteLesson)
    }
  }, [errorDeleteLesson])

  React.useEffect(() => {
    if (errorUpdateLesson) {
      console.log(errorUpdateLesson)
    }
  }, [errorUpdateLesson])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setCourse(data.statusedLessonsByCourseId.course)
      setAllLessons(data.statusedLessonsByCourseId.lessons)
    }
  }, [data, loading, error])

  const addLessonHandler = (
    form,
    closeFunc,
    imageFile,
    audioFileKZ,
    audioFileRU,
    setImageFile,
    setAudioFileKZ,
    setAudioFileRU,
    imageFileRef,
    audioFileKZRef,
    audioFileRURef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addLesson({
          variables: {
            imageFile: imageFile,
            audioFileKZ: audioFileKZ,
            audioFileRU: audioFileRU,
            input: {
              level: val.level,
              status: val.status,
              nameKZ: val.nameKZ && val.nameKZ?.trim(),
              nameRU: val.nameRU && val.nameRU?.trim(),
              type: val.type,
              course: courseId,
              topics: val.topics,
              timerDuration: val.timerDuration,
              practiceTasks: val.practiceTasks,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        setAudioFileKZ(null)
        setAudioFileRU(null)
        imageFileRef.current.value = null
        audioFileKZRef.current.value = null
        audioFileRURef.current.value = null
        closeFunc(false)
      })
  }

  const updateLessonHandler = (
    form,
    lessonId,
    closeFunc,
    imageFile,
    audioFileKZ,
    audioFileRU,
    setImageFile,
    setAudioFileKZ,
    setAudioFileRU,
    imageFileRef,
    audioFileKZRef,
    audioFileRURef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateLesson({
          variables: {
            id: lessonId,
            imageFile: imageFile,
            audioFileKZ: audioFileKZ,
            audioFileRU: audioFileRU,
            input: {
              level: val.level,
              status: val.status,
              nameKZ: val.nameKZ && val.nameKZ?.trim(),
              nameRU: val.nameRU && val.nameRU?.trim(),
              type: val.type,
              course: courseId,
              topics: val.topics,
              timerDuration: val.timerDuration,
              practiceTasks: val.practiceTasks,
              restrictions: val.restrictions
            }
          }
        })
      })
      .then(() => {
        setImageFile(null)
        setAudioFileKZ(null)
        setAudioFileRU(null)
        imageFileRef.current.value = null
        audioFileKZRef.current.value = null
        audioFileRURef.current.value = null
        closeFunc(false)
      })
  }

  const deleteLessonHandler = (lessonId, closeFunc) => {
    showLoading()
    deleteLesson({
      variables: {
        id: lessonId
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  return (
    <Lessons
      collectionId={collectionId}
      course={course}
      lessons={allLessons}
      loading={loading}
      addLesson={addLesson}
      refetch={refetch}
      addLessonHandler={addLessonHandler}
      updateLessonHandler={updateLessonHandler}
      deleteLessonHandler={deleteLessonHandler}
    />
  )
}

export default WithMainLayout(LessonsContainer)
