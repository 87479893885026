import React from 'react'
import { Table, Button, Form, Modal, Input } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import TagForm from './TagsForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Tags = ({ tags, addTagHandler, updateTagHandler, deleteTagHandler }) => {
  const [form] = Form.useForm()
  const [formForUpdate] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [tagItem, setTagItem] = React.useState(null)
  const [updateModal, setUpdateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)

  const [searchTagname, setSearchTagname] = React.useState(null)

  const [listTags, setListTags] = React.useState(null)

  React.useEffect(() => {
    const descriptiveTags = tags.map(tag => {
      return {
        id: tag.id,
        nameKZ: tag.nameKZ,
        nameRU: tag.nameRU,
        status: tag.status
      }
    })
    let searchArray = descriptiveTags

    if (searchTagname) {
      searchArray = searchArray.filter(tag => {
        return (
          tag.nameKZ?.toLowerCase().includes(searchTagname) ||
          tag.nameRU?.toLowerCase().includes(searchTagname)
        )
      })
    }

    setListTags(searchArray)
  }, [tags, searchTagname])

  const columns = [
    {
      title: '',
      width: '0.001%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Название(каз)',
      dataIndex: 'nameKZ',
      width: '30%'
    },
    {
      title: 'Название(рус)',
      dataIndex: 'nameRU',
      width: '30%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: (text, item) => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForUpdate.resetFields()
                setUpdateModal(true)
                setTagItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setTagItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listTags}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Name"
              onChange={e => {
                setSearchTagname(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый тэг
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый тэг"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => addTagHandler(form, setModalVisible)}
      >
        <TagForm form={form} />
      </Modal>
      {updateModal && (
        <Modal
          open={updateModal}
          onCancel={() => {
            setUpdateModal(false)
          }}
          onOk={() =>
            updateTagHandler(formForUpdate, tagItem.id, setUpdateModal)
          }
        >
          <TagForm
            form={formForUpdate}
            nameKZ={tagItem.nameKZ}
            nameRU={tagItem.nameRU}
            photo={tagItem.photo}
            status={tagItem.status}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteTagHandler(tagItem.id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить тэг "{tagItem.nameKZ} -{' '}
            {tagItem.nameRU}" ?
          </h3>
        </Modal>
      )}
    </>
  )
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  addTagHandler: PropTypes.func.isRequired,
  updateTagHandler: PropTypes.func.isRequired
}

export default Tags
