import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Categories from './Categories'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_CATEGORIES_BY_INDEX = gql`
  query statusedCategoriesByIndex {
    statusedCategoriesByIndex {
      id
      nameKZ
      nameRU
      photo
      index
      status
    }
  }
`

const ADD_CATEGORY = gql`
  mutation addCategory($photoFile: FileUpload!, $input: CategoryInput) {
    addCategory(photoFile: $photoFile, input: $input) {
      id
      nameKZ
      nameRU
      photo
      index
      status
    }
  }
`

const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $id: ID!
    $photoFile: FileUpload
    $input: UpdateCategoryInput
  ) {
    updateCategory(id: $id, photoFile: $photoFile, input: $input) {
      id
      nameKZ
      nameRU
      photo
      index
      status
    }
  }
`

const DELETE_CATEGORY = gql`
  mutation updateCategory($id: ID!, $input: UpdateCategoryInput) {
    updateCategory(id: $id, input: $input) {
      id
      nameKZ
      nameRU
      photo
      index
      status
    }
  }
`

const CategoriesContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allCategories, setAllCategories] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_CATEGORIES_BY_INDEX)
  const [addCategory, { error: errorAddCategory }] = useMutation(ADD_CATEGORY, {
    update(cache, { data: { addCategory: addCategoryItem } }) {
      const { statusedCategoriesByIndex } = cache.readQuery({
        query: GET_CATEGORIES_BY_INDEX
      })
      cache.writeQuery({
        query: GET_CATEGORIES_BY_INDEX,
        data: {
          statusedCategoriesByIndex: statusedCategoriesByIndex
            .concat(addCategoryItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Категория добавлена')
    }
  })
  // const [updateCategory, { data: updateCategoryData }] = useMutation(UPDATE_CATEGORY)
  const [updateCategory, { error: errorUpdateCategory }] = useMutation(
    UPDATE_CATEGORY,
    {
      update(cache, { data: { updateCategory: updateCategoryItem } }) {
        const { statusedCategoriesByIndex } = cache.readQuery({
          query: GET_CATEGORIES_BY_INDEX
        })
        cache.writeQuery({
          query: GET_CATEGORIES_BY_INDEX,
          data: {
            statusedCategoriesByIndex: statusedCategoriesByIndex
              .filter(category => category.id !== updateCategoryItem.id)
              .concat(updateCategoryItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Категория обновлена')
      }
    }
  )
  const [deleteCategory, { error: errorDeleteCategory }] = useMutation(
    DELETE_CATEGORY,
    {
      update(cache, { data: { updateCategory: deleteCategoryItem } }) {
        const { statusedCategoriesByIndex } = cache.readQuery({
          query: GET_CATEGORIES_BY_INDEX
        })
        cache.writeQuery({
          query: GET_CATEGORIES_BY_INDEX,
          data: {
            statusedCategoriesByIndex: statusedCategoriesByIndex.filter(
              category => category.id !== deleteCategoryItem.id
            )
          }
        })
        hideLoading()
        toast.success('Категория удалена')
      }
    }
  )

  React.useEffect(() => {
    if (errorDeleteCategory) {
      console.log(errorDeleteCategory)
    }
  }, [errorDeleteCategory])

  React.useEffect(() => {
    if (errorAddCategory) {
      console.log(errorAddCategory)
    }
  }, [errorAddCategory])

  React.useEffect(() => {
    if (errorUpdateCategory) {
      console.log(errorUpdateCategory)
    }
  }, [errorUpdateCategory])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllCategories(data.statusedCategoriesByIndex)
    }
  }, [data, loading, error])

  const addCategoryHandler = (form, closeFunc, file, setFile, fileRef) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addCategory({
          variables: {
            photoFile: file,
            input: {
              nameKZ: val.nameKZ,
              nameRU: val.nameRU,
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setFile(null)
        fileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateCategoryHandler = (
    form,
    categoryId,
    closeFunc,
    file,
    setFile,
    fileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateCategory({
          variables: {
            id: categoryId,
            photoFile: file,
            input: {
              nameKZ: val.nameKZ,
              nameRU: val.nameRU,
              index: val.index,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setFile(null)
        fileRef.current.value = null
        // hideLoading()
        closeFunc(false)
        // toast.success('Жанр изменен')
      })
  }

  const deleteCategoryHandler = (categoryId, closeFunc) => {
    showLoading()
    deleteCategory({
      variables: {
        id: categoryId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Categories
      categories={allCategories}
      addCategory={addCategory}
      refetch={refetch}
      addCategoryHandler={addCategoryHandler}
      updateCategoryHandler={updateCategoryHandler}
      deleteCategoryHandler={deleteCategoryHandler}
    />
  )
}

export default WithMainLayout(CategoriesContainer)
