import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar } from 'antd'
import Icon, { PlusOutlined, SearchOutlined } from '@ant-design/icons'
// import Icon from '@ant-design/icons'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import ColorForm from './ColorForm'
// import Genres from '../shared/Genres'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const StyledAvatar = styled.img``

const Colors = ({
  colors,
  addColorHandler,
  updateColorHandler,
  deleteColorHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [colorItem, setColorItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchColor, setSearchColor] = React.useState(null)

  const [listColors, setListColors] = React.useState(null)

  const [audioFile, setAudioFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const audioFileRef = React.useRef()
  const imageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveColors = colors.map(color => {
      return {
        id: color.id,
        index: color.index,
        color: color.color,
        link2Audio: color.link2Audio,
        link2Image: color.link2Image,
        status: color.status,
        restrictions: color.restrictions
      }
    })
    let searchArray = descriptiveColors

    if (searchColor) {
      searchArray = searchArray.filter(color => {
        return color.color?.toLowerCase().includes(searchColor)
      })
    }

    setListColors(searchArray)
  }, [colors, searchColor])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    // {
    //   title: 'Фото',
    //   width: '5%',
    //   render: (item) => {
    //     return (
    //      <>
    //       <Avatar shape="square" size={64} src={item.photo}/>
    //      </>
    //    );
    //   },
    // },
    {
      title: 'Очередность',
      dataIndex: 'index',
      width: '2%'
    },
    // {
    //   title: 'Фото',
    //   width: '5%',
    //   render: item => {
    //     return (
    //       <>
    //         <img src={item.link2Image} />
    //       </>
    //     )
    //   }
    // },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        return (
          <>
            <Avatar shape="square" size={64} src={item.link2Image} />
          </>
        )
      }
    },
    {
      title: 'Дорожка',
      width: '5%',
      render: item => {
        let audioLink = item.link2Audio
        if (!audioLink.startsWith('https://')) {
          audioLink = 'https://' + audioLink
        }
        return (
          <audio controls="controls">
            <source src={audioLink} type="audio/mpeg" />
          </audio>
        )
      }
    },
    {
      title: 'Цвет',
      dataIndex: 'color',
      width: '5%'
    },
    {
      title: 'План',
      dataIndex: 'restrictions',
      width: '5%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setColorItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setColorItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listColors}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Цвет"
              onChange={e => {
                setSearchColor(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый Цвет
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый цвет"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setImageFile(null)
          audioFileRef.current.value = null
          imageFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            if (imageFile !== null) {
              addColorHandler(
                form,
                setModalVisible,
                audioFile,
                imageFile,
                setAudioFile,
                setImageFile,
                audioFileRef,
                imageFileRef
              )
            } else {
              message.error('Пожалуйста, выберите Изображение')
            }
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <ColorForm
          setAudioFile={setAudioFile}
          setImageFile={setImageFile}
          audioFileRef={audioFileRef}
          imageFileRef={imageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setImageFile(null)
            audioFileRef.current.value = null
            imageFileRef.current.value = null
          }}
          onOk={() => {
            updateColorHandler(
              formForEdit,
              colorItem.id,
              setEditModal,
              audioFile,
              imageFile,
              setAudioFile,
              setImageFile,
              audioFileRef,
              imageFileRef
            )
          }}
        >
          <ColorForm
            form={formForEdit}
            color={colorItem.color}
            index={colorItem.index}
            status={colorItem.status}
            restrictions={colorItem.restrictions}
            setAudioFile={setAudioFile}
            setImageFile={setImageFile}
            audioFileRef={audioFileRef}
            imageFileRef={imageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteColorHandler(colorItem.id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить '{colorItem.color}' ?</h3>
        </Modal>
      )}
    </>
  )
}

Colors.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  addColorHandler: PropTypes.func.isRequired,
  updateColorHandler: PropTypes.func.isRequired
}

export default Colors
