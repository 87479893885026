import React, { useEffect, useMemo, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    }
  }
}
const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

// const chartAreaBackground = {
//   id: 'chartAreaBackground',
//   beforeDatasetDraw(chart, args, plugins) {
//     const {
//       ctx,
//       chartArea: { left, top, width, height }
//     } = chart
//     ctx.save()
//     ctx.fillStyle = 'rgba(0,0,0,0.5)'
//     ctx.fillRect(left, top, width, height)
//   }
// }

const GET_TRANSACTIONS = gql`
  query transactionsCount($params: paramsInput) {
    transactionsCount(params: $params) {
      type
      counts
    }
  }
`

const TransactionsContainer = () => {
  const [transactions, setTransactions] = useState([])
  const [year, setYear] = useState(new Date().getFullYear())

  const labels = useMemo(
    () => (currentYear === year ? months.slice(0, currentMonth) : months),
    [currentYear, year]
  )

  const [getTransactions, { loading }] = useLazyQuery(GET_TRANSACTIONS, {
    variables: { params: { year } },
    onCompleted: data => {
      setTransactions(data.transactionsCount)
    },
    onError: error => {
      console.log(error)
    }
  })

  useEffect(() => {
    getTransactions()
  }, [year])

  const data = {
    labels,
    datasets: transactions.map((value, index) => ({
      label: value.type,
      data: value.counts,
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 206, 86)',
        'rgb(75, 192, 192)',
        'rgb(153, 102, 255)',
        'rgb(255, 159, 64)',
        'rgb(0, 0, 0)'
      ][index]
    }))
  }

  if (loading) return <Loading />

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div style={{ display: 'flex', columnGap: 10, alignItems: 'center' }}>
        <button
          onClick={() => {
            setYear(prev => prev - 1)
          }}
        >
          {year - 1}
        </button>
        <span>{year}</span>
        <button
          onClick={() => {
            setYear(prev => prev + 1)
          }}
        >
          {year + 1}
        </button>
      </div>
      <Bar
        options={options}
        data={data}
        // plugins={[chartAreaBackground]}
      />
    </div>
  )
}

export default WithMainLayout(TransactionsContainer)
