import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import PracticeLetters from './PracticeLetters'
import WithMainLayout from '../../../hocs/withMainLayout'

const GET_PRACTICE_LETTERS = gql`
  query statusedPracticeLettersByIndex {
    statusedPracticeLettersByIndex {
      id
      index
      letter
      audioURL
      imageURL
      status
    }
  }
`

const ADD_PRACTICE_LETTER = gql`
  mutation addPracticeLetter(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $input: PracticeLetterInput!
  ) {
    addPracticeLetter(
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      letter
      index
      audioURL
      imageURL
      status
    }
  }
`

const UPDATE_PRACTICE_LETTER = gql`
  mutation updatePracticeLetter(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $input: UpdatePracticeLetterInput
  ) {
    updatePracticeLetter(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      input: $input
    ) {
      id
      index
      letter
      audioURL
      imageURL
      status
    }
  }
`

const DELETE_PRACTICE_LETTER = gql`
  mutation deletePracticeLetter($id: ID!) {
    deletePracticeLetter(id: $id) {
      id
      message
    }
  }
`

const LettersContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allLetters, setAllLetters] = React.useState([])
  const { data, loading, error } = useQuery(GET_PRACTICE_LETTERS)
  const [addPracticeLetter, { error: errorAddPracticeLetter }] = useMutation(
    ADD_PRACTICE_LETTER,
    {
      update(cache, { data: { addPracticeLetter: addPracticeLetterItem } }) {
        const { statusedPracticeLettersByIndex } = cache.readQuery({
          query: GET_PRACTICE_LETTERS
        })
        cache.writeQuery({
          query: GET_PRACTICE_LETTERS,
          data: {
            statusedPracticeLettersByIndex: statusedPracticeLettersByIndex
              .concat(addPracticeLetterItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        })
        hideLoading()
        toast.success('Буква практики добавлена')
      }
    }
  )
  const [
    updatePracticeLetter,
    { error: errorUpdatePracticeLetter }
  ] = useMutation(UPDATE_PRACTICE_LETTER, {
    update(
      cache,
      { data: { updatePracticeLetter: updatePracticeLetterItem } }
    ) {
      const { statusedPracticeLettersByIndex } = cache.readQuery({
        query: GET_PRACTICE_LETTERS
      })
      cache.writeQuery({
        query: GET_PRACTICE_LETTERS,
        data: {
          statusedPracticeLettersByIndex: statusedPracticeLettersByIndex
            .filter(i => i.id !== updatePracticeLetterItem.id)
            .concat(updatePracticeLetterItem)
            .sort((a, b) =>
              a.index < b.index ? -1 : b.index > a.index ? 1 : 0
            )
        }
      })
      hideLoading()
      toast.success('Буква практики обновлена')
    }
  })
  const [
    deletePracticeLetter,
    { error: errorDeletePracticeLetter }
  ] = useMutation(DELETE_PRACTICE_LETTER, {
    update(
      cache,
      { data: { deletePracticeLetter: deletePracticeLetterItem } }
    ) {
      const { statusedPracticeLettersByIndex } = cache.readQuery({
        query: GET_PRACTICE_LETTERS
      })
      cache.writeQuery({
        query: GET_PRACTICE_LETTERS,
        data: {
          statusedPracticeLettersByIndex: statusedPracticeLettersByIndex.filter(
            i => i.id !== deletePracticeLetterItem.id
          )
        }
      })
      hideLoading()
      toast.success('Буква практики удалена')
    }
  })

  React.useEffect(() => {
    if (errorAddPracticeLetter) {
      console.log(errorAddPracticeLetter)
    }
  }, [errorAddPracticeLetter])

  React.useEffect(() => {
    if (errorDeletePracticeLetter) {
      console.log(errorDeletePracticeLetter)
    }
  }, [errorDeletePracticeLetter])

  React.useEffect(() => {
    if (errorUpdatePracticeLetter) {
      console.log(errorUpdatePracticeLetter)
    }
  }, [errorUpdatePracticeLetter])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllLetters(data.statusedPracticeLettersByIndex)
    }
  }, [data, loading, error])

  const addPracticeLetterHandler = (
    form,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addPracticeLetter({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            input: {
              index: val.index,
              letter: val.letter?.trim(),
              status: val.status
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updatePracticeLetterHandler = (
    form,
    letterId,
    closeFunc,
    audioFile,
    imageFile,
    setAudioFile,
    setImageFile,
    audioFileRef,
    imageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updatePracticeLetter({
          variables: {
            id: letterId,
            audioFile,
            imageFile,
            input: {
              index: val.index,
              letter: val.letter?.trim(),
              status: val.status
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        closeFunc(false)
        // toast.success('Буква обновлена')
      })
  }

  const deletePracticeLetterHandler = (letterId, closeFunc) => {
    showLoading()
    deletePracticeLetter({
      variables: {
        id: letterId
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  return (
    <PracticeLetters
      letters={allLetters}
      loading={loading}
      addPracticeLetterHandler={addPracticeLetterHandler}
      updatePracticeLetterHandler={updatePracticeLetterHandler}
      deletePracticeLetterHandler={deletePracticeLetterHandler}
    />
  )
}

export default WithMainLayout(LettersContainer)
