import React from 'react'

import { Form, Input, InputNumber, Select, Space, Button } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import './Lessons.css'

const LessonForm = ({
  form,
  level,
  status,
  nameKZ,
  nameRU,
  type,
  restrictions,
  timerDuration,
  topics,
  topic,
  practiceTasks,
  setImageFile,
  setAudioKZFile,
  setAudioRUFile,
  imageFileRef,
  audioKZFileRef,
  audioRUFileRef
}) => {
  const FormItem = Form.Item
  const [lessonType, setLessonType] = React.useState(type)
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <div>
          <>Изображение</>
          <input
            type="file"
            accept="image/*"
            ref={imageFileRef}
            onChange={event => setImageFile(event.target.files[0])}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <>{'Аудио(каз)'}</>
          <input
            type="file"
            accept="audio/*"
            ref={audioKZFileRef}
            onChange={event => {
              setAudioKZFile(event.target.files[0])
            }}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <>{'Аудио(рус)'}</>
          <input
            type="file"
            accept="audio/*"
            ref={audioRUFileRef}
            onChange={event => {
              setAudioRUFile(event.target.files[0])
            }}
          />
        </div>
      </div>
      <FormItem
        key="status"
        label="Статус"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="level"
        label="Уровень"
        name="level"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите уровень`
          }
        ]}
        initialValue={lessonType === 'learning' ? 0 : level}
      >
        <InputNumber min={0} max={lessonType === 'learning' ? 0 : 100000} />
      </FormItem>
      <FormItem
        key="nameKZ"
        label="Название(каз)"
        name="nameKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Название(каз)`
          }
        ]}
        initialValue={nameKZ}
      >
        <Input placeholder="Название(каз)" />
      </FormItem>
      <FormItem
        key="nameRU"
        label="Название(рус)"
        name="nameRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Название(рус)`
          }
        ]}
        initialValue={nameRU}
      >
        <Input placeholder="Название(рус)" />
      </FormItem>
      <FormItem
        key="type"
        label="Тип урока"
        name="type"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите тип урока`
          }
        ]}
        initialValue={type}
      >
        <Select placeholder="Выберите" mode="single" onChange={setLessonType}>
          <Select.Option key="learning" value="learning">
            Обучение
          </Select.Option>
          <Select.Option key="practice" value="practice">
            Практика
          </Select.Option>
        </Select>
      </FormItem>

      {(topic || topics) && (
        <FormItem
          key="topics"
          label="Темы"
          name="topics"
          initialValue={topic}
          rules={[
            {
              required: true,
              message: `Пожалуйста, выберите темы`
            }
          ]}
        >
          <Select
            placeholder="Выберите Темы"
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {topics.map(topic => (
              <Select.Option key={topic.id} value={topic.id}>
                {topic.textKZ} - {topic.textRU}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      )}

      {lessonType === 'practice' && (
        <>
          <FormItem
            key="timerDuration"
            label="Таймер в секундах"
            name="timerDuration"
            initialValue={timerDuration}
          >
            <InputNumber min={1} />
          </FormItem>
          <Form.List
            name="practiceTasks"
            label="Задания"
            key="practiceTasks"
            initialValue={practiceTasks}
          >
            {(fields = { practiceTasks }, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      rules={[
                        { required: true, message: 'Отсутствует тип задания' }
                      ]}
                    >
                      <Select
                        placeholder="Выберите"
                        mode="single"
                        style={{ minWidth: '200px' }}
                      >
                        <Select.Option key="firstLetter" value="firstLetter">
                          Первая буква
                        </Select.Option>
                        <Select.Option
                          key="missingLetter"
                          value="missingLetter"
                        >
                          Пропущенная буква
                        </Select.Option>
                        <Select.Option key="buildWord" value="buildWord">
                          Собери слово
                        </Select.Option>
                        <Select.Option
                          key="letterByImage"
                          value="letterByImage"
                        >
                          Буква по картинке
                        </Select.Option>
                        <Select.Option
                          key="imageByLetter"
                          value="imageByLetter"
                        >
                          Картинка по букве
                        </Select.Option>
                        <Select.Option key="wordByImage" value="wordByImage">
                          Слово по картинке
                        </Select.Option>

                        <Select.Option key="wordByNumber" value="wordByNumber">
                          Название цифры
                        </Select.Option>
                        <Select.Option key="numberByName" value="numberByName">
                          Цифра по названию
                        </Select.Option>
                        <Select.Option
                          key="compareByImage"
                          value="compareByImage"
                        >
                          Сопоставь
                        </Select.Option>
                        <Select.Option key="countByImage" value="countByImage">
                          Количество предметов
                        </Select.Option>
                        <Select.Option
                          key="imageByNumber"
                          value="imageByNumber"
                        >
                          Картинка по цифре
                        </Select.Option>
                        <Select.Option key="dragNdropColor" value="">
                          Собери правильные цвета
                        </Select.Option>

                        <Select.Option value="wordByColor" key="wordByColor">
                          Название цвета
                        </Select.Option>
                        <Select.Option value="colorByName" key="colorByName">
                          Цвет по названию
                        </Select.Option>
                        <Select.Option value="findColor" key="findColor">
                          Найди цвет
                        </Select.Option>
                        <Select.Option value="mergeColors" key="mergeColors">
                          Слияние цветов
                        </Select.Option>
                        <Select.Option value="colorByImage" key="colorByImage">
                          Цвет предмета
                        </Select.Option>
                        <Select.Option value="twoColors" key="twoColors">
                          Два цвета
                        </Select.Option>

                        <Select.Option value="nameByFigure" key="nameByFigure">
                          Найди название фигуры
                        </Select.Option>
                        <Select.Option
                          value="separateFigure"
                          key="separateFigure"
                        >
                          Найди отличающуюся фигуру
                        </Select.Option>
                        <Select.Option
                          value="compareByFigure"
                          key="compareByFigure"
                        >
                          Сопоставь подходящую фигуру
                        </Select.Option>
                        <Select.Option
                          value="figureByImage"
                          key="figureByImage"
                        >
                          Найди фигуру на картинке
                        </Select.Option>
                        <Select.Option value="drawFigure" key="drawFigure">
                          Нарисуй фигуру
                        </Select.Option>
                        <Select.Option
                          value="completeFigure"
                          key="completeFigure"
                        >
                          Найди целую фигуру
                        </Select.Option>
                        <Select.Option value="findFigures" key="findFigures">
                          Найди фигуры
                        </Select.Option>
                        <Select.Option value="findSyllable" key="findSyllable">
                          Найди слог
                        </Select.Option>
                        <Select.Option value="wordBySyllable" key="wordBySyllable">
                          Найди слово по слогу
                        </Select.Option>
                        <Select.Option value="buildBySyllable" key="buildBySyllable">
                          Собери слово из слогов
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      // label="Число"
                      name={[name, 'count']}
                      rules={[
                        { required: true, message: 'Отсутствует число задач' }
                      ]}
                    >
                      <InputNumber placeholder="Число" min={1} />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить задание
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      )}

      <FormItem
        key="restrictions"
        label="План"
        name="restrictions"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите план`
          }
        ]}
        initialValue={restrictions}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="basic" value="basic">
            Basic
          </Select.Option>
          <Select.Option key="premium" value="premium">
            Premium
          </Select.Option>
        </Select>
      </FormItem>
    </Form>
  )
}

LessonForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

LessonForm.defaultProps = {
  nameKZ: '',
  nameRU: '',
  status: 'active'
}

export default LessonForm
