import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import LessonTypeAudio from './LessonTypeAudio'

const UPLOAD_LESSON_TYPE_AUDIO = gql`
  mutation uploadLessonTypeAudio(
    $audioFile: FileUpload!
    $type: String!
    $language: String!
  ) {
    uploadLessonTypeAudio(
      audioFile: $audioFile
      type: $type
      language: $language
    ) {
      status
      message
    }
  }
`

const LessonTypeAudioContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [uploadLessonImage, { error: errorUploadLessonImage }] = useMutation(
    UPLOAD_LESSON_TYPE_AUDIO
  )

  React.useEffect(() => {
    if (errorUploadLessonImage) {
      console.log(errorUploadLessonImage)
    }
  }, [errorUploadLessonImage])

  const uploadLessonTypeAudioHandler = (
    type,
    setType,
    language,
    setLanguage,
    audioFile,
    setAudioFile,
    audioFileRef
  ) => {
    showLoading()
    uploadLessonImage({
      variables: {
        audioFile,
        type,
        language
      }
    }).then(() => {
      toast.success('Аудио обновлено!')
      setType(null)
      setLanguage(null)
      setAudioFile(null)
      audioFileRef.current.value = null
      hideLoading()
    })
  }

  return (
    <LessonTypeAudio
      uploadLessonTypeAudioHandler={uploadLessonTypeAudioHandler}
    />
  )
}

export default WithMainLayout(LessonTypeAudioContainer)
