import React from 'react'

import { Form, Input, Select, Switch, DatePicker, TimePicker } from 'antd'
import PropTypes from 'prop-types'
import './Story.css'
import ageGroups from '../shared/ageGroups'
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons'
import styled from 'styled-components'
import moment from 'moment'

const StyledDivDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StoryForm = ({
  form,
  date,
  isCombined,
  header,
  headerRU,
  link,
  linkDescription,
  linkDescriptionRU,
  status,
  isCombinedState,
  setIsCombinedState,
  setImageFile,
  setImageRUFile,
  imageFileRef,
  imageRUFileRef
}) => {
  React.useEffect(() => {
    !isCombined && setIsCombinedState(isCombined)
  }, [isCombined])

  const FormItem = Form.Item

  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="date"
        label="Дата"
        name="date"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите дату`
          }
        ]}
        initialValue={date}
      >
        <DatePicker />
        {/* <TimePicker /> */}
      </FormItem>
      <FormItem
        key="time"
        label="Время"
        name="time"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите время`
          }
        ]}
        initialValue={date}
      >
        <TimePicker />
      </FormItem>
      <FormItem
        key="link"
        label="Ссылка"
        name="link"
        rules={[{ type: 'url', message: 'Недействительная ссылка' }]}
        initialValue={link}
      >
        <Input placeholder="Ссылка" />
      </FormItem>
      <FormItem
        key="isCombined"
        label="Комбинированный"
        name="isCombined"
        initialValue={isCombined}
      >
        <Switch
          defaultChecked={isCombined}
          onChange={e => {
            setIsCombinedState(e)
          }}
        />
      </FormItem>
      <FormItem
        key="header"
        label="Заголовок"
        name="header"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите заголовок`
          }
        ]}
        initialValue={header}
      >
        <Input placeholder="Заголовок" />
      </FormItem>
      <FormItem
        key="linkDescription"
        label="Описание ссылки"
        name="linkDescription"
        rules={[
          {
            required: false,
            message: `Пожалуйста, введите описание ссылки`
          }
        ]}
        initialValue={linkDescription}
      >
        <Input placeholder="Описание..." />
      </FormItem>
      <div style={{ marginBottom: '3%' }}>
        <>История</>
        <StyledDivDelete>
          <input
            type="file"
            accept="image/*"
            ref={imageFileRef}
            onChange={event => setImageFile(event.target.files[0])}
          />
        </StyledDivDelete>
      </div>
      {!isCombinedState && (
        <>
          <FormItem
            key="headerRU"
            label="Заголовок на русском"
            name="headerRU"
            rules={[
              {
                required: true,
                message: `Пожалуйста, введите заголовок на русском`
              }
            ]}
            initialValue={headerRU}
          >
            <Input placeholder="Заголовок на русском" />
          </FormItem>
          <FormItem
            key="linkDescriptionRU"
            label="Описание ссылки на русском"
            name="linkDescriptionRU"
            rules={[
              {
                required: false,
                message: `Пожалуйста, введите описание ссылки`
              }
            ]}
            initialValue={linkDescriptionRU}
          >
            <Input placeholder="Описание..." />
          </FormItem>
          <div style={{ marginBottom: '3%' }}>
            <>История на русском</>
            <StyledDivDelete>
              <input
                type="file"
                accept="image/*"
                ref={imageRUFileRef}
                onChange={event => setImageRUFile(event.target.files[0])}
              />
            </StyledDivDelete>
          </div>
        </>
      )}
    </Form>
  )
}

StoryForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string
}

StoryForm.defaultProps = {
  header: '',
  isCombined: true,
  status: 'active',
  date: moment().local()
}

export default StoryForm
