import React, { useState } from 'react'

import { Form, Input, InputNumber, Select } from 'antd'
import { HexColorPicker, HexColorInput } from 'react-colorful'

import PropTypes from 'prop-types'
import './Courses.css'

const CourseForm = ({
  form,
  index,
  status,
  nameKZ,
  nameRU,
  topics,
  setImageFile,
  imageFileRef,
  color,
  colorTemp,
  setColorTemp,
  allTopics
}) => {
  React.useEffect(() => {
    setColorTemp(color || '#FFFFFF')
  }, [])

  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <div style={{ marginBottom: '3%' }}>
        <div>
          <>Изображение</>
          <input
            type="file"
            accept="image/*"
            ref={imageFileRef}
            onChange={event => setImageFile(event.target.files[0])}
          />
        </div>
      </div>
      <FormItem
        key="status"
        label="Статус Слова"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите очередность`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} />
      </FormItem>
      <FormItem
        key="nameKZ"
        label="Название(каз)"
        name="nameKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Название(каз)`
          }
        ]}
        initialValue={nameKZ}
      >
        <Input placeholder="Название(каз)" />
      </FormItem>
      <FormItem
        key="nameRU"
        label="Название(рус)"
        name="nameRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Название(рус)`
          }
        ]}
        initialValue={nameRU}
      >
        <Input placeholder="Название(рус)" />
      </FormItem>
      <FormItem
        key="topics"
        label="Темы"
        name="topics"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите Темы`
          }
        ]}
        initialValue={topics
          .filter(item => item !== null)
          .map(item => {
            return item
          })}
      >
        <Select
          placeholder="Выберите Темы"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allTopics.map(topic => (
            <Select.Option key={topic.id} value={topic.id}>
              {topic.textKZ} - {topic.textRU}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      Цвет
      <HexColorPicker color={colorTemp || '#FFFFFF'} onChange={setColorTemp} />
      <HexColorInput color={colorTemp || '#FFFFFF'} onChange={setColorTemp} />
      <button
        onClick={() => {
          setColorTemp(color || '#FFFFFF')
        }}
      >
        сбросить цвет
      </button>
    </Form>
  )
}

CourseForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
}

CourseForm.defaultProps = {
  nameKZ: '',
  nameRU: '',
  status: 'active',
  topics: []
}

export default CourseForm
