import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import { toast } from 'react-toastify'

import Notifications from './Notifications'
import withMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_NOTIFICATION = gql`
  query {
    notifications {
      id
      headingKZ
      headingRU
      messageKZ
      messageRU
      hours
      time
      type
      created_at
    }
  }
`

const SEND_PUSH = gql`
  mutation addNotification($input: NotificationInput) {
    addNotification(input: $input) {
      type
      time
      hours
      headingKZ
      headingRU
      messageKZ
      messageRU
    }
  }
`

const columns = [
  {
    title: 'Заголовок (қаз)',
    dataIndex: 'headingKZ'
  },
  {
    title: 'Заголовок (рус)',
    dataIndex: 'headingRU'
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    render: type => {
      if (type === 'scheduled') return <span>Интервальный</span>
      else return <span>Для всех</span>
    }
  },
  {
    title: 'Дата отправки',
    dataIndex: 'time'
  },
  {
    title: 'Часы',
    dataIndex: 'hours'
  }
]

const NotificationsContainer = () => {
  const [notifications, setNotifications] = React.useState([])
  const [headingKZ, setHeadingKZ] = React.useState('')
  const [headingRU, setHeadingRU] = React.useState('')
  const [messageKZ, setMessageKZ] = React.useState('')
  const [messageRU, setMessageRU] = React.useState('')
  const [time, setTime] = React.useState(moment())
  const [hours, setHours] = React.useState(0)
  const [type, setType] = React.useState('broadcast')
  const [isVisible, setIsVisible] = React.useState(false)
  const { data, loading, error, refetch } = useQuery(GET_NOTIFICATION)
  const [sendNotification, { loading: sendPushLoading }] = useMutation(
    SEND_PUSH,
    {
      onCompleted: () => {
        setHeadingKZ('')
        setHeadingRU('')
        setMessageKZ('')
        setMessageRU('')
        setHours(1)
        setType('')
        setTime(moment())
        toast.success('Уведомление создано успешно')
        setIsVisible(false)
        refetch()
      }
    }
  )

  React.useEffect(() => {
    if (!loading && data && !error) {
      const momentedArray = data.notifications.map(item => {
        return {
          ...item,
          time: item.time
            ? moment(item.time).format('HH:mm:ss | DD-MM-yyyy')
            : null,
          created_at: moment(item.created_at).format('dd DD MMMM, yyyy | HH:mm')
        }
      })
      setNotifications(momentedArray)
    }
  }, [data, loading, error])

  const handleSendNotification = async () => {
    if (headingKZ && headingRU && messageKZ && messageRU && type) {
      let options
      if (type === 'scheduled') {
        options = {
          variables: {
            input: {
              headingKZ,
              headingRU,
              messageKZ,
              messageRU,
              hours,
              type
            }
          }
        }
      } else {
        options = {
          variables: {
            input: {
              headingKZ,
              headingRU,
              messageKZ,
              messageRU,
              time,
              type
            }
          }
        }
      }
      await sendNotification({ ...options })
    } else {
      toast.error('Поля не должны быть пустыми')
    }
  }

  if (loading || sendPushLoading) {
    return <Loading />
  }

  return (
    <Notifications
      {...{
        headingKZ,
        headingRU,
        messageKZ,
        messageRU,
        notifications,
        columns,
        isVisible,
        setIsVisible,
        setMessageKZ,
        setMessageRU,
        setHeadingKZ,
        setHeadingRU,
        time,
        hours,
        setHours,
        setTime,
        type,
        setType,
        handleSendNotification
      }}
    />
  )
}

export default withMainLayout(NotificationsContainer)
