import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import TraditionsArticles from './TraditionsArticles'

const GET_TRADITIONS_ARTICLES = gql`
  query traditionsArticles {
    traditionsArticles {
      id
      order
      title
      textShort
      text
      storiesImageUrls
    }
  }
`
const CREATE_TRADITIONS_ARTICLE = gql`
  mutation createTraditionsArticle($input: TraditionsArticlesInput) {
    createTraditionsArticle(input: $input) {
      id
      order
      title
      textShort
      text
      storiesImageUrls
    }
  }
`
const UPDATE_TRADITIONS_ARTICLE = gql`
  mutation updateTraditionsArticle($input: UpdateTraditionsArticlesInput) {
    updateTraditionsArticle(input: $input) {
      id
      order
      title
      textShort
      text
      storiesImageUrls
    }
  }
`

const DELETE_TRADITIONS_ARTICLE = gql`
  mutation deleteTraditionsArticle($id: ID!) {
    deleteTraditionsArticle(id: $id) {
      id
    }
  }
`

const TraditionsArticlesContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [traditionsArticles, setTraditionsArticles] = useState([])
  const { loading } = useQuery(GET_TRADITIONS_ARTICLES, {
    onCompleted: data => {
      setTraditionsArticles(data.traditionsArticles)
    },
    onError: error => console.log(error)
  })
  const [createTraditionsArticle] = useMutation(CREATE_TRADITIONS_ARTICLE, {
    update(cache, { data: { createTraditionsArticle: newItem } }) {
      const { traditionsArticles } = cache.readQuery({
        query: GET_TRADITIONS_ARTICLES
      })
      const newArray = [newItem, ...traditionsArticles]
      setTraditionsArticles(newArray)
      cache.writeQuery({
        query: GET_TRADITIONS_ARTICLES,
        data: {
          traditionsArticles: newArray
        }
      })
      hideLoading()
      toast.success('Статья традиций добавлена')
    }
  })

  const [updateTraditionsArticle] = useMutation(UPDATE_TRADITIONS_ARTICLE, {
    update(cache, { data: { updateTraditionsArticle: newItem } }) {
      const { traditionsArticles } = cache.readQuery({
        query: GET_TRADITIONS_ARTICLES
      })
      const newArray = [...traditionsArticles]
      const index = traditionsArticles.findIndex(item => item.id === newItem.id)
      if (index !== -1) {
        newArray[index] = newItem
      }
      setTraditionsArticles(newArray)
      cache.writeQuery({
        query: GET_TRADITIONS_ARTICLES,
        data: {
          traditionsArticles: newArray
        }
      })
      hideLoading()
      toast.success('Статья традиций обновлена')
    }
  })

  const [deleteTraditionsArticle] = useMutation(DELETE_TRADITIONS_ARTICLE, {
    onError: error => console.log(error),
    update(cache, { data: { deleteTraditionsArticle: newItem } }) {
      const { traditionsArticles } = cache.readQuery({
        query: GET_TRADITIONS_ARTICLES
      })
      const newArray = [...traditionsArticles]
      const index = traditionsArticles.findIndex(item => item.id === newItem.id)
      if (index !== -1) {
        delete newArray[index]
      }
      setTraditionsArticles(newArray)
      cache.writeQuery({
        query: GET_TRADITIONS_ARTICLES,
        data: {
          traditionsArticles: newArray
        }
      })
      hideLoading()
      toast.success('Статья традиций удалена')
    }
  })

  const createTraditionsArticleHandler = (
    form,
    closeFunc,
    storiesImageFiles
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        createTraditionsArticle({
          variables: {
            input: {
              title: val.title,
              order: val.order,
              textShort: val.textShort,
              text: val.text,
              storiesImageFiles
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updateTraditionsArticleHandler = (
    form,
    articleId,
    closeFunc,
    storiesImageFiles
  ) => {
    form
      .validateFields()
      .then(val => {
        showLoading()
        updateTraditionsArticle({
          variables: {
            input: {
              id: articleId,
              title: val.title,
              order: val.order,
              textShort: val.textShort,
              text: val.text,
              storiesImageFiles
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const deleteTraditionsArticleHandler = (traditionsArticleId, closeFunc) => {
    showLoading()
    deleteTraditionsArticle({
      variables: {
        id: traditionsArticleId
      }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <TraditionsArticles
      traditionsArticles={traditionsArticles}
      createTraditionsArticleHandler={createTraditionsArticleHandler}
      updateTraditionsArticleHandler={updateTraditionsArticleHandler}
      deleteTraditionsArticleHandler={deleteTraditionsArticleHandler}
    />
  )
}

export default WithMainLayout(TraditionsArticlesContainer)
