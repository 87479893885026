import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../context/useLoading'
import Publishers from './Publishers'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'

const GET_PUBLISHERS = gql`
  query statusedPublishers {
    statusedPublishers {
      id
      name
      status
    }
  }
`

const ADD_PUBLISHER = gql`
  mutation addPublisher($input: PublisherInput) {
    addPublisher(input: $input) {
      id
      name
      status
    }
  }
`

const UPDATE_PUBLISHER = gql`
  mutation updatePublisher($id: ID!, $input: UpdatePublisherInput) {
    updatePublisher(id: $id, input: $input) {
      id
      name
      status
    }
  }
`

const DELETE_PUBLISHER = gql`
  mutation updatePublisher($id: ID!, $input: UpdatePublisherInput) {
    updatePublisher(id: $id, input: $input) {
      id
      name
      status
    }
  }
`

// const DELETE_PUBLISHER = gql`
//   mutation deletePublisher($id: ID!) {
//     deletePublisher(id: $id) {
//       id
//       message
//     }
//   }
// `

const PublishersContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allPublishers, setAllPublishers] = React.useState([])
  const { data, loading, error, refetch } = useQuery(GET_PUBLISHERS)
  const [addPublisher, { error: errorAddPublisher }] = useMutation(
    ADD_PUBLISHER,
    {
      update(cache, { data: { addPublisher: addPublisherItem } }) {
        const { statusedPublishers } = cache.readQuery({
          query: GET_PUBLISHERS
        })
        cache.writeQuery({
          query: GET_PUBLISHERS,
          data: {
            statusedPublishers: [addPublisherItem].concat(statusedPublishers)
          }
        })
        hideLoading()
        toast.success('Издатель добавлен')
      }
    }
  )
  const [updatePublisher, { data: updatePublisherData }] = useMutation(
    UPDATE_PUBLISHER
  )
  const [deletePublisher, { error: errorDeletePublisher }] = useMutation(
    DELETE_PUBLISHER,
    {
      update(cache, { data: { updatePublisher: deletePublisherItem } }) {
        const { statusedPublishers } = cache.readQuery({
          query: GET_PUBLISHERS
        })
        cache.writeQuery({
          query: GET_PUBLISHERS,
          data: {
            statusedPublishers: statusedPublishers.filter(
              publisher => publisher.id !== deletePublisherItem.id
            )
          }
        })
        hideLoading()
        toast.success('Издатель удален')
      }
    }
  )

  React.useEffect(() => {
    if (errorDeletePublisher) {
      console.log(errorDeletePublisher)
    }
  }, [errorDeletePublisher])

  React.useEffect(() => {
    if (errorAddPublisher) {
      console.log(errorAddPublisher)
    }
  }, [errorAddPublisher])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setAllPublishers(data.statusedPublishers)
    }
  }, [data, loading, error, updatePublisherData])

  const addPublisherHandler = (form, closeFunc) => {
    // console.log(form)
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addPublisher({
          variables: {
            input: {
              name: val.name,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updatePublisherHandler = (form, publisherId, closeFunc) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updatePublisher({
          variables: {
            id: publisherId,
            input: {
              name: val.name,
              status: val.status
            }
          }
        })
      })
      .then(() => {
        hideLoading()
        closeFunc(false)
        toast.success('Издатель изменен')
      })
  }

  const deletePublisherHandler = (publisherId, closeFunc) => {
    showLoading()
    deletePublisher({
      variables: {
        id: publisherId,
        input: {
          status: 'deleted'
        }
      }
    }).then(() => {
      // hideLoading()
      closeFunc(false)
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Publishers
      publishers={allPublishers}
      addPublisher={addPublisher}
      refetch={refetch}
      addPublisherHandler={addPublisherHandler}
      updatePublisherHandler={updatePublisherHandler}
      deletePublisherHandler={deletePublisherHandler}
    />
  )
}

export default WithMainLayout(PublishersContainer)
