import React from 'react'

import { Form, Input, Select, InputNumber } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDivDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PlaylistsCollectionForm = ({
  form,
  id,
  titleKZ,
  titleRU,
  playlists,
  categories,
  index,
  status,
  allPlaylists,
  allCategories
}) => {
  // console.log('allPlaylists', allPlaylists)
  const FormItem = Form.Item
  return (
    <Form form={form} layout="vertical" hideRequiredMark>
      <FormItem
        key="status"
        label="Статус документа"
        name="status"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
        initialValue={status}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option key="active" value="active">
            Автивный
          </Select.Option>
          <Select.Option key="inactive" value="inactive">
            Скрытый
          </Select.Option>
        </Select>
      </FormItem>
      <FormItem
        key="index"
        label="Очередность документа"
        name="index"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите Очередность документа`
          }
        ]}
        initialValue={index}
      >
        <InputNumber min={1} placeholder="Очередность документа" />
      </FormItem>
      {/* <FormItem
        key="title"
        label="Название"
        name="title"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Плейлиста`
          }
        ]}
        initialValue={title}
      >
        <Input placeholder="Название" />
      </FormItem> */}
      <FormItem
        key="titleKZ"
        label="Название(каз)"
        name="titleKZ"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Коллекции плейлистов на казахском`
          }
        ]}
        initialValue={titleKZ}
      >
        <Input placeholder="Аты" />
      </FormItem>
      <FormItem
        key="titleRU"
        label="Название(рус)"
        name="titleRU"
        rules={[
          {
            required: true,
            message: `Пожалуйста, введите название Коллекции плейлистов на русском`
          }
        ]}
        initialValue={titleRU}
      >
        <Input placeholder="Название" />
      </FormItem>
      <FormItem
        key="categories"
        label="Категории"
        name="categories"
        rules={[
          {
            required: false,
            message: `Пожалуйста, выберите категории`
          }
        ]}
        initialValue={categories?.map(item => (item ? item?.id : item))}
      >
        <Select
          placeholder="Выберите категории"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allCategories?.categories.map(item => (
            <>
              {/* {console.log('item', item)} */}
              <Select.Option key={item.id} value={item.id}>
                {`${item.nameKZ} - ${item.nameRU}`}
              </Select.Option>
            </>
          ))}
        </Select>
      </FormItem>
      <FormItem
        key="playlists"
        label="Плейлисты"
        name="playlists"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите плейлисты`
          }
        ]}
        initialValue={playlists.map(item => (item ? item?.id : item))}
      >
        <Select
          placeholder="Выберите плейлисты"
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allPlaylists.playlists.map(playlist => (
            <Select.Option key={playlist.id} value={playlist.id}>
              {`${playlist.titleKZ} - ${playlist.titleRU}`}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </Form>
  )
}

PlaylistsCollectionForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  titleKZ: PropTypes.string,
  titleRU: PropTypes.string
}

PlaylistsCollectionForm.defaultProps = {
  titleKZ: '',
  titleRU: '',
  playlists: [],
  categories: [],
  index: null,
  status: 'active'
}

export default PlaylistsCollectionForm
