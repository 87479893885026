import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useLoading } from '../../../context/useLoading'
import EducationElements from './EducationElements'
import WithMainLayout from '../../../hocs/withMainLayout'

const GET_EDUCATION_ELEMENTS = gql`
  query statusedEducationElementsByTopic($topicId: ID!) {
    statusedEducationElementsByTopic(topicId: $topicId) {
      topic {
        id
        textKZ
        textRU
      }
      educationElements {
        id
        index
        status
        name
        value
        audioURL
        imageURL
        practiceAudioURL
        practiceImageURL
      }
    }
  }
`

const ADD_EDUCATION_ELEMENT = gql`
  mutation addEducationElement(
    $audioFile: FileUpload!
    $imageFile: FileUpload!
    $practiceAudioFile: FileUpload
    $practiceImageFile: FileUpload
    $input: EducationElementInput
  ) {
    addEducationElement(
      audioFile: $audioFile
      imageFile: $imageFile
      practiceAudioFile: $practiceAudioFile
      practiceImageFile: $practiceImageFile
      input: $input
    ) {
      id
      index
      status
      name
      value
      audioURL
      imageURL
      practiceAudioURL
      practiceImageURL
    }
  }
`

const UPDATE_EDUCATION_ELEMENT = gql`
  mutation updateEducationElement(
    $id: ID!
    $audioFile: FileUpload
    $imageFile: FileUpload
    $practiceAudioFile: FileUpload
    $practiceImageFile: FileUpload
    $input: UpdateEducationElementInput
  ) {
    updateEducationElement(
      id: $id
      audioFile: $audioFile
      imageFile: $imageFile
      practiceAudioFile: $practiceAudioFile
      practiceImageFile: $practiceImageFile
      input: $input
    ) {
      id
      index
      status
      name
      value
      audioURL
      imageURL
      practiceAudioURL
      practiceImageURL
    }
  }
`

const DELETE_EDUCATION_ELEMENT = gql`
  mutation deleteEducationElement($id: ID!) {
    deleteEducationElement(id: $id) {
      id
      message
    }
  }
`

const EducationElementsContainer = () => {
  const { id: topicId } = useParams()
  const { showLoading, hideLoading } = useLoading()
  const [allEducationElements, setAllEducationElements] = useState([])
  const [topic, setTopic] = useState()
  const { data, loading, error, refetch } = useQuery(GET_EDUCATION_ELEMENTS, {
    variables: { topicId }
  })
  const [
    addEducationElement,
    { error: errorAddEducationElement }
  ] = useMutation(ADD_EDUCATION_ELEMENT, {
    update(cache, { data: { addEducationElement: addEducationElementItem } }) {
      const { statusedEducationElementsByTopic } = cache.readQuery({
        query: GET_EDUCATION_ELEMENTS,
        variables: { topicId }
      })
      cache.writeQuery({
        query: GET_EDUCATION_ELEMENTS,
        variables: { topicId },
        data: {
          statusedEducationElementsByTopic: {
            topic: statusedEducationElementsByTopic.topic,
            educationElements: statusedEducationElementsByTopic.educationElements
              .concat(addEducationElementItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        }
      })
      hideLoading()
      toast.success('Слово добавлено')
    }
  })
  const [
    updateEducationElement,
    { error: errorUpdateEducationElement }
  ] = useMutation(UPDATE_EDUCATION_ELEMENT, {
    update(
      cache,
      { data: { updateEducationElement: updateEducationElementItem } }
    ) {
      const { statusedEducationElementsByTopic } = cache.readQuery({
        query: GET_EDUCATION_ELEMENTS,
        variables: { topicId }
      })
      cache.writeQuery({
        query: GET_EDUCATION_ELEMENTS,
        variables: { topicId },
        data: {
          statusedEducationElementsByTopic: {
            topic: statusedEducationElementsByTopic.topic,
            educationElements: statusedEducationElementsByTopic.educationElements
              .filter(i => i.id !== updateEducationElementItem.id)
              .concat(updateEducationElementItem)
              .sort((a, b) =>
                a.index < b.index ? -1 : b.index > a.index ? 1 : 0
              )
          }
        }
      })
      hideLoading()
      toast.success('Слово обновлено')
    }
  })
  const [
    deleteEducationElement,
    { error: errorDeleteEducationElement }
  ] = useMutation(DELETE_EDUCATION_ELEMENT, {
    update(
      cache,
      { data: { deleteEducationElement: deleteEducationElementItem } }
    ) {
      const { statusedEducationElementsByTopic } = cache.readQuery({
        query: GET_EDUCATION_ELEMENTS,
        variables: { topicId }
      })
      cache.writeQuery({
        query: GET_EDUCATION_ELEMENTS,
        variables: { topicId },
        data: {
          statusedEducationElementsByTopic: {
            topic: statusedEducationElementsByTopic.topic,
            educationElements: statusedEducationElementsByTopic.educationElements.filter(
              i => i.id !== deleteEducationElementItem.id
            )
          }
        }
      })
      hideLoading()
      toast.success('Слово удалено')
    }
  })

  React.useEffect(() => {
    if (errorAddEducationElement) {
      console.log(errorAddEducationElement)
    }
  }, [errorAddEducationElement])

  React.useEffect(() => {
    if (errorDeleteEducationElement) {
      console.log(errorDeleteEducationElement)
    }
  }, [errorDeleteEducationElement])

  React.useEffect(() => {
    if (errorUpdateEducationElement) {
      console.log(errorUpdateEducationElement)
    }
  }, [errorUpdateEducationElement])

  React.useEffect(() => {
    if (data && !error && !loading) {
      setTopic(data.statusedEducationElementsByTopic.topic)
      setAllEducationElements(data.statusedEducationElementsByTopic.educationElements)
    }
  }, [data, loading, error])

  const addEducationElementHandler = (
    form,
    closeFunc,
    audioFile,
    imageFile,
    practiceAudioFile,
    practiceImageFile,
    setAudioFile,
    setImageFile,
    setPracticeAudioFile,
    setPracticeImageFile,
    audioFileRef,
    imageFileRef,
    practiceAudioFileRef,
    practiceImageFileRef
  ) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        addEducationElement({
          variables: {
            audioFile: audioFile,
            imageFile: imageFile,
            practiceAudioFile: practiceAudioFile,
            practiceImageFile: practiceImageFile,
            input: {
              index: val.index,
              status: val.status,
              value: val.value,
              name: val.name && val.name?.trim()?.toUpperCase(),
              topic: topicId
            }
          }
        })
      })
      .then(() => {
        setAudioFile(null)
        setImageFile(null)
        setPracticeAudioFile(null)
        setPracticeImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        practiceAudioFileRef.current.value = null
        practiceImageFileRef.current.value = null
        closeFunc(false)
      })
  }

  const updateEducationElementHandler = (
    form,
    educationElementId,
    closeFunc,
    audioFile,
    imageFile,
    practiceAudioFile,
    practiceImageFile,
    setAudioFile,
    setImageFile,
    setPracticeAudioFile,
    setPracticeImageFile,
    audioFileRef,
    imageFileRef,
    practiceAudioFileRef,
    practiceImageFileRef
  ) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateEducationElement({
          variables: {
            id: educationElementId,
            audioFile: audioFile,
            imageFile: imageFile,
            practiceAudioFile: practiceAudioFile,
            practiceImageFile: practiceImageFile,
            input: {
              index: val.index,
              status: val.status,
              value: val.value,
              name: val.name && val.name?.trim()?.toUpperCase(),
              topic: topicId
            }
          }
        })
      })
      .then(() => {
        // hideLoading()
        setAudioFile(null)
        setImageFile(null)
        setPracticeAudioFile(null)
        setPracticeImageFile(null)
        audioFileRef.current.value = null
        imageFileRef.current.value = null
        practiceAudioFileRef.current.value = null
        practiceImageFileRef.current.value = null
        closeFunc(false)
        // toast.success('Буква обновлена')
      })
  }

  const deleteEducationElementHandler = (educationElementId, closeFunc) => {
    showLoading()
    deleteEducationElement({
      variables: {
        id: educationElementId
      }
    }).then(() => {
      closeFunc(false)
    })
  }

  return (
    <EducationElements
      topic={topic}
      educationElements={allEducationElements}
      loading={loading}
      addEducationElement={addEducationElement}
      refetch={refetch}
      addEducationElementHandler={addEducationElementHandler}
      updateEducationElementHandler={updateEducationElementHandler}
      deleteEducationElementHandler={deleteEducationElementHandler}
    />
  )
}

export default WithMainLayout(EducationElementsContainer)
